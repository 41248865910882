/* eslint-disable no-extra-boolean-cast */
import { Box, Group, Skeleton, Text } from '@mantine/core';
import * as React from 'react';
import { StatusBadge } from '../../../statusBadge/statusBadge';
import { formatPriceByCurrency } from '@iverifyng/utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IRequestCardProps } from '../../request/requestCard/requestCard';
import { useDashRequestCardStyle } from './dashRequestCard.style';
import { useStatusText } from '../../../../../hooks/useStatusColor';
dayjs.extend(advancedFormat);

export const DashRequestCard = ({
  active,
  renderIcon,
  request,
  skeleton = false,
  onClick,
}: IRequestCardProps) => {
  const { id, status, name, text, supportingText, price, date, currency } =
    request;
  const statusName = useStatusText(status);
  const { classes } = useDashRequestCardStyle({ active, skeleton });

  return (
    <Group
      px={32}
      py="sm"
      align="center"
      position="apart"
      onClick={() => {
        if (!!onClick) onClick(id);
      }}
      className={classes.base}
    >
      <Group>
        <Box>
          <Skeleton visible={skeleton}>
            <Group spacing="xs">
              {renderIcon}
              <Text weight={600}>{name}</Text>
              <StatusBadge status={status} radius="sm">
                <Text transform="capitalize" size="xs" align="center">
                  {statusName}
                </Text>
              </StatusBadge>
            </Group>

            <Text size="sm">
              {text}
              {text && supportingText ? ' — ' : null}
              {supportingText && (
                <Text
                  component="span"
                  size="sm"
                  sx={(theme) => ({ color: theme.colors.gray[6] })}
                >
                  {supportingText}
                </Text>
              )}
            </Text>
          </Skeleton>
        </Box>
      </Group>
      <Group direction="column" spacing={skeleton ? 5 : 0} position="right">
        <Skeleton visible={skeleton}>
          <Text color="blue" weight={700} align="right">
            {formatPriceByCurrency(price, { notation: 'standard', currency })}
          </Text>
        </Skeleton>
        <Skeleton visible={skeleton}>
          <Text
            size="xs"
            sx={(theme) => ({ color: theme.colors.gray[8] })}
            align="right"
          >
            {dayjs(date).format('Do MMM, YYYY')}
          </Text>
        </Skeleton>
      </Group>
    </Group>
  );
};
