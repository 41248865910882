import { Group, Button, Stack, Box, AccordionItem, Text } from '@mantine/core';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../components/primitives/price/servicecharge';
import { PropertyIcon } from '../../../../../../assets/icons/index';

import { RequestDetailsBlock } from '../../../../../../connected/requestDetails/block';
import RequestDataLayout from '../../../../../../components/layouts/request/requestDataLayout';
import { IPropertyFormProps } from './interface';
import { PDFDownloadButton } from '../../../../../../components/primitives/button/download';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import { WarningAlertCard } from '../../../../../../components/primitives/cards/alerts/alerts';
import { ArrowLeft } from 'tabler-icons-react';

interface IPropertyConfirmationProps {
  data: IPropertyFormProps;
  billed?: IServiceCharge;
  loading?: boolean;
  onBack?: () => void;
  onEdit?: () => void;
  onSubmit?: () => void;
  renderIf?: boolean;
}

export const PropertyFormConfirmation = ({
  data,
  billed,
  onBack,
  loading,
  onSubmit,
  renderIf,
}: IPropertyConfirmationProps) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Property Verification"
          supportingText="Review"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          withBackArrow
          icon={<PropertyIcon width={32} height={32} />}
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            leftIcon={<ArrowLeft size={16} />}
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Go Back
          </Button>
          <Button
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack justify="space-between" sx={() => ({ height: '100%' })}>
        <Box pt={'md'}>
          <Stack px="lg">
            <WarningAlertCard
              title="Please Note!"
              description="Verification may attract extra charges that vary due to several
          factors such as property type, location e.t.c"
            />
            {data.data.map((item, index) => (
              <FormListAccordion
                iconPosition="right"
                initialItem={0}
                multiple
                key={index}
              >
                <AccordionItem
                  label={<FormListAccordionLabel listCount={index + 1} />}
                >
                  <RequestDetailsBlock title="Property details">
                    <RequestDataLayout
                      label="Property Type"
                      data={
                        <Text size="sm" transform="capitalize">
                          {data.data[index].type}
                        </Text>
                      }
                    />
                    <RequestDataLayout
                      label="Property Document"
                      data={
                        <PDFDownloadButton
                          onClick={() =>
                            window.open(data.data[index].documentUrl, '_blank')
                          }
                        >
                          Preview
                        </PDFDownloadButton>
                      }
                    />
                    <RequestDataLayout
                      label="Street"
                      data={data.data[index].street}
                    />
                    <RequestDataLayout
                      label="City"
                      data={data.data[index].city}
                    />
                    <RequestDataLayout
                      label="State"
                      data={data.data[index].state}
                    />
                    <RequestDataLayout
                      label="Country"
                      data={data.data[index].country}
                    />
                  </RequestDetailsBlock>
                </AccordionItem>
              </FormListAccordion>
            ))}
          </Stack>
        </Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Stack>
    </RequestLayout>
  );
};
