import { Box, Button, Group, Stack } from '@mantine/core';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../../components/primitives/price/servicecharge';
import { IPhoneNoRequestProps } from '../phoneNumber';
import { RequestLayout } from '../../../../../../../../components/layouts/request/requestLayout';
import { TelephoneIcon } from '../../../../../../../../assets/icons/index';
import { DataInfo } from '../../../../../../../../components/primitives/details/dataInfo';
import { RequestHeader } from '../../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';

interface IDetailsInfoDisplay {
  data: IPhoneNoRequestProps;
  onEditClick: () => void;
  onBack?: () => void;
  loading?: boolean;
  onSubmit?: () => void;
  renderIf?: boolean;
  billed?: IServiceCharge;
}

export const NGPhoneNoConfirmation = ({
  data,
  onEditClick,
  onSubmit,
  loading,
  billed,
  onBack,
  renderIf,
}: IDetailsInfoDisplay) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Phone Number Verification"
          supportingText="Review"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          withBackArrow
          icon={<TelephoneIcon width={42} height={42} />}
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel request
          </Button>
          <Button
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
            loading={loading}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack justify="space-between" sx={() => ({ height: '100%' })}>
        <Box px="lg">
          <DataInfo
            title="Phone Number"
            description={data.idNumber}
            icon={true}
            onEditClick={onEditClick}
          />
          <DataInfo
            title="Description"
            description={data.description}
            icon={true}
            onEditClick={onEditClick}
          />
        </Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Stack>
    </RequestLayout>
  );
};
