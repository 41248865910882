import {
  Accordion,
  AccordionProps,
  createStyles,
  Group,
  Text,
} from '@mantine/core';

const useFormListStyle = createStyles((theme, _params, getRef) => {
  return {
    item: {
      border: '1px solid #eee',
      borderColor: theme.colors.gray[3],
      borderRadius: theme.radius.md,
      backgroundColor: theme.colors.gray[0],
    },
    control: {
      padding: 14,
    },
    contentInner: {
      backgroundColor: theme.white,
      borderRadius: theme.radius.md,
    },
    icon: {
      margin: 0,
      padding: 0,
      color: theme.colors.gray[7],
      width: 20,
      height: 20,
    },
  };
});

interface IReportFormccordionLabel {
  title?: string;
}

export const ReportAccordionLabel = ({ title }: IReportFormccordionLabel) => {
  return (
    <Group position="apart">
      <Group style={{ flex: 1 }}>
        <Text
          lineClamp={1}
          transform="uppercase"
          size="sm"
          sx={(theme) => ({
            color: theme.colors.gray[6],
          })}
        >
          {title}
        </Text>
      </Group>
    </Group>
  );
};

export const ReportFromAccordion = ({
  children,
  ...props
}: IReportFormccordionLabel & AccordionProps) => {
  const { classes } = useFormListStyle();
  return (
    <Accordion classNames={{ ...classes }} {...props}>
      {children}
    </Accordion>
  );
};
