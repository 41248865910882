import { Menu, Box, Image, createStyles, Tooltip } from '@mantine/core';
import { useCurrentCountry } from '../../../hooks/country';
import countryFlags from '../../../assets/countryflags';
import { useMemo } from 'react';

const useStyles = createStyles((theme) => ({
  ring: {
    borderRadius: '50%',
    border: `2px solid ${theme.colors.gray[1]}`,
    cursor: 'pointer',
    '&:hover': {
      outline: '4px  solid #e1ffe126',
    },
  },
}));

const countries = [
  { label: 'Nigeria', value: 'NG', image: countryFlags.Nigeria },
  { label: 'Ghana', value: 'GH', image: countryFlags.Ghana },
  { label: 'Kenya', value: 'KE', image: countryFlags.Kenya },
  { label: 'Uganda', value: 'UG', image: countryFlags.Uganda },
  { label: 'South Africa', value: 'ZA', image: countryFlags.SouthAfrica },
];

const CountrySelector = () => {
  const { classes } = useStyles();

  const { country, setCountry } = useCurrentCountry();
  const selected = useMemo(() => {
    if (!country) return null;
    return countries.find((option) => option.value === country);
  }, [country]);
  const countryOptions = countries.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={24} height={24} />}
      onClick={() => setCountry(item.value)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));
  return (
    <Menu
      placement="center"
      size="sm"
      control={
        <Tooltip label="Toggle to view services by country">
          <Box className={classes.ring}>
            <Image src={selected?.image} alt="country flag" />
          </Box>
        </Tooltip>
      }
    >
      {countryOptions}
    </Menu>
  );
};

export default CountrySelector;
