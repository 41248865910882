import {
  InputWrapper,
  SimpleGrid,
  Stack,
  TextInput,
  Select,
  Textarea,
  Checkbox,
  Divider,
  Group,
  Button,
} from '@mantine/core';
import { PhoneNumberInput } from '../../../../../../components/controls/phoneInput/phoneInput';
import { useForm, formList } from '@mantine/form';
import { RequestDetailsBlock } from '../../../../../../connected/requestDetails/block';
import { ITenantFormItemProps } from '../interface';
import { TenantGuarantorDto, TenantLandlordDto } from '@iverifyng/dtos';
import { selectIDData } from './tenantForm';
import { useCallback } from 'react';
import { TenantVerificationSchema } from '@iverifyng/validators';
import { showNotification } from '@mantine/notifications';

interface ITenantFormModalProps {
  initialValues: ITenantFormItemProps;
  onClose: () => void;
  onSubmit: (values: ITenantFormItemProps) => void;
}

export const TenantFormModal = ({
  initialValues,
  onClose,
  onSubmit,
}: ITenantFormModalProps) => {
  const form = useForm<ITenantFormItemProps>({
    initialValues: {
      ...initialValues,
      guarantors: formList<TenantGuarantorDto>(initialValues.guarantors),
      landlords: formList<TenantLandlordDto>(initialValues.landlords),
    },
  });

  const handleSubmit = useCallback(
    (values: ITenantFormItemProps) => {
      onSubmit(values);

      onClose();
    },
    [onSubmit, onClose]
  );
  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack spacing={'md'} pb="lg">
        <RequestDetailsBlock title="Tenant's Details">
          <SimpleGrid cols={2} spacing="lg">
            <TextInput
              label="Full Name"
              placeholder="Enter tenant's full name"
              {...form.getInputProps('fullName')}
              required
            />
            <InputWrapper
              size="sm"
              label="Phone Number"
              placeholder="Enter phone number"
              required
            >
              <PhoneNumberInput size="sm" {...form.getInputProps('phoneNo')} />
            </InputWrapper>
            <Select
              label="Means of Identification"
              placeholder="Choose means of identification"
              data={selectIDData}
              {...form.getInputProps('idType')}
              required
            />
            <TextInput
              label={'ID Number'}
              placeholder="Enter ID Number"
              {...form.getInputProps('idNumber')}
              required
            />
            <TextInput
              label="Residential address"
              placeholder="Enter residential address"
              {...form.getInputProps('residentialAddress')}
              required
            />
            <TextInput
              label="Work Address"
              placeholder="Enter work address"
              {...form.getInputProps('workAddress')}
            />

            <TextInput
              label="Spouse Name"
              placeholder="Enter spouse name"
              {...form.getInputProps('spouseName')}
            />

            <Textarea
              label="Reason for relocating"
              rows={2}
              placeholder="State reason for relocating"
              {...form.getInputProps('relocationReason')}
            />

            <Checkbox
              label="Perform Credit Check"
              {...form.getInputProps('performCreditCheck', {
                type: 'checkbox',
              })}
            />
          </SimpleGrid>
          <Divider />
          {initialValues.guarantors.map((guarantor, index) => (
            <RequestDetailsBlock title="Guarantor's Details" key={index}>
              <SimpleGrid cols={2} spacing="md">
                <TextInput
                  label="Full Name"
                  placeholder="Enter guarantor's full name"
                  {...form.getListInputProps('guarantors', index, 'fullname')}
                  required
                />
                <Select
                  label="Means of Identification"
                  placeholder="Choose guarantor's ID"
                  data={selectIDData}
                  {...form.getListInputProps('guarantors', index, 'idType')}
                  required
                />
                <TextInput
                  label={'ID Number'}
                  placeholder="Enter guarantor's ID Number"
                  {...form.getListInputProps('guarantors', index, 'idNumber')}
                  required
                />
                <TextInput
                  label="Residential address"
                  placeholder="Enter guarantor's residential address"
                  {...form.getListInputProps('guarantors', index, 'address')}
                  required
                />
                <TextInput
                  label="Work address"
                  placeholder="Enter guarantor's work address"
                  {...form.getListInputProps(
                    'guarantors',
                    index,
                    'workAddress'
                  )}
                />
                <InputWrapper
                  size="sm"
                  label="Phone Number"
                  placeholder="Enter guarantor's phone number"
                  required
                >
                  <PhoneNumberInput
                    size="sm"
                    {...form.getListInputProps('guarantors', index, 'phoneNo')}
                  />
                </InputWrapper>
                <TextInput
                  label="Relationship"
                  placeholder="Wife/Husband, Father, Mother etc."
                  {...form.getListInputProps(
                    'guarantors',
                    index,
                    'relationship'
                  )}
                  required
                />
              </SimpleGrid>
            </RequestDetailsBlock>
          ))}
        </RequestDetailsBlock>

        <Divider />
        {initialValues.landlords.map((landlord, index) => (
          <RequestDetailsBlock title="Landlord's Details" key={index}>
            <SimpleGrid cols={2} spacing="md">
              <TextInput
                label="Full Name"
                placeholder="Enter landlord's full name"
                {...form.getListInputProps('landlords', index, 'fullname')}
                required
              />
              <TextInput
                label="Residential address"
                placeholder="Enter landlord's residential address"
                {...form.getListInputProps('landlords', index, 'address')}
                required
              />
              <InputWrapper
                size="sm"
                label="Phone Number"
                placeholder="Enter landlord's phone number"
                required
              >
                <PhoneNumberInput
                  size="sm"
                  {...form.getListInputProps('landlords', index, 'phoneNo')}
                />
              </InputWrapper>
            </SimpleGrid>
          </RequestDetailsBlock>
        ))}
        <Group position="right" spacing={'xs'}>
          <Button variant="outline" color={'dark'} onClick={onClose} px={30}>
            Close
          </Button>
          <Button px={30} type="submit">
            confirm
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
