import { createStyles } from '@mantine/core';

export const useTabStyle = createStyles((theme) => ({
  tabControl: {
    backgroundColor: theme.colors.gray[0],
    color: theme.colors.gray[6],
    border: `1px solid ${theme.colors.gray[0]}`,
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    padding: `4px 12px`,
    height: 32,
    margin: '4px',
    borderRadius: '4px',
    textTransform: 'capitalize',

    '&:hover': {
      color: theme.colors.blue[6],
      border: `1px solid ${theme.colors.blue[3]}`,
    },
  },

  tabActive: {
    fontWeight: 600,
    backgroundColor: theme.colors.blue[0],
    borderColor: theme.colors.blue[0],
    color: theme.colors.blue[5],
    '&:hover': {
      borderColor: theme.colors.blue[0],
    },
  },
}));

export const useServiceSelectStyle = createStyles((theme) => ({
  unstyledWrapper: {
    width: 300,
    '&:hover': {
      borderRadius: 4,
      background: theme.colors.gray[1],
    },
  },
}));
