import * as Joi from 'joi';
import { PhoneNumberValidator } from './helpers';
export const UserValidatorSchema = {
  UPDATE_PROFILE: Joi.object({
    firstName: Joi.string().optional().label('First Name'),
    lastName: Joi.string().optional().label('Last Name'),
    phoneNo: Joi.string()
      .allow(null, '')
      .custom(PhoneNumberValidator)
      .optional()
      .label('Phone Number'),
    notify_new_request: Joi.boolean().optional(),
    notify_new_activity: Joi.boolean().optional(),
    notify_weekly_newsletter: Joi.boolean().optional(),
    notify_news_updates: Joi.boolean().optional(),
  }),
  RESET_PASSWORD: Joi.object({
    newPassword: Joi.string().min(6).required().label('New Password'),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref('newPassword'))
      .label('Password Confirmation')
      .messages({
        'any.only': 'Password Confirmation must match',
      }),
  }),
};
