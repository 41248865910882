
import { ITenantCSVDataType } from "./interface.";

export const getTenantDetailsFromCSV = (data: ITenantCSVDataType) => {
    if (data?.driversLicence)
        return { type: 'DRIVERS_LICENSE', number: data?.driversLicence };
    if (data?.nin) return { type: 'NIN', number: data?.nin };
    if (data?.votersId) return { type: 'VOTER_ID', number: data?.votersId };
    return { type: '', number: '' };

}

export const getTenantGuarantorDetailsFromCSV = (data: ITenantCSVDataType) => {
    if (data?.guarantor_driversLicence)
        return { type: 'DRIVERS_LICENSE', number: data?.guarantor_driversLicence };
    if (data?.guarantor_nin)
        return { type: 'NIN', number: data?.guarantor_nin };
    if (data?.guarantor_votersId)
        return { type: 'VOTER_ID', number: data?.guarantor_votersId };

    return { type: '', number: '' };
}
