import { PreviousWorkVerificationStatusEnum } from '@prisma/client';
import * as Joi from 'joi';
import { PhoneNumberValidator } from './helpers';


const SINGLE_PREVIOUS_WORK_SCHEMA = {
    fullName: Joi.string().required().label('Full name'),
    phoneNo: Joi.string()
        .custom(PhoneNumberValidator)
        .required()
        .label("Employee's Phone Number"),

    previousWorkAddress: Joi.string().required().label('Previous work Address'),
    companyName: Joi.string().required().label("Previous Company' Name")

};
const NEW_VERIFICATION_SCHEMA = Joi.object({
    data: Joi.array()
        .items(Joi.object(SINGLE_PREVIOUS_WORK_SCHEMA))
        .min(1)
        .required()
        .label('Previous work data'),
});
const UPDATE_VERIFICATION_SCHEMA = Joi.object({
    ...SINGLE_PREVIOUS_WORK_SCHEMA,
    id: Joi.string().required().label('ID'),
    requestId: Joi.string().optional().label('Request ID'),
}).fork(
    [
        'fullName',
        'phoneNo',
        'companyName',
        'previousWorkAddress',
    ],
    (schema) => schema.optional()
);
const ADMIN_UPDATE_REPORT = Joi.object({
    previousWorkId: Joi.string().required().label('Previou Work ID'),
    reportUrl: Joi.string().uri().required().label('Report URL'),
});

const ADMIN_UPDATE_PREVIOUS_WORK = Joi.object({
    ...SINGLE_PREVIOUS_WORK_SCHEMA,
    id: Joi.string().required().label('ID'),
    requestId: Joi.string().optional().label('Request ID'),
    status: Joi.string()
        .valid(...Object.values(PreviousWorkVerificationStatusEnum))
        .optional()
        .label('Status'),
}).fork(
    [
        'fullName',
        'phoneNo',
        'companyName',
        'previousWorkAddress',

    ],
    (schema) => schema.optional()
);

export const PreviousWorkVerificationSchema = {
    NEW_VERIFICATION_SCHEMA,
    UPDATE_VERIFICATION_SCHEMA,
    ADMIN_UPDATE_REPORT,
    ADMIN_UPDATE_PREVIOUS_WORK,
};
