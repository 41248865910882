import { Paper, Group, Button, TextInput } from '@mantine/core';
import { AuthLayout } from '../../../components/layouts/auth';

import { ReVerifyEmailDto } from '../../../dto/auth';
import { useForm, joiResolver } from '@mantine/form';
import { AuthValidatorSchema } from '@iverifyng/validators';
import { useCallback, useMemo } from 'react';
import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';
import { PreAuthCheckApi } from '../../../services/apis/users';
import { PreAuthCheckDto } from '@iverifyng/services/auth';

export default function ReVerifyEmail() {
  const form = useForm<ReVerifyEmailDto>({
    schema: joiResolver(AuthValidatorSchema.PRE_AUTH_CHECK),
    initialValues: {
      email: '',
    },
  });

  const preAuthCheckMutation = useMutation((values: PreAuthCheckDto) =>
    PreAuthCheckApi(values)
  );

  const handleSubmit = useCallback(
    (values: ReVerifyEmailDto) => {
      //check if user already exists
      preAuthCheckMutation.mutate(
        { email: values.email },
        {
          onSuccess: (response) => {
            if (response.data.deactivated) {
              showNotification({
                message:
                  'Unfortunately, login access has been disabled on this account. Please contact support.',
                color: 'red',
              });
              return;
            }
            if (!response.data.exists) {
              showNotification({
                message: 'Found no account linked to the provided email.',
                color: 'red',
              });
              return;
            }
            console.log('email resend button clicked', values);

            //       resendMutation.mutate(values);
          },
        }
      );
    },
    [preAuthCheckMutation]
  );

  const isResending = useMemo(() => {
    return preAuthCheckMutation.isLoading;
  }, [preAuthCheckMutation.isLoading]);

  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Group direction="column" grow>
            <TextInput
              label="Email"
              placeholder="Email"
              {...form.getInputProps('email')}
            />
          </Group>

          <Group position="apart" mt="xl">
            <Button
              type="submit"
              fullWidth
              loading={isResending}
              disabled={isResending}
            >
              Resend
            </Button>
          </Group>
        </form>
      </Paper>
    </AuthLayout>
  );
}
