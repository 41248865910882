import React from 'react';
import { Text, Group, InputWrapper, Anchor } from '@mantine/core';
import { Download } from 'tabler-icons-react';
import { FileUploader } from '../../../../components/controls/fileuploader/fileuploader';
import { MIME_TYPES } from '@iverifyng/utils';
import PapaParse from 'papaparse';
import { IEmployeeCSVDataType } from './interface';
import { NewEmployeeVerificationDto } from '@iverifyng/dtos';
import {
  getGuarantor1IdDetailsFromCSV,
  getGuarantor2IdDetailsFromCSV,
  getIdDetailsFromCSV,
} from './util';

interface IMultipleEmployeeModal {
  closeModal: () => void;
  onFileParsed: (values: Array<NewEmployeeVerificationDto>) => void;
}

export const MultipleEmployeeModal = ({
  closeModal,
  onFileParsed,
}: IMultipleEmployeeModal) => {
  return (
    <InputWrapper
      label={
        <Group position="apart" sx={() => ({ flex: 1 })}>
          <InputWrapper
            label="Upload CSV Document"
            description="Only CSV file allowed. Download sample csv →"
            required
            children={null}
          />
          <Anchor
            href="https://storage.googleapis.com/iverify-public/forms/csv/multiple-employee-verification.csv"
            target="_blank"
            download={true}
          >
            <Group spacing={2}>
              <Download width={16} height={16} />
              <Text size="sm">Download format</Text>
            </Group>
          </Anchor>
        </Group>
      }
      styles={{ label: { display: 'flex' } }}
    >
      <FileUploader
        accept={[MIME_TYPES.csv]}
        instantUpload={false}
        onFileSelect={(file) => {
          PapaParse.parse<IEmployeeCSVDataType>(file, {
            header: true,
            complete: (results, file) => {
              console.log('results', results);
              const mapping: Array<NewEmployeeVerificationDto> =
                results.data.map((value) => {
                  const { type: idType, number: idNumber } =
                    getIdDetailsFromCSV(value);
                  const { type: guarantor1IDType, number: guarantor1IDNumber } =
                    getGuarantor1IdDetailsFromCSV(value);
                  const { type: guarantor2IDType, number: guarantor3IDNumber } =
                    getGuarantor2IdDetailsFromCSV(value);
                  return {
                    fullName: value?.fullName ?? '',
                    otherName: value?.otherName ?? '',
                    bvn: value?.bvn ?? '',
                    phoneNo: value?.phoneNo ?? '',
                    residentialAddress: value?.residentialAddress ?? '',
                    workAddress: value?.workAddress ?? '',
                    previousWorkName: value?.previousWorkName ?? '',
                    previousWorkAddress: value?.previousWorkAddress ?? '',
                    idNumber,
                    idType,
                    guarantors: [
                      {
                        fullname: value?.guarantor1_name ?? '',
                        address: value?.guarantor1_address ?? '',
                        workAddress: '',
                        phoneNo: value?.guarantor1_phoneno ?? '',
                        relationship: value.guarantor1_relationship ?? '',
                        idNumber: guarantor1IDNumber,
                        idType: guarantor1IDType,
                      },
                      {
                        fullname: value?.guarantor2_name ?? '',
                        address: value?.guarantor2_address ?? '',
                        workAddress: '',
                        phoneNo: value?.guarantor2_phoneno ?? '',
                        relationship: value?.guarantor2_relationship ?? '',
                        idNumber: guarantor3IDNumber,
                        idType: guarantor2IDType,
                      },
                    ],
                  };
                });
              onFileParsed(mapping);
            },
          });
          closeModal();
        }}
      />
    </InputWrapper>
  );
};
