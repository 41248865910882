import { IUniversity, FindUniversityDto } from '@iverifyng/utils';
import axiosInstance from '../axios';
import qs from 'query-string';

export const ExternalFindUniverstiesApi = async (
  query: FindUniversityDto
): Promise<Array<IUniversity>> => {
  const url = qs.stringifyUrl({
    url: '/external/findUniversities',
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};
