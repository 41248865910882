import { MIME_TYPES } from '@iverifyng/utils';
import { Alert, Group, InputWrapper, Stack } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IdentityRequest } from '@prisma/client';
import { FileUploader } from '../../../../components/controls/fileuploader/fileuploader';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { AdminUpdateIdentityReportUrlApi } from '../../../../services/apis/identity';

interface IProps {
  record?: IdentityRequest;
  closeModal?: () => void;
}

export const AdminChangeIdentityReportModal = ({
  record,
  closeModal,
}: IProps) => {
  const updateRecord = useMutation(AdminUpdateIdentityReportUrlApi);
  const handleSubmit = useCallback(
    (reportUrl: string) => {
      if (!record) return;

      showNotification({
        id: 'updatereport',
        color: 'blue',
        message: 'Processing request...',
        loading: true,
      });
      updateRecord.mutate(
        { identityId: record.id, reportUrl },
        {
          onSuccess: () => {
            updateNotification({
              id: 'updatereport',
              color: 'green',
              message: 'Record updated',
              loading: false,
            });
            if (closeModal) closeModal();
          },
          onError: (error) => {
            updateNotification({
              id: 'updatereport',
              color: 'red',
              loading: false,
              message:
                (error as AxiosError<{ message: string }>).response?.data
                  .message ?? 'Error occurred',
            });
          },
        }
      );
    },
    [closeModal, record, updateRecord]
  );

  return (
    <Group spacing={'md'} direction="column" grow>
      <Stack>
        <Alert>
          Use this feature to update the report attached to this request. The
          uploaded report will be sent to the customer via email as an update.
        </Alert>
        <InputWrapper label="Completed Report">
          <FileUploader
            mode={updateRecord?.isLoading ? 'read' : 'edit'}
            accept={[MIME_TYPES.pdf]}
            instantUpload={true}
            onSuccess={(url) => {
              handleSubmit(url);
            }}
          />
        </InputWrapper>
      </Stack>
    </Group>
  );
};
