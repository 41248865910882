import {
  Button,
  Group,
  InputWrapper,
  Select,
  SimpleGrid,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { PhoneNumberInput } from '../../../../components/controls/phoneInput/phoneInput';
import { EmployeeGuarantorDto } from '@iverifyng/dtos';
import { EmployeeVerificationSchema } from '@iverifyng/validators';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { RequestDetailsBlock } from '../../../requestDetails/block';
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';

interface IGuarantorFormProps {
  formInitialValue: EmployeeGuarantorDto;
  onBack: () => void;
  renderIf: boolean;
  onSubmit: (values: EmployeeGuarantorDto) => void;
}

export const GuarantorModal = ({
  formInitialValue,
  onBack,
  renderIf,
  onSubmit,
}: IGuarantorFormProps) => {
  const form = useForm<EmployeeGuarantorDto>({
    // schema: joiResolver(EmployeeVerificationSchema.SINGLE_GUARANTOR_SCHEMA),
    initialValues: {
      fullname: formInitialValue?.fullname,
      address: formInitialValue?.address,
      workAddress: formInitialValue?.workAddress,
      relationship: formInitialValue?.relationship,
      phoneNo: formInitialValue?.phoneNo,
      idNumber: formInitialValue?.idNumber,
      idType: formInitialValue?.idType,
    },
  });
  // console.log('formInitialValue', formInitialValue);
  // console.log('form values === ', form.values);
  useEffect(() => {
    //if (formInitialValue) form.setValues(formInitialValue);
  }, [form, formInitialValue]);
  const handleSubmit = (values: EmployeeGuarantorDto) => {
    const { error } =
      EmployeeVerificationSchema.SINGLE_GUARANTOR_SCHEMA.validate(values);
    if (error) {
      console.log('error', error);
      showNotification({ message: error.message, color: 'red' });
      return;
    }
    onSubmit(values);
    form.reset();
  };

  const idType = form.values?.idType;
  if (!renderIf) return null;
  return (
    <>
      <Title order={4} mb="md">
        GUARANTOR DETAILS
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack spacing={'sm'}>
          <RequestDetailsBlock title="Basic Info" spacing={'xs'}>
            <TextInput
              required
              label="Full Name"
              placeholder="Guarantor's first and last name"
              size="md"
              {...form.getInputProps('fullname')}
            />
            <SimpleGrid cols={2} spacing="md">
              <InputWrapper size="md" label="Phone Number" required>
                <PhoneNumberInput
                  size="md"
                  {...form.getInputProps('phoneNo')}
                />
              </InputWrapper>
              <TextInput
                required
                size="md"
                label="Relationship"
                placeholder="Relationship with employee"
                {...form.getInputProps('relationship')}
              />
            </SimpleGrid>
          </RequestDetailsBlock>
          <RequestDetailsBlock title="Identification" spacing={'xs'}>
            <SimpleGrid cols={2} spacing="md">
              <Select
                label="Means of Identification"
                size="md"
                required
                placeholder="Choose means of identification"
                {...form.getInputProps('idType')}
                data={[
                  {
                    label: 'National Identity Number (NIN)',
                    value: 'NIN',
                  },
                  {
                    label: 'Drivers License',
                    value: 'DRIVERS_LICENSE',
                  },
                  {
                    label: 'Voters Card',
                    value: 'VOTER_ID',
                  },
                ]}
              />
              <TextInput
                required
                label="ID Number"
                placeholder="Provide ID number"
                size="md"
                disabled={!idType}
                {...form.getInputProps('idNumber')}
              />
              {idType === 'DRIVERS_LICENSE' && (
                <DatePicker
                  required
                  label="Date of Birth"
                  size="md"
                  placeholder="Select date of birth"
                  inputFormat="YYYY-MM-DD"
                  initialMonth={dayjs().subtract(15, 'years').toDate()}
                  maxDate={dayjs().subtract(15, 'years').toDate()}
                  defaultValue={dayjs().subtract(15, 'years').toDate()}
                  {...form.getInputProps('dob')}
                />
              )}
            </SimpleGrid>
          </RequestDetailsBlock>
          <RequestDetailsBlock title="Address" spacing={'xs'}>
            <SimpleGrid cols={2} spacing="md">
              <TextInput
                required
                label="Residential Address"
                placeholder="Guarantor's residential or work address"
                size="md"
                {...form.getInputProps('address')}
              />
              <TextInput
                label="Work Address"
                placeholder="Guarantor's work address"
                size="md"
                {...form.getInputProps('workAddress')}
              />
            </SimpleGrid>
          </RequestDetailsBlock>

          <Group position="right" spacing={'xs'} mt={20}>
            <Button variant="outline" color={'dark'} onClick={onBack}>
              Go Back
            </Button>
            <Button px={30} type="submit">
              Submit Details
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};
