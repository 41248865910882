import { useMatch } from '@tanstack/react-location';
import { AgentsView } from '../../../components/agents/agents';
import { WorkspaceGenerics } from '../workspaceDetail';

export const WorkspaceAgents = () => {
  const {
    params: { detailId },
  } = useMatch<WorkspaceGenerics>();

  return <AgentsView requestId={detailId} />;
};
