import { showNotification, updateNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { AdminUpdateServiceApi } from '../apis/admin';

export const useAdminServicesUpdateMtn = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(AdminUpdateServiceApi, {
    onMutate: () => {
      showNotification({
        id: 'updateservice',
        color: 'blue',
        title: 'Service Update',
        message: 'Submitting request...',
      });
    },
    onSuccess: () => {
      updateNotification({
        id: 'updateservice',
        message: 'Service Updated',
      });
      queryClient.invalidateQueries('adminServices');
    },
    onError: (error) => {
      updateNotification({
        id: 'updateservice',
        color: 'red',
        message:
          (error as AxiosError<{ message: string }>).response?.data.message ??
          'Error occurred',
      });
    },
  });

  return mutation;
};
