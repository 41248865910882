import { createStyles } from '@mantine/core';

export const useAdminCardStyle = createStyles(
  (theme, { active, skeleton }: { active?: boolean; skeleton?: boolean }) => ({
    base: {
      border: `1px solid ${theme.colors.gray[0]}`,
      borderRadius: '0px',
      cursor: 'pointer',
      backgroundColor: active ? theme.colors.blue[0] : theme.white,
      boxShadow: '0px 3px 15px rgba(16, 24, 40, 0.05)',
      '&:hover': {
        backgroundColor: !skeleton ? theme.colors.gray[0] : 'none',
        outline: !skeleton ? ` 2px  solid ${theme.colors.gray[1]}` : 'none',
      },
    },
  })
);
