/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Joi from 'joi';
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';

export const PhoneNumberValidator = (
  value: any,
  helpers: Joi.CustomHelpers<any>
) => {
  if (value) {
    const isValid =
      isPossiblePhoneNumber(value, { defaultCountry: 'NG' }) ||
      isValidPhoneNumber(value, { defaultCountry: 'NG' });
    if (!isValid) {
      return helpers.message({
        'any.invalid': 'Invalid phone number',
        custom: 'Invalid phone number',
      });
    }
  }
  return value;
};
