import { GetUserResponseDto, UpdateUserRequestDto } from '@iverifyng/dtos';
import { UserValidatorSchema } from '@iverifyng/validators';
import {
  Button,
  Group,
  Stack,
  Text,
  Title,
  Box,
  // Image,
  TextInput,
  Loader,
  InputWrapper,
} from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateUserProfile } from '../../services/apis/users';
import { useUserProfile } from '../../services/queries/users';
import { PhoneNumberInput } from '../controls/phoneInput/phoneInput';

const ProfileForm = ({ user }: { user: GetUserResponseDto }) => {
  const queryClient = useQueryClient();
  const updateProfile = useMutation(UpdateUserProfile, {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Profile updated',
        message: 'Your profile has been updated successfully.',
      });
      queryClient.invalidateQueries('profile');
    },
  });
  const form = useForm<UpdateUserRequestDto>({
    schema: joiResolver(UserValidatorSchema.UPDATE_PROFILE),
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNo: user?.phoneNo || '',
    },
  });

  const onSubmit = React.useCallback(() => {
    const data = form.values;

    updateProfile.mutate({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNo: data.phoneNo ?? null,
    });
  }, [form.values, updateProfile]);
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack
        spacing={'xl'}
        sx={() => ({
          width: '100%',
        })}
      >
        <div>
          <Title order={2}>Profile</Title>
          <Text component="span" weight={500} color="gray">
            Update your photo and personal details.
          </Text>
        </div>
        <Stack spacing="md">
          <Group direction="row" grow align={'flex-start'}>
            <TextInput
              label="First Name"
              placeholder="First Name"
              size="md"
              {...form.getInputProps('firstName')}
            />
            <TextInput
              label="Last Name"
              placeholder="Last Name"
              size="md"
              {...form.getInputProps('lastName')}
            />
          </Group>
          <TextInput
            label="Email"
            placeholder="abc@gmail.com"
            value={user?.email}
            disabled
            size="md"
          />
          <InputWrapper
            size="md"
            label="Phone Number"
            placeholder="Enter your phone number"
          >
            <PhoneNumberInput size="md" {...form.getInputProps('phoneNo')} />
          </InputWrapper>
        </Stack>
        <Box>
          <Button
            type="submit"
            fullWidth={false}
            loading={updateProfile.isLoading}
            disabled={updateProfile.isLoading}
          >
            Save Changes
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export const Profile = () => {
  const { data: user, isLoading } = useUserProfile();

  if (isLoading) return <Loader variant="dots" color="white" />;
  return <ProfileForm user={user!} />;
};
