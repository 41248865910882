import { useCurrentCountry } from '../../../hooks/country';
import { useMemo } from 'react';
import { NGPhoneNumberForm } from '../routes/ng/identityVerification/subRoutes/phoneNumber/phoneNumber';

export const SharedPhoneNumber = () => {
  const { country } = useCurrentCountry();

  const container = useMemo(() => {
    switch (country) {
      case 'NG':
        return <NGPhoneNumberForm />;
      default:
        return null;
    }
  }, [country]);

  return container;
};
