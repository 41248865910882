import { getNameInitials } from '@iverifyng/utils';
import { Avatar, Group, Skeleton, Stack, Text } from '@mantine/core';
import { User } from '@prisma/client';
import React from 'react';

interface IAgentCardProps {
  user: Partial<User>;
  imageUrl?: string;
  skeleton?: boolean;
  comment?: string | null;
  renderRightSection?: (agentID: string) => React.ReactNode;
}

export const AgentCard = ({
  user,
  imageUrl,
  renderRightSection,
  skeleton = false,
  comment,
}: IAgentCardProps) => {
  return (
    <Stack pl={skeleton ? 20 : 0} spacing={'xs'}>
      <Group position="apart" pr={0}>
        <Group
          sx={() => ({
            flex: 1,
          })}
        >
          <Skeleton
            circle={true}
            visible={skeleton}
            p={0}
            m={0}
            height={40}
            width={40}
          >
            {!!imageUrl ||
              (user.firstName && (
                <Avatar
                  src={imageUrl}
                  alt={user.firstName!}
                  radius="xl"
                  color="indigo"
                >
                  {getNameInitials({
                    name: `${user.firstName} ${user.lastName}`,
                    maxInitials: 2,
                  })}
                </Avatar>
              ))}
          </Skeleton>
          <Stack spacing={skeleton ? 'xs' : 0}>
            <Skeleton visible={skeleton}>
              <Text size="sm" weight={500}>
                {`${user.firstName}  ${user.lastName}`}
              </Text>
            </Skeleton>
            <Skeleton visible={skeleton}>
              <Text
                size="sm"
                sx={(theme) => ({
                  color: theme.colors.gray[7],
                })}
              >
                {user.email}
              </Text>
            </Skeleton>
          </Stack>
        </Group>

        {!!renderRightSection && <>{renderRightSection(user.id!)}</>}
      </Group>
      {comment && (
        <Text size="sm" color="gray">
          <strong>Comment: </strong>
          <i>{comment}</i>
        </Text>
      )}
    </Stack>
  );
};
