import { GetUserResponseDto } from '@iverifyng/dtos';
import { ActionIcon, Group, Menu } from '@mantine/core';
import { useUserProfile } from '../../../services/queries/users';
import React, { useCallback } from 'react';
import { DotsVertical } from 'tabler-icons-react';
import { useMutation, useQueryClient } from 'react-query';
import { useModals } from '@mantine/modals';
import { AdminEditUserDetailModal } from '../../modals/admin/adminEditUserDetailModal';
import {
  AdminDeactivateUser,
  AdminReactivateUser,
} from '../../../services/apis/admin';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useAdminUsers } from '../../../hooks/admin-users';

interface IUserProps {
  user: GetUserResponseDto;
}

export const AdminUserDotMenu = ({ user }: IUserProps) => {
  const { isAdmin, isAgent } = useUserProfile();
  const modals = useModals();
  const { queryKey } = useAdminUsers({});

  const queryClient = useQueryClient();
  const refreshUserDetails = useCallback(
    (userId: string) => {
      queryClient.invalidateQueries(['adminUserDetail', userId]);
      queryClient.invalidateQueries(queryKey);
    },
    [queryClient, queryKey]
  );

  const deactivateUser = useMutation(AdminDeactivateUser);
  const reactivateUser = useMutation(AdminReactivateUser);

  const deactivateText = user.deactivate ? 'Reactivate' : 'Deactivate';

  const onConfirm = useCallback(
    (deactivated: boolean) => {
      if (deactivated) {
        return reactivateUser.mutate(user.id, {
          onSuccess: () => {
            showNotification({
              color: 'blue',
              message: 'User reactivated',
            });
            refreshUserDetails(user.id);
            modals.closeModal('reactivate-user-modal');
          },
          onError: (error) => {
            showNotification({
              color: 'blue',
              message:
                (error as AxiosError<{ message: string }>).response?.data
                  .message ?? 'Error occurred',
            });
          },
        });
      }
      return deactivateUser.mutate(user.id, {
        onSuccess: () => {
          showNotification({
            color: 'blue',
            message: 'User deactivated',
          });
          refreshUserDetails(user.id);
          modals.closeModal('deactivate-user-modal');
        },
        onError: (error) => {
          showNotification({
            color: 'blue',
            message:
              (error as AxiosError<{ message: string }>).response?.data
                .message ?? 'Error occurred',
          });
        },
      });
    },
    [deactivateUser, modals, reactivateUser, refreshUserDetails, user.id]
  );

  return (
    <Group spacing={0} position="right">
      <Menu
        transition="pop"
        withArrow
        placement="end"
        onClick={(event) => event.stopPropagation()}
        control={
          <ActionIcon>
            <DotsVertical color="grey" size={16} />
          </ActionIcon>
        }
      >
        <Menu.Item
          disabled={!isAdmin}
          onClick={() =>
            modals.openModal({
              id: 'update-user-details',
              title: 'Update User Details',
              children: (
                <AdminEditUserDetailModal
                  user={user}
                  closeModal={() => {
                    modals.closeModal('update-user-details');
                    refreshUserDetails(user.id);
                  }}
                />
              ),
            })
          }
        >
          Edit details
        </Menu.Item>
        <Menu.Item
          color={user.deactivate ? 'green' : 'red'}
          disabled={deactivateUser.isLoading || reactivateUser.isLoading}
          onClick={() =>
            modals.openConfirmModal({
              id: 'deactivate-user-modal',
              title: `${deactivateText} User`,
              children: `Are you sure you want to ${deactivateText} this user?`,
              labels: { confirm: 'Confirm', cancel: 'Cancel' },
              onCancel: () => modals.closeModal('deactivate-user-modal'),
              onConfirm: () => {
                onConfirm(user.deactivate!);
              },
            })
          }
        >
          {deactivateText} User
        </Menu.Item>
      </Menu>
    </Group>
  );
};
