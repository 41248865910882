import {
  Badge,
  Button,
  Center,
  Group,
  Loader,
  Paper,
  ScrollArea,
  Table,
  Text,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Workspace } from '@prisma/client';
import { DeleteAdminWorkspaceMember } from '../../../services/apis/admin';
import { useGetAdminWorkspaceMembers } from '../../../hooks/admin-workspace';
import EmptyState from '../../../components/primitives/emptyState/emptyState';
import { UserDetailZeroIcon } from '../../../assets/icons';
import { useUserProfile } from '../../../services/queries/users';

interface IAdminDeleteWorkspaceMemberModal {
  workspace: Workspace;
  closeModal?: () => void;
}

export const AdminDeleteWorkspaceMemberModal = ({
  workspace,
  closeModal,
}: IAdminDeleteWorkspaceMemberModal) => {
  const queryClient = useQueryClient();
  const { data: currentUser } = useUserProfile();

  const deleteWorkspaceMember = useMutation(DeleteAdminWorkspaceMember);
  const {
    data: workspaceMembers,
    isLoading,
    queryKey,
  } = useGetAdminWorkspaceMembers(workspace.id);

  const handleDeleteWorkspaceMember = useCallback(
    (memberId: string) => {
      deleteWorkspaceMember.mutate(
        { memberId, WorkspaceId: workspace.id },
        {
          onSuccess: () => {
            showNotification({
              color: 'blue',
              message: 'Workspace member deleted',
            });
            queryClient.invalidateQueries(queryKey);
            if (closeModal) closeModal();
          },
          onError: (error) => {
            showNotification({
              color: 'blue',
              message:
                (error as AxiosError<{ message: string }>).response?.data
                  .message ?? 'Error occurred',
            });
          },
        }
      );
    },
    [closeModal, deleteWorkspaceMember, queryClient, queryKey, workspace.id]
  );

  const isEmpty = useMemo(() => {
    if (isLoading) return false;
    if (!workspaceMembers || !workspaceMembers.length) return true;
    return false;
  }, [isLoading, workspaceMembers]);

  return (
    <Group spacing={'md'} direction="column" grow>
      <Paper shadow="xs" withBorder={true} radius="md" mt="md">
        {isLoading ? (
          <Center py="lg">
            <Loader />
          </Center>
        ) : isEmpty ? (
          <Center style={{ height: '100%' }} py="lg">
            <EmptyState
              title="No members"
              subTitle="There are no workspace members available."
              icon={<UserDetailZeroIcon />}
            />
          </Center>
        ) : (
          <ScrollArea>
            <Table
              horizontalSpacing="xl"
              verticalSpacing="sm"
              highlightOnHover
              style={{ maxHeight: 350 }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th style={{ width: 100 }}></th>
                </tr>
              </thead>
              <tbody>
                {workspaceMembers.map((member) => (
                  <tr key={member.id}>
                    <td>
                      <Text size="sm" weight={500}>
                        {`${member.user.firstName} ${member.user.lastName} `}
                      </Text>
                    </td>
                    <td>
                      <Text size="sm" weight={500} color="gray">
                        {member.user.email}
                      </Text>
                    </td>
                    <td>
                      <Badge color="gray">
                        <Text
                          transform="capitalize"
                          size="xs"
                          align="center"
                          color="gray"
                        >
                          {member.mainRole}
                        </Text>
                      </Badge>
                    </td>
                    <td>
                      {member.userId === currentUser?.id ||
                      member.mainRole === 'OWNER' ? null : (
                        <Button
                          color="red"
                          variant="subtle"
                          size="xs"
                          onClick={() =>
                            handleDeleteWorkspaceMember(member.userId)
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
        )}
      </Paper>

      <Group position="right" mt={'md'}>
        <Button
          type="submit"
          disabled={deleteWorkspaceMember.isLoading}
          loading={deleteWorkspaceMember.isLoading}
        >
          Save Changes
        </Button>
      </Group>
    </Group>
  );
};
