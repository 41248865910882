import {
  PropertyVerificationRecordDto,
  UpdatePropertyVerificationDto,
} from '@iverifyng/dtos';
import { PropertyVerificationSchema } from '@iverifyng/validators';
import { Button, Group, InputWrapper, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification, updateNotification } from '@mantine/notifications';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { PropertyStatusEnum } from '@prisma/client';
import { FileUploader } from '../../../../components/controls/fileuploader/fileuploader';
import { MIME_TYPES } from '@iverifyng/utils';
import { AxiosError } from 'axios';
import {
  AdminUpdatePropertyApi,
  AdminUpdatePropertyReportApi,
} from '../../../../services/apis/property';

interface IProps {
  record?: PropertyVerificationRecordDto;
  closeModal?: () => void;
}
export const AdminPropertyStatusModal = ({ record, closeModal }: IProps) => {
  const form = useForm<UpdatePropertyVerificationDto>({
    initialValues: {
      status: record?.status,
    },
  });
  const updateRecord = useMutation(AdminUpdatePropertyApi);
  const handleSubmit = useCallback(
    (values: UpdatePropertyVerificationDto) => {
      const payload = { ...values, id: record?.id };
      const { error } =
        PropertyVerificationSchema.ADMIN_UPDATE_PROPERTY.validate(payload);
      if (error) {
        showNotification({ color: 'red', message: error.message });
        return;
      }

      showNotification({
        id: 'updatestatus',
        color: 'blue',
        message: 'Processing request...',
        loading: true,
      });
      updateRecord.mutate(payload, {
        onSuccess: () => {
          updateNotification({
            id: 'updatestatus',
            color: 'green',
            message: 'Status updated',
          });
          if (closeModal) closeModal();
        },
        onError: (error) => {
          updateNotification({
            id: 'updatestatus',
            color: 'red',
            message:
              (error as AxiosError<{ message: string }>).response?.data
                .message ?? 'Error occurred',
          });
        },
      });
    },
    [closeModal, record?.id, updateRecord]
  );

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group spacing={'md'} direction="column" grow>
        <Select
          required
          label="Status"
          data={Object.values(PropertyStatusEnum).map((value) => ({
            label: value,
            value,
          }))}
          {...form.getInputProps('status')}
        />
        <Group position="right" mt={'md'}>
          <Button variant="default" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={updateRecord.isLoading}
            loading={updateRecord.isLoading}
          >
            Submit
          </Button>
        </Group>
      </Group>
    </form>
  );
};

export const AdminPropertyReportUploadModal = ({
  record,
  closeModal,
}: IProps) => {
  const updateRecord = useMutation(AdminUpdatePropertyReportApi);
  const handleSubmit = useCallback(
    (reportUrl: string) => {
      if (!record) return;

      showNotification({
        id: 'updatereport',
        color: 'blue',
        message: 'Processing request...',
        loading: true,
      });
      updateRecord.mutate(
        { reportUrl: reportUrl, propertyId: record?.id },
        {
          onSuccess: () => {
            updateNotification({
              id: 'updatereport',
              color: 'green',
              message: 'Record updated',
              loading: false,
            });
            if (closeModal) closeModal();
          },
          onError: (error) => {
            updateNotification({
              id: 'updatereport',
              color: 'red',
              loading: false,
              message:
                (error as AxiosError<{ message: string }>).response?.data
                  .message ?? 'Error occurred',
            });
          },
        }
      );
    },
    [closeModal, record, updateRecord]
  );

  return (
    <Group spacing={'md'} direction="column" grow>
      <InputWrapper label="Completed Report">
        <FileUploader
          accept={[MIME_TYPES.pdf]}
          instantUpload={true}
          onSuccess={(url) => {
            handleSubmit(url);
          }}
        />
      </InputWrapper>
    </Group>
  );
};
