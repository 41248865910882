import { Stack, Text, Group } from '@mantine/core';
import { MultipleAddressIcon, SingleAddressIcon } from 'apps/client/src/assets/icons';
import { useState } from 'react';
import { VerificationType } from '../../../../../../components/primitives/cards/employee/employeeType';


 const verificationTypes = [
    { icon: <SingleAddressIcon />, text: 'Single', value: 'single' },
    { icon: <MultipleAddressIcon />, text: 'Multiple', value: 'multiple' },
  ];

interface IAddressSelectionType {
    onSingleActive: () => void;
    onMultipleActive: () => void;
    renderIf?: boolean;
}
export const AddressSelectType = ({onSingleActive, onMultipleActive, renderIf}: IAddressSelectionType) => {
  const [active, setActive] = useState('');
  
  if (!renderIf) return null;
  return (
    <Stack spacing="xl" p="24px">
      <Text size="sm" sx={(theme) => ({ color: theme.colors['gray'][7] })}>
        Verify any address by filling the
        details below.
      </Text>
      <Text weight={'bolder'}>
        How many verifications are you looking to conduct?
      </Text>
      <Group position="apart">
        <Group position="apart">
          {verificationTypes.map((type, index) => (
            <VerificationType
              key={index}
              icon={type.icon}
              actionText={type.text}
              isActive={active === type.value}
              onClick={() => {
                setActive(type.value);

                if (type.value === 'single') {
                onSingleActive()
                }

                if (type.value === 'multiple') {
                    onMultipleActive()
                }
              }}
            />
          ))}
        </Group>
      </Group>
    </Stack>
  );
};
