import { Group, Text } from '@mantine/core';
import { ReactNode } from 'react';

interface IRequestDataLayout {
  label: string;
  data: ReactNode;
}

const RequestDataLayout = ({ label, data }: IRequestDataLayout) => {
  return (
    <Group position="apart" spacing={5}>
      <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[7] })}>
        {label}
      </Text>
      <Text
        size="sm"
        weight={500}
        sx={(theme) => ({ color: theme.colors.gray[9] })}
      >
        {data}
      </Text>
    </Group>
  );
};

export default RequestDataLayout;
