import * as React from 'react';
import { joiResolver, useForm } from '@mantine/form';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../hooks/request';
import { useCurrentCountry } from '../../../../../../hooks/country';
import { ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../hooks/workspace';
import { NINFormData } from '../../../ng/identityVerification/subRoutes/nin/containers/ninForm';
import { GHVotersCardForm } from './components/votersCardForm';
import { GHVotersCardDetailsConfirmation } from './components/votersCardDetailsConfirmation';
import { showNotification } from '@mantine/notifications';
import { useConsentConfirmation } from '../../../../../../hooks/consent';
import { debounce } from 'lodash';

export type IssuedTimelineType = 'beforeJune2020' | 'afterJune2020';

export const GHVotersCard = () => {
  const [verify, setVerify] = React.useState(true);
  const { country } = useCurrentCountry();
  const [issuedTimeline, setIssuedTimeLine] =
    React.useState<IssuedTimelineType | null>(null);

  const form = useForm<NINFormData>({
    initialValues: {
      idNumber: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.GH_VOTER_ID),
  });

  const { showConsent } = useConsentConfirmation();
  const createIdentity = useCreateIdentityVerification();
  const { workspaceId } = useCurrentWorkspace();
  const { data: serviceCharge } = useServiceCharge({
    code: 'VOTER_ID',
    country: country! as ServiceCountry,
  });

  const handleVerify = () => {
    if (!issuedTimeline) {
      return showNotification({
        message: 'Please select when your card was issued',
        color: 'red',
      });
    } else {
      setVerify(false);
    }
  };

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;

        if (issuedTimeline === 'afterJune2020') {
          return createIdentity.mutate({
            code: 'NEW_VOTER_ID',
            country: country! as ServiceCountry,
            workspaceId: workspaceId!,
            params: {
              idNumber: data.idNumber,
              description: data.description,
            },
          });
        }

        return createIdentity.mutate({
          code: 'VOTER_ID',
          country: country! as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            description: data.description,
          },
        });
      },
    });
  }, [
    country,
    createIdentity,
    form.values,
    issuedTimeline,
    showConsent,
    workspaceId,
  ]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <GHVotersCardForm
        {...{ form }}
        onSelect={setIssuedTimeLine}
        renderIf={verify}
      />
      <GHVotersCardDetailsConfirmation
        requestDetails={form.values.idNumber}
        requestDescription={form.values.description}
        onEditClick={() => setVerify(true)}
        price={serviceCharge!}
        renderIf={!verify}
        onSubmit={handleDoubleClick}
        isSubmitting={createIdentity.isLoading}
      />
    </form>
  );
};
