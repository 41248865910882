import { RequestBodySkeleton } from '../../../../components/primitives/skeletons/requestDetail';
import { Box, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { formatPriceByCurrency } from '@iverifyng/utils';
import { PaymentStatus } from '../../../../components/primitives/statusBadge/statusBadge';
import dayjs from 'dayjs';
import { RequestDetailsBlock } from '../../../../connected/requestDetails/block';
import { GetRequestResponseDto } from '@iverifyng/dtos';

interface IAdminRequestPaymentDetailsProps {
  request?: GetRequestResponseDto;
  isLoading?: boolean;
  error?: unknown;
}
export const AdminRequestPaymentDetailsView = ({
  request,
  error,
  isLoading,
}: IAdminRequestPaymentDetailsProps) => {
  if (!isLoading && !request) return null;
  if (isLoading || error) return <RequestBodySkeleton skeleton={true} />;
  return (
    <Box
      style={{
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <Stack px={'xl'} sx={() => ({ overflow: 'scroll', height: '100%' })}>
        <RequestDataLayout
          label="Transaction Ref."
          data={request?.payment.trxRef}
        />
        {request && request.payment.status ? (
          <RequestDataLayout
            label="Status"
            data={<PaymentStatus status={request.payment.status!} />}
          />
        ) : null}
        <RequestDataLayout
          label="Service Cost"
          data={formatPriceByCurrency(Number(request?.service.costAmount), {
            currency: request?.service.costCurrency,
          })}
        />
        <RequestDataLayout
          label="Total Cost"
          data={formatPriceByCurrency(Number(request?.payment.amount), {
            currency: request?.payment.currency,
          })}
        />
        <RequestDataLayout label="Quantity" data={request?.payment.quantity} />
        <RequestDataLayout label="Provider" data={request?.payment.provider} />

        <RequestDetailsBlock title="Other Information">
          <RequestDataLayout
            label="No. of Retry"
            data={request?.payment.numRetries}
          />

          <RequestDataLayout label="Payment ID" data={request?.payment.id} />
          <RequestDataLayout
            label="Created At"
            data={dayjs(request?.payment.createdAt).format(
              'Do MMM, YYYY HH:mm A'
            )}
          />
          <RequestDataLayout
            label="Last Updated"
            data={dayjs(request?.payment.updatedAt).format(
              'Do MMM, YYYY HH:mm A'
            )}
          />
        </RequestDetailsBlock>
      </Stack>
    </Box>
  );
};
