/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import * as React from 'react';
import { Box } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { useLocation } from '@tanstack/react-location';

import { GH_DriversLicenseRequestDto, ServiceCodeTypes } from '@iverifyng/dtos';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import { useCurrentWorkspace } from '../../../../../../hooks/workspace';
import { useCurrentCountry } from '../../../../../../hooks/country';
import { ServiceCountry } from '@prisma/client';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../hooks/request';
import { GHDLDetailsConfirmation } from './components/DLDetailsConfirmation';
import { GHDLRequestForm } from './components/DLRequestForm';
import { useConsentConfirmation } from '../../../../../../hooks/consent';
import { debounce } from 'lodash';

type DriverLicensePage = 'form' | 'verify';
export interface GHDLFormData extends GH_DriversLicenseRequestDto {
  description: string;
}

export const GHDriversLicense = () => {
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const [page, setPage] = React.useState<DriverLicensePage>('form');
  const { history } = useLocation();
  const { showConsent } = useConsentConfirmation();

  const form = useForm<GHDLFormData>({
    initialValues: {
      idNumber: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.GH_DRIVERS_LICENSE),
  });

  const driverLicenseRequest = useCreateIdentityVerification();
  const { data: serviceCharge } = useServiceCharge({
    code: 'DRIVERS_LICENSE' as ServiceCodeTypes,
    country: country! as ServiceCountry,
  });

  const handleVerify = React.useCallback(() => {
    setPage('verify');
  }, []);

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        driverLicenseRequest.mutate({
          code: 'DRIVERS_LICENSE' as ServiceCodeTypes,
          country: country as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            description: data.description,
          },
        });
      },
    });
  }, [showConsent, form.values, driverLicenseRequest, country, workspaceId]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  const isLoading = React.useMemo(() => {
    return driverLicenseRequest.isLoading;
  }, [driverLicenseRequest.isLoading]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <Box sx={() => ({ height: '100%' })}>
        <GHDLRequestForm
          {...{ form }}
          onBack={() => history.back()}
          renderIf={page === 'form'}
        />

        <GHDLDetailsConfirmation
          loading={isLoading}
          {...{ form }}
          onSubmit={handleDoubleClick}
          data={form.values}
          onEditClick={() => setPage('form')}
          billed={{
            currency: serviceCharge?.currency!,
            amount: serviceCharge?.amount!,
          }}
          onBack={() => setPage('form')}
          renderIf={page === 'verify'}
        />
      </Box>
    </form>
  );
};
