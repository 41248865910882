import { useQuery } from 'react-query';
import {
  AdminGetWorkspaceStatsApi,
  GetAnalyticsAreaRequests,
  GetAnalyticsAreaServiceTypes,
  GetAnalyticsPayments,
  GetAnalyticsPieRequests,
  GetAnalyticsPieServiceTypes,
  GetAnalyticsUserGrowth,
  GetCompletedPayment,
  IAnalyticsUserQueryDto,
} from '../services/apis/analytics';

export const useAnalyticsUserGrowth = ({
  ...params
}: IAnalyticsUserQueryDto) => {
  const queryKey = ['adminUsersGrowth', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAnalyticsUserGrowth({ ...params })
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useCompletedPayment = ({ ...params }: IAnalyticsUserQueryDto) => {
  const queryKey = ['adminCompletedPayment', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetCompletedPayment({ ...params })
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};
export const useAnalyticsPayments = ({ ...params }: IAnalyticsUserQueryDto) => {
  const queryKey = ['analyticsPayments', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAnalyticsPayments({ ...params })
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useAnalyticsAreaRequests = ({
  ...params
}: IAnalyticsUserQueryDto) => {
  const queryKey = ['analyticsAreaRequests', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAnalyticsAreaRequests({ ...params })
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useAnalyticsPieRequests = ({
  ...params
}: IAnalyticsUserQueryDto) => {
  const queryKey = ['analyticsPieRequests', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAnalyticsPieRequests({ ...params })
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useAnalyticsAreaServiceTypes = ({
  ...params
}: IAnalyticsUserQueryDto) => {
  const queryKey = ['analyticsAreaServices', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAnalyticsAreaServiceTypes({ ...params })
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useAnalyticsPieServiceTypes = ({
  ...params
}: IAnalyticsUserQueryDto) => {
  const queryKey = ['analyticsPieServices', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAnalyticsPieServiceTypes({ ...params })
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useAdminWorkspaceStatsQuery = (workspaceId: string) => {
  const queryKey = ['admin-workspace-stats', workspaceId];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => AdminGetWorkspaceStatsApi(workspaceId),
    { enabled: !!workspaceId }
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};
