import * as Joi from 'joi';

//BEGIN NIGERIA
const NG_NINSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{11}$/)
    .required()
    .label('NIN'),
  description: Joi.string().allow(null, '').optional(),
  //showSlip: Joi.boolean().label('Show Slip'),
});

const NG_PhoneNoSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{11}$/)
    .required()
    .label('Phone Number'),
  description: Joi.string().allow(null, '').optional(),
  //showSlip: Joi.boolean().label('Show Slip'),
});

const NG_DriversLicenseSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$/i)
    .required()
    .label('License Number'),
  firstName: Joi.string().required().label('First Name'),
  lastName: Joi.string().required().label('Last Name'),
  dob: Joi.date().iso().required().label('Date of Birth'),
  description: Joi.string().allow('').optional(),
  //showSlip: Joi.boolean().label('Show Slip'),
});

const NG_VoterIDSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[a-zA-Z0-9 ]{9,19}$/i)
    .required()
    .label('Voter ID'),
  description: Joi.string().allow(null, '').optional(),
});

const NG_CACSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{5,8}$/)
    .required()
    .label('RC Number'),
  company: Joi.string().required().label('Company'),
  description: Joi.string().allow(null, '').optional(),
});

const NG_TINSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{8,}-[0-9]{4,}$/)
    .required()
    .label('TIN'),
  description: Joi.string().allow(null, '').optional(),
});
//END NIGERIA

//BEGIN GHANA
const GH_DriversLicenseSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[a-zA-Z0-9]{6,10}$/i)
    .required()
    .label('License Number'),
  description: Joi.string().allow('').optional(),
});
const GH_PassportSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^G[a-zA-Z0-9]{7,9}$/i)
    .required()
    .label('Passport Number'),
  description: Joi.string().allow('').optional(),
});
const GH_SSNITSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[a-zA-Z]{1}[a-zA-Z0-9]{12,14}$/i)
    .required()
    .label('SSNIT Number'),
  description: Joi.string().allow('').optional(),
});
const GH_VoterIDSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{10,12}$/)
    .required()
    .label("Voter's ID Number"),
  description: Joi.string().allow('').optional(),
});
const GH_NewVoterIDSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{10,12}$/)
    .required()
    .label("Voter's ID Number"),
  description: Joi.string().allow('').optional(),
});
//END GHANA

//BEGIN KENYA
const KE_AlienCardSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{6,9}$/)
    .required()
    .label('Alien Card Number'),
  description: Joi.string().allow('').optional(),
});
const KE_NationalIDSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{1,9}$/)
    .required()
    .label('National ID Number'),
  description: Joi.string().allow('').optional(),
});
const KE_PassportSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[A-Z0-9]{7,9}$/)
    .required()
    .label('National ID Number'),
  description: Joi.string().allow('').optional(),
});
//END KENYA

//BEGIN SOUTH_AFRICA
const ZA_NationalIdSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[0-9]{13}$/)
    .required()
    .label('National identity number'),
  description: Joi.string().allow(null, '').optional(),
});
//END SOUTH_AFRICA

//BEGIN UGANDA
const UG_NationalIdSchema = Joi.object({
  idNumber: Joi.string()
    .regex(/^[A-Z0-9]{14}$/i)
    .required()
    .label('National identity number'),
  description: Joi.string().allow(null, '').optional(),
});
//END UGANDA

const UPDATE_IDENTITY_REPORT = Joi.object({
  reportUrl: Joi.string().uri().required().label('Report URL'),
});

//all schemas
export const IdentityValidatorSchema = {
  NEW_REQUEST: Joi.object({
    country: Joi.string()
      .valid(
        ...Object.values({
          NG: 'NG',
          GH: 'GH',
          KE: 'KE',
          ZA: 'ZA',
          UG: 'UG',
          ALL_COUNTRIES: 'ALL_COUNTRIES',
        })
      )
      .required()
      .label('Service Country'),
    code: Joi.string().required().label('Service Code'),
    workspaceId: Joi.string().required().label('Workspace'),
    params: Joi.object()
      .when('code', {
        switch: [
          { is: 'NIN', then: NG_NINSchema },
          { is: 'NIN_SLIP', then: NG_NINSchema },
          { is: 'PHONE_NUMBER', then: NG_PhoneNoSchema },
          { is: 'CAC', then: NG_CACSchema },
          { is: 'TIN', then: NG_TINSchema },
          { is: 'SSNIT', then: GH_SSNITSchema },
          { is: 'NEW_VOTER_ID', then: GH_NewVoterIDSchema },
          { is: 'ALIEN_CARD', then: KE_AlienCardSchema },
          {
            is: 'DRIVERS_LICENSE',
            then: Joi.object().when('country', {
              switch: [
                { is: 'NG', then: NG_DriversLicenseSchema },
                { is: 'GH', then: GH_DriversLicenseSchema },
              ],
            }),
          },
          {
            is: 'PASSPORT',
            then: Joi.object().when('country', {
              switch: [
                { is: 'GH', then: GH_PassportSchema },
                { is: 'KE', then: KE_PassportSchema },
              ],
            }),
          },
          {
            is: 'VOTER_ID',
            then: Joi.object().when('country', {
              switch: [
                { is: 'NG', then: NG_VoterIDSchema },
                { is: 'GH', then: GH_VoterIDSchema },
              ],
            }),
          },
          {
            is: 'NATIONAL_ID',
            then: Joi.object().when('country', {
              switch: [
                { is: 'KE', then: KE_NationalIDSchema },
                { is: 'ZA', then: ZA_NationalIdSchema },
              ],
            }),
          },
          {
            is: 'NATIONAL_ID_NO_PHOTO',
            then: Joi.object().when('country', {
              switch: [
                { is: 'ZA', then: ZA_NationalIdSchema },
                { is: 'UG', then: UG_NationalIdSchema },
              ],
            }),
          },
        ],
        otherwise: Joi.object().optional(),
      })
      .label('Parameters'),
  }),
  //Nigeria
  NIN: NG_NINSchema,
  NIN_SLIP: NG_NINSchema,
  PHONE_NUMBER: NG_PhoneNoSchema,
  DRIVERS_LICENSE: NG_DriversLicenseSchema,
  VOTER_ID: NG_VoterIDSchema,
  CAC: NG_CACSchema,
  TIN: NG_TINSchema,

  //Ghana
  GH_DRIVERS_LICENSE: GH_DriversLicenseSchema,
  GH_PASSPORT: GH_PassportSchema,
  GH_SSNIT: GH_SSNITSchema,
  GH_VOTER_ID: GH_VoterIDSchema,
  GH_NEW_VOTER_ID: GH_NewVoterIDSchema,

  //Kenya
  KE_ALIEN_CARD: KE_AlienCardSchema,
  KE_NATIONAL_ID: KE_NationalIDSchema,
  KE_PASSPORT: KE_PassportSchema,

  //South Africa
  ZA_NATIONAL_ID: ZA_NationalIdSchema,
  ZA_NATIONAL_ID_NO_PHOTO: ZA_NationalIdSchema,

  //Uganda
  UG_NATIONAL_ID_NO_PHOTO: UG_NationalIdSchema,

  UPDATE_IDENTITY_REPORT,
};
