import { IProps } from './newCriminalCheck';
import { Divider, Stack } from '@mantine/core';
import { useNavigate } from '@tanstack/react-location';
import { RequestLayout } from '../../../..//src/components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../src/components/primitives/cards/request/requestHeader/requestHeader';
import { CommonRequestDetails } from '../../requestDetails/commonInfo';
import { RequestDetailsBlock } from '../../requestDetails/block';
import { GuarantorAccordionItem } from '../../requestDetails/guarantor/accordionItem';
import { GuarantorIcon } from '../../../assets/icons';

export const GuarantorDetailsView = ({
  data,
  renderIf,
  footerNode,
}: IProps) => {
  const navigate = useNavigate();
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Guarantor Verification"
          withClose
          withBackground
          icon={<GuarantorIcon width={42} height={42} />}
          onCancel={() => navigate({ to: '/request' })}
        />
      }
      footerFragment={footerNode}
    >
      <Stack pt="sm" px="lg">
        <CommonRequestDetails {...data} />
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <RequestDetailsBlock title="Request Details">
          {data.guarantors.map((check, index) => (
            <GuarantorAccordionItem key={index} record={check} index={index} />
          ))}
        </RequestDetailsBlock>
      </Stack>
    </RequestLayout>
  );
};
