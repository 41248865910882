import { ReactComponent as CACIcon } from '../svg/cac-icon.svg';
import { ReactComponent as CertificateIcon } from '../svg/certificate-icon.svg';
import { ReactComponent as CreditIcon } from '../svg/creditcheck.svg';
import { ReactComponent as CriminalIcon } from '../svg/criminal-icon.svg';
import { ReactComponent as DriverIcon } from '../svg/drivers-icon.svg';
import { ReactComponent as EmployeeIcon } from '../svg/employee-icon.svg';
import { ReactComponent as GuarantorIcon } from '../svg/guarantor-icon.svg';
import { ReactComponent as IdentityIcon } from '../svg/identity-icon.svg';
import { ReactComponent as PaymentCashIcon } from '../svg/payments-cash.svg';
import { ReactComponent as PropertyIcon } from '../svg/property-icon.svg';
import { ReactComponent as StarCheckIcon } from '../svg/star-check.svg';
import { ReactComponent as TenantIcon } from '../svg/tenant-icon.svg';
import { ReactComponent as VoterIcon } from '../svg/voters-icon.svg';
import { ReactComponent as NINIcon } from '../svg/nin-icon.svg';
import { ReactComponent as NotFound } from '../svg/not-found.svg';
import { ReactComponent as Edit } from '../svg/edit-3.svg';
import { ReactComponent as CompanyIcon } from '../svg/company-icon.svg';
import { ReactComponent as TINIcon } from '../svg/tin-icon.svg';
import { ReactComponent as AlienCardIcon } from '../svg/alienCard-icon.svg';
import { ReactComponent as PassportIcon } from '../svg/passport-icon.svg';
import { ReactComponent as SSNITIcon } from '../svg/ssnit-icon.svg';
import { ReactComponent as NationalIdIcon } from '../svg/nationalId-icon.svg';
import { ReactComponent as RequestZeroIcon } from '../svg/requestZero-icon.svg';
import { ReactComponent as AgentZeroIcon } from '../svg/agentsZero-icon.svg';
import { ReactComponent as AdminDetailZeroIcon } from '../svg/adminDetailZero-icon.svg';
import { ReactComponent as CommentZeroIcon } from '../svg/commentZero-icon.svg';
import { ReactComponent as ResponseZeroIcon } from '../svg/responseZero-icon.svg';
import { ReactComponent as DashboardZeroIcon } from '../svg/dashboardZero-icon.svg';
import { ReactComponent as TransactionZeroIcon } from '../svg/transactionZero-icon.svg';
import { ReactComponent as NewCriminalCheckIcon } from '../svg/new-criminal-check.svg';
import { ReactComponent as ValidateCriminalCheckIcon } from '../svg/validate-criminal-check.svg';
import { ReactComponent as PDFIcon } from '../svg/pdfIcon.svg';
import { ReactComponent as SingleEmloyeeIcon } from '../svg/singleEmployeeIcon.svg';
import { ReactComponent as MutlipleEmployeeIcon } from '../svg/multipleEmployeeIcon.svg';
import { ReactComponent as TelephoneIcon } from '../svg/telephone.svg';
import { ReactComponent as CreditCheckIndividualIcon } from '../svg/creditcheck-individual.svg';
import { ReactComponent as CreditCheckCommercialIcon } from '../svg/creditcheck-commercial.svg';
import { ReactComponent as PreviousWorkAddressIcon } from '../svg/previous-work-icon.svg';
import { ReactComponent as SingleAddressIcon } from '../svg/single-address-icon.svg';
import { ReactComponent as MultipleAddressIcon } from '../svg/multiple-address-icon.svg';

export {
  CACIcon,
  CertificateIcon,
  CreditIcon,
  CriminalIcon,
  DriverIcon,
  EmployeeIcon,
  GuarantorIcon,
  IdentityIcon,
  PaymentCashIcon,
  PropertyIcon,
  StarCheckIcon,
  TenantIcon,
  VoterIcon,
  NINIcon,
  NotFound,
  Edit,
  CompanyIcon,
  TINIcon,
  AlienCardIcon,
  PassportIcon,
  SSNITIcon,
  NationalIdIcon,
  ResponseZeroIcon,
  RequestZeroIcon,
  TransactionZeroIcon,
  DashboardZeroIcon,
  NewCriminalCheckIcon,
  ValidateCriminalCheckIcon,
  PDFIcon,
  AgentZeroIcon,
  SingleEmloyeeIcon,
  MutlipleEmployeeIcon,
  CommentZeroIcon,
  AdminDetailZeroIcon,
  TelephoneIcon,
  CreditCheckIndividualIcon,
  CreditCheckCommercialIcon,
  PreviousWorkAddressIcon,
  SingleAddressIcon,
  MultipleAddressIcon
};
