import { CreateWorkspaceRequestDto } from '@iverifyng/dtos';
import { WorkspaceValidatorSchema } from '@iverifyng/validators';
import { Button, Group, Radio, RadioGroup, TextInput } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { showNotification, updateNotification } from '@mantine/notifications';
import { CreateWorkspaceApi } from '../../../services/apis/workspace';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useCurrentWorkspace } from '../../../hooks/workspace';

interface IProps {
  closeModal?: () => void;
}
export const CreateWorkspaceForm = ({ closeModal }: IProps) => {
  const { setWorkspaceId } = useCurrentWorkspace();
  const form = useForm<CreateWorkspaceRequestDto>({
    schema: joiResolver(WorkspaceValidatorSchema.CREATE_WORKSPACE),
    initialValues: {
      name: '',
      type: 'PERSONAL',
    },
  });

  const createWorkspace = useMutation(CreateWorkspaceApi);

  const handleSubmit = useCallback(
    (values: CreateWorkspaceRequestDto) => {
      showNotification({
        id: 'create-workspace',
        message: 'Creating workspace',
        loading: true,
      });
      createWorkspace.mutate(values, {
        onSuccess: (response) => {
          updateNotification({
            id: 'create-workspace',
            message: 'Workspace created. Redirecting...',
          });
          setWorkspaceId(response.id);
          window.location.href = '/dashboard';
        },
        onError: (error, unknown) => {
          updateNotification({
            id: 'create-workspace',
            message: (error as AxiosError<{ message: string }>).response?.data
              .message,
          });
        },
      });
    },
    [createWorkspace, setWorkspaceId]
  );

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group spacing={'md'} direction="column" grow>
        <TextInput
          required
          label="Workspace name"
          description="What's your space called?"
          {...form.getInputProps('name')}
        />

        <RadioGroup
          label="Workspace type"
          description="Choose between Personal and Corporate workspaces"
          {...form.getInputProps('type')}
        >
          <Radio value="PERSONAL" label="Personal" size="sm" />
          <Radio value="CORPORATE" label="Corporate" size="sm" />
        </RadioGroup>
        <Group position="right" mt={'md'}>
          <Button variant="default" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={createWorkspace.isLoading}
            loading={createWorkspace.isLoading}
          >
            Submit
          </Button>
        </Group>
      </Group>
    </form>
  );
};
