import { Avatar, Center, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { GHVotersResponseDto } from '../../gh/interface';

export const GHVotersCardReport = (props: GHVotersResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.Picture ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64,${props.Picture}`}
            radius="xl"
            size="lg"
            alt=""
          />
        </Center>
      ) : null}
      <RequestDataLayout label="Surname" data={props.Surname ?? 'N/A'} />
      <RequestDataLayout label="Other names" data={props.Othernames ?? 'N/A'} />
      <RequestDataLayout label="Full name" data={props.Fullname ?? 'N/A'} />
      <RequestDataLayout label="Voter ID" data={props.VoterId ?? 'N/A'} />
      <RequestDataLayout label="Age" data={props.Age ?? 'N/A'} />
      <RequestDataLayout label="Gender" data={props.Gender ?? 'N/A'} />
      <RequestDataLayout label="Reg. Date" data={props.RegDate ?? 'N/A'} />
      <RequestDataLayout
        label="Date of Birth"
        data={props.DateOfBirth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Polling Station"
        data={props.PollingStation ?? 'N/A'}
      />
      <RequestDataLayout
        label="Residential Address"
        data={props.ResidentialAddress ?? 'N/A'}
      />
    </Stack>
  );
};
