import { Stack, Text, Group, TextInput, Textarea, Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { TelephoneIcon } from '../../../../../../../../assets/icons';
import { IPhoneNoRequestProps } from '../phoneNumber';
import { RequestLayout } from '../../../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';

interface IPhoneNoRequestForm {
  onBack?: () => void;
  form: UseFormReturnType<IPhoneNoRequestProps>;
  renderIf?: boolean;
}

export const NGPhoneNoRequestForm = ({
  form,
  onBack,
  renderIf,
}: IPhoneNoRequestForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Phone Number Verification"
          supportingText=""
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<TelephoneIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Stack justify="space-between" py="md" sx={() => ({ minHeight: '100%' })}>
        <Group direction="column" px="lg" grow spacing="md">
          <Text color="gray" size="sm">
            Get full name, address, gender, and residential address related to a
            phone number.
          </Text>
          <TextInput
            required
            label="Phone Number"
            placeholder="Enter phone number"
            {...form.getInputProps('idNumber')}
          />

          <Textarea
            label="Description"
            autosize
            minRows={2}
            {...form.getInputProps('description')}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
