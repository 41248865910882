import { createStyles } from '@mantine/core';

export const useUserCardStyle = createStyles(
  (theme, { active, skeleton }: { active?: boolean; skeleton?: boolean }) => ({
    base: {
      cursor: 'pointer',
      backgroundColor: active ? theme.colors.blue[0] : theme.white,
      '&:hover': {
        backgroundColor: !skeleton ? theme.colors.gray[0] : 'none',
        outline: !skeleton ? ` 1px  solid ${theme.colors.gray[1]}` : 'none',
      },
    },
  })
);
