import {
  GetAdminPaymentsQueryDto,
  GetReceiptUrlResponseDto,
  GetUserPaymentsResponseDto,
  RetryPaymentResponseDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';
import qs from 'query-string';

//get all payments for current user in workspace
export const GetUserPaymentsByWorkspaceApi = async (
  params: { workspaceId: string } & GetAdminPaymentsQueryDto
): Promise<GetUserPaymentsResponseDto> => {
  const { workspaceId, ...queryParams } = params;
  const url = qs.stringifyUrl({
    url: `/payments/user/workspace/${workspaceId}`,
    query: { ...queryParams },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

//get receipt by id
export const GetReceiptUrlApi = async (
  paymentId: string
): Promise<GetReceiptUrlResponseDto> => {
  return await axiosInstance
    .get(`/payments/getreceipt/${paymentId}`)
    .then((response) => response.data);
};

export const AdminGetPaymentsApi = async (
  params: GetAdminPaymentsQueryDto
): Promise<GetUserPaymentsResponseDto> => {
  const url = qs.stringifyUrl({
    url: '/admin/payments',
    query: { ...params },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

export const RetryPaymentApi = async (params: {
  trxRef: string;
}): Promise<RetryPaymentResponseDto> => {
  return await axiosInstance
    .post(`/payments/retry/${params.trxRef}`)
    .then((response) => response.data);
};
