import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { IValidateCriminalFormList } from './validateCriminalCheckForm';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';
import { AccordionItem, Box, Button, Group, Stack, Text } from '@mantine/core';
import {
  PDFIcon,
  ValidateCriminalCheckIcon,
} from '../../../../../../..//assets/icons';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import RequestDataLayout from '../../../../../../../components/layouts/request/requestDataLayout';
import { ArrowLeft } from 'tabler-icons-react';

interface IValidateCriminalCheckConfirmation {
  data: IValidateCriminalFormList;
  renderIf?: boolean;
  isLoading: boolean;
  onBack?: () => void;
  onSubmit: () => void;
  billed?: IServiceCharge;
}

export const ValidateCriminalCheckConfirmation = ({
  onBack,
  renderIf,
  onSubmit,
  data,
  billed,
  isLoading,
}: IValidateCriminalCheckConfirmation) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Review"
          supportingText="Validate Criminal Certificate"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<ValidateCriminalCheckIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
            leftIcon={<ArrowLeft width={16} height={16} />}
          >
            Go back
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack
        justify="space-between"
        sx={() => ({ height: '100%' })}
        spacing={0}
      >
        <Box>
          <Box px="lg" my="md">
            <Stack spacing={'md'}>
              {data.data.map((item, index) => (
                <FormListAccordion
                  iconPosition="right"
                  initialItem={0}
                  multiple
                  key={index}
                >
                  <AccordionItem
                    label={<FormListAccordionLabel listCount={index + 1} />}
                  >
                    <Stack spacing="sm">
                      <RequestDataLayout
                        label="Name"
                        data={item.subject_name}
                      />
                      <RequestDataLayout label="Gender" data={item.gender} />
                      <RequestDataLayout
                        label="Document"
                        data={
                          <Group
                            spacing={2}
                            noWrap
                            sx={() => ({
                              maxWidth: 220,
                            })}
                          >
                            <PDFIcon width={14} height={14} />
                            <Text size="sm" lineClamp={1}>
                              {item.file?.name}
                            </Text>
                          </Group>
                        }
                      />
                    </Stack>
                  </AccordionItem>
                </FormListAccordion>
              ))}
            </Stack>
          </Box>
        </Box>
        <Box>
          {!!billed && (
            <ServiceCharge
              serviceCharge={{
                currency: billed?.currency,
                amount: +billed?.amount,
              }}
              icon
              withBackground
            />
          )}
        </Box>
      </Stack>
    </RequestLayout>
  );
};
