import { Skeleton, Stack } from '@mantine/core';
import { CACDetails } from '../../../connected/responseDetails/ng/cac';

export const RequestDetailSkeleton = () => {
  return (
    <CACDetails
      skeleton
      status="COMPLETED"
      idNumber="0000000"
      billed={{
        currency: 'NGN',
        amount: 5666,
      }}
      description="Sample description to generate skeleton'"
      responseDetails={{
        Activity: 'Technology Solutions Company',
        Address: '10, Workbox, Ojora Close, Victoria Island, Lagos',
        BranchOffice: '',
        City: 'LAGOS',
        Company: 'Smile Identity Ltd',
        Directors: 'Doe Joe, Jean Rol',
        Email: 'smile@smileidentity.com',
        HeadOffice: '',
        Name: 'SMILE IDENTITY LIMITED',
        Objectives: '',
        RegistrationDate: '28 Jan 2016',
        RegistrationNo: 'RC 000000',
        Secretary: 'Leo Don Jon',
        State: 'LAGOS',
        Status: 'Unknown',
        LGA: '',
        TypeofEntity: 'Private Company Limited By Shares',
      }}
      renderIf
    />
  );
};

export const RequestBodySkeleton = ({
  type = 'body',
  skeleton = false,
}: {
  type?: 'header' | 'body' | 'footer';
  skeleton?: boolean;
}) => {
  return (
    <Stack
      p="lg"
      sx={(theme) => ({
        borderBottom:
          type === 'header' ? `1px solid ${theme.colors.gray[2]}` : 'none',
        borderTop:
          type === 'footer' ? `1px solid ${theme.colors.gray[2]}` : 'none',
      })}
    >
      <Skeleton height={30} visible={skeleton} />
      {type === 'body' && (
        <Stack>
          <Skeleton height={40} visible={skeleton} />
          <Skeleton height={80} visible={skeleton} />
        </Stack>
      )}
    </Stack>
  );
};
