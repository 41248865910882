import { fileExtension, formatSizeToUnits } from '@iverifyng/utils';
import {
  Group,
  Text,
  Stack,
  Breadcrumbs,
  useMantineTheme,
} from '@mantine/core';
import { getIconByFileExtension } from '../../../utils/file';
import { ReactNode } from 'react';
import { Circle } from 'tabler-icons-react';

interface IProps {
  file: File;
  actionsNode?: ReactNode;
}
export const FileDisplayComp = ({ file, actionsNode }: IProps) => {
  const theme = useMantineTheme();
  const FileIcon = getIconByFileExtension(fileExtension(file?.name as string));
  return (
    <Group position="apart" spacing={2}>
      <Group position="left" spacing={8} sx={() => ({ flex: 1 })}>
        {FileIcon && <FileIcon width={24} />}
        <Stack
          spacing={0}
          align={'flex-start'}
          justify={'flex-start'}
          sx={() => ({ flex: 1 })}
        >
          <Text size="sm" lineClamp={1}>
            {file.name}
          </Text>
          <Group spacing={0}>
            <Breadcrumbs
              separator={
                <Circle fill={theme.colors.gray[5]} width={5} height={5} />
              }
              styles={{ separator: { margin: '0 4px' } }}
            >
              {[fileExtension(file.name), formatSizeToUnits(file.size)]
                .filter(Boolean)
                .map((text, index) => (
                  <Text
                    key={index}
                    color={theme.colors.gray[5]}
                    sx={() => ({ fontSize: 10 })}
                  >
                    {text}
                  </Text>
                ))}
            </Breadcrumbs>
          </Group>
        </Stack>
      </Group>
      {actionsNode}
    </Group>
  );
};
