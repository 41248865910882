/* eslint-disable no-duplicate-case */
import { RequestStatusTypes } from '@iverifyng/dtos';
import {
  CriminalRecordStatusEnum,
  PaymentStatusEnum,
  CertificateStatusEnum,
} from '@prisma/client';
import { useMemo } from 'react';

export const useStatusColor = (
  status?:
    | RequestStatusTypes
    | PaymentStatusEnum
    | CriminalRecordStatusEnum
    | CertificateStatusEnum
) => {
  const color = useMemo(() => {
    switch (status) {
      case 'COMPLETED':
      case 'VERIFIED':
      case 'SUCCESS':
        return 'green';
      case 'ONGOING_VERIFICATION':
        return 'teal';
      case 'UNVERIFIED':
        return 'orange';
      case 'REFUNDED':
        return 'gray';
      case 'PENDING':
      case 'AWAITING_ASSIGNMENT':
      case 'DOCUMENT_REQUIRED':
      case 'PROCESSING':
        return 'violet';
      case 'REJECTED':
      case 'CANCELLED':
      case 'FAILED':
        return 'red';
      default:
        return 'violet';
    }
  }, [status]);

  return color;
};

export const useStatusText = (status?: RequestStatusTypes) => {
  const text = useMemo(() => {
    switch (status) {
      case 'COMPLETED':
        return 'Completed';
      case 'ONGOING_VERIFICATION':
        return 'Ongoing Verification';
      case 'AWAITING_ASSIGNMENT':
        return 'Awaiting Assignment';
      case 'PENDING':
        return 'Pending';
      case 'REJECTED':
        return 'Rejected';
      case 'CANCELLED':
        return 'Cancelled';
      default:
        return status;
    }
  }, [status]);

  return text;
};
