import { GetUserResponseDto, UpdateUserRequestDto,} from '@iverifyng/dtos';
import {
  CreateUserDto,
  ForgotPasswordDto,
  VerifyAccountCodeDto,
  ResetPasswordDto,
  PreAuthCheckDto,
  ResendVerificationCodeDto,
} from '@iverifyng/services/auth';
import axiosInstance from '../axios';

//request user's profile
export const RequestMyProfile = async (): Promise<GetUserResponseDto> => {
  return await axiosInstance
    .get('/users/profile/me')
    .then((response) => response.data);
};

//sign up
export const SignUpUserApi = (data: CreateUserDto) => {
  return axiosInstance.post('/users/auth/signup', data);
};

//pre-auth check
export const PreAuthCheckApi = (data: PreAuthCheckDto) => {
  return axiosInstance.post('/users/auth/preAuthCheck', data);
};

//forgot password
export const ForgotPasswordApi = (data: ForgotPasswordDto) => {
  return axiosInstance.post('/users/auth/forgotpassword', data);
};

//verify password reset code api
export const VerifyAccountCodeApi = (data: VerifyAccountCodeDto) => {
  return axiosInstance.post('/users/auth/verifycode', data);
};

export const ResendVerificationCodeApi = (data: ResendVerificationCodeDto) => {
  return axiosInstance.post('/users/auth/resendVerificationCode', data);
};

//reset password api
export const ResetPasswordApi = (data: ResetPasswordDto) => {
  return axiosInstance.post('/users/auth/resetpassword', data);
};


//  update user profile
export const UpdateUserProfile = (data: UpdateUserRequestDto ) => {
  return axiosInstance.put('users/profile/update', data);
}


//change password