import { Box, Button, Group, Stack } from '@mantine/core';
import { useLocation } from '@tanstack/react-location';
import { VoterIcon } from '../../../../../../../assets/icons';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { DataInfo } from '../../../../../../../components/primitives/details/dataInfo';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';

interface VotersCardDetailsConfirmationProps {
  requestDetails: string;
  requestDescription: string;
  onEditClick: () => void;
  price: IServiceCharge;
  onSubmit?: () => void;
  renderIf?: boolean;
  isSubmitting?: boolean;
}

export const GHVotersCardDetailsConfirmation = ({
  requestDetails,
  requestDescription,
  onEditClick,
  price,
  onSubmit,
  renderIf,
  isSubmitting,
}: VotersCardDetailsConfirmationProps) => {
  const { history } = useLocation();

  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Voter ID"
          onClickBack={() => history.back()}
          withBackArrow
          withBackground
          icon={<VoterIcon width={42} height={42} />}
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button color="dark" variant="outline" onClick={() => history.back()}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack
        justify="space-between"
        sx={() => ({ height: '100%' })}
        spacing={0}
      >
        <Box px="lg">
          <DataInfo
            title="Voter ID Number"
            description={requestDetails}
            icon={true}
            onEditClick={onEditClick}
          />
          <DataInfo
            title="Description"
            description={requestDescription}
            icon={true}
            onEditClick={onEditClick}
          />
        </Box>

        {price ? (
          <Box>
            <ServiceCharge withBackground serviceCharge={price} icon />
          </Box>
        ) : null}
      </Stack>
    </RequestLayout>
  );
};
