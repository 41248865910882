import { CommentType, RequestStatus, ServiceCountry } from '@prisma/client';
import * as Joi from 'joi';

const GET_REQUEST_COST_SCHEMA = Joi.object({
  code: Joi.string().required().label('Service Code'),
  country: Joi.string()
    .valid(...Object.values(ServiceCountry))
    .required()
    .label('Service Country'),
  quantity: Joi.number().min(1).allow(null).optional().label('Quantity'),
});

const ASSIGN_AGENTS_SCHEMA = Joi.object({
  data: Joi.array()
    .items(
      Joi.object({
        userId: Joi.string().required().label('User Id'),
        comment: Joi.string().optional().allow(null, ''),
      }).required()
    )
    .min(1)
    .required()
    .label('Data'),
});

const UNASSIGN_AGENTS_SCHEMA = Joi.object({
  users: Joi.array()
    .items(Joi.string().required())
    .min(1)
    .required()
    .label('Users'),
});

const CREATE_COMMENT_SCHEMA = Joi.object({
  message: Joi.string().min(1).required().label('Message'),
  type: Joi.string()
    .valid(...Object.values(CommentType))
    .optional()
    .label('Message Type'),
});

const UPDATE_STATUS_SCHEMA = Joi.object({
  status: Joi.string()
    .valid(...Object.values(RequestStatus))
    .required()
    .label('Status'),
});
export const RequestValidatorSchema = {
  GET_REQUEST_COST: GET_REQUEST_COST_SCHEMA,
  ASSIGN_AGENTS_SCHEMA,
  UNASSIGN_AGENTS_SCHEMA,
  CREATE_COMMENT_SCHEMA,
  UPDATE_STATUS_SCHEMA,
};
