import { useLocation } from '@tanstack/react-location';
import { useCallback, useState } from 'react';
import { formList, useForm } from '@mantine/form';
import { CreditCheckSchema } from '@iverifyng/validators';
import { useServiceCharge } from '../../../../../../../src/hooks/request';
import { useCurrentCountry } from '../../../../../../../src/hooks/country';
import { CreditCheckProductCodeEnum, ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../../src/hooks/workspace';
import { CommercialCreditCheckForm } from './container/commercialCreditCheckForm';
import { ICommercialCheckFormList } from '../interface';
import { CommercialCreditCheckConfirmation } from './container/commercialCreditCheckConfirmation';
import { useCreateCreditCheckVerification } from '../../../../../../services/mutations/creditCheck';
import { showNotification } from '@mantine/notifications';
import { debounce } from 'lodash';

type CommercialCheckPage = 'form' | 'verify';

export const CommercialCreditCheck = () => {
  const [page, setPage] = useState<CommercialCheckPage>('form');
  const { history } = useLocation();
  const { country } = useCurrentCountry();
  const { workspaceId } = useCurrentWorkspace();
  const form = useForm({
    initialValues: {
      code: 'COMMERCIAL_DETAILED_REPORT',
      data: formList<ICommercialCheckFormList>([
        {
          enquiryReason: '',
          businessName: '',
          businessRegNo: '',
          accountNumber: '',
        },
      ]),
      description: '',
    },
  });

  const { data: serviceCharge } = useServiceCharge({
    code: 'CREDIT_CHECK_DETAILED_COMMERCIAL',
    country: country! as ServiceCountry,
  });

  const createCreditCheck = useCreateCreditCheckVerification();

  const handleVerify = useCallback(() => {
    const { error } = CreditCheckSchema.NEW_VERIFICATION_SCHEMA.validate(
      form.values
    );
    if (error) {
      showNotification({
        color: 'red',
        title: 'Validation Error',
        message: error.message,
      });
      return;
    }
    setPage('verify');
  }, [form.values]);

  const handleSubmit = useCallback(() => {
    createCreditCheck.mutate({
      workspaceId: workspaceId!,
      code: form.values.code as CreditCheckProductCodeEnum,
      data: form.values.data,
      description: form.values.description,
    });
  }, [
    createCreditCheck,
    form.values.code,
    form.values.data,
    form.values.description,
    workspaceId,
  ]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <CommercialCreditCheckForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Fill the form with the reqiuired details for commercial credit check"
      />
      <CommercialCreditCheckConfirmation
        data={form.values}
        isLoading={createCreditCheck.isLoading}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        renderIf={page === 'verify'}
        billed={serviceCharge}
      />
    </form>
  );
};
