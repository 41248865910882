import { createStyles, Group, Select, Text } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import React from 'react';
import { CalendarEvent, ChevronDown } from 'tabler-icons-react';

const filters = {
  sixMonths: '6 Months',
  threeMonths: '3 Months',
  currentMonth: 'This Month',
  lastMonth: 'Last Month',
  custom: 'Custom',
};

export const useDatePickerStyle = createStyles((theme) => ({
  input: {
    backgroundColor: theme.colors.gray[0],
    width: 150,
  },
  datePickerStyle: {
    backgroundColor: theme.colors.gray[0],
  },
  unstyledInput: {
    maxWidth: 150,
    '&:hover': {
      borderRadius: 4,
      background: theme.colors.gray[1],
    },
  },
}));

export const useSelectStyle = createStyles((theme) => ({
  unstyledWrapper: {
    width: 100,
    paddingLeft: '0.256rem',
    '&:hover': {
      borderRadius: 4,
      background: theme.colors.gray[1],
    },
  },
  dropdown: {
    minWidth: 150,
  },
}));

export interface IHeaderProps {
  label: string;
  rightNode?: React.ReactNode;
  filterValue?: string;
  onFilterChange?: (value: string) => void;
  dateRange?: [Date | null, Date | null] | undefined;
  onDateRangeChange?: (date: [Date | null, Date | null]) => void;
  dateRangeDisabled?: boolean;
}

export const ChartHeader = ({
  label,
  rightNode,
  dateRange,
  onDateRangeChange,
  dateRangeDisabled,
  filterValue,
  onFilterChange,
}: IHeaderProps) => {
  const { classes: dateClasses } = useDatePickerStyle();
  const { classes: selectClasses } = useSelectStyle();
  return (
    <Group position="apart" pl="sm" sx={{ flexWrap: 'nowrap' }}>
      <Text size="sm" weight={500} transform="capitalize">
        {label}
      </Text>

      <Group sx={{ flexWrap: 'nowrap' }} spacing="xs">
        <Select
          clearable
          size="xs"
          searchable
          value={filterValue}
          data={
            filters
              ? Object.entries(filters).map(([value, label]) => ({
                  value,
                  label,
                }))
              : []
          }
          onChange={onFilterChange}
          variant="unstyled"
          placeholder="Filter "
          rightSection={<ChevronDown size={20} color="grey" />}
          rightSectionWidth={30}
          styles={{ rightSection: { pointerEvents: 'none' } }}
          classNames={{
            wrapper: selectClasses.unstyledWrapper,
            dropdown: selectClasses.dropdown,
          }}
        />
        <DateRangePicker
          size="xs"
          icon={<CalendarEvent width={16} />}
          value={dateRange}
          onChange={(range) => {
            if (range[0] && range[1] && onDateRangeChange) {
              onDateRangeChange(range);
            }

            //handle for clearing
            if (range[0] === null && range[1] === null && onDateRangeChange) {
              onDateRangeChange([null, null]);
            }
          }}
          classNames={{
            input: dateClasses.unstyledInput,
          }}
          disabled={dateRangeDisabled}
          placeholder="Select dates"
          closeCalendarOnChange
          variant="unstyled"
          clearable={!dateRangeDisabled}
          inputFormat="DD/MM/YY"
        />

        {!!rightNode && rightNode}
      </Group>
    </Group>
  );
};
