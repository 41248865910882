import { useQuery } from 'react-query';
import {
  GetAdminUserDetail,
  GetAdminUsers,
  IAdminUsers,
} from '../services/apis/admin';

export const useAdminUsers = (params: IAdminUsers) => {
  const queryKey = ['adminUsers', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAdminUsers({ ...params }),
    {
      enabled: !!params.limit,
    }
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useAdminUserDetails = (userId: string) => {
  const { data, isError, isLoading, isFetching, error } = useQuery(
    ['adminUserDetail', userId],
    () => GetAdminUserDetail(userId),
    {
      enabled: !!userId,
    }
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
  };
};
