import { formatPriceByCurrency } from '@iverifyng/utils';
import { Group, Text } from '@mantine/core';
import cashIcon from '../../../assets/svg/payments-cash.svg';

export type IServiceCharge = {
  currency: string;
  amount: number;
  kobo?: number;
};

interface ServiceChargeProps {
  serviceCharge: IServiceCharge;
  withBackground?: boolean;
  icon?: boolean;
}

export const ServiceCharge = ({
  withBackground,
  serviceCharge,
  icon = false,
}: ServiceChargeProps) => {
  return (
    <Group
      position="apart"
      py={withBackground ? 'sm' : 'none'}
      px={withBackground ? 'lg' : 'none'}
      sx={(theme) => ({
        backgroundColor: withBackground ? theme.colors.gray[1] : 'transparent',
      })}
    >
      <Group direction="row" spacing="xs">
        {icon ? <img src={cashIcon} alt="iverifyng" width={20} /> : null}
        <Text weight={600}>
          Service Fee{' '}
          <Text
            component="span"
            weight={400}
            size="xs"
            sx={(theme) => ({
              color: theme.colors.gray[7],
            })}
          >
            (VAT Incl.)
          </Text>
        </Text>
      </Group>
      {serviceCharge ? (
        <Text size="lg" weight={700}>
          {formatPriceByCurrency(serviceCharge.amount, {
            currency: serviceCharge.currency,
          })}
        </Text>
      ) : null}
    </Group>
  );
};
