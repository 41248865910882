import { createStyles, MantineColor } from "@mantine/core";


export interface NotificationStyles {
  color: MantineColor;
}




export const useAlertStyle = createStyles((theme, { color }: NotificationStyles) => {
  return {
    root: {
      boxSizing: 'border-box',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: '12px 20px',
      color: color,
      backgroundColor: theme.white,
      boxShadow: theme.shadows.xs,
      border: `1px solid ${theme.colors.gray[2]}`,

      '&::before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 6,
        top: 0,
        bottom: 0,
        backgroundColor: color,
        left: 0,
      },
    },
  };
});
