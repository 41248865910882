import { useQuery } from 'react-query';
import {
    AdminGetWorkspaceUserApi,
  GetAdminAvailableAgents,
  GetAdminRequests,
  GetIdentityResponseDetail,
  GetRequestAssignedAgents,
  GetRequestComments,
  IAdminRequestQueryDto,
} from '../services/apis/admin';
import { GetRequestByIdApi } from '../services/apis/requests';

export const useAdminRequests = ({
  status,
  ...query
}: IAdminRequestQueryDto) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['adminRequests', status, query],
    () => GetAdminRequests({ status, ...query }),
    {
      enabled: !!status,
    }
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
  };
};

export const useAvailableAgents = () => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['availableAgents'],
    () => GetAdminAvailableAgents()
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
  };
};
export const useRequestAssignedAgents = (requestId: string) => {
  const { isError, refetch, isLoading, data, error, isFetching } = useQuery(
    ['requestAssignedAgents', requestId],
    () => GetRequestAssignedAgents(requestId)
  );

  return {
    isLoading,
    isFetching,
    isError,
    data: data ?? undefined,
    error,
    refetch,
  };
};

export const useAdminRequestDetails = (requestId: string) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['request', requestId],
    () => GetRequestByIdApi(requestId),
    { enabled: !!requestId }
  );

  return {
    isLoading,
    isFetching,
    isError,
    data: data ?? undefined,
    error,
  };
};

export const useAdminWorkspaceUser = (memberId: string, workspaceId: string) => {
    const { data, isError, isLoading, isFetching, error } = useQuery(
      ['member', memberId, workspaceId],
      () => AdminGetWorkspaceUserApi(memberId, workspaceId),
      {
        enabled: !!memberId && !!workspaceId,
      }
    );
  
    return {
        error,
      isError,
      isFetching,
      isLoading,
      user: data ?? undefined,
    };
  };

export const useIdentityResponseDetails = ({
  requestId,
  identityId,
  isEnabled,
}: {
  requestId: string;
  identityId: string;
  isEnabled: boolean;
}) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['responseDetail', requestId],
    () => GetIdentityResponseDetail(requestId, identityId),
    {
      enabled: isEnabled,
    }
  );

  return {
    isLoading,
    isFetching,
    isError,
    data: (data as Record<string, unknown>) ?? undefined,
    error,
  };
};

export const useRequestComments = (requestId: string) => {
  const { data, isError, isLoading, isFetching } = useQuery(
    ['comments', requestId],
    () => GetRequestComments(requestId),
    {
      enabled: !!requestId,
    }
  );

  return {
    isError,
    isFetching,
    isLoading,
    comments: data ?? undefined,
  };
};
