import { Box, Button, Group, Stack } from '@mantine/core';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { CreditCheckIndividualIcon } from '../../../../../../../../src/assets/icons';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import RequestDataLayout from '../../../../../../../components/layouts/request/requestDataLayout';
import { ArrowLeft } from 'tabler-icons-react';
import { IConsumerCreditCheckProps } from '../../interface';
import { RequestDetailsBlock } from '../../../../../../../connected/requestDetails/block';
import dayjs from 'dayjs';
import { CreditCheckProductDescMap } from '@iverifyng/dtos';
import { CreditCheckProductCodeEnum } from '@prisma/client';

interface IConsumerCheckConfirmationProps {
  data: IConsumerCreditCheckProps;
  renderIf?: boolean;
  onBack?: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  billed?: IServiceCharge;
}

export const ConsumerCreditCheckConfirmation = ({
  data,
  billed,
  renderIf,
  isLoading,
  onBack,
  onSubmit,
}: IConsumerCheckConfirmationProps) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Consumer Credit Check"
          supportingText="Review"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<CreditCheckIndividualIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="subtle"
            onClick={() => {
              if (onBack) onBack();
            }}
            leftIcon={<ArrowLeft width={16} height={16} />}
          >
            Go back
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack
        justify="space-between"
        sx={() => ({ height: '100%' })}
        spacing={0}
      >
        <Box>
          <Box px="lg" my="md">
            <Stack spacing={'md'}>
              {data.data.map((item, index) => (
                <RequestDetailsBlock
                  title="Consumer Credit Check details"
                  key={index}
                >
                  <Stack spacing="sm">
                    {data.code && (
                      <RequestDataLayout
                        label="Request Type"
                        data={
                          CreditCheckProductDescMap[
                            data.code as CreditCheckProductCodeEnum
                          ]
                        }
                      />
                    )}

                    <RequestDataLayout
                      label="Enquiry Reason"
                      data={item.enquiryReason!}
                    />
                    {item.consumerName && (
                      <RequestDataLayout
                        label="Full Name"
                        data={item.consumerName!}
                      />
                    )}
                    {item.identification && (
                      <RequestDataLayout
                        label="BVN"
                        data={item.identification}
                      />
                    )}
                    {item.dateOfBirth && (
                      <RequestDataLayout
                        label="Date of Birth"
                        data={dayjs(item.dateOfBirth).format('DD/MM/YYYY')}
                      />
                    )}
                    {item.accountNumber && (
                      <RequestDataLayout
                        label="Account Number"
                        data={item.accountNumber}
                      />
                    )}
                  </Stack>
                </RequestDetailsBlock>
              ))}
            </Stack>
          </Box>
        </Box>

        <Box>
          {!!billed && (
            <ServiceCharge
              serviceCharge={{
                currency: billed?.currency,
                amount: +billed?.amount,
              }}
              icon
              withBackground
            />
          )}
        </Box>
      </Stack>
    </RequestLayout>
  );
};
