import { formList, useForm } from '@mantine/form';
import { useLocation } from '@tanstack/react-location';
import { useCurrentCountry } from '../../../../../../src/hooks/country';
import { useCurrentWorkspace } from '../../../../../../src/hooks/workspace';
import { useCallback, useEffect, useState } from 'react';
import { IAddressFormListItem, TPageState } from './interface';
import { AddressVerificationForm } from './containers/form';
import { useServiceCharge } from '../../../../../../src/hooks/request';
import { ServiceCountry } from '@prisma/client';
import { AddressVerificationSchema } from '@iverifyng/validators';
import { showNotification } from '@mantine/notifications';
import { debounce } from 'lodash';
import { useCreateAddressVerification } from '../../../../../services/mutations/address';
import { AddressVerificationReview } from './containers/review';
import { AddressSelectType } from './containers/selectType';
import { useModals } from '@mantine/modals';
import { MutlipleAddressModal } from 'apps/client/src/connected/modals/requests/address/mutlipleAddressModal';

export const AddressVerification = () => {
  const [page, setPage] = useState<TPageState>('select-type');
  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const modals = useModals();

  const form = useForm({
    initialValues: {
      data: formList<IAddressFormListItem>([
        {
          fullName: '',
          phoneNo: '',
          address: '',
        },
      ]),
    },
  });

  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'ADDRESS',
    country: country! as ServiceCountry,
    quantity: form?.values?.data?.length ?? 1,
  });

  const handleVerify = useCallback(() => {
    const { error } =
      AddressVerificationSchema.NEW_VERIFICATION_SCHEMA.validate(form.values);
    if (error) {
      showNotification({ color: 'red', message: error.message });
      return;
    }
    setPage('verify');
  }, [form.values]);

  const createAddressVerification = useCreateAddressVerification();

  const handleSubmit = useCallback(() => {
    createAddressVerification.mutate({
      workspaceId: workspaceId!,
      data: form.values.data,
    });
  }, [createAddressVerification, workspaceId, form.values.data]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  useEffect(() => {
    if (page === 'verify' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <AddressSelectType
        renderIf={page === 'select-type'}
        onSingleActive={() => setPage('form')}
        onMultipleActive={() =>
          modals.openModal({
            id: 'multiple-request',
            title: 'Address - MultiplecVerification',
            centered: true,
            closeOnClickOutside: false,
            children: (
              <MutlipleAddressModal
                closeModal={() => {
                  modals.closeModal('multiple-request');
                  setPage('form');
                }}
                onFileParsed={async (values) => {
                  if (values.length) {
                    form.setFieldValue('data', formList(values));
                  }
                }}
              />
            ),
          })
        }
      />

      <AddressVerificationForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Verify any address."
      />
      <AddressVerificationReview
        {...{ form }}
        data={form.values}
        isLoading={false}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        renderIf={page === 'verify'}
        billed={serviceCharge}
      />
    </form>
  );
};
