import { formList, useForm } from '@mantine/form';
import { useLocation } from '@tanstack/react-location';
import { useCurrentCountry } from '../../../../../../src/hooks/country';
import { useCurrentWorkspace } from '../../../../../../src/hooks/workspace';
import React, { useCallback, useEffect, useState } from 'react';
import { PreviousWorkVerificationForm } from './containers/form';
import { IPreviousWorkFormListItem } from './interface';
import { PreviousWorkVerificationReview } from './containers/review';
import { useServiceCharge } from '../../../../../../src/hooks/request';
import { ServiceCountry } from '@prisma/client';
import { PreviousWorkVerificationSchema } from '@iverifyng/validators';
import { showNotification } from '@mantine/notifications';
import { debounce } from 'lodash';
import { useCreatePreviousWorkVerification } from '../../../../../services/mutations/previouswork';

type TPageState = 'form' | 'verify';

export const PreviousWorkVerification = () => {
  const [page, setPage] = useState<TPageState>('form');
  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();

  const form = useForm({
    initialValues: {
      data: formList<IPreviousWorkFormListItem>([
        {
          fullName: '',
          phoneNo: '',
          previousWorkAddress: '',
          companyName: '',
        },
      ]),
    },
  });

  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'PREVIOUS_WORK',
    country: country! as ServiceCountry,
    quantity: form?.values?.data?.length ?? 1,
  });

  const handleVerify = useCallback(() => {
    const { error } =
      PreviousWorkVerificationSchema.NEW_VERIFICATION_SCHEMA.validate(
        form.values
      );
    if (error) {
      showNotification({ color: 'red', message: error.message });
      return;
    }
    setPage('verify');
  }, [form.values]);

  const createPreviousWork = useCreatePreviousWorkVerification();

  const handleSubmit = React.useCallback(() => {
    createPreviousWork.mutate({
      workspaceId: workspaceId!,
      data: form.values.data,
    });
  }, [createPreviousWork, workspaceId, form.values.data]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  useEffect(() => {
    if (page === 'verify' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <PreviousWorkVerificationForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Verify Employee previous work."
      />
      <PreviousWorkVerificationReview
        {...{ form }}
        data={form.values}
        isLoading={false}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        renderIf={page === 'verify'}
        billed={serviceCharge}
      />
    </form>
  );
};
