import { showNotification, updateNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { RetryPaymentApi } from '../apis/payments';

export const useRetryPaymentMutation = () => {
  return useMutation(RetryPaymentApi, {
    onMutate(variables) {
      showNotification({
        id: 'paymentRetry',
        title: 'Retry Payment',
        message: 'Processing your request. Please hold...',
        loading: true,
      });
    },
    onSuccess(response, variables, context) {
      updateNotification({
        id: 'paymentRetry',
        color: 'green',
        message: 'Redirecting to payment provider, please hold...',
      });
      if (response?.paymentUrl) {
        window.location.href = response.paymentUrl;
      }
    },
    onError(error, variables, context) {
      showNotification({
        id: 'paymentRetry',
        color: 'red',
        message:
          (error as AxiosError<{ message?: string }>).response?.data.message ??
          'Request failed. Please try again later.',
      });
    },
  });
};
