import { AgentReportServiceCodes } from '@iverifyng/dtos';
import { Button, Group } from '@mantine/core';
import { useCallback, useState } from 'react';
import { FileDownload } from 'tabler-icons-react';
import { getFirebaseAuth } from '../../../services/firebase';
import qs from 'query-string';
import { showNotification, updateNotification } from '@mantine/notifications';

export const DownloadAgentReportPDF = (props: {
  serviceCode: AgentReportServiceCodes;
  requestItemId: string;
  disabled?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const onClick = useCallback(() => {
    setLoading(true);
    showNotification({
      loading: true,
      message: 'Processing request...',
      id: 'downloadpdf',
      color: 'blue',
    });
    getFirebaseAuth.currentUser
      ?.getIdToken()
      .then((token) => {
        const url = qs.stringifyUrl({
          url: `${process.env['NX_APP_API_URL']}/api/agent/report/pdf`,
          query: {
            serviceCode: props.serviceCode,
            requestItemId: props.requestItemId,
            bearerToken: token,
            mode: 'preview',
          },
        });
        window.open(url, '_blank');
        updateNotification({
          id: 'downloadpdf',
          message: 'Report generated successfully',
          color: 'green',
          loading: false,
        });
      })
      .catch((error) => {
        updateNotification({
          id: 'downloadpdf',
          message: 'Failed to generate auth token',
          color: 'red',
          loading: false,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.requestItemId, props.serviceCode]);
  return (
    <Button
      disabled={props.disabled || loading}
      variant="default"
      leftIcon={<FileDownload width={16} height={16} />}
      onClick={onClick}
    >
      Preview
    </Button>
  );
};

export const AgentReportDrawerFooter = (props: {
  onCancel: () => void;
  pdfDisabled: boolean;
  serviceCode: AgentReportServiceCodes;
  saveDisabled: boolean;
  requestItemId: string;
  onSave: () => void;
  saveLoading: boolean;
}) => {
  return (
    <Group
      sx={() => ({
        position: 'fixed',
        bottom: 0,
        background: '#F8F9FA',
        width: '100%',
      })}
      position="right"
      px={'md'}
      py="md"
    >
      <Button variant="subtle" onClick={props.onCancel}>
        Close
      </Button>
      <DownloadAgentReportPDF
        disabled={props.pdfDisabled}
        serviceCode={props.serviceCode}
        requestItemId={props.requestItemId}
      />
      <Button
        disabled={props.saveDisabled}
        onClick={props.onSave}
        loading={props.saveLoading}
      >
        Save Changes
      </Button>
    </Group>
  );
};
