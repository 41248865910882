import { GuarantorStatusEnum } from '@prisma/client';
import * as Joi from 'joi';
import { PhoneNumberValidator } from './helpers';
import { IdentityValidatorSchema } from './identity';

const SINGLE_GUARANTOR_SCHEMA = {
  fullName: Joi.string().required().label('Full Name'),
  phoneNo: Joi.string()
    .custom(PhoneNumberValidator)
    .required()
    .label('Phone Number'),
  relationship: Joi.string()
    .allow(null, '')
    .optional()
    .label('Relationship with guarantor'),
  address: Joi.string().required().label('Residential Address'),
  workAddress: Joi.string().allow(null).optional().label('Work Address'),
  idType: Joi.string()
    .valid('DRIVERS_LICENSE', 'NIN', 'VOTER_ID')
    .required()
    .label('Means of identification'),
  idNumber: Joi.string()
    .when('idType', {
      switch: [
        { is: 'NIN', then: IdentityValidatorSchema.NIN.extract('idNumber') },
        {
          is: 'DRIVERS_LICENSE',
          then: IdentityValidatorSchema.DRIVERS_LICENSE.extract('idNumber'),
        },
        {
          is: 'VOTER_ID',
          then: IdentityValidatorSchema.VOTER_ID.extract('idNumber'),
        },
      ],
    })
    .required()
    .label('ID Number'),
};

const NEW_VERIFICATION_SCHEMA = Joi.object({
  data: Joi.array()
    .items(Joi.object(SINGLE_GUARANTOR_SCHEMA))
    .min(1)
    .required()
    .label('Guarantor data'),
  description: Joi.string().allow('').optional(),
});

const ADMIN_UPDATE_REQUEST = Joi.object({
  ...SINGLE_GUARANTOR_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  reportUrl: Joi.when('status', {
    switch: [
      {
        is: 'VERIFIED',
        then: Joi.string().uri().required(),
        otherwise: Joi.string().uri().optional().allow(null),
      },
    ],
  }).label('Report URL'),
  status: Joi.string()
    .valid(...Object.values(GuarantorStatusEnum))
    .optional()
    .label('Status'),
}).fork(['fullName', 'address', 'phoneNo', 'idType', 'idNumber'], (schema) =>
  schema.optional()
);

const ADMIN_UPDATE_REPORT = Joi.object({
  guarantorId: Joi.string().required().label('Guarantor ID'),
  reportUrl: Joi.string().uri().required().label('Report URL'),
});

const ADMIN_UPDATE_GUARANTOR = Joi.object({
  ...SINGLE_GUARANTOR_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  status: Joi.string()
    .valid(...Object.values(GuarantorStatusEnum))
    .optional()
    .label('Status'),
}).fork(['fullName', 'address', 'phoneNo', 'idType', 'idNumber'], (schema) =>
  schema.optional()
);

export const GuarantorVerificationSchema = {
  NEW_VERIFICATION_SCHEMA,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_REPORT,
  ADMIN_UPDATE_GUARANTOR,
};
