/* eslint-disable react/jsx-no-useless-fragment */
import { WorkspaceRBACKeys, workspaceRoleAccessCheck } from '@iverifyng/utils';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import AccessDenied from '../notfound/404pages';
import { withAuth } from '../../hoc/withAuth';
import { useCurrentWorkspace } from '../../hooks/workspace';
import { LoadingOverlay } from '@mantine/core';

interface IPermissionProps {
  action: WorkspaceRBACKeys;
  children: ReactElement;
  renderNoAccess?: ReactNode;
}

const WorkspaceComp = ({
  action,
  children,
  renderNoAccess,
}: IPermissionProps): JSX.Element => {
  const { workspaceUserRole } = useCurrentWorkspace();
  const response = useMemo(() => {
    if (!workspaceUserRole) return <LoadingOverlay visible />;
    const hasAccess = workspaceRoleAccessCheck(workspaceUserRole!, action);
    if (!hasAccess) {
      return <>{renderNoAccess}</> ?? <AccessDenied />;
    }
    return children;
  }, [action, children, renderNoAccess, workspaceUserRole]);
  return response;
};

export const WorkspaceRBAC = withAuth(WorkspaceComp);
