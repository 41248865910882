import { MantineProvider } from '@mantine/core';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  Navigate,
  Outlet,
  ReactLocation,
  Router,
} from '@tanstack/react-location';
import Dashboard from './containers/dashboard/dashboard';
//import '@fontsource/lato';
import Login from './containers/auth/login/login';
import SignUp from './containers/auth/signup/signup';
import ReVerifyEmail from './containers/auth/reVerifyEmail/reVerifyEmail';
import ForgotPassword from './containers/auth/forgotpwd/forgotpwd';
import ResetPassword from './containers/auth/resetpwd/resetpwd';
import VerifyOtp from './containers/auth/verifyotp/verifyotp';
import OtpVerified from './containers/auth/verifyotp/otpverified';
import ResetPwdSuccess from './containers/auth/resetpwd/resetpwdsuccess';

import './services/firebase'; //initialize firebase
import './styles.scss';
import { NotificationsProvider } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import Request from './containers/request/request';
import { RequestDrawer } from './containers/request/requestDrawer/requestDrawer';
import { ChooseRequest } from './containers/request/routes/ng/chooseRequest/chooseRequest';
import { IdentityVerification } from './containers/request/routes/ng/identityVerification/identityVerification';
import { CertificateVerification } from './containers/request/routes/ng/certificateVerification/certificateVerification';
import { PropertyVerification } from './containers/request/routes/ng/propertyVerification/propertyVerification';
import { CreditCheck } from './containers/request/routes/ng/creditCheck/creditCheck';
import { EmployeeVerification } from './containers/request/routes/ng/employeeVerification/employeeVerification';
import { TenantVerification } from './containers/request/routes/ng/tenantVerification/tenantVerification';
import { GuarantorVerification } from './containers/request/routes/ng/guarantorVerification/guarantorVerification';
import { CriminalRecord } from './containers/request/routes/ng/criminalRecord/criminalRecord';
import { NIN } from './containers/request/routes/ng/identityVerification/subRoutes/nin/nin';
import { CAC } from './containers/request/routes/ng/companyVerification/cac/cac';
import AccountVerified from './containers/auth/verifyotp/accountVerified';
import { CompanyVerification } from './containers/request/routes/ng/companyVerification/companyVerification';
import { TIN } from './containers/request/routes/ng/companyVerification/tin/tin';
import { RequestDetail } from './containers/request/detail/detail';
import { SharedVotersCard } from './containers/request/shared/votersCard';
import Payments from './containers/payments/payments';
import { SharedDriversLicense } from './containers/request/shared/driversLicense';
import { GHSSNIT } from './containers/request/routes/gh/identityVerification/ssnit/ssnit';
import { KEAlienCard } from './containers/request/routes/ke/identityVerification/alienCard/alienCard';
import { SharedPassport } from './containers/request/shared/passport';
import { SharedNationalID } from './containers/request/shared/nationalId';
import { ModalsProvider } from '@mantine/modals';
import { AcceptWorkspaceInvite } from './containers/redirections/workspace/acceptInvite';
import Fileupload from './containers/auth/fileupload';
import { NewCriminalCheck } from './containers/request/routes/ng/criminalRecord/newCriminalCheck/newCriminalCheck';
import { ValidateCriminalCheck } from './containers/request/routes/ng/criminalRecord/validateCriminalCheck/validateCriminalCheck';

//Admin Routes
import AdminDashboard from './containers/admin/dashboard/dashboard';
import AdminUsers from './containers/admin/users/users';
import AdminRequest from './containers/admin/request/request';
import AdminPayments from './containers/admin/payments/payments';
import AdminWorkspace from './containers/admin/workspace/workpace';
import AdminServices from './containers/admin/services/services';
import UserSettings from './containers/user/userSettings';
import { Profile } from './components/tabs/profile';
import { Password } from './components/tabs/password';
import { Notification } from './components/tabs/notification';
import { General } from './components/tabs/general';
import { TeamMembers } from './components/tabs/teamMember';
import { Agents } from './containers/admin/request/containers/agents/agents';
import { RequestDetail as AdminRequestDetail } from './containers/admin/request/containers/detail/detail';
import { TabOutlet } from './containers/admin/request/containers/tabOutlet';
import { Comments } from './containers/admin/request/containers/comments/comments';
import { AdminRequestPaymentDetails } from './containers/admin/request/containers/payment/payment';
import { AdminUserTabOutlet } from './containers/admin/users/userTabOutlet';
import { AdminUserDetail } from './containers/admin/users/containers/userDetail';
import { AdminUserWorkspace } from './containers/admin/users/containers/userWorkspace';
import AdminWorkspaceDetail from './containers/admin/workspace/containers/workspaceDetail';
import { AdminServiceRequesterDetails } from './containers/admin/request/containers/requester/requester';
import AdminAnalytics from './containers/admin/analytics/analytics';
import { AdminWorkspaceTabOutlet } from './containers/admin/workspace/containers/tabOutlet';
import { WorkspaceDetailPage } from './containers/admin/workspace/containers/detail/detail';
import { WorkspaceServiceRequesterDetails } from './containers/admin/workspace/containers/requester/requester';
import { WorkspaceRequestPaymentDetails } from './containers/admin/workspace/containers/payment/payment';
import { WorkspaceAgents } from './containers/admin/workspace/containers/agents/agents';
import { WorkspaceComments } from './containers/admin/workspace/containers/comments/comments';
import { SharedPhoneNumber } from './containers/request/shared/phoneNumber';
import { CommercialCreditCheck } from './containers/request/routes/ng/creditCheck/commercialCreditCheck/commercialCreditCheck';
import { ConsumerCreditCheck } from './containers/request/routes/ng/creditCheck/consumerCreditCheck/consumerCreditCheck';
import { PreviousWorkVerification } from './containers/request/routes/ng/previousWorkVerification/previousWorkVerification';
import { AddressVerification } from './containers/request/routes/ng/address/addressVerification';

const location = new ReactLocation();

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MantineProvider
    withNormalizeCSS
    withGlobalStyles
    theme={{
      fontFamily:
        'Lato,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
      headings: {
        fontFamily:
          'Lato,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji',
      },
      colors: {
        blue: [
          '#E5F3FF',
          '#B8DEFF',
          '#8AC9FF',
          '#5CB4FF',
          '#2E9FFF',
          '#008AFF',
          '#006ECC',
          '#005399',
          '#003766',
          '#001C33',
        ],
      },
      primaryColor: 'blue',
      primaryShade: 5,
    }}
  >
    <QueryClientProvider client={queryClient}>
      <ModalsProvider>
        <NotificationsProvider position="top-right">
          <Router
            location={location}
            routes={[
              {
                path: '/',
                element: <Navigate to="/dashboard" />,
              },
              {
                path: 'dashboard',
                element: <Dashboard />,
              },
              {
                path: 'file',
                element: <Fileupload />,
              },
              {
                path: 'request',
                element: <Request />,
                children: [
                  {
                    path: 'new',
                    element: <RequestDrawer />,
                    children: [
                      { path: '/', element: <ChooseRequest /> },
                      {
                        path: 'identity',
                        children: [
                          { path: '/', element: <IdentityVerification /> },
                          {
                            path: 'driver-license',
                            element: <SharedDriversLicense />,
                          },
                          {
                            path: 'phone-number',
                            element: <SharedPhoneNumber />,
                          },
                          { path: 'nin', element: <NIN /> },
                          {
                            path: 'voters-card',
                            element: <SharedVotersCard />,
                          },
                          {
                            path: 'passport',
                            element: <SharedPassport />,
                          },
                          {
                            path: 'ssnit',
                            element: <GHSSNIT />,
                          },
                          {
                            path: 'national-id',
                            element: <SharedNationalID />,
                          },
                          {
                            path: 'alien-card',
                            element: <KEAlienCard />,
                          },
                        ],
                      },
                      {
                        path: 'company',
                        children: [
                          { path: '/', element: <CompanyVerification /> },
                          {
                            path: 'cac',
                            element: <CAC />,
                          },
                          { path: 'tin', element: <TIN /> },
                        ],
                      },
                      {
                        path: 'certificate',
                        element: <CertificateVerification />,
                      },
                      { path: 'property', element: <PropertyVerification /> },
                      {
                        path: 'credit-check',
                        children: [
                          { path: '/', element: <CreditCheck /> },
                          {
                            path: 'commercial',
                            element: <CommercialCreditCheck />,
                          },
                          {
                            path: 'consumer',
                            element: <ConsumerCreditCheck />,
                          },
                        ],
                      },
                      {
                        path: 'employee',
                        children: [
                          { path: '/', element: <EmployeeVerification /> },
                        ],
                      },
                      { path: 'tenant', element: <TenantVerification /> },
                      {
                        path: 'previous-work',
                        element: <PreviousWorkVerification />,
                      },
                      { path: 'address', element: <AddressVerification /> },
                      {
                        path: 'guarantor',
                        element: <GuarantorVerification />,
                      },
                      {
                        path: 'criminal-record',
                        children: [
                          { path: '/', element: <CriminalRecord /> },
                          {
                            path: 'new-criminal-check',
                            element: <NewCriminalCheck />,
                          },
                          {
                            path: 'validate-criminal-check',
                            element: <ValidateCriminalCheck />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'details',
                    element: <RequestDrawer />,
                    children: [
                      {
                        path: ':requestId',
                        element: <RequestDetail />,
                        // Load the individual invoice before rendering
                      },
                    ],
                  },
                ],
              },
              {
                path: 'payments',
                element: <Payments />,
              },
              {
                path: 'settings',
                element: <UserSettings />,
                children: [
                  { path: 'profile', element: <Profile /> },
                  { path: '/password', element: <Password /> },
                  {
                    path: 'notification',
                    element: <Notification />,
                  },
                  {
                    path: 'workspace',
                    children: [
                      {
                        path: 'details',
                        element: <General />,
                      },
                      {
                        path: 'members',
                        element: <TeamMembers />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'admin',
                children: [
                  {
                    path: 'dashboard',
                    element: <AdminDashboard />,
                  },
                  {
                    path: 'analytics',
                    element: <AdminAnalytics />,
                  },
                  {
                    path: 'users',
                    element: <AdminUsers />,
                    children: [
                      {
                        path: ':userId',
                        element: <AdminUserTabOutlet />,
                        children: [
                          {
                            path: 'details',
                            element: <AdminUserDetail />,
                          },
                          {
                            path: 'workspace',
                            element: <AdminUserWorkspace />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'requests',
                    children: [
                      {
                        path: ':statusId',
                        element: <AdminRequest />,
                        children: [
                          {
                            path: ':requestId',
                            element: <TabOutlet />,
                            children: [
                              {
                                path: 'details',
                                element: <AdminRequestDetail />,
                              },
                              {
                                path: 'requester',
                                element: <AdminServiceRequesterDetails />,
                              },
                              {
                                path: 'payment',
                                element: <AdminRequestPaymentDetails />,
                              },
                              {
                                path: 'agents',
                                element: <Agents />,
                              },
                              {
                                path: 'comments',
                                element: <Comments />,
                              },
                              {
                                path: '/',
                                element: <Navigate to={'details'} />,
                              },
                            ],
                            //   loader: async ({ params: { requestId } }) => ({
                            //     request: await GetRequestDetails(requestId),
                            //   }),
                          },
                        ],
                      },
                      {
                        path: '/',
                        element: <Navigate to="all" />,
                      },
                    ],
                  },
                  {
                    path: 'payments',
                    element: <AdminPayments />,
                  },
                  {
                    path: 'workspace',
                    element: <AdminWorkspace />,
                    children: [
                      {
                        path: '/',
                        element: <Navigate to="detail" />,
                      },
                    ],
                  },
                  {
                    path: 'workspace-detail',
                    children: [
                      {
                        path: ':workspaceId',
                        element: <AdminWorkspaceDetail />,
                        children: [
                          {
                            path: ':tabId',
                            children: [
                              {
                                path: ':detailId',
                                element: <AdminWorkspaceTabOutlet />,
                                children: [
                                  {
                                    path: 'details',
                                    element: <WorkspaceDetailPage />,
                                  },
                                  {
                                    path: 'requester',
                                    element: (
                                      <WorkspaceServiceRequesterDetails />
                                    ),
                                  },
                                  {
                                    path: 'payment',
                                    element: <WorkspaceRequestPaymentDetails />,
                                  },
                                  {
                                    path: 'agents',
                                    element: <WorkspaceAgents />,
                                  },
                                  {
                                    path: 'comments',
                                    element: <WorkspaceComments />,
                                  },
                                  {
                                    path: '/',
                                    element: <Navigate to={'details'} />,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'services',
                    element: <AdminServices />,
                  },

                  {
                    path: '/',
                    element: <Navigate to="dashboard" />,
                  },
                ],
              },
              {
                path: 'auth',
                children: [
                  {
                    path: 'login',
                    element: <Login />,
                  },
                  {
                    path: 'signup',
                    element: <SignUp />,
                  },
                  {
                    path: 'reverifyemail',
                    element: <ReVerifyEmail />,
                  },
                  {
                    path: 'verifyotp',
                    element: <VerifyOtp />,
                  },
                  {
                    path: 'otpverified',
                    element: <OtpVerified />,
                  },
                  {
                    path: 'forgotpassword',
                    element: <ForgotPassword />,
                  },
                  {
                    path: 'resetpassword',
                    element: <ResetPassword />,
                  },
                  {
                    path: 'resetpasswordsuccess',
                    element: <ResetPwdSuccess />,
                  },
                  {
                    path: 'accountverified',
                    element: <AccountVerified />,
                  },
                  {
                    path: '/',
                    element: <Navigate to={'/auth/login'} />,
                  },
                ],
              },

              {
                path: 'redirection',
                children: [
                  {
                    path: '/workspace/acceptInvite/:inviteId',
                    element: <AcceptWorkspaceInvite />,
                  },
                  {
                    path: '/',
                    element: <Navigate to={'/auth/login'} />,
                  },
                ],
              },

              {
                element: <div>404 not found</div>,
              },
            ]}
          >
            <Outlet />
          </Router>
        </NotificationsProvider>
      </ModalsProvider>
    </QueryClientProvider>
  </MantineProvider>
);
