import { Box, Tabs } from '@mantine/core';
import { getRequestCardIcon } from '../../../../components/primitives/cards/request/requestCard/helper';
import { RequestHeader } from '../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { useCallback, useEffect, useMemo } from 'react';
import { useTabStyle } from '../request.styles';
import {
  MakeGenerics,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from '@tanstack/react-location';
import { useQueryClient } from 'react-query';
import { useInterval } from '@mantine/hooks';
import { RequestBodySkeleton } from '../../../../components/primitives/skeletons/requestDetail';
import { GetRequestResponseDto } from '@iverifyng/dtos';
import { useAdminRequestDetails } from '../../../../hooks/admin';
import { AdminIdentityHeader3DotMenu } from '../../../../connected/menus/requests/identity';

type TabID = 'details' | 'agents' | 'comments' | 'payment' | 'requester';
const tabs: Record<TabID, string> = {
  details: 'details',
  requester: 'requester',
  payment: 'payment',
  agents: 'agents',
  comments: 'comments',
};

export type RequestGenerics = MakeGenerics<{
  LoaderData: {
    request: GetRequestResponseDto;
  };
}>;

export const TabOutlet = () => {
  const {
    params: { requestId, statusId },
  } = useMatch<RequestGenerics>();
  const { error, data: request, isLoading } = useAdminRequestDetails(requestId);

  const { current } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const refetchInterval = useInterval(
    () =>
      queryClient.invalidateQueries(['request', requestId], { stale: true }),
    5000
  );

  const tabArray = Object.keys(tabs);
  const path = current.pathname.split('/').pop();

  const activeTab = useMemo(() => {
    const current = tabArray.indexOf(path as TabID);
    return current;
  }, [path, tabArray]);

  // const { data: request, isLoading, isFetching } = useRequestDetails(requestId);
  const { classes } = useTabStyle();

  //refetch the data when Pending or Ongoing Verification
  //this will ensure the data is updated in UI
  useEffect(() => {
    const status = request?.status;
    const isPendingOrOngoing =
      status && ['PENDING', 'ONGOING_VERIFICATION'].includes(status);
    const isIdentityRequest = request?.service?.category === 'IDENTITY';
    if (isPendingOrOngoing && isIdentityRequest) {
      refetchInterval.start();
    } else {
      refetchInterval.stop();
    }
  }, [request?.service?.category, request?.status, refetchInterval]);

  const onTabChange = useCallback(
    (active: number, tabKey: string) => {
      navigate({
        to: `/admin/requests/${statusId}/${requestId}/${tabKey.toLocaleLowerCase()}` as string,
        search: (old) => ({ ...old }),
      });
    },
    [navigate, requestId, statusId]
  );

  const HeaderActionMenuNode = useMemo(() => {
    if (!request) return null;
    switch (request?.service?.category) {
      case 'IDENTITY':
        return <AdminIdentityHeader3DotMenu record={request?.identity} />;

      default:
        return null;
    }
  }, [request]);

  return (
    <Box>
      <Box>
        {isLoading || error ? (
          <RequestBodySkeleton type="header" skeleton={true} />
        ) : (
          <RequestHeader
            icon={<>{getRequestCardIcon(request!)}</>}
            text={request?.service.name}
            rightNode={HeaderActionMenuNode}
          />
        )}
      </Box>
      <Tabs
        color="blue"
        classNames={classes}
        variant="unstyled"
        px="xl"
        py="sm"
        active={activeTab}
        onTabChange={onTabChange}
        tabPadding="md"
      >
        {Object.entries(tabs).map(([key, value]) => (
          <Tabs.Tab tabKey={key} label={value} key={key} />
        ))}
      </Tabs>
      <Box
        sx={() => ({
          flex: 1,
          overflowY: 'hidden',
          height: 'calc(100vh - 286px)',
        })}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
