import {
  EmployeeGuarantorDto,
  NewEmployeeVerificationDto,
} from '@iverifyng/dtos';
import {
  Group,
  Select,
  TextInput,
  Text,
  Box,
  Button,
  Table,
  SimpleGrid,
  Stack,
  ActionIcon,
  InputWrapper,
} from '@mantine/core';
import { FormList } from '@mantine/form/lib/form-list/form-list';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { PhoneNumberInput } from '../../../../components/controls/phoneInput/phoneInput';
import { useMemo } from 'react';
import { Edit, Plus, Trash } from 'tabler-icons-react';
import { RequestDetailsBlock } from '../../../requestDetails/block';
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';

interface IGuarantorFormProps {
  form: UseFormReturnType<
    NewEmployeeVerificationDto & {
      guarantors: FormList<EmployeeGuarantorDto>;
    }
  >;
  onAddGuarantor: () => void;
  renderIf: boolean;
  onClose: () => void;
  onRemoveGuarantor?: (guarantorIndex: number) => void;
  onEditGuarantor?: (guarantorIndex: number) => void;
  //data: EmployeeGuarantorDto[];
}

export const SingleEmployeeFormModal = ({
  form,
  onAddGuarantor,
  onRemoveGuarantor,
  renderIf,
  onClose,
  onEditGuarantor,
}: IGuarantorFormProps) => {
  const idType = form.values.idType;
  const guarantors = form.values.guarantors;

  const AddGuarantorNode = useMemo(
    () => (
      <Button
        size="sm"
        compact
        variant="subtle"
        leftIcon={<Plus width={16} height={16} />}
        onClick={onAddGuarantor}
      >
        Add Guarantor
      </Button>
    ),
    [onAddGuarantor]
  );

  if (!renderIf) return null;
  return (
    <Stack spacing={'sm'}>
      <RequestDetailsBlock title="Applicant Info" spacing={'xs'}>
        <SimpleGrid cols={2} spacing="md">
          <TextInput
            label="Full Name"
            placeholder="Applicant's First and Last name"
            required
            size="md"
            {...form.getInputProps('fullName')}
          />
          <TextInput
            label="Other Name"
            placeholder="Applicant's Other Name"
            size="md"
            {...form.getInputProps('otherName')}
          />
          <InputWrapper size="md" label="Phone Number" required>
            <PhoneNumberInput size="md" {...form.getInputProps('phoneNo')} />
          </InputWrapper>
          <TextInput
            label="BVN"
            placeholder="Applicant's BVN"
            size="md"
            {...form.getInputProps('bvn')}
          />
          <Select
            label="Means of Identification"
            size="md"
            required
            placeholder="Choose means of identification"
            {...form.getInputProps('idType')}
            data={[
              {
                label: 'National Identity Number (NIN)',
                value: 'NIN',
              },
              {
                label: 'Drivers License',
                value: 'DRIVERS_LICENSE',
              },
              {
                label: 'Voters Card',
                value: 'VOTER_ID',
              },
            ]}
          />
          <TextInput
            required
            label="ID Number"
            placeholder="Provide ID number"
            size="md"
            disabled={!idType}
            {...form.getInputProps('idNumber')}
          />
          {idType === 'DRIVERS_LICENSE' && (
            <DatePicker
              required
              label="Date of Birth"
              size="md"
              placeholder="Select date of birth"
              inputFormat="YYYY-MM-DD"
              maxDate={dayjs().subtract(15, 'years').toDate()}
              initialMonth={dayjs().subtract(15, 'years').toDate()}
              defaultValue={dayjs().subtract(15, 'years').toDate()}
              {...form.getInputProps('dob')}
            />
          )}
        </SimpleGrid>
      </RequestDetailsBlock>
      <RequestDetailsBlock title="Address" spacing={'xs'}>
        <SimpleGrid cols={2}>
          <TextInput
            label="Residential address"
            placeholder="Enter applicant's residential address"
            size="md"
            required
            {...form.getInputProps('residentialAddress')}
          />
          <TextInput
            label="Work address"
            placeholder="Enter applicant's work address"
            size="md"
            {...form.getInputProps('workAddress')}
          />
        </SimpleGrid>
      </RequestDetailsBlock>
      <RequestDetailsBlock title="Work History" spacing={'xs'}>
        <SimpleGrid cols={2}>
          <TextInput
            label="Previous work name"
            placeholder="Enter previous work name"
            size="md"
            {...form.getInputProps('previousWorkName')}
          />
          <TextInput
            label="Previous work address"
            placeholder="Enter previous work address"
            size="md"
            {...form.getInputProps('previousWorkAddress')}
          />
        </SimpleGrid>
      </RequestDetailsBlock>
      <RequestDetailsBlock
        title={
          <Group position="apart">
            <span>GUARANTOR DETAILS</span>
            {AddGuarantorNode}
          </Group>
        }
        spacing={'xs'}
      >
        {guarantors && guarantors.length > 0 ? (
          <Box
            sx={() => ({
              border: '1px solid #e6e6e6',
              borderRadius: '4px',
            })}
          >
            <Table horizontalSpacing={'lg'} verticalSpacing={'xs'}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Relationship</th>
                  <th>Address</th>
                  <th>Phone Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {guarantors.map((element, index) => (
                  <tr key={index}>
                    <td>{element.fullname ? element.fullname : '—'}</td>
                    <td>{element.relationship ? element.relationship : '—'}</td>
                    <td>{element.address ? element.address : '—'}</td>
                    <td>{element.phoneNo ? element.phoneNo : '—'}</td>
                    <td>
                      <Group>
                        <ActionIcon
                          color="gray"
                          size="xs"
                          onClick={() => {
                            if (onEditGuarantor) onEditGuarantor(index);
                          }}
                        >
                          <Edit />
                        </ActionIcon>

                        <ActionIcon
                          color="gray"
                          size={16}
                          onClick={() => {
                            if (onRemoveGuarantor) onRemoveGuarantor(index);
                          }}
                        >
                          <Trash />
                        </ActionIcon>
                      </Group>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              backgroundColor: theme.colors.gray[0],
              textAlign: 'center',
              padding: theme.spacing.xl,
              borderRadius: theme.radius.md,
            })}
          >
            <Stack>
              <Text>
                You have not provided any guarantors for this applicant.
              </Text>
              <div>{AddGuarantorNode}</div>
            </Stack>
          </Box>
        )}
      </RequestDetailsBlock>
      <Group position="right" spacing={'xs'}>
        <Button variant="outline" color={'dark'} onClick={onClose} px={30}>
          Close
        </Button>
        <Button px={30} type="submit">
          Submit
        </Button>
      </Group>
    </Stack>
  );
};
