/* eslint-disable no-extra-boolean-cast */
import { withAuth } from '../../../hoc/withAuth';
import { useCallback, useMemo, useState } from 'react';
import { AdminLayout } from '../../../components/layouts/admin';
import {
  Box,
  Center,
  Divider,
  Grid,
  Group,
  Tabs,
  Text,
  TextInput,
  Button,
  Popover,
} from '@mantine/core';
import {
  MakeGenerics,
  Outlet,
  useLocation,
  useMatch,
  useMatchRoute,
  useNavigate,
} from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { GetServices } from '../../../services/apis/requests';
import { useTabStyle } from './request.styles';
import { AdminRequestCard } from '../../../components/primitives/cards/admin/adminRequestCard/adminRequestCard';
import { getRequestCardIcon } from '../../../components/primitives/cards/request/requestCard/helper';
import { useAdminRequests } from '../../../hooks/admin';
import EmptyState from '../../../components/primitives/emptyState/emptyState';
import { FilterLinesIcon, RequestZeroIcon } from '../../../assets/icons';
import { AdminRequestLoader } from '../../../components/primitives/skeletons/agent';
import { withAdmin } from '../../../hoc/withAdmin';
import { ServiceCodeTypes } from '@iverifyng/dtos';
import lodash from 'lodash-es';
import { RequestLayout } from '../../../components/layouts/request/requestLayout';
import { Pagination } from '../../../components/primitives/pagination/pagination';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { AdminRequestFilterForm } from './requestFilter';
import { Search } from 'tabler-icons-react';
import { useForm } from '@mantine/form';

export type StatusTab = 'all' | 'pending' | 'completed' | 'rejected';
const tabs = {
  all: 'all',
  pending: 'pending',
  completed: 'completed',
  rejected: 'rejected',
};

export type FilterForm = {
  dates: [Date | null, Date | null];
  serviceCode: ServiceCodeTypes | '';
  userId: string;
  userName?: string;
};

type LocationGenerics = MakeGenerics<{
  Search: {
    serviceCode: ServiceCodeTypes;
    startDate: string;
    endDate: string;
    userName: string;
    page: number;
    searchterm?: string;
    userId?: string;
  };
}>;
const PAGE_LIMIT = 20;

function AdminRequest() {
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const { current } = useLocation<LocationGenerics>();
  const { classes } = useTabStyle();

  const [searchValue, setSearchValue] = useState(
    () => current.search.searchterm ?? ''
  );
  const [searchterm] = useDebouncedValue(searchValue, 800);

  const [page, setPage] = useState(() => current.search.page ?? 1);

  const form = useForm<FilterForm>({
    initialValues: {
      dates: [
        current.search.startDate ? new Date(current.search.startDate) : null,
        current.search.endDate ? new Date(current.search.endDate) : null,
      ],
      serviceCode: current.search.serviceCode ?? '',
      userId: current.search.userId ?? '',
      userName: current.search.userName ?? '',
    },
  });

  const tabsArray = Object.keys(tabs);
  const match = useMatch();
  const matchRoute = useMatchRoute();
  const matchesTabStatusId = match ? match.params['statusId'] : null;
  const matchRequest = matchRoute({
    to: '/admin/requests/:statusId/:requestId/:tabId',
  });
  const matchedRequestId = matchRequest?.['requestId'] ?? null;

  const {
    data: requests,
    isLoading,
    isFetching,
  } = useAdminRequests({
    status: matchesTabStatusId as StatusTab,
    serviceCode: current.search.serviceCode,
    startDate: current.search.startDate,
    endDate: current.search.endDate,
    searchterm,
    limit: PAGE_LIMIT,
    page,
    userId: current.search.userId,
  });

  const {
    data: services,
    isLoading: loadingServices,
    isFetching: fetchingServices,
  } = useQuery('services', GetServices);

  const onTabChange = useCallback(
    (active: number, tabKey: string) => {
      navigate({
        to: `/admin/requests/${tabKey}` as string,
        search: (old) => ({ ...old }),
      });
    },
    [navigate]
  );

  const handleRouteNavigate = useCallback(
    (params: Record<string, unknown>) => {
      navigate({
        search: (old) => {
          return lodash.pickBy(
            {
              ...old,
              ...params,
            },
            lodash.identity
          );
        },
        replace: true,
      });
    },
    [navigate]
  );

  const onClear = useCallback(async () => {
    setPage(1);
    handleRouteNavigate({
      serviceCode: undefined,
      endDate: null,
      startDate: null,
      userId: null,
      userName: null,
      page: 1,
    });
    form.setValues({
      dates: [null, null],
      serviceCode: '',
      userId: '',
      userName: '',
    });
    setOpened(false);
  }, [handleRouteNavigate, form]);

  const totalPages = useMemo(
    (): number => (requests ? Math.ceil(requests.count / PAGE_LIMIT) : 0),
    [requests]
  );

  const onSubmitFilterForm = useCallback(
    async (data: FilterForm) => {
      setPage(1);
      handleRouteNavigate({
        serviceCode: data.serviceCode ? data.serviceCode : undefined,
        startDate: data.dates[0] ? data.dates[0] : null,
        endDate: data.dates[1] ? data.dates[1] : null,
        userId: data.userId ? data.userId : null,
        userName: data.userName ? data.userName : null,
        page: 1,
      });
      setOpened(false);
    },
    [handleRouteNavigate]
  );

  const onChange = useCallback(
    (page: number) => {
      setPage(page);
      handleRouteNavigate({ page });
    },
    [handleRouteNavigate]
  );

  const handleSearchTerm = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      event.preventDefault();

      setSearchValue(event.target.value);
      setPage(1);
      handleRouteNavigate({ searchterm: event.target.value });
    },
    [handleRouteNavigate]
  );

  const pagination = usePagination({
    total: totalPages,
    page,
    onChange,
  });

  const isServiceListLoading =
    loadingServices || (fetchingServices && !services);

  const activeTabIndex = useMemo(() => {
    return tabsArray.indexOf(matchesTabStatusId as StatusTab);
  }, [matchesTabStatusId, tabsArray]);

  const isEmpty = useMemo(() => {
    if (isFetching || isLoading) return false;
    if ((!requests?.count && !requests?.count) || requests?.data.length < 1)
      return true;
    return false;
  }, [isFetching, isLoading, requests?.count, requests?.data]);

  return (
    <AdminLayout>
      <Group px="xl" py="sm" position="apart">
        <Tabs
          color="blue"
          onTabChange={onTabChange}
          initialTab={1}
          active={activeTabIndex}
          classNames={classes}
          variant="unstyled"
        >
          {Object.entries(tabs).map(([key, value]) => (
            <Tabs.Tab key={key} tabKey={key} label={value} />
          ))}
        </Tabs>
        <Popover
          mx="xl"
          opened={opened}
          gutter={5}
          closeOnClickOutside={false}
          placement="end"
          arrowSize={7}
          onClose={() => setOpened(false)}
          target={
            <Button
              variant="subtle"
              color="gray"
              onClick={() => setOpened((o) => !o)}
              leftIcon={<FilterLinesIcon width={16} />}
            >
              <Text weight={500} size="sm" color="#868E96">
                Filters
              </Text>
            </Button>
          }
          width={293}
          position="bottom"
          withArrow
        >
          <form onSubmit={form.onSubmit(onSubmitFilterForm)}>
            <AdminRequestFilterForm
              form={form}
              onClear={onClear}
              services={services!}
              isServiceListLoading={isServiceListLoading}
            />
          </form>
        </Popover>
      </Group>
      <Divider sx={(theme) => ({ color: theme.colors['gray'][1] })} />

      <Grid
        sx={() => ({
          height: 'calc(100vh - 143.19px)',
          width: '100%',
        })}
        gutter={0}
        columns={12}
      >
        <Grid.Col
          sx={(theme) => ({
            height: '100%',
            overflowY: 'auto',
            borderRight: `1px solid ${theme.colors['gray'][4]}`,
          })}
          span={4}
        >
          <RequestLayout
            headerFragment={
              <Box px={32} py="sm">
                <TextInput
                  icon={<Search size={20} />}
                  placeholder="Search requests"
                  value={searchValue}
                  onChange={handleSearchTerm}
                />
              </Box>
            }
            footerFragment={
              totalPages > 1 ? (
                <Pagination
                  current={pagination.active}
                  totalPages={totalPages}
                  onNext={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    pagination.next();
                  }}
                  onPrevious={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    pagination.previous();
                  }}
                  color="gray"
                />
              ) : null
            }
          >
            {isEmpty ? (
              <Center pt="xl" sx={{ height: '100%' }}>
                <EmptyState
                  icon={<RequestZeroIcon />}
                  subTitle="Requests empty!"
                />
              </Center>
            ) : !!requests?.data && !isLoading ? (
              <>
                {requests.data.map((request, i) => (
                  <AdminRequestCard
                    key={i}
                    active={matchedRequestId === request.id}
                    request={{
                      id: request.id,
                      status: request.status,
                      text: request?.service.description,
                      supportingText: [
                        request.id.toUpperCase(),
                        request.description,
                      ]
                        .filter(Boolean)
                        .join(' - '),
                      name: request.service.description!,
                      price: Number(request.payment?.amount),
                      currency: request.payment?.currency,
                      date: request.createdAt,
                      requester: {
                        firstName: request?.requester?.user?.firstName,
                        lastName: request?.requester?.user?.lastName,
                      },
                    }}
                    renderIcon={<>{getRequestCardIcon(request)}</>}
                    onClick={() =>
                      navigate({
                        to: `/admin/requests/${matchesTabStatusId}/${request.id}/details`,
                        search: (old) => ({ ...old }),
                      })
                    }
                  />
                ))}
              </>
            ) : (
              <AdminRequestLoader />
            )}
          </RequestLayout>
        </Grid.Col>

        <Grid.Col
          span={4}
          sx={(theme) => ({
            height: '100%',
            borderRight: `1px solid ${theme.colors['gray'][4]}`,
          })}
        >
          <Outlet />
        </Grid.Col>

        <Grid.Col
          span={4}
          style={{
            minHeight: '100%',
            width: '100%',
            overflowY: 'auto',
          }}
        ></Grid.Col>
      </Grid>
    </AdminLayout>
  );
}

export default withAuth(withAdmin(AdminRequest));
