import { Request } from '@prisma/client';
import axiosInstance from '../axios';

export const AdminUpdateIdentityReportUrlApi = async (data: {
  identityId: string;
  reportUrl: string;
}): Promise<Request> => {
  const url = `/admin/requests/identity/${data.identityId}/reportUrl`;
  return await axiosInstance
    .put(url, { reportUrl: data.reportUrl })
    .then((response) => response.data);
};
