import { AdminAreaDataDto } from '@iverifyng/dtos';
import {
  Box,
  Group,
  Paper,
  ScrollArea,
  Text as Typography,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  TooltipProps,
} from 'recharts';
import { Props } from 'recharts/types/component/DefaultLegendContent';

export const colorVariants = [4, 5, 6, 7];
interface IAreaChart {
  areaData: AdminAreaDataDto;
  xKey: string;
  tipLabel?: string;
}
export const useColors = () => {
  const theme = useMantineTheme();
  const {
    teal,
    blue,
    pink,
    cyan,
    grape,
    green,
    indigo,
    lime,
    red,
    orange,
    violet,
    yellow,
  } = theme.colors;

  return [
    teal,
    blue,
    cyan,
    red,
    grape,
    green,
    lime,
    yellow,
    violet,
    pink,
    orange,
    indigo,
  ];
};

const renderLegend = (props: Props) => {
  const { payload } = props;

  return (
    <ScrollArea style={{ maxHeight: 40, width: 300, marginInline: 'auto' }}>
      <Group
        spacing="lg"
        sx={{
          width: 300,
          height: 40,
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        {payload &&
          payload.map((entry, index) => {
            const val = entry.value;
            if (val.toLowerCase() === 'total') return null;

            return (
              <Group
                sx={{ flexShrink: 0 }}
                key={`${entry.value}-${index}`}
                spacing="xs"
              >
                <Box
                  component="span"
                  sx={{ width: 10, height: 10, backgroundColor: entry.color }}
                />
                <Typography
                  size="xs"
                  key={`item-${index}`}
                  weight={500}
                  lineClamp={1}
                >
                  {val.charAt(0).toUpperCase() +
                    val.slice(1).toLowerCase().split('_').join(' ')}
                </Typography>
              </Group>
            );
          })}
      </Group>
    </ScrollArea>
  );
};

export default function AdminAreaChart({
  areaData,
  xKey,
  tipLabel,
}: IAreaChart) {
  const colors = useColors();
  const keysArr = Object.keys(areaData[0]).slice(1);
  let tooltip: string;

  const getLineChart = () => {
    const areaArr: JSX.Element[] = [];
    keysArr.forEach((item, index) => {
      const areaColor =
        colors[index % colors.length][
          colorVariants[
            Math.floor(index / colors.length) % colorVariants.length
          ]
        ];
      if (item === 'total') return;

      areaArr.push(
        <Area
          key={index}
          type="monotone"
          dataKey={item}
          stroke={areaColor}
          fill={`url(#color${index + 1})`}
          onMouseOver={() => (tooltip = item)}
        />
      );
    });

    return areaArr;
  };
  const getLinearGradients = () => {
    const linearGradientArr: JSX.Element[] = [];

    keysArr.forEach((item, index) => {
      const gradientColor =
        colors[index % colors.length][
          colorVariants[
            Math.floor(index / colors.length) % colorVariants.length
          ]
        ];

      linearGradientArr.push(
        <linearGradient
          key={item}
          id={`color${index + 1}`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="5%" stopColor={gradientColor} stopOpacity={0.3} />
          <stop offset="95%" stopColor={gradientColor} stopOpacity={0} />
        </linearGradient>
      );
    });
    return linearGradientArr;
  };

  //Custom Tooltip popper
  const renderTooltip = ({ active, payload }: TooltipProps<string, number>) => {
    if (!active || !payload) return null;
    for (const bar of payload) {
      if (bar.dataKey === tooltip && payload[0]) {
        const formattedName = bar?.name?.toString().split('_').join(' ');
        return (
          <Paper px="lg" py="xs" shadow="sm">
            <Typography size="sm" weight={500} transform="capitalize">{`${
              formattedName ?? ''
            } for ${payload[0].payload.label} `}</Typography>
            <Typography>{` #${bar.payload[bar.dataKey]}`}</Typography>
          </Paper>
        );
      }
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        height={300}
        data={areaData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>{getLinearGradients()}</defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xKey} axisLine={false} tickLine={false} />
        <YAxis axisLine={false} />
        <Tooltip
          cursor={false}
          itemStyle={{
            fontSize: '12px',
            margin: 0,
            padding: 0,
          }}
          labelStyle={{
            fontSize: '14px',
          }}
          content={renderTooltip}
        />
        <Legend height={40} content={renderLegend} />
        {getLineChart()}
      </AreaChart>
    </ResponsiveContainer>
  );
}
