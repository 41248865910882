import { useQuery } from "react-query";
import { GetAdminServices } from "../services/apis/admin";

export const useGetAdminServices = () => {
  const { data, isError, isLoading,  error } = useQuery(
    ['adminServices'],
    () => GetAdminServices(),
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    error,
    
  };
}
