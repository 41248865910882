import { Box, Button } from '@mantine/core';
import { useNavigate } from '@tanstack/react-location';
import { PropsWithChildren } from 'react';
import { SwitchHorizontal } from 'tabler-icons-react';
import Header from '../../connected/header/header';
import { withAuth } from '../../hoc/withAuth';
import { useUserProfile } from '../../services/queries/users';
import { CustomerHeaderLinks } from './common';

export const DashboardLayout = ({ children }: PropsWithChildren<unknown>) => {
  const navigate = useNavigate();
  const { isAdmin, isAgent } = useUserProfile();
  const showSwitchButton = isAdmin || isAgent;
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        minHeight: '100vh',
        borderRadius: theme.radius.xs,
        backgroundColor: theme.colors.gray[0],
        overflow: 'hidden',
      })}
    >
      <Header
        links={CustomerHeaderLinks}
        rightSideNode={
          showSwitchButton ? (
            <Button
              leftIcon={<SwitchHorizontal width={12} />}
              variant="subtle"
              color="grape"
              onClick={() =>
                navigate({ to: `/admin/${isAdmin ? 'dashboard' : 'requests'}` })
              }
            >
              Switch to {isAdmin ? 'Admin' : 'Agent'}
            </Button>
          ) : null
        }
      />
      {children}
    </Box>
  );
};

export default withAuth(DashboardLayout);
