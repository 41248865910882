import { IUniversity } from '@iverifyng/utils';
import {
  Autocomplete,
  Loader,
  SelectItemProps,
  Text,
  Group,
} from '@mantine/core';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { debounce, uniqBy } from 'lodash-es';
import { ExternalFindUniverstiesApi } from '../../../../../../services/apis/external';

interface ItemProps extends SelectItemProps, Partial<IUniversity> {
  value: string;
  group?: string;
  createNew?: 'true' | 'false';
}

interface IProps {
  defaultValue?: string;
  onItemSelect?: (item: ItemProps) => void;
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, createNew, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          {createNew === 'true' ? (
            <Text size="sm">{`Add "${value}"`}</Text>
          ) : (
            <Text size="sm">{value}</Text>
          )}
        </Group>
      </div>
    );
  }
);

export const UniversitiesDropdown = ({
  defaultValue,
  onItemSelect,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ItemProps[]>([]);
  const handleSearch = useCallback(async (val: string) => {
    if (val.trim().length === 0) return;

    setLoading(true);
    const results = await ExternalFindUniverstiesApi({ name: val });
    const uniqueResults = uniqBy(results, 'name');
    if (uniqueResults && uniqueResults.length >= 1) {
      const mapped = uniqueResults.map((uni) => ({
        ...uni,
        value: uni.name,
        label: uni.name,
        group: uni.country,
      }));
      setData(mapped);
    } else {
      setData([
        { value: val, label: val, createNew: 'true', group: 'No Results' },
      ]);
    }
    setLoading(false);
  }, []);

  //Delay searched packages refetch for 800ms after onchange
  const debouncedSearch = useRef(debounce(handleSearch, 500)).current;
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  return (
    <Autocomplete
      data={data}
      defaultValue={defaultValue}
      onChange={debouncedSearch}
      rightSection={loading ? <Loader size={16} /> : null}
      maxDropdownHeight={200}
      onItemSubmit={onItemSelect}
      itemComponent={AutoCompleteItem}
    />
  );
};
