import { Button, Center, Divider, Loader, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  getRequestCardIcon,
  getRequestTypeLabel,
} from '../../../components/primitives/cards/request/requestCard/helper';
import EmptyState from '../../../components/primitives/emptyState/emptyState';
import { ResponseZeroIcon } from '../../../assets/icons';
import { CloudDownload, Repeat } from 'tabler-icons-react';
import { SharedReport } from '../../../../src/containers/admin/request/containers/sharedReport/sharedReport';
import { RequestLayout } from '../../../../src/components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../src/components/primitives/cards/request/requestHeader/requestHeader';
import { useNavigate } from '@tanstack/react-location';
import { CommonRequestDetails } from '../../requestDetails/commonInfo';
import { RequestDetailsBlock } from '../../requestDetails/block';
import { IdentityResponseProps } from '../interface';
dayjs.extend(advancedFormat);

export const IdentityRequestDetail = ({
  request,
  responseDetails,
  responseDetailLoading,
  onDownloadReport,
  onPaymentRetry,
  disableRetryBtn,
  disableDownloadBtn,
}: IdentityResponseProps) => {
  const { status, identity } = request;
  const navigate = useNavigate();

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          icon={<>{getRequestCardIcon(request!, 36)}</>}
          text={request?.service.name}
          withClose
          withBackground
          onCancel={() => navigate({ to: '/request' })}
        />
      }
      footerFragment={
        onPaymentRetry ? (
          <Button
            fullWidth
            leftIcon={<Repeat />}
            onClick={onPaymentRetry}
            disabled={disableRetryBtn}
          >
            Retry Payment
          </Button>
        ) : (
          <Button
            fullWidth
            leftIcon={<CloudDownload />}
            disabled={disableDownloadBtn || !onDownloadReport}
            onClick={onDownloadReport}
          >
            Download Report
          </Button>
        )
      }
    >
      <Stack px="lg" py="md">
        <CommonRequestDetails {...request} />
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <RequestDetailsBlock title="Request Details">
          <RequestDataLayout
            label={getRequestTypeLabel(request)!}
            data={identity?.idNumber ? identity?.idNumber : null}
          />
        </RequestDetailsBlock>
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <RequestDetailsBlock title="Response Details">
          {status === 'COMPLETED' && responseDetails ? (
            <SharedReport {...{ request }} {...{ responseDetails }} />
          ) : status === 'COMPLETED' && responseDetailLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <EmptyState
              icon={<ResponseZeroIcon />}
              subTitle="Response details not available"
            />
          )}
        </RequestDetailsBlock>
      </Stack>
    </RequestLayout>
  );
};
