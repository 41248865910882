import { Divider, Stack } from '@mantine/core';
import { NewCriminalCheckIcon } from '../../../../src/assets/icons';
import { RequestLayout } from '../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../components/primitives/cards/request/requestHeader/requestHeader';
import { ReactNode, useCallback } from 'react';
import { GetRequestResponseDto } from '@iverifyng/dtos';
import { UpdateNewCriminalCheckRequest } from '../../../../src/services/apis/requests';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from '@tanstack/react-location';
import { useModals } from '@mantine/modals';
import { CriminalCheckAccordionItem } from '../../requestDetails/criminalcheck/accordionItem';
import { CommonRequestDetails } from '../../requestDetails/commonInfo';
import { RequestDetailsBlock } from '../../requestDetails/block';

export interface IProps {
  data: GetRequestResponseDto;
  renderIf: boolean;
  footerNode?: ReactNode;
}

export const NewCriminalCheckResponse = ({
  data,
  renderIf,
  footerNode,
}: IProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const modals = useModals();

  const UpdateNewCriminalCheck = useMutation(UpdateNewCriminalCheckRequest);

  const handleCriminalCheckUpdate = useCallback(
    (url: string, id: string, onSucess?: () => void) => {
      UpdateNewCriminalCheck.mutate(
        {
          registry_report_url: url,
          id: id,
          workspaceId: data.workspaceId,
          requestId: data.id,
        },
        {
          onSuccess: () => {
            if (onSucess) onSucess();
          },
        }
      );
    },
    [UpdateNewCriminalCheck, data]
  );

  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Criminal Record Check"
          withClose
          withBackground
          icon={<NewCriminalCheckIcon width={42} height={42} />}
          onCancel={() => navigate({ to: '/request' })}
        />
      }
      footerFragment={footerNode}
    >
      <Stack px="lg" pt="sm">
        <CommonRequestDetails {...data} />
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <RequestDetailsBlock title="Request Details">
          {data.criminalRecordChecks.map((check, index) => (
            <CriminalCheckAccordionItem
              record={check}
              index={index}
              key={index}
              onFileUploadSuccess={(url) =>
                handleCriminalCheckUpdate(url, check.id, () => {
                  queryClient.invalidateQueries(['request', check.requestId]);
                  modals.closeModal('file-uploader');
                })
              }
            />
          ))}
        </RequestDetailsBlock>
      </Stack>
    </RequestLayout>
  );
};
