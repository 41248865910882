import {
  useAdminRequestDetails,
  useIdentityResponseDetails,
} from '../../../../../hooks/admin';
import { useMatch } from '@tanstack/react-location';

import { RequestDetailView } from '../../../components/detail/detail';
import { WorkspaceGenerics } from '../workspaceDetail';
import { useAdminUserDetails } from '../../../../../hooks/admin-users';
import { AdminUserDetailView } from '../../../users/components/userDetailView';

export const WorkspaceDetailPage = () => {
  const {
    params: { tabId, detailId, workspaceId },
  } = useMatch<WorkspaceGenerics>();
  const { error, data: request, isLoading } = useAdminRequestDetails(detailId);

  const {
    data: responseDetail,
    isLoading: responseDetailLoading,
    isFetching: responseDetailIsFetching,
  } = useIdentityResponseDetails({
    identityId: request?.identity?.id as string,
    requestId: detailId,
    isEnabled: !!request?.identity?.id && request?.status === 'COMPLETED',
  });

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useAdminUserDetails(detailId);

  if (tabId === 'team_members') {
    return (
      <AdminUserDetailView
        user={user}
        isError={isUserError}
        isLoading={isUserLoading}
      />
    );
  }

  return (
    <RequestDetailView
      request={request}
      responseDetail={responseDetail}
      responseDetailIsFetching={responseDetailIsFetching}
      isLoadingRequest={isLoading}
      isErrorRequest={error}
      responseDetailLoading={responseDetailLoading}
    />
  );
};
