import { useLocation } from '@tanstack/react-location';
import { useCallback, useEffect, useState } from 'react';
import {
  IFormList,
  NewCriminalCheckForm,
} from './container/newCriminalCheckForm';
import { formList, joiResolver, useForm } from '@mantine/form';
import { CriminalRecordCheckSchema } from '@iverifyng/validators';
import React from 'react';
import { NewCriminalCheckConfirmation } from './container/newCriminalCheckConfirmation';
import {
  useCreateNewCriminalRecordCheck,
  useServiceCharge,
} from '../../../../../../../src/hooks/request';
import { useCurrentCountry } from '../../../../../../../src/hooks/country';
import { ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../../src/hooks/workspace';
import { CriminalRecordCheckCategory } from '@iverifyng/dtos';
import { debounce } from 'lodash';

type NewCriminalCheckPage = 'form' | 'verify';

export const NewCriminalCheck = () => {
  const [page, setPage] = useState<NewCriminalCheckPage>('form');
  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const form = useForm({
    schema: joiResolver(CriminalRecordCheckSchema.NEW_REQUEST),
    initialValues: {
      category: 'EMPLOYMENT',
      country: country!,
      description: '',
      data: formList<IFormList>([
        {
          subject_name: '',
          gender: undefined,
        },
      ]),
    },
  });

  const { data: serviceCharge, refetch } = useServiceCharge({
    code:
      form.values.category === 'EMPLOYMENT'
        ? 'CRIMINAL_RECORD_CHECK_EMPLOYMENT'
        : 'CRIMINAL_RECORD_CHECK_IMMIGRATION',
    country: country! as ServiceCountry,
    quantity: form?.values?.data?.length ?? 1,
  });

  const handleVerify = useCallback(() => {
    setPage('verify');
  }, []);

  const postNewCriminalCheck = useCreateNewCriminalRecordCheck(workspaceId!);

  const handleSubmit = React.useCallback(() => {
    postNewCriminalCheck.mutate({
      workspaceId: workspaceId!,
      country: country as ServiceCountry,
      data: form.values.data,
      category: form.values.category as CriminalRecordCheckCategory,
    });
  }, [postNewCriminalCheck, workspaceId, country, form.values]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  useEffect(() => {
    if (page === 'verify' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <NewCriminalCheckForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Create one or more criminal record checks. After successful payment, you will be sent a form to fill. Ensure to fill and submit completed information."
      />
      <NewCriminalCheckConfirmation
        {...{ form }}
        data={form.values}
        isLoading={postNewCriminalCheck.isLoading}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        renderIf={page === 'verify'}
        billed={serviceCharge}
      />
    </form>
  );
};
