import React from 'react';
import { Group, Skeleton, Text, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IRequestCardProps } from '../../request/requestCard/requestCard';
import { Workspace } from '@prisma/client';
import { AdminWorkspaceDotMenu } from '../../../../../connected/menus/admin/workspace';
import { trim } from 'lodash';
dayjs.extend(advancedFormat);

interface IUserCardProps
  extends Omit<IRequestCardProps, 'request' | 'renderIcon'> {
  workspace: Workspace;
}

export const AdminWorkspaceCard = ({
  active,
  workspace,
  skeleton = false,
  onClick,
}: IUserCardProps) => {
  return (
    <Group
      position="apart"
      px="md"
      py="xs"
      onClick={() => {
        if (onClick) onClick(workspace.id);
      }}
    >
      <Stack spacing={skeleton ? 'xs' : 0}>
        <Skeleton visible={skeleton}>
          <Text transform="capitalize" size="sm" weight={500}>
            {workspace.name}
          </Text>
        </Skeleton>
        <Skeleton visible={skeleton}>
          <Text
            size="sm"
            sx={(theme) => ({
              color: theme.colors.gray[6],
            })}
          >
            {trim(workspace.domain!).length ? workspace.domain : 'N/A'}
          </Text>
        </Skeleton>
      </Stack>
      <AdminWorkspaceDotMenu workspace={workspace} />
    </Group>
  );
};
