import { GetRequestResponseDto } from '@iverifyng/dtos';
import { formatPriceByCurrency } from '@iverifyng/utils';
import { Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import RequestDataLayout from '../../components/layouts/request/requestDataLayout';
import { StatusBadge } from '../../components/primitives/statusBadge/statusBadge';
import { useStatusText } from '../../hooks/useStatusColor';

export const CommonRequestDetails = ({
  service,
  payment,
  status,
  createdAt,
  description,
  ...request
}: GetRequestResponseDto) => {
  const statusName = useStatusText(status);
  return (
    <Stack>
      <RequestDataLayout label="ID" data={request.id.toUpperCase()} />
      <RequestDataLayout label="Service" data={service.description} />
      <RequestDataLayout
        label="Fee"
        data={formatPriceByCurrency(Number(payment.amount), {
          currency: payment.currency,
        })}
      />
      <RequestDataLayout label="Payment Reference" data={payment.trxRef} />
      <RequestDataLayout
        label="Status"
        data={
          <StatusBadge status={status} radius="sm">
            <Text transform="capitalize" size="xs" align="center">
              {statusName}
            </Text>
          </StatusBadge>
        }
      />
      <RequestDataLayout
        label="Created"
        data={dayjs(createdAt).format('Do MMM, YYYY HH:mm A')}
      />
      {description && (
        <RequestDataLayout label="Description" data={description ?? 'N/A'} />
      )}
    </Stack>
  );
};
