import { ServiceCodeTypes } from "@iverifyng/dtos";
import { Service } from '@prisma/client'


export const getServiceByProperty = (property: keyof Service, value: unknown, services: Service[]) => {
    return services.find((service) => service[property] === value)
}

export const getServiceValueByProps = (
    code: ServiceCodeTypes,
    data: Service[],
    serviceKey: keyof Service,

) => {
    if (data) {
        const service = getServiceByProperty('serviceCode', code, data);
        if (service) {
            return service[serviceKey];
        }

    }
    return null
};


