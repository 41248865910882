import { Button, Center, ScrollArea, Stack } from '@mantine/core';
import { Workspace, WorkspaceUser } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../src/hooks/workspace';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import {
  AddNewWorkspaceBlock,
  WorkspaceBlock,
} from './components/workspaceBlock';

interface ISwitchWorkspaceModalProps {
  workspaces?: (WorkspaceUser & {
    workspace: Workspace;
  })[];
  isLoading?: boolean;
  onWorkspaceChange?: (id: string) => void;
}

export const SwitchWorkspaceModal = ({
  workspaces,
  isLoading,
  onWorkspaceChange,
}: PropsWithChildren<ISwitchWorkspaceModalProps>) => {
  const { workspaceId, setWorkspaceId, workspaceDetails, workspaceUserRole } =
    useCurrentWorkspace();
  const [id, setId] = useState(() => workspaceId ?? '');

  const userAccounts = useMemo(() => {
    if (isLoading) return null;
    return workspaces?.map((item) => {
      return (
        <WorkspaceBlock
          key={item.id}
          workspace={item.workspace}
          workspaceId={id!}
          onChange={(id) => {
            setId(id);
          }}
        />
      );
    });
  }, [id, isLoading, workspaces]);

  return (
    <Stack>
      <ScrollArea>
        <Stack pb="lg">
          {workspaces && userAccounts}
          <AddNewWorkspaceBlock
            onChange={(id) => setId(id)}
            workspaceId={id!}
          />
        </Stack>
      </ScrollArea>
      <Center>
        <Button
          disabled={id === workspaceId}
          onClick={() => {
            if (onWorkspaceChange && id) onWorkspaceChange(id);
          }}
        >
          Continue
        </Button>
      </Center>
    </Stack>
  );
};
