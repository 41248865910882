/* eslint-disable react/jsx-no-useless-fragment */
import { Group, Menu } from '@mantine/core';
import { useUserProfile } from '../../../services/queries/users';
import {
  Edit,
  Trash,
  CloudUpload,
  MailForward,
  TrashOff,
  SquarePlus,
} from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import { EmployeeVerificationRecordDto } from '@iverifyng/dtos';
import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  AdminEmployeeReportUploadModal,
  AdminEmployeeStatusModal,
} from '../../modals/requests/employee/adminEditEmployee';
import { useRemoveEmployeeReportMutation } from '../../../services/mutations/employee';
import { EmployeeReportFormDrawer } from '../../drawers/reports/employee';
import { ReportBaseDrawer } from '../../drawers/reports/drawer';
interface IProps {
  record: EmployeeVerificationRecordDto;
}
export const AdminEmployee3DotMenu = ({ record }: IProps) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const { isAdmin, isAgent } = useUserProfile();
  const modals = useModals();
  const removeReportMtn = useRemoveEmployeeReportMutation();
  const queryClient = useQueryClient();
  const refreshRequest = useCallback(
    (requestId: string) => {
      queryClient.invalidateQueries(['request', requestId]);
    },
    [queryClient]
  );

  if (!isAdmin && !isAgent) return null;
  return (
    <>
      <Group spacing={0} position="right">
        <Menu
          transition="pop"
          withArrow
          placement="end"
          onClick={(event) => event.stopPropagation()}
        >
          <Menu.Item
            icon={<Edit size={16} />}
            disabled={!isAdmin}
            onClick={() =>
              modals.openModal({
                id: 'updatestatus',
                title: 'Update Status',
                closeOnClickOutside: false,
                closeOnEscape: false,
                children: (
                  <AdminEmployeeStatusModal
                    record={record}
                    closeModal={() => {
                      modals.closeModal('updatestatus');
                      refreshRequest(record.requestId);
                    }}
                  />
                ),
              })
            }
          >
            Edit Status
          </Menu.Item>
          <Menu.Item
            icon={<CloudUpload size={16} />}
            onClick={() =>
              modals.openModal({
                id: 'updatereport',
                title: 'Update Report',
                closeOnClickOutside: false,
                closeOnEscape: false,
                children: (
                  <AdminEmployeeReportUploadModal
                    record={record}
                    closeModal={() => {
                      modals.closeModal('updatereport');
                      refreshRequest(record.requestId);
                    }}
                  />
                ),
              })
            }
          >
            Upload Report
          </Menu.Item>
          <Menu.Item
            icon={<SquarePlus size={16} />}
            onClick={() => setShowDrawer(true)}
          >
            Create Report
          </Menu.Item>

          <Menu.Item
            icon={<TrashOff size={16} />}
            disabled={!record.reportUrl}
            onClick={() =>
              modals.openConfirmModal({
                title: 'Remove Report',
                children:
                  'Are you sure you want to remove the completed report?',
                labels: { confirm: 'Yes', cancel: 'No' },
                closeOnClickOutside: false,
                closeOnEscape: false,
                onCancel: () => console.log('Cancel'),
                onConfirm: () =>
                  removeReportMtn.mutate(
                    { employeeId: record.id },
                    {
                      onSuccess(data, variables, context) {
                        refreshRequest(record.requestId);
                      },
                    }
                  ),
              })
            }
          >
            Remove Report
          </Menu.Item>
          <Menu.Item
            icon={<MailForward size={16} />}
            disabled
            onClick={() =>
              modals.openConfirmModal({
                title: 'Resend Report',
                children: 'Are you sure you want to resend this report?',
                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                onCancel: () => console.log('Cancel'),
                onConfirm: () => console.log('Confirmed'),
              })
            }
          >
            Resend Report
          </Menu.Item>
          <Menu.Item
            icon={<Trash size={16} />}
            color="red"
            disabled
            onClick={() =>
              modals.openConfirmModal({
                title: 'Delete Record',
                children: 'Are you sure you want to delete this request?',
                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                onCancel: () => console.log('Cancel'),
                onConfirm: () => console.log('Confirmed'),
              })
            }
          >
            Delete Request
          </Menu.Item>
        </Menu>
      </Group>
      <ReportBaseDrawer
        opened={showDrawer}
        onCloseDrawer={() => setShowDrawer(false)}
        title={'Employee Verification - Generate Report'}
      >
        {showDrawer ? (
          <EmployeeReportFormDrawer
            onCancel={() => setShowDrawer(false)}
            record={record}
          />
        ) : (
          <></>
        )}
      </ReportBaseDrawer>
    </>
  );
};
