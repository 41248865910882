import { IHeaderLinksMenu } from '../primitives/headerMenu/headerMenu.interface';

export const AdminHeaderLinks: IHeaderLinksMenu = {
  dashboard: {
    path: '/admin/dashboard',
    name: 'dashboard',
    action: 'canViewDashboard',
  },

  requests: {
    path: '/admin/requests',
    name: 'requests',
    action: 'canViewRequests',
  },

  payments: {
    path: '/admin/payments',
    name: 'payments',
    action: 'canViewPayments',
  },

  more: {
    name: 'More',
    path: '#',
    action: 'canViewMore',
    children: {
      users: { path: '/admin/users', name: 'user', action: 'canViewUsers' },
      workspace: {
        path: '/admin/workspace',
        name: 'workspace',
        action: 'canViewWorkspace',
      },
      services: {
        path: '/admin/services',
        name: 'services',
        action: 'canViewServices',
      },
      analytics: {
        path: '/admin/analytics',
        name: 'analytics',
        action: 'canViewAnalytics',
      },
    },
  },
};

export const CustomerHeaderLinks: IHeaderLinksMenu = {
  dashboard: {
    path: '/dashboard',
    name: 'dashboard',
  },
  request: {
    path: '/request',
    name: 'requests',
  },
  payments: {
    path: '/payments',
    name: 'payments',
  },
};
