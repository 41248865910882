import { WorkspaceValidatorSchema } from '@iverifyng/validators';
import {
  Button,
  Checkbox,
  Group,
  Radio,
  RadioGroup,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Prisma, Workspace } from '@prisma/client';
import { AdminUpdateUserWorkspaceDetailsApi } from '../../../services/apis/admin';

interface IAdminEditWorkspaceDetailModal {
  workspace: Workspace;
  closeModal?: () => void;
}

export const AdminEditWorkspaceDetailModal = ({
  workspace,
  closeModal,
}: IAdminEditWorkspaceDetailModal) => {
  const queryClient = useQueryClient();

  const form = useForm<Prisma.WorkspaceUpdateInput>({
    initialValues: {
      name: workspace?.name ?? '',
      domain: workspace?.domain ?? '',
      type: workspace.type,
      allowOfflinePayment: workspace.allowOfflinePayment,
    },
  });

  const updateWorkspace = useMutation(AdminUpdateUserWorkspaceDetailsApi);

  const handleSubmit = useCallback(
    (values: Prisma.WorkspaceUpdateInput) => {
      const payload = {
        workspace: values,
        workspaceId: workspace.id!,
      };
      const { error } =
        WorkspaceValidatorSchema.UPDATE_WORKSPACE.validate(values);

      if (error) {
        showNotification({ color: 'red', message: error.message });
        return;
      }

      updateWorkspace.mutate(payload, {
        onSuccess: (response) => {
          console.log('success response', response);

          showNotification({
            color: 'blue',
            message: 'Workspace Updated',
          });
          queryClient.invalidateQueries('adminUserWorkspaces');
          queryClient.invalidateQueries(['adminWorkspaces']);
          if (closeModal) closeModal();
        },
        onError: (error) => {
          showNotification({
            color: 'blue',
            message:
              (error as AxiosError<{ message: string }>).response?.data
                .message ?? 'Error occurred',
          });
        },
      });
    },
    [closeModal, queryClient, updateWorkspace, workspace.id]
  );

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group spacing={'md'} direction="column" grow>
        <TextInput
          label="Workspace title"
          placeholder="Enter workspace title"
          {...form.getInputProps('name')}
        />
        <TextInput
          label="Domain"
          placeholder="Enter domain"
          {...form.getInputProps('domain')}
        />
        <Group spacing={'md'} grow>
          <RadioGroup label="Workspace type" {...form.getInputProps('type')}>
            <Radio value="PERSONAL" label="Personal" size="sm" />
            <Radio value="CORPORATE" label="Corporate" size="sm" />
          </RadioGroup>
        </Group>

        <Checkbox
          label="Allow offline payment?"
          {...form.getInputProps('allowOfflinePayment', { type: 'checkbox' })}
        />

        <Group position="right" mt={'md'}>
          <Button
            type="submit"
            disabled={updateWorkspace.isLoading}
            loading={updateWorkspace.isLoading}
          >
            Save Changes
          </Button>
        </Group>
      </Group>
    </form>
  );
};
