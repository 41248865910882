import { Button, Group, Select, Text } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { FilterForm } from './request';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import {
  ItemProps,
  UsersDropdown,
} from '../../../connected/reponse/filterbyuser';
import { Service } from '@prisma/client';

interface IAdminRequestFilterForm {
  form: UseFormReturnType<FilterForm>;
  onClear: () => void;
  isServiceListLoading?: boolean;
  services: Service[];
}

export const AdminRequestFilterForm = ({
  form,
  onClear,
  services,
  isServiceListLoading,
}: IAdminRequestFilterForm) => {
  return (
    <Group direction="column" spacing={16} grow>
      <DateRangePicker
        label="Date"
        placeholder="Select start and end date"
        inputFormat="YYYY-MM-DD"
        // defaultValue={date}
        {...form.getInputProps('dates')}
      />

      <Select
        label="Service type"
        clearable
        placeholder="Select service type"
        data={
          services && services
            ? services.map((service) => ({
                value: service.serviceCode!,
                label: service.description!,
                group: service.category!,
              }))
            : []
        }
        disabled={isServiceListLoading}
        {...form.getInputProps('serviceCode')}
      />

      <UsersDropdown
        defaultValue={form.values.userName}
        onItemSelect={(item: ItemProps) => {
          form.setFieldValue('userId', item.id!);
          form.setFieldValue('userName', item.value!);
        }}
      />

      <Group position="apart">
        <Button
          type="button"
          color="red"
          variant="white"
          size="xs"
          onClick={onClear}
        >
          Clear
        </Button>
        <Button type="submit" size="xs">
          Save
        </Button>
      </Group>
    </Group>
  );
};
