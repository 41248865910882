import { Input } from '@mantine/core';
import ReactOtpInput, { OtpInputProps } from '@beldore/react-otp-input';

interface IOtpInput extends Partial<OtpInputProps> {
  value: string;
  onChange: (otp: string) => void;
}
export const OtpInput = ({
  value,
  onChange,
  numInputs = 4,
  ...rest
}: IOtpInput) => {
  return (
    <ReactOtpInput
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      isInputNum
      shouldAutoFocus
      containerStyle={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gap: '16px',
      }}
      InputComponent={
        <Input
          styles={{
            input: {
              width: 80,
              height: 80,
              textAlign: 'center',
              fontSize: 36,
              color: '#008AFF',
              fontWeight: 'bold',
            },
          }}
        />
      }
      inputWrapperStyle={{
        flex: 1,
      }}
      {...rest}
    />
  );
};
