import {
  CreateWorkspaceRequestDto,
  CreateWorkspaceResponseDto,
  CreateWorkspaceUserResponseDto,
  GetWorkspaceInvitesResponseDto,
  GetWorkspaceUsersResponseDto,
  InviteWorkspaceMemberRequestDto,
  UpdateWorkspaceMemberRoleRequestDto,
} from '@iverifyng/dtos';
import { Prisma } from '@prisma/client';
import axiosInstance from '../axios';

//create workspace
export const CreateWorkspaceApi = async (
  data: CreateWorkspaceRequestDto
): Promise<CreateWorkspaceResponseDto> => {
  return await axiosInstance
    .post(`/workspaces/create`, data)
    .then((response) => response.data);
};

//accept workspace invite
export const AcceptWorkspaceInviteApi = async (
  inviteId: string
): Promise<CreateWorkspaceUserResponseDto> => {
  return await axiosInstance
    .post(`workspaces/invites/accept/${inviteId}`, {})
    .then((response) => response.data);
};

// update workspace
export interface IUpdateWorkspaceRequestDto {
  workspace: Prisma.WorkspaceUpdateInput;
  workspaceId: string;
}
export const UpdateUserWorkspace = async ({
  workspaceId,
  workspace,
}: IUpdateWorkspaceRequestDto) => {
  return await axiosInstance
    .put(`workspaces/${workspaceId}`, workspace)
    .then((response) => response.data);
};

//delete workspace
export const DeleteUserWorkspace = async (workspaceId: string) => {
  return await axiosInstance
    .delete(`workspaces/${workspaceId}`)
    .then((response) => response.data);
};

interface IInviteWorkspaceMemberRequesDto
  extends InviteWorkspaceMemberRequestDto {
  workspaceId: string;
}

//invite team member
export const InviteTeamMemberApi = async ({
  workspaceId,
  ...data
}: IInviteWorkspaceMemberRequesDto) => {
  return await axiosInstance
    .post(`/workspaces/invites/${workspaceId}`, data)
    .then((response) => response.data);
};

//get workspace members

export const GetWorkspaceMembersApi = async (
  workspaceId: string
): Promise<GetWorkspaceUsersResponseDto> => {
  return await axiosInstance
    .get(`/workspaces/${workspaceId}/members`)
    .then((response) => response.data);
};

//get invited team members
export const GetInvitedTeamMember = async (
  workspaceId: string
): Promise<GetWorkspaceInvitesResponseDto> => {
  return await axiosInstance
    .get(`/workspaces/invites/${workspaceId}`)
    .then((response) => response.data);
};

//delete workspace invite
export const DeleteWorkspaceInvite = async (params: {
  WorkspaceId: string;
  inviteId: string;
}): Promise<void> => {
  return await axiosInstance
    .delete(`/workspaces/invites/${params.WorkspaceId}/${params.inviteId}`)
    .then((response) => response.data);
};

//delete workspace team member
export const DeleteWorkspaceMember = async (params: {
  WorkspaceId: string;
  userId: string;
}): Promise<void> => {
  return await axiosInstance
    .delete(`/workspaces/${params.WorkspaceId}/user/${params.userId}`)
    .then((response) => response.data);
};

//update workspace role
interface IWorkspaceUsersRequestDto
  extends UpdateWorkspaceMemberRoleRequestDto {
  workspaceId: string;
}

export const UpdateWorkspaceMemberRole = async ({
  workspaceId,
  ...data
}: IWorkspaceUsersRequestDto): Promise<GetWorkspaceUsersResponseDto> => {
  return await axiosInstance
    .put(`/workspaces/${workspaceId}/member/changerole`, data)
    .then((response) => response.data);
};
