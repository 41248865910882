import { useLocation } from '@tanstack/react-location';
import { useCallback, useEffect, useState } from 'react';
import { formList, useForm } from '@mantine/form';
import { CertificateVerificationSchema } from '@iverifyng/validators';
import React from 'react';
import { useServiceCharge } from '../../../../../../src/hooks/request';
import { useCurrentCountry } from '../../../../../../src/hooks/country';
import { CertificateTypeEnum, ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../src/hooks/workspace';
import { CertificateVerificationForm } from './container/form';
import { CertificateVerificationReview } from './container/review';
import { ICertificateFormListItem } from './interface';
import { showNotification } from '@mantine/notifications';
import { useCreateCertificateVerification } from '../../../../../services/mutations/certificate';
import { debounce } from 'lodash';

type TPageState = 'form' | 'verify';

export const CertificateVerification = () => {
  const [page, setPage] = useState<TPageState>('form');
  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const form = useForm({
    initialValues: {
      country: country!,
      description: '',
      data: formList<ICertificateFormListItem>([
        {
          fullName: '',
          type: 'UNIVERSITY',
          matricNumber: '',
        },
      ]),
    },
  });

  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'CERTIFICATE_VERIFICATION',
    country: country! as ServiceCountry,
    quantity: form?.values?.data?.length ?? 1,
  });

  const handleVerify = useCallback(() => {
    const { error } =
      CertificateVerificationSchema.NEW_VERIFICATION_SCHEMA.validate(
        form.values
      );
    if (error) {
      showNotification({ color: 'red', message: error.message });
      return;
    }
    setPage('verify');
  }, [form.values]);

  const createCertificate = useCreateCertificateVerification();

  const handleSubmit = React.useCallback(() => {
    createCertificate.mutate({
      workspaceId: workspaceId!,
      country: form.values.country as ServiceCountry,
      data: form.values.data.map((d) => ({
        certificateUrl: d.certificateUrl,
        consentFormUrl: d?.consentFormUrl,
        fullName: d.fullName,
        university: d.type === 'UNIVERSITY' ? d.university : {},
        matricNumber: d.type === 'UNIVERSITY' ? d.matricNumber : null,
        type: d.type as CertificateTypeEnum,
      })),
    });
  }, [createCertificate, workspaceId, form.values.country, form.values.data]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  useEffect(() => {
    if (page === 'verify' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <CertificateVerificationForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Authenticate or evaluate academic certificates issued by institutions across the globe."
      />
      <CertificateVerificationReview
        {...{ form }}
        data={form.values}
        isLoading={createCertificate.isLoading}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        renderIf={page === 'verify'}
        billed={serviceCharge}
      />
    </form>
  );
};
