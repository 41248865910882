import { ActionIcon, Group, Menu } from '@mantine/core';
import { useUserProfile } from '../../../services/queries/users';
import React, { useCallback } from 'react';
import { DotsVertical } from 'tabler-icons-react';
import { useMutation, useQueryClient } from 'react-query';
import { useModals } from '@mantine/modals';
import { Workspace } from '@prisma/client';
import { AdminEditWorkspaceDetailModal } from '../../modals/admin/adminEditWorkspaceDetailModal';
import { AdminDeleteWorkspaceMemberModal } from '../../modals/admin/adminDeleteWorkspaceMemberModal';
import { AxiosError } from 'axios';
import { showNotification } from '@mantine/notifications';
import {
  DeactivateAdminWorkspaceApi,
  ReactivateAdminWorkspaceApi,
} from '../../../services/apis/admin';

interface IUserProps {
  workspace: Workspace;
}

export const AdminWorkspaceDotMenu = ({ workspace }: IUserProps) => {
  const { isAdmin, isAgent } = useUserProfile();
  const modals = useModals();

  const queryClient = useQueryClient();
  const refreshWorkspaceDetails = useCallback(
    (userId: string) => {
      queryClient.invalidateQueries(['adminUserWorkspaces', userId]);
    },
    [queryClient]
  );

  const deactivateText = workspace.active ? 'Deactivate' : 'Reactivate';

  const deactivateWorkspace = useMutation(DeactivateAdminWorkspaceApi);
  const reactivateWorkspace = useMutation(ReactivateAdminWorkspaceApi);

  const onConfirm = useCallback(
    (active: boolean) => {
      if (active) {
        return deactivateWorkspace.mutate(workspace.id, {
          onSuccess: () => {
            showNotification({
              color: 'blue',
              message: 'Workspace deactivated',
            });
            refreshWorkspaceDetails(workspace.id);
            modals.closeModal('deactivate-workspace-modal');
          },
          onError: (error) => {
            showNotification({
              color: 'blue',
              message:
                (error as AxiosError<{ message: string }>).response?.data
                  .message ?? 'Error occurred',
            });
          },
        });
      }
      return reactivateWorkspace.mutate(workspace.id, {
        onSuccess: () => {
          showNotification({
            color: 'blue',
            message: 'Workspace reactivated',
          });
          refreshWorkspaceDetails(workspace.id);
          modals.closeModal('deactivate-workspace-modal');
        },
        onError: (error) => {
          showNotification({
            color: 'blue',
            message:
              (error as AxiosError<{ message: string }>).response?.data
                .message ?? 'Error occurred',
          });
        },
      });
    },
    [
      deactivateWorkspace,
      modals,
      reactivateWorkspace,
      refreshWorkspaceDetails,
      workspace.id,
    ]
  );

  return (
    <Group spacing={0} position="right">
      <Menu
        transition="pop"
        withArrow
        placement="end"
        onClick={(event) => event.stopPropagation()}
        control={
          <ActionIcon>
            <DotsVertical color="grey" size={16} />
          </ActionIcon>
        }
      >
        <Menu.Item
          disabled={!isAdmin}
          onClick={() =>
            modals.openModal({
              id: 'update-workspace-details',
              title: 'Update Workspace Details',
              children: (
                <AdminEditWorkspaceDetailModal
                  workspace={workspace}
                  closeModal={() => {
                    modals.closeModal('update-workspace-details');
                    refreshWorkspaceDetails(workspace.id);
                  }}
                />
              ),
            })
          }
        >
          Edit workspace
        </Menu.Item>
        <Menu.Item
          disabled={!isAdmin}
          onClick={() =>
            modals.openModal({
              id: 'update-workspace-members',
              title: 'Manage Workspace Members',
              size: 'xl',
              children: (
                <AdminDeleteWorkspaceMemberModal
                  workspace={workspace}
                  closeModal={() => {
                    modals.closeModal('update-workspace-members');
                  }}
                />
              ),
            })
          }
        >
          Manage members
        </Menu.Item>
        <Menu.Item
          color="red"
          disabled={
            deactivateWorkspace.isLoading || reactivateWorkspace.isLoading
          }
          onClick={() =>
            modals.openConfirmModal({
              id: 'deactivate-workspace-modal',
              title: `${deactivateText} Workspace`,
              children: `Are you sure you want to ${deactivateText} this workspace?`,
              labels: { confirm: 'Confirm', cancel: 'Cancel' },
              onCancel: () => modals.closeModal('deactivate-workspace-modal'),
              onConfirm: () => {
                onConfirm(workspace.active);
              },
            })
          }
        >
          {deactivateText} workspace
        </Menu.Item>
      </Menu>
    </Group>
  );
};
