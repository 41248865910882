import {
  Box,
  Stack,
  Text,
  Group,
  TextInput,
  Button,
  AccordionItem,
  ActionIcon,
  InputWrapper,
} from '@mantine/core';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import { Trash } from 'tabler-icons-react';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { AddMoreButton } from '../../../../../../components/primitives/button/download';
import { IAddressFormProps } from '../interface';
import { PhoneNumberInput } from '../../../../../../components/controls/phoneInput/phoneInput';
import { PropertyIcon } from '../../../../../../assets/icons';

interface IAddressRequestForm {
  introText: string;
  form: UseFormReturnType<IAddressFormProps>;
  renderIf?: boolean;
  onBack?: () => void;
}

const FormItem = ({
  form,
  index,
}: {
  index: number;
  form: UseFormReturnType<IAddressFormProps>;
}) => {
  return (
    <Group direction="column" spacing="md" grow>
      <TextInput
        label="Full Name"
        placeholder="Enter applicant name"
        required
        autoFocus
        {...form.getListInputProps('data', index, 'fullName')}
      />
      <InputWrapper
        size="sm"
        label="Phone Number"
        placeholder="Enter phone number"
        required
      >
        <PhoneNumberInput
          size="sm"
          {...form.getListInputProps('data', index, 'phoneNo', {
            type: 'input',
          })}
        />
      </InputWrapper>

      <TextInput
        label="Address"
        placeholder="Enter  address"
        {...form.getListInputProps('data', index, 'address')}
        required
      />
    </Group>
  );
};

export const AddressVerificationForm = ({
  introText,
  form,
  renderIf,
  onBack,
}: IAddressRequestForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Address Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<PropertyIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Review</Button>
        </Group>
      }
    >
      <Stack spacing="xl" p="24px">
        <Box
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">{introText}</Text>
        </Box>

        {form.values.data.length > 1 ? (
          <Stack spacing="lg">
            {form.values.data.map((item, index) => (
              <FormListAccordion
                key={index}
                iconPosition="right"
                initialItem={0}
                multiple
              >
                <AccordionItem
                  label={
                    <FormListAccordionLabel
                      listCount={index + 1}
                      rightIcon={
                        <ActionIcon
                          onClick={() => {
                            form.removeListItem('data', index);
                          }}
                        >
                          <Trash color="gray" size={16} />
                        </ActionIcon>
                      }
                    />
                  }
                >
                  <FormItem form={form} index={index} />
                </AccordionItem>
              </FormListAccordion>
            ))}
          </Stack>
        ) : (
          <FormItem form={form} index={0} />
        )}
        {form.values.data.length > 1 && (

        <Group position="left">
          <AddMoreButton
            onClick={() => {
              form.addListItem('data', {
                fullName: '',
                phoneNo: '',
                address: '',
              });
            }}
          />
        </Group>
        )}
      </Stack>
    </RequestLayout>
  );
};
