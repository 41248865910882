type Currencies = 'USD' | 'EUR' | 'NGN' | 'GHS' | string;
type NumberFormat = {
  currency?: Currencies;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name';
  currencySign?: 'standard' | 'accounting';
  notation?: Intl.NumberFormatOptions['notation'];
};
export const formatPriceByCurrency = (
  price: number,
  {
    currency = 'USD',
    currencyDisplay = 'symbol',
    notation = 'standard',
    ...others
  }: NumberFormat
) => {
  return new Intl.NumberFormat('en-US', {
    ...others,
    style: 'currency',
    currency,
    currencyDisplay,
    notation,
  }).format(price);
};

export const getCurrencySymbol = (locale: string, currency: Currencies) => {
  try {
    return (0)
      .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  } catch (error) {
    return currency;
  }
};
