import { getNameInitials } from '@iverifyng/utils';
import {
  Avatar,
  createStyles,
  Group,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calender from 'dayjs/plugin/calendar';
dayjs.extend(advancedFormat);
dayjs.extend(calender);

interface ICommentProps {
  message: string;
  fullName?: string;
  date: Date;
  skeleton?: boolean;
}

const useStyles = createStyles((theme) => ({
  container: {
    '&:hover': {
      background: theme.colors.gray[1],
    },
  },
}));

export const Comment = ({
  message,
  fullName,
  date,
  skeleton = false,
}: ICommentProps) => {
  const { classes } = useStyles();
  return (
    <Group align="flex-start" py={'md'} px="xl" className={classes.container}>
      <Skeleton
        circle={true}
        visible={skeleton}
        p={0}
        m={0}
        height={40}
        width={40}
      >
        <Avatar color="indigo" radius="xl">
          {getNameInitials({ name: fullName!, maxInitials: 2 })}
        </Avatar>
      </Skeleton>
      <Stack spacing={'xs'}>
        <Skeleton visible={skeleton}>
          <Group>
            <Text size="sm" weight={700} transform="capitalize">
              {fullName}
            </Text>
            <Text
              size="xs"
              sx={(theme) => ({
                color: theme.colors.gray[6],
              })}
            >
              {dayjs(date).format('HH:mm A')}
            </Text>
          </Group>
        </Skeleton>
        <Skeleton visible={skeleton} width={300}>
          <Text size="xs" sx={() => ({ fontSize: 14 })}>
            {message}
          </Text>
        </Skeleton>
      </Stack>
    </Group>
  );
};
