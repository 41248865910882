import { AdminRequestLayout } from '../../../../../components/layouts/admin-request';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  UpdatedRequestStatus,
  UpdateStatusParam,
} from '../../../../../../src/services/apis/admin';
import { showNotification, updateNotification } from '@mantine/notifications';
import { Check, CircleCheck } from 'tabler-icons-react';
import { Button, Divider } from '@mantine/core';
import { GetRequestResponseDto } from '@iverifyng/dtos';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useUserProfile } from '../../../../../services/queries/users';
import { CommonRequestDetails } from '../../../../../connected/requestDetails/commonInfo';
import { RequestDetailsBlock } from '../../../../../connected/requestDetails/block';
import { EmployeeAccordionItem } from '../../../../../connected/requestDetails/employee/accordionItem';

dayjs.extend(advancedFormat);

interface IEmployeeProps {
  data: GetRequestResponseDto;
}

export const AdminEmployeeDetails = ({ data }: IEmployeeProps) => {
  const { status, id } = data;
  const queryClient = useQueryClient();
  const { isAdmin } = useUserProfile();
  const updateStatus = useMutation(UpdatedRequestStatus);
  const refreshRequest = useCallback(() => {
    queryClient.invalidateQueries(['request', id]);
  }, [id, queryClient]);
  const updateRequestStatus = useCallback(
    (param: UpdateStatusParam) => {
      showNotification({
        id: 'update-status',
        title: ' Updating Status',
        message: 'updating request status',
        loading: true,
        disallowClose: true,
      });
      updateStatus.mutate(param, {
        onSuccess: () => {
          updateNotification({
            id: 'update-status',
            title: 'Status Updated',
            message: 'status updated',
            icon: <CircleCheck />,
          });
          refreshRequest();
        },
      });
    },
    [updateStatus, refreshRequest]
  );

  return (
    <AdminRequestLayout
      footerFragment={
        status !== 'COMPLETED' ? (
          <Button
            leftIcon={<Check />}
            color="green"
            disabled={!isAdmin}
            loading={updateStatus.isLoading}
            onClick={() =>
              updateRequestStatus({ requestId: id, status: 'COMPLETED' })
            }
            fullWidth
          >
            Mark as Completed
          </Button>
        ) : null
      }
    >
      <CommonRequestDetails {...data} />
      <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
      <RequestDetailsBlock title="Request Details">
        {data.employees.map((employee, index) => (
          <EmployeeAccordionItem key={index} record={employee} index={index} />
        ))}
      </RequestDetailsBlock>
    </AdminRequestLayout>
  );
};
