import { Group, Button, Stack, Box } from '@mantine/core';
import { RequestHeader } from '../../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../../../../components/layouts/request/requestLayout';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../../components/primitives/price/servicecharge';
import { NINFormData } from './ninForm';
import { DataInfo } from '../../../../../../../../components/primitives/details/dataInfo';
import { NINIcon } from '../../../../../../../../assets/icons/index';

interface INinConfirmationProps {
  data: NINFormData;
  billed?: IServiceCharge;
  loading?: boolean;
  onBack?: () => void;
  onEdit?: () => void;
  onSubmit?: () => void;
  renderIf?: boolean;
}

export const NINFormConfirmation = ({
  data,
  billed,
  onBack,
  onEdit,
  loading,
  onSubmit,
  renderIf,
}: INinConfirmationProps) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="NIN Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          withBackArrow
          withBackground
          icon={<NINIcon width={42} height={42} />}
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel request
          </Button>
          <Button
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
            loading={loading}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack justify="space-between" sx={() => ({ height: '100%' })}>
        <Box px="lg">
          <DataInfo
            title="National Identity Number (NIN)"
            description={data.idNumber}
            icon={true}
            onEditClick={onEdit}
          />
          <DataInfo
            title="Description"
            description={data.description}
            icon={true}
            onEditClick={onEdit}
          />
        </Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Stack>
    </RequestLayout>
  );
};
