import { Stack } from '@mantine/core';
import React from 'react';
import { AdminRequestCard } from '../cards/admin/adminRequestCard/adminRequestCard';
import { Comment } from '../comment/comment';

interface IAgentProps {
  limit?: number;
}

export const CommentLoader = ({ limit = 1 }: IAgentProps) => {
  return (
    <Stack spacing="md" px="lg">
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <Comment
            key={i}
            fullName="Jane Doe"
            date={new Date()}
            message="https://images.unsplash.com/photo-1592520113018-180c8bc831c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
            skeleton={true}
          />
        ))}
    </Stack>
  );
};

export const AdminRequestLoader = ({ limit = 7 }: IAgentProps) => {
  return (
    <>
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <AdminRequestCard
            key={i}
            skeleton
            request={{
              id: '1',
              name: 'NIN Slip Verification - Nigeria',
              text: '#0000000000000',
              supportingText: 'Sample description to generate skeleton',
              price: 0,
              date: new Date(),
            }}
          />
        ))}
    </>
  );
};
