import { Box, Button, Group, Stack } from '@mantine/core';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../components/primitives/price/servicecharge';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { GuarantorIcon } from '../../../../../../../src/assets/icons';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { IGuarantorVerifyFormProps } from '../interface';
import { DataInfo } from '../../../../../../components/primitives/details/dataInfo';

interface ICriminalCheckConfirmationProps {
  data: IGuarantorVerifyFormProps;
  renderIf?: boolean;
  onBack?: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  billed?: IServiceCharge;
}

export const GuarantorVerificationReview = ({
  data,
  billed,
  renderIf,
  isLoading,
  onBack,
  onSubmit,
}: ICriminalCheckConfirmationProps) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Guarantor Verification"
          supportingText="Review"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<GuarantorIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Go back
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack
        justify="space-between"
        sx={() => ({ height: '100%' })}
        spacing={0}
      >
        <Box px="lg" my="md">
          <Stack spacing={'md'}>
            {data.data.map((item, index) => (
              <Stack spacing="sm" key={index}>
                <DataInfo
                  title="Full Name"
                  description={item.fullName!}
                  icon={false}
                />
                <DataInfo
                  title="Phone No"
                  description={item.phoneNo!}
                  icon={false}
                />
                <DataInfo
                  title="Residential Address"
                  description={item.address!}
                  icon={false}
                />
                <DataInfo
                  title="Work Address"
                  description={item.workAddress! ?? 'N/A'}
                  icon={false}
                />
                <DataInfo
                  title="Means of Identification"
                  description={item.idType!}
                  icon={false}
                />
                <DataInfo
                  title="ID Number"
                  description={item.idNumber!}
                  icon={false}
                />
              </Stack>
            ))}
          </Stack>
        </Box>

        <Box>
          {!!billed && (
            <ServiceCharge
              serviceCharge={{
                currency: billed?.currency,
                amount: +billed?.amount,
              }}
              icon
              withBackground
            />
          )}
        </Box>
      </Stack>
    </RequestLayout>
  );
};
