import {
  ServiceCountry,
  GenderEnum,
  CriminalRecordStatusEnum,
} from '@prisma/client';
import * as Joi from 'joi';

const CRIMINAL_RECORD_OBJECT = {
  subject_name: Joi.string().required().label('Subject Name'),
  gender: Joi.string()
    .valid(...Object.values(GenderEnum))
    .required()
    .label('Gender'),
};

const VALIDATE_CRIMINAL_RECORD_OBJECT = Joi.object(CRIMINAL_RECORD_OBJECT).keys(
  {
    registry_report_url: Joi.string()
      .uri()
      .required()
      .label('Registry Report URL'),
    file: Joi.any().optional(),
  }
);
export const CriminalRecordCheckSchema = {
  NEW_REQUEST: Joi.object({
    category: Joi.string()
      .valid('EMPLOYMENT', 'IMMIGRATION')
      .required()
      .label('Category'),
    description: Joi.string().allow('').optional(),
    country: Joi.string()
      .valid(...Object.values(ServiceCountry))
      .required()
      .label('Service Country'),
    data: Joi.array()
      .items(Joi.object(CRIMINAL_RECORD_OBJECT))
      .min(1)
      .required()
      .label('Persons'),
  }),
  NEW_VALIDATE_REQUEST: Joi.object({
    description: Joi.string().allow('').optional(),
    country: Joi.string()
      .valid(...Object.values(ServiceCountry))
      .required()
      .label('Service Country'),
    data: Joi.array()
      .items(VALIDATE_CRIMINAL_RECORD_OBJECT)
      .min(1)
      .required()
      .label('Persons'),
  }),
  UPDATE_REQUEST: Joi.object({
    id: Joi.string().required().label('ID'),
    subject_name: CRIMINAL_RECORD_OBJECT.subject_name.optional(),
    gender: CRIMINAL_RECORD_OBJECT.gender.optional(),
    registry_report_url: Joi.string()
      .uri()
      .optional()
      .allow(null)
      .label('Registry Report URL'),
  }),
  ADMIN_UPDATE_REQUEST: Joi.object({
    id: Joi.string().required().label('ID'),
    //requestId: Joi.string().required().label('Request ID'),
    subject_name: CRIMINAL_RECORD_OBJECT.subject_name.optional(),
    gender: CRIMINAL_RECORD_OBJECT.gender.optional(),
    registry_report_url: Joi.string()
      .uri()
      .optional()
      .allow(null)
      .label('Registry Report URL'),
    validated_report_url: Joi.when('status', {
      switch: [
        {
          is: 'VERIFIED',
          then: Joi.string().uri().required(),
          otherwise: Joi.string().uri().optional().allow(null),
        },
      ],
    }).label('Validated Report URL'),
    status: Joi.string()
      .valid(...Object.values(CriminalRecordStatusEnum))
      .optional()
      .label('Status'),
  }),
};
