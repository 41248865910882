import { Box, Text } from '@mantine/core';

export const ReportFormHeader = ({ title }: { title: string }) => {
  return (
    <Box
      px="14px"
      py="7px"
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.colors.gray[2],
      })}
    >
      <Text>{title}</Text>
    </Box>
  );
};
