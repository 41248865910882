import { Text, Group, InputWrapper, Anchor } from '@mantine/core';
import { Download } from 'tabler-icons-react';
import { FileUploader } from '../../../../components/controls/fileuploader/fileuploader';
import { MIME_TYPES } from '@iverifyng/utils';
import PapaParse from 'papaparse';

import { ITenantCSVDataType } from './interface.';
import {
  getTenantDetailsFromCSV,
  getTenantGuarantorDetailsFromCSV,
} from './utils';
import { ITenantFormItemProps } from '../../../../containers/request/routes/ng/tenantVerification/interface';

interface ITenantEmployeeModal {
  closeModal: () => void;
  onFileParsed: (values: Array<ITenantFormItemProps>) => void;
}

export const MultipleTenantModal = ({
  closeModal,
  onFileParsed,
}: ITenantEmployeeModal) => {
  return (
    <InputWrapper
      label={
        <Group position="apart" sx={() => ({ flex: 1 })}>
          <InputWrapper
            label="Upload CSV Document"
            description="Only CSV file allowed. Download sample csv →"
            required
            children={null}
          />
          <Anchor
            href="https://storage.googleapis.com/iverify-public/forms/csv/multiple-tenant-verification.csv"
            target="_blank"
            download={true}
          >
            <Group spacing={2}>
              <Download width={16} height={16} />
              <Text size="sm">Download format</Text>
            </Group>
          </Anchor>
        </Group>
      }
      styles={{ label: { display: 'flex' } }}
    >
      <FileUploader
        accept={[MIME_TYPES.csv]}
        instantUpload={false}
        onFileSelect={(file) => {
          PapaParse.parse<ITenantCSVDataType>(file, {
            header: true,
            complete: (results, file) => {
              console.log('results', results);
              const mapping: Array<ITenantFormItemProps> = results.data.map(
                (value) => {
                  const { type: idType, number: idNumber } =
                    getTenantDetailsFromCSV(value);
                  const { type: guarantorIDType, number: guarantorIDNumber } =
                    getTenantGuarantorDetailsFromCSV(value);
                  return {
                    fullName: value?.fullName ?? '',
                    residentialAddress: value?.residentialAddress ?? '',
                    relocationReason: value?.relocationReason ?? '',
                    spouseName: value?.spouseName ?? '',
                    performCreditCheck: true,
                    phoneNo: value?.phoneNo ?? '',
                    workAddress: value?.workAddress ?? '',
                    idNumber,
                    idType,
                    guarantors: [
                      {
                        fullname: value?.guarantor_fullName ?? '',
                        address: value?.guarantor_residentialAddress ?? '',
                        workAddress: value?.guarantor_workAddress ?? '',
                        phoneNo: value?.guarantor_phoneNo ?? '',
                        relationship: value?.guarantor_relationship ?? '',
                        idNumber: guarantorIDNumber,
                        idType: guarantorIDType,
                      },
                    ],
                    landlords: [
                      {
                        fullname: value?.landlord_fullName ?? '',
                        address: value?.landlord_address ?? '',
                        phoneNo: value?.landlord_phoneNo ?? '',
                      },
                    ],
                  } as ITenantFormItemProps;
                }
              );
              onFileParsed(mapping);
            },
          });
          closeModal();
        }}
      />
    </InputWrapper>
  );
};
