import { Box, Button, Group, Stack } from '@mantine/core';
import { DriverIcon } from '../../../../../../../assets/icons';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { DataInfo } from '../../../../../../../components/primitives/details/dataInfo';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';
import { GHDLFormData } from '../driversLicense';

interface IDetailsInfoDisplay {
  data: GHDLFormData;
  onEditClick: () => void;
  onBack?: () => void;
  loading?: boolean;
  onSubmit?: () => void;
  renderIf?: boolean;
  billed?: IServiceCharge;
}

export const GHDLDetailsConfirmation = ({
  data,
  onEditClick,
  onSubmit,
  loading,
  billed,
  onBack,
  renderIf,
}: IDetailsInfoDisplay) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="NIN Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          withBackArrow
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel request
          </Button>
          <Button
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
            loading={loading}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack justify="space-between" sx={() => ({ height: '100%' })}>
        <Box>
          <RequestHeader
            text="Driver's Liecense Number"
            icon={<DriverIcon width={42} height={42} />}
            withBackground
          />
          <Box px="lg">
            <DataInfo
              title="Driver's Liecense Number"
              description={data.idNumber}
              icon={true}
              onEditClick={onEditClick}
            />
            <DataInfo
              title="Description"
              description={data.description}
              icon={true}
              onEditClick={onEditClick}
            />
          </Box>
        </Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Stack>
    </RequestLayout>
  );
};
