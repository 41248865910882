import { Stack, Text, Group, TextInput, Textarea, Button } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { DriverIcon } from '../../../../../../../../assets/icons';
import { IDLRequestProps } from '../driversLicense';
import { RequestLayout } from '../../../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { ServiceSampleImageAccordion } from '../../../../../../../../components/primitives/cards/services/serviceSampleImage';

interface IDLRequestForm {
  onBack?: () => void;
  form: UseFormReturnType<IDLRequestProps>;
  renderIf?: boolean;
}

export const DLRequestForm = ({ form, onBack, renderIf }: IDLRequestForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Driver's License Number"
          supportingText=""
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<DriverIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Stack justify="space-between" py="md" sx={() => ({ minHeight: '100%' })}>
        <Group direction="column" px="lg" grow spacing="md">
          <ServiceSampleImageAccordion
            title="Sample Driver's License"
            image="https://storage.googleapis.com/iverify-public/sample-ids/ng-drivers-license.png"
          />
          <Text color="gray" size="sm">
            Driver's License verification returns the owner's Full Name,
            Expiration Date etc.
          </Text>
          <TextInput
            required
            label="License Number"
            placeholder="Enter license number"
            {...form.getInputProps('idNumber')}
          />
          <DatePicker
            required
            label="Date of Birth"
            placeholder="Select date of birth"
            inputFormat="YYYY-MM-DD"
            {...form.getInputProps('dob')}
          />
          <TextInput
            required
            label="First Name"
            placeholder="Enter first name"
            {...form.getInputProps('firstName')}
          />
          <TextInput
            required
            label="Last Name"
            placeholder="Enter last name"
            {...form.getInputProps('lastName')}
          />

          <Textarea
            label="Description"
            autosize
            minRows={2}
            {...form.getInputProps('description')}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
