import { withAuth } from '../../../hoc/withAuth';
import { AdminLayout } from '../../../components/layouts/admin';
import { formatPriceByCurrency } from '@iverifyng/utils';
import {
  Text,
  Stack,
  Group,
  TextInput,
  Table,
  ScrollArea,
  Paper,
  Avatar,
  createStyles,
  Anchor,
  Skeleton,
  Center,
  Box,
  Divider,
} from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { Link } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Search, CalendarEvent, File, CircleCheck } from 'tabler-icons-react';
import EmptyState from '../../../components/primitives/emptyState/emptyState';
import {
  DownloadStatus,
  PaymentStatus,
} from '../../../components/primitives/statusBadge/statusBadge';
import { GetReceiptUrlApi } from '../../../services/apis/payments';
import { showNotification, updateNotification } from '@mantine/notifications';
import { TransactionZeroIcon } from '../../../assets/icons';
import { withAdmin } from '../../../hoc/withAdmin';
import { AppRBAC } from '../../permission/app';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { useGetAdminPayments } from '../../../hooks/admin-payments';
import { Pagination } from '../../../components/primitives/pagination/pagination';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colors['blue'][8],
    zIndex: 50,
  },
}));
const paymentPermissionAction = 'canViewPayments';
const PAGE_LIMIT = 20;

function AdminPayments() {
  const { classes } = useStyles();
  const [page, setPage] = useState(1);
  const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);
  const [searchValue, setSearchValue] = useState('');
  const [searchterm] = useDebouncedValue(searchValue, 800);
  const { data: payments, isLoading } = useGetAdminPayments({
    limit: PAGE_LIMIT,
    page,
    searchterm,
    startDate: date?.[0] ? dayjs(date[0]).format('YYYY-MM-DD') : null,
    endDate: date?.[1] ? dayjs(date[1]).format('YYYY-MM-DD') : null,
  });
  const totalPages = useMemo(
    (): number => (payments ? Math.ceil(payments?.count / PAGE_LIMIT) : 0),
    [payments]
  );
  const onChange = useCallback((page: number) => {
    setPage(page);
  }, []);
  const pagination = usePagination({
    total: totalPages,
    page,
    onChange,
  });
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    setPage(1);
  }, []);

  const onDateChange = useCallback((date: [Date | null, Date | null]) => {
    setDate(date);
    setPage(1);
  }, []);

  const getReceiptUrl = useMutation(GetReceiptUrlApi);

  const handleReceiptDownload = useCallback(
    (paymentId: string) => {
      showNotification({
        id: 'fetching-invoice',
        title: 'Fetching Invoice',
        message: 'Invoice data is been processed. Please hold.',
        loading: true,
        disallowClose: true,
      });
      getReceiptUrl.mutate(paymentId, {
        onSuccess: (response) => {
          updateNotification({
            id: 'fetching-invoice',
            title: 'Invoice Received',
            message: 'Invoice has been fetched.',
            icon: <CircleCheck />,
          });
          window.open(response.result, '_blank');
        },
      });
    },
    [getReceiptUrl]
  );

  const isEmpty = useMemo(() => {
    if (isLoading) return false;
    if (!payments?.data || !payments?.data.length) return true;
    return false;
  }, [isLoading, payments?.data]);

  const header = useMemo(
    () => (
      <tr>
        <th style={{ color: '#fff' }}>
          <Group spacing="sm">
            <Text size="sm">Service Request</Text>
          </Group>
        </th>
        <th style={{ color: '#fff' }}>Reference</th>
        <th style={{ color: '#fff' }}>Amount</th>
        <th style={{ color: '#fff' }}>Date</th>
        <th style={{ color: '#fff' }}>Status</th>
        <th></th>
      </tr>
    ),
    []
  );
  const rows = useMemo(() => {
    const data = isLoading ? Array(10).fill('*') : payments?.data;
    return (data || []).map((payment) => (
      <tr>
        <td>
          <Skeleton visible={isLoading}>
            <Group spacing="sm">
              <Avatar color="blue" radius="xl">
                <File size={24} />
              </Avatar>
              <Anchor
                component={Link}
                to={`/admin/requests/all/${payment.requestId}`}
              >
                <Text transform="uppercase">{payment.requestId}</Text>
              </Anchor>
            </Group>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>{payment.trxRef}</Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            {formatPriceByCurrency(Number(payment.amount), {
              currency: payment.currency,
            })}
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            {dayjs(payment.createdAt).format('DD/MM/YYYY HH:mmA')}
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <PaymentStatus status={payment.status!} />
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <DownloadStatus
              status={payment.status!}
              onDownload={() => handleReceiptDownload(payment.id)}
            />
          </Skeleton>
        </td>
      </tr>
    ));
  }, [handleReceiptDownload, isLoading, payments?.data]);
  return (
    <AppRBAC action={paymentPermissionAction}>
      <AdminLayout>
        <Stack
          spacing="xl"
          px={32}
          sx={() => ({
            height: 'calc(100vh - 77.19px)',
          })}
        >
          <Text
            pt="xl"
            sx={() => ({
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: 500,
            })}
          >
            Payments
          </Text>
          <Group position="apart">
            <Group>
              <TextInput
                icon={<Search size={20} />}
                placeholder="Search by reference"
                value={searchValue}
                onChange={handleChange}
              />
              <DateRangePicker
                icon={<CalendarEvent />}
                inputFormat="DD/MM/YYYY"
                placeholder="Select date range"
                value={date}
                onChange={onDateChange}
                sx={() => ({
                  width: 250,
                })}
              />
            </Group>
            {/* <Button color="blue" leftIcon={<FileExport />} disabled>
              Export CSV
            </Button> */}
          </Group>
          <Paper shadow="xs" withBorder={true} radius="md">
            {isEmpty ? (
              <Center style={{ height: 'calc(100vh - 280px)' }}>
                <EmptyState
                  title="No results"
                  subTitle="There were no payments found"
                  icon={<TransactionZeroIcon />}
                />
              </Center>
            ) : (
              <ScrollArea style={{ height: 'calc(100vh - 280px)' }}>
                <Table
                  horizontalSpacing="xl"
                  verticalSpacing="sm"
                  highlightOnHover
                >
                  <thead className={classes.header}>{header}</thead>
                  <tbody>{rows}</tbody>
                </Table>
              </ScrollArea>
            )}
            {totalPages > 1 ? (
              <Box
                sx={(theme) => ({
                  '&:hover': {
                    backgroundColor: theme.colors['gray'][1],
                  },
                })}
              >
                <Divider />
                <Box px="lg" py={12}>
                  <Pagination
                    current={pagination.active}
                    totalPages={totalPages}
                    onNext={() => {
                      pagination.next();
                    }}
                    onPrevious={() => {
                      pagination.previous();
                    }}
                    color="gray"
                  />
                </Box>
              </Box>
            ) : null}
          </Paper>
        </Stack>
      </AdminLayout>
    </AppRBAC>
  );
}

export default withAuth(withAdmin(AdminPayments));
