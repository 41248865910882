import { Skeleton, Stack } from '@mantine/core';
import React from 'react';
import { AdminRequestCard } from '../cards/admin/adminRequestCard/adminRequestCard';
import { AgentCard } from '../cards/admin/agentCard/agentCard';

interface IAgentProps {
  limit?: number;
}

export const AgentLoader = ({ limit = 3 }: IAgentProps) => {
  return (
    <Stack spacing="md">
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <AgentCard
            key={i}
            user={{
              appRoles: ['WORKSPACE', 'ADMIN', 'AGENT'],
              createdAt: new Date(),
              default_country: 'NG',
              email: 'jane_doe@gmail.com',
              emailVerified: true,
              firebaseId: 'Cd0zkHLY2',
              firstName: 'Jane',
              id: '7fb17636-084339b',
              lastName: 'Doe',
              notify_new_activity: true,
              notify_new_request: true,
              notify_news_updates: true,
              notify_weekly_newsletter: true,
              phoneNo: null,
              updatedAt: new Date(),
              deactivate: false,
              verificationCode: '46',
            }}
            imageUrl="https://images.unsplash.com/photo-1592520113018-180c8bc831c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
            skeleton={true}
            renderRightSection={() => (
              <Skeleton visible={true} width={60} height={30} />
            )}
          />
        ))}
    </Stack>
  );
};

export const AdminRequestLoader = ({ limit = 7 }: IAgentProps) => {
  return (
    <>
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <AdminRequestCard
            key={i}
            skeleton
            request={{
              id: '1',
              name: 'NIN Slip Verification - Nigeria',
              text: '#0000000000000',
              supportingText: 'Sample description to generate skeleton',
              price: 0,
              date: new Date(),
            }}
          />
        ))}
    </>
  );
};
