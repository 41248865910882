/* eslint-disable @typescript-eslint/no-explicit-any */
export async function promisify<E = Error, R = any>(
  promise: Promise<any>
): Promise<[E, R]> {
  try {
    const data = await promise;
    return [null as any as E, data as R];
  } catch (err) {
    return [err as E, null as any as R];
  }
}
