import {
  AdminAreaResponseDto,
  AdminDashboardResponseDto,
  AdminWorkspaceStatsResponseDto,
} from '@iverifyng/dtos';
import qs from 'query-string';
import axiosInstance from '../axios';

export interface IAnalyticsUserQueryDto {
  startdate?: string | null;
  enddate?: string | null;
}

// get analytic users data by query
export const GetAnalyticsUserGrowth = async ({
  ...query
}: IAnalyticsUserQueryDto): Promise<AdminDashboardResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/analytics/admin/users/growth`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

// get analytic completed payments data by query
export const GetCompletedPayment = async ({
  ...query
}: IAnalyticsUserQueryDto): Promise<AdminDashboardResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/analytics/admin/payments/completed`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

// get payments chart  by status
export const GetAnalyticsPayments = async ({
  ...query
}: IAnalyticsUserQueryDto): Promise<AdminDashboardResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/analytics/admin/payments/by-status`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

// get area chart requests  by status
export const GetAnalyticsAreaRequests = async ({
  ...query
}: IAnalyticsUserQueryDto): Promise<AdminAreaResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/analytics/admin/requests/status/areachart`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

// get pie chart requests by status
export const GetAnalyticsPieRequests = async ({
  ...query
}: IAnalyticsUserQueryDto): Promise<AdminDashboardResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/analytics/admin/requests/status/piechart`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

// get area chart requests  by status
export const GetAnalyticsAreaServiceTypes = async ({
  ...query
}: IAnalyticsUserQueryDto): Promise<AdminAreaResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/analytics/admin/requests/by-service-code`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

// get pie chart requests by status
export const GetAnalyticsPieServiceTypes = async ({
  ...query
}: IAnalyticsUserQueryDto): Promise<AdminDashboardResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/analytics/admin/services/by-code-and-request-count`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

export const AdminGetWorkspaceStatsApi = async (
  workspaceId: string
): Promise<AdminWorkspaceStatsResponseDto> => {
  const url = `/analytics/admin/workspace/${workspaceId}/stats`;
  return await axiosInstance.get(url).then((response) => response.data);
};
