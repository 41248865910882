import { Avatar, Center, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { VotersResponseDto } from '../../ng/interface';

export const VotersCardReport = (props: VotersResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.Picture ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64,${props.Picture}`}
            radius="xl"
            size="lg"
            alt=""
          />
        </Center>
      ) : null}
      <RequestDataLayout label="First Name" data={props.FirstName ?? 'N/A'} />
      <RequestDataLayout label="Last Name" data={props.LastName ?? 'N/A'} />
      <RequestDataLayout label="Mobile No" data={props.MobileNo ?? 'N/A'} />
      <RequestDataLayout label="VIN" data={props.VIN ?? 'N/A'} />
      <RequestDataLayout label="Date of Birth" data={props.Address ?? 'N/A'} />
      <RequestDataLayout label="Address" data={props.Address ?? 'N/A'} />
      <RequestDataLayout label="State" data={props.State ?? 'N/A'} />
      <RequestDataLayout label="LGA" data={props.LGA ?? 'N/A'} />
      <RequestDataLayout label="Occupation" data={props.Occupation ?? 'N/A'} />
    </Stack>
  );
};
