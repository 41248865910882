import {
  Text,
  Group,
  TextInput,
  Button,
  Stack,
  Select,
  InputWrapper,
  Divider,
  Textarea,
  Checkbox,
} from '@mantine/core';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { TenantIcon } from '../../../../../../assets/icons/index';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { CreateTenantVerificationDto } from '@iverifyng/dtos';
import { ITenantFormItemProps } from '../interface';
import { useForm } from '@mantine/form';
import { PhoneNumberInput } from '../../../../../../components/controls/phoneInput/phoneInput';
import { RequestDetailsBlock } from '../../../../../../connected/requestDetails/block';

// tenant identity data
export const selectIDData = [
  { value: 'NIN', label: 'National Identity Number (NIN)' },
  { value: 'DRIVERS_LICENSE', label: "Driver's License" },
  { value: 'VOTER_ID', label: 'Voters Card' },
];

export type TenantFormData = CreateTenantVerificationDto;
interface IFormProps {
  // form: UseFormReturnType<ITenantFormProps>;
  initialValues: ITenantFormItemProps;
  renderIf?: boolean;
  onBack?: () => void;
  onSubmit: (values: ITenantFormItemProps) => void;
}

// voter card, national id, drivers license
export const TenantForm = ({
  initialValues,
  onBack,
  renderIf,
  onSubmit,
}: IFormProps) => {
  // const [initialValue, setInitialValue] = useState(initialValues);
  const form = useForm({
    initialValues: { ...initialValues },
  });

  // useEffect(() => {
  //   setInitialValue(initialValues);
  // }, [form, initialValues]);

  if (!renderIf) return null;
  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(onSubmit)}>
      <RequestLayout
        headerFragment={
          <RequestHeader
            text="Tenant Verification"
            onClickBack={() => {
              if (onBack) onBack();
            }}
            icon={<TenantIcon width={42} height={42} />}
            withBackArrow
            withBackground
          />
        }
        footerFragment={
          <Group position="apart" grow>
            <Button
              color="dark"
              variant="outline"
              onClick={() => {
                if (onBack) onBack();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Verify</Button>
          </Group>
        }
      >
        <Stack
          justify="space-between"
          py="md"
          sx={() => ({ minHeight: '100%' })}
        >
          <Group direction="column" px="lg" grow spacing="md">
            <Text color="gray" size="sm">
              Verify your prospective tenant by filling the details below.
            </Text>
            <RequestDetailsBlock title="Tenant's basic details">
              <TextInput
                label="Full Name"
                placeholder="Enter tenant's full name"
                // value={initialValues?.fullName}
                {...form.getInputProps('fullName')}
                required
              />
              <InputWrapper
                size="sm"
                label="Phone Number"
                placeholder="Enter phone number"
                required
              >
                <PhoneNumberInput
                  size="sm"
                  {...form.getInputProps('phoneNo')}
                />
              </InputWrapper>
              <Select
                label="Means of Identification"
                placeholder="Choose means of identification"
                data={selectIDData}
                {...form.getInputProps('idType')}
                required
              />
              <TextInput
                label={'ID Number'}
                placeholder="Enter ID Number"
                {...form.getInputProps('idNumber')}
                required
              />
              <TextInput
                label="Residential address"
                placeholder="Enter residential address"
                {...form.getInputProps('residentialAddress')}
                required
              />
              <TextInput
                label="Work Address"
                placeholder="Enter work address"
                {...form.getInputProps('workAddress')}
              />
              <TextInput
                label="Spouse Name"
                placeholder="Enter spouse name"
                {...form.getInputProps('spouseName')}
              />

              <Textarea
                label="Reason for relocating"
                placeholder="State reason for relocating"
                {...form.getInputProps('relocationReason')}
              />
              <Checkbox
                label="Perform Credit Check"
                {...form.getInputProps('performCreditCheck', {
                  type: 'checkbox',
                })}
              />
            </RequestDetailsBlock>

            <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
            <RequestDetailsBlock title="Guarantor's Details">
              <TextInput
                label="Full Name"
                placeholder="Enter guarantor's full name"
                {...form.getListInputProps('guarantors', 0, 'fullname')}
                required
              />
              <Select
                label="Means of Identification"
                placeholder="Choose guarantor's ID"
                data={selectIDData}
                {...form.getListInputProps('guarantors', 0, 'idType')}
                required
              />
              <TextInput
                label={'ID Number'}
                placeholder="Enter guarantor's ID Number"
                {...form.getListInputProps('guarantors', 0, 'idNumber')}
                required
              />
              <TextInput
                label="Residential address"
                placeholder="Enter guarantor's residential address"
                required
                {...form.getListInputProps('guarantors', 0, 'address')}
              />
              <TextInput
                label="Work address"
                placeholder="Enter guarantor's work address"
                {...form.getListInputProps('guarantors', 0, 'workAddress')}
              />
              <InputWrapper
                size="sm"
                label="Phone Number"
                placeholder="Enter guarantor's phone number"
                required
              >
                <PhoneNumberInput
                  size="sm"
                  {...form.getListInputProps('guarantors', 0, 'phoneNo')}
                />
              </InputWrapper>
              <TextInput
                label="Relationship"
                placeholder="Wife/Husband, Father, Mother etc."
                {...form.getListInputProps('guarantors', 0, 'relationship')}
                required
              />
            </RequestDetailsBlock>
            <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
            <RequestDetailsBlock title="Landlord's Details">
              <TextInput
                label="Full Name"
                placeholder="Enter landlord's full name"
                {...form.getListInputProps('landlords', 0, 'fullname')}
                required
              />
              <TextInput
                label="Residential address"
                placeholder="Enter landlord's residential address"
                required
                {...form.getListInputProps('landlords', 0, 'address')}
              />
              <InputWrapper
                size="sm"
                label="Phone Number"
                placeholder="Enter landlord's phone number"
                required
              >
                <PhoneNumberInput
                  size="sm"
                  {...form.getListInputProps('landlords', 0, 'phoneNo')}
                />
              </InputWrapper>
            </RequestDetailsBlock>
          </Group>
        </Stack>
      </RequestLayout>
    </form>
  );
};
