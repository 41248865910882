import { Group, Menu } from '@mantine/core';
import { useUserProfile } from '../../../services/queries/users';
import { Edit, Trash, CloudUpload, MailForward } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import { GuarantoreVerificationRecordDto } from '@iverifyng/dtos';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import {
  AdminGuarantorReportUploadModal,
  AdminGuarantorStatusModal,
} from '../../modals/requests/guarantor/adminActions';

interface IProps {
  record: GuarantoreVerificationRecordDto;
}
export const AdminGuarantor3DotMenu = ({ record }: IProps) => {
  const { isAdmin, isAgent } = useUserProfile();
  const modals = useModals();
  const queryClient = useQueryClient();
  const refreshRequest = useCallback(
    (requestId: string) => {
      queryClient.invalidateQueries(['request', requestId]);
    },
    [queryClient]
  );

  if (!isAdmin && !isAgent) return null;
  return (
    <Group spacing={0} position="right">
      <Menu
        transition="pop"
        withArrow
        placement="end"
        onClick={(event) => event.stopPropagation()}
      >
        <Menu.Item
          icon={<Edit size={16} />}
          disabled={!isAdmin}
          onClick={() =>
            modals.openModal({
              id: 'updatestatus',
              title: 'Update Status',
              children: (
                <AdminGuarantorStatusModal
                  record={record}
                  closeModal={() => {
                    modals.closeModal('updatestatus');
                    refreshRequest(record.requestId);
                  }}
                />
              ),
            })
          }
        >
          Edit Status
        </Menu.Item>
        <Menu.Item
          icon={<CloudUpload size={16} />}
          onClick={() =>
            modals.openModal({
              id: 'updatereport',
              title: 'Update Report',
              children: (
                <AdminGuarantorReportUploadModal
                  record={record}
                  closeModal={() => {
                    modals.closeModal('updatereport');
                    refreshRequest(record.requestId);
                  }}
                />
              ),
            })
          }
        >
          Upload Report
        </Menu.Item>
        <Menu.Item
          icon={<MailForward size={16} />}
          disabled
          onClick={() =>
            modals.openConfirmModal({
              title: 'Resend Report',
              children: 'Are you sure you want to resend this report?',
              labels: { confirm: 'Confirm', cancel: 'Cancel' },
              onCancel: () => console.log('Cancel'),
              onConfirm: () => console.log('Confirmed'),
            })
          }
        >
          Resend Report
        </Menu.Item>
        <Menu.Item
          icon={<Trash size={16} />}
          color="red"
          disabled
          onClick={() =>
            modals.openConfirmModal({
              title: 'Delete Record',
              children: 'Are you sure you want to delete this request?',
              labels: { confirm: 'Confirm', cancel: 'Cancel' },
              onCancel: () => console.log('Cancel'),
              onConfirm: () => console.log('Confirmed'),
            })
          }
        >
          Delete Request
        </Menu.Item>
      </Menu>
    </Group>
  );
};
