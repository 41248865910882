import {
  Box,
  Stack,
  Text,
  Group,
  TextInput,
  Button,
  InputWrapper,
  Select,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { GuarantorIcon } from '../../../../../../../src/assets/icons';
import { IGuarantorVerifyFormProps } from '../interface';
import { PhoneNumberInput } from '../../../../../../components/controls/phoneInput/phoneInput';

const selectIDData = [
  { value: 'NIN', label: 'National Identity Number (NIN)' },
  { value: 'DRIVERS_LICENSE', label: "Driver's License" },
  { value: 'VOTER_ID', label: 'Voters Card' },
];

const FormItem = ({
  form,
  index,
}: {
  index: number;
  form: UseFormReturnType<IGuarantorVerifyFormProps>;
}) => {
  const formdata = form.values.data[index];
  return (
    <Group direction="column" spacing="md" grow>
      <TextInput
        label="Full Name"
        placeholder="Applicant name"
        required
        autoFocus
        {...form.getListInputProps('data', index, 'fullName')}
      />
      <InputWrapper label="Phone Number" required>
        <PhoneNumberInput
          size="sm"
          {...form.getListInputProps('data', index, 'phoneNo', {
            type: 'input',
          })}
        />
      </InputWrapper>
      <TextInput
        label="Residential Address"
        placeholder="Guarantor's residential address"
        required
        {...form.getListInputProps('data', index, 'address')}
      />
      <TextInput
        label="Work Address"
        placeholder="Guarantor's work address"
        {...form.getListInputProps('data', index, 'workAddress')}
      />
      <Select
        label="Means of Identification"
        placeholder="Choose means of identification"
        data={selectIDData}
        {...form.getListInputProps('data', index, 'idType')}
        required
      />
      {formdata.idType ? (
        <TextInput
          label={'ID Number'}
          placeholder="Enter ID Number"
          {...form.getListInputProps('data', index, 'idNumber')}
          required
        />
      ) : null}
    </Group>
  );
};

interface ICriminalCheckRequestForm {
  introText: string;
  form: UseFormReturnType<IGuarantorVerifyFormProps>;
  renderIf?: boolean;
  onBack?: () => void;
}
export const GuarantorVerificationForm = ({
  introText,
  form,
  renderIf,
  onBack,
}: ICriminalCheckRequestForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Guarantor Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<GuarantorIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Review</Button>
        </Group>
      }
    >
      <Stack spacing="xl" p="24px">
        <Box
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">{introText}</Text>
        </Box>

        <FormItem form={form} index={0} />
      </Stack>
    </RequestLayout>
  );
};
