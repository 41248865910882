import { Text, Group, TextInput, Textarea, Button, Stack } from '@mantine/core';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { NationalIdIcon } from '../../../../../../../assets/icons/index';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { UG_NationalIdRequestDto } from '@iverifyng/dtos';
import { ServiceSampleImageAccordion } from '../../../../../../../components/primitives/cards/services/serviceSampleImage';

export interface UGNationalIDFormData extends UG_NationalIdRequestDto {
  description: string;
}

interface IUGNationalIDFormProps {
  form: UseFormReturnType<UGNationalIDFormData>;
  renderIf?: boolean;
  onBack?: () => void;
}

export const UGNationalIDForm = ({
  form,
  onBack,
  renderIf,
}: IUGNationalIDFormProps) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="National ID (without photo)"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<NationalIdIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Stack justify="space-between" py="md" sx={() => ({ minHeight: '100%' })}>
        <Group direction="column" px="lg" grow spacing="md">
          <ServiceSampleImageAccordion
            title="Sample National ID Slip"
            image="https://storage.googleapis.com/iverify-public/sample-ids/ug-nationalid.png"
          />
          <Text color="gray" size="sm">
            National ID verification request provides you with the owner's first
            and last name, date of birth, etc.
          </Text>
          <TextInput
            label="National Identity Number"
            placeholder="Enter National ID"
            {...form.getInputProps('idNumber')}
            required
          />
          <Textarea
            label="Description"
            {...form.getInputProps('description')}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
