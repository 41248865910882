import {
  Paper,
  Stack,
  Title,
  Text,
  Center,
  Group,
  Button,
  useMantineTheme,
} from '@mantine/core';
import { AuthLayout } from '../../../components/layouts/auth';
import { useNavigate } from '@tanstack/react-location';
import { withDashboard } from '../../../hoc/withDashboard';
import { StarCheckIcon } from '../../../assets/icons';

function ResetPwdSuccess() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <Center style={{ marginBottom: 20 }}>
          <StarCheckIcon color={theme.colors.blue[5]} />
        </Center>
        <Stack spacing={8} justify="center" align={'center'} mb="xl">
          <Title order={2}>Success</Title>
          <Text size="md" align="center">
            Hooo! You have successfully reset your password.
          </Text>
        </Stack>
        <Group position="apart" mt="xl">
          <Button
            fullWidth
            onClick={() => navigate({ to: '/auth/login', replace: true })}
          >
            Go to Login
          </Button>
        </Group>
      </Paper>
    </AuthLayout>
  );
}

export default withDashboard(ResetPwdSuccess);
