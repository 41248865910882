import {
  Button,
  Stack,
  Text,
  Title,
  TextInput,
  Group,
  LoadingOverlay,
  Divider,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { UpdateWorkspaceRequestDto } from '@iverifyng/dtos';
import { WorkspaceValidatorSchema } from '@iverifyng/validators';
import { useCallback } from 'react';
import { Workspace } from '@prisma/client';
import { useMutation, useQueryClient } from 'react-query';
import { showNotification } from '@mantine/notifications';
import { useCurrentWorkspace } from '../../hooks/workspace';
import {
  DeleteUserWorkspace,
  UpdateUserWorkspace,
} from '../../services/apis/workspace';
import { useModals } from '@mantine/modals';
import { AxiosError } from 'axios';
import { WorkspaceRBAC } from '../../containers/permission/workspace';

const GeneralForm = ({ workspace }: { workspace: Workspace }) => {
  const queryClient = useQueryClient();
  const modals = useModals();

  const form = useForm<UpdateWorkspaceRequestDto>({
    initialValues: {
      name: workspace?.name || '',
      domain: workspace?.domain || '',
    },
  });

  const deleteWorkspace = useMutation(() => DeleteUserWorkspace(workspace.id), {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Workspace deleted',
        message: 'Your workspace has been deleted successfully.',
      });

      window.location.reload();
    },
    onError(error, variables, context) {
      showNotification({
        color: 'red',
        title: 'Error',
        message:
          (error as AxiosError<{ message: string }>)?.response?.data?.message ??
          'Error occurred',
      });
    },
  });

  const handleDelete = useCallback(() => {
    modals.openConfirmModal({
      title: 'Remove Workspace',
      children:
        'Are you sure you want to delete your workspace? This action cannot be retrieved.',
      labels: { confirm: 'Yes, Delete Workspace', cancel: 'No' },
      onConfirm: () => deleteWorkspace.mutate(),
    });
  }, [deleteWorkspace, modals]);

  const updateWorkspace = useMutation(UpdateUserWorkspace, {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Workspace updated',
        message: 'Your workspace has been updated successfully.',
      });
      queryClient.invalidateQueries('profile');
    },
  });

  const onSubmit = useCallback(() => {
    const data = form.values;
    const { error } = WorkspaceValidatorSchema.UPDATE_WORKSPACE.validate(data);
    if (error) {
      showNotification({
        color: 'red',
        message: error.message,
        title: 'Validation Error',
      });
      return;
    }
    updateWorkspace.mutate({
      workspaceId: workspace.id,
      workspace: {
        name: data.name,
        domain: data.domain,
      },
    });
  }, [updateWorkspace, form.values, workspace?.id]);

  return (
    <WorkspaceRBAC action="canViewWorkspace">
      <Stack spacing={'xl'}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Stack spacing={'xl'}>
            <div>
              <Title order={2}>Workspace Details</Title>
              <Text component="span" weight={500} color="gray">
                Update your workspace details.
              </Text>
            </div>

            <Stack spacing="md">
              <TextInput
                label="Workspace Name"
                placeholder="workspace name"
                size="md"
                {...form.getInputProps('name')}
              />
              <TextInput
                label="Domain"
                placeholder="www.example.com"
                size="md"
                {...form.getInputProps('domain')}
              />
            </Stack>
            <Group position="apart">
              <Button
                type="submit"
                disabled={updateWorkspace.isLoading}
                loading={updateWorkspace.isLoading}
              >
                Save Changes
              </Button>
            </Group>
          </Stack>
        </form>
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <Group position="apart">
          <div>
            <Title order={4}>Remove Workspace</Title>
            <Text component="span" weight={500} color="gray">
              Be careful about this operation as deleted workspaces cannot be
              retrieved.
            </Text>
          </div>
          <Button variant="outline" color="red" onClick={handleDelete}>
            Delete Workspace
          </Button>
        </Group>
      </Stack>
    </WorkspaceRBAC>
  );
};

export const General = () => {
  const { workspaceDetails } = useCurrentWorkspace();

  if (!workspaceDetails) {
    return <LoadingOverlay visible />;
  }
  return <GeneralForm workspace={workspaceDetails!} />;
};
