import { RequestHeader } from '../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../components/layouts/request/requestLayout';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { Center, Stack } from '@mantine/core';
import RequestType from '../../../../../components/primitives/cards/request/requestType';
import EmptyState from '../../../../../components/primitives/emptyState/emptyState';
import { DashboardImage } from '../../../../../assets/icons';
import { criminalRecordRequestList } from '../../../../../fakedata/requestData';
import { useQuery } from 'react-query';
import { GetServices } from '../../../../../services/apis/requests';
import { RequestTypeSkeleton } from '../../../../../components/primitives/skeletons/requests';

// interface ICriminalRecordProps {}

export const CriminalRecord = () => {
  const { history } = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery('services', GetServices);

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Criminal Record Check"
          onClickBack={() => history.back()}
          withBackArrow
        />
      }
    >
      {isLoading || !data?.length ? (
        <RequestTypeSkeleton limit={3} />
      ) : criminalRecordRequestList ? (
        <Stack p="xl">
          {criminalRecordRequestList.map(
            ({ type, description, icon, pathName, visiblefn }, idx) => {
              if (visiblefn) {
                const isvisible = visiblefn(data!);
                console.log('data ===', data);
                if (!isvisible) return null;
              }
              return (
                <RequestType
                  key={idx}
                  {...{ type }}
                  {...{ description }}
                  {...{ icon }}
                  onClick={() => navigate({ to: pathName })}
                />
              );
            }
          )}
        </Stack>
      ) : (
        <Center p={'xl'} style={{ height: '100%' }}>
          <EmptyState
            title="No Service"
            subTitle="There are no criminal record check services available. Please check back later."
            icon={<DashboardImage />}
          />
        </Center>
      )}
    </RequestLayout>
  );
};
