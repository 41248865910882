import { NewEmployeeVerificationDto } from '@iverifyng/dtos';
import { Stack } from '@mantine/core';
import RequestDataLayout from '../../../../../../components/layouts/request/requestDataLayout';
import { RequestDetailsBlock } from '../../../../../../connected/requestDetails/block';
import dayjs from 'dayjs';

export const EmployeeReviewAccordionItem = (
  data: NewEmployeeVerificationDto
) => {
  return (
    <Stack mt={10} spacing="md">
      <RequestDetailsBlock title="Biodata" spacing={'sm'}>
        <Stack spacing={'xs'}>
          <RequestDataLayout label="Full Name" data={data.fullName! ?? 'N/A'} />
          {data.otherName && (
            <RequestDataLayout label="Other Name" data={data.otherName} />
          )}
          {data.phoneNo && (
            <RequestDataLayout label="Phone No." data={data.phoneNo} />
          )}
          {data.bvn && <RequestDataLayout label="BVN" data={data.bvn} />}
          {data.idType && (
            <RequestDataLayout label="ID Type" data={data.idType} />
          )}
          {data.idNumber && (
            <RequestDataLayout label="ID Number" data={data.idNumber} />
          )}
          {data?.dob && (
            <RequestDataLayout
              label="Date of Birth"
              data={dayjs(data.dob).format('YYYY-MM-DD')}
            />
          )}
        </Stack>
      </RequestDetailsBlock>
      <RequestDetailsBlock title="Address" spacing={'sm'}>
        <Stack spacing={'xs'}>
          <RequestDataLayout
            label="Residential address"
            data={data.residentialAddress ?? 'N/A'}
          />
          {data.workAddress && (
            <RequestDataLayout label="Work Address" data={data.workAddress} />
          )}
        </Stack>
      </RequestDetailsBlock>
      {data.previousWorkName || data.previousWorkAddress ? (
        <RequestDetailsBlock title="Work History" spacing={'sm'}>
          <Stack spacing={'xs'}>
            {data.previousWorkName && (
              <RequestDataLayout
                label="Previous work name"
                data={data.previousWorkName}
              />
            )}
            {data.previousWorkAddress && (
              <RequestDataLayout
                label="Previous work address"
                data={data.previousWorkAddress}
              />
            )}
          </Stack>
        </RequestDetailsBlock>
      ) : null}
      {data.guarantors.map((guarantor, index) => (
        <RequestDetailsBlock
          title={`GUARANTOR DETAILS ${index + 1}`}
          spacing={'sm'}
        >
          <Stack spacing={'xs'}>
            {guarantor.fullname && (
              <RequestDataLayout label="Full Name" data={guarantor.fullname} />
            )}
            {guarantor.relationship && (
              <RequestDataLayout
                label="Relationship"
                data={guarantor.relationship}
              />
            )}
            {guarantor.address && (
              <RequestDataLayout
                label="Residential Address"
                data={guarantor.address}
              />
            )}
            {guarantor.workAddress && (
              <RequestDataLayout
                label="Work Address"
                data={guarantor.workAddress}
              />
            )}
            {guarantor.phoneNo && (
              <RequestDataLayout label="Phone No." data={guarantor.phoneNo} />
            )}
            {guarantor.idType && (
              <RequestDataLayout label="ID Type" data={guarantor.idType} />
            )}
            {guarantor.idNumber && (
              <RequestDataLayout label="ID Number" data={guarantor.idNumber} />
            )}
          </Stack>
        </RequestDetailsBlock>
      ))}
    </Stack>
  );
};
