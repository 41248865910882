import { getFileObjFromList } from '@iverifyng/utils';
import {
  Paper,
  createStyles,
  Group,
  Text,
  ActionIcon,
  Tooltip,
  RingProgress,
} from '@mantine/core';
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import {
  Exchange,
  Plus,
  Trash,
  X as Cancel,
  Upload,
  AlertCircle,
  Eye as Preview,
} from 'tabler-icons-react';
import {
  useFileUploadState,
  IUploadState,
} from '../../../hooks/use-file-uploader';
import { FileDisplayComp } from './uploader.comp';

const useStyles = createStyles((theme) => ({
  container: {
    background: theme.white,
    borderColor: theme.colors.gray[4],
  },
  hidden: {
    display: 'none',
  },
}));

interface IProps extends IUploadState {
  accept?: string[];
  instantUpload?: boolean;
  onSuccess?: (url: string, file: File) => void;
  onFileSelect?: (file: File) => void;
}
export const FileUploader = ({
  accept,
  mode,
  file,
  instantUpload,
  onSuccess,
  onFileSelect,
}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { classes, theme } = useStyles();
  const { state, upload, removeFile, stopUpload, processUpload } =
    useFileUploadState({
      mode,
      file,
    });
  const handleInputChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const fileslist = e.target.files;
      const result = await getFileObjFromList(fileslist);
      if (!result || !result.length) return;
      const file = result[0].file;
      if (onFileSelect) onFileSelect(file);
      upload({
        file,
        instantUpload,
        onSuccess: (url, file) => onSuccess?.(url, file),
      });
    },
    [instantUpload, onFileSelect, onSuccess, upload]
  );
  //handle browse files
  const openFilePicker = useCallback(() => {
    if (state.mode === 'read') return;
    if (inputRef && inputRef.current) {
      inputRef.current?.click();
    }
  }, [state.mode]);

  //handle upload if file object is defined
  useEffect(() => {
    if (file && mode === 'edit') {
      upload({
        file,
        instantUpload,
        onSuccess: (url, file) => onSuccess?.(url, file),
      });
    }
  }, [file, instantUpload, mode, onSuccess, upload]);
  return (
    <>
      {!state.file && (
        <Paper
          px="sm"
          py="xs"
          withBorder
          className={classes.container}
          onClick={openFilePicker}
        >
          <Group position="apart">
            <Text size="sm" color={theme.colors.gray[5]}>
              Click to select file
            </Text>
            <Plus width={16} height={16} color={theme.colors.gray[5]} />
          </Group>
        </Paper>
      )}

      {state.file && (
        <Paper px="sm" py="xs" withBorder className={classes.container}>
          <FileDisplayComp
            file={state.file}
            actionsNode={
              <>
                {state.mode === 'edit' && state.status === 'pending' && (
                  <Group spacing={'xs'}>
                    <Tooltip label="Change file" withArrow>
                      <ActionIcon variant="default" onClick={openFilePicker}>
                        <Exchange width={12} color={theme.colors.gray[5]} />
                      </ActionIcon>
                    </Tooltip>

                    <Tooltip label="Remove file" withArrow>
                      <ActionIcon variant="default" onClick={removeFile}>
                        <Trash width={12} color={theme.colors.gray[5]} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                )}
                {state.mode === 'edit' && state.status === 'uploading' && (
                  <Group spacing={'xs'}>
                    <RingProgress
                      size={32}
                      thickness={2}
                      sections={[{ value: state.percent!, color: 'blue' }]}
                      label={
                        <Text
                          color="blue"
                          weight={700}
                          align="center"
                          sx={() => ({ fontSize: 6 })}
                        >
                          {state.percent}%
                        </Text>
                      }
                    />

                    <Tooltip label="Stop upload" withArrow>
                      <ActionIcon onClick={stopUpload}>
                        <Cancel width={12} color={theme.colors.red[5]} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                )}
                {state.mode === 'edit' && state.status === 'failed' && (
                  <Group spacing={'xs'}>
                    <Tooltip
                      label={state.error?.response?.message ?? 'Error occurred'}
                    >
                      <ActionIcon>
                        <AlertCircle width={12} color={theme.colors.red[5]} />
                      </ActionIcon>
                    </Tooltip>

                    <Tooltip label="Retry upload" withArrow>
                      <ActionIcon
                        variant="default"
                        onClick={() => processUpload()}
                      >
                        <Upload width={12} color={theme.colors.gray[5]} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                )}
                {state.mode === 'edit' && state.status === 'success' && (
                  <Group spacing={'xs'}>
                    <Tooltip label="Preview file" withArrow>
                      <ActionIcon
                        variant="default"
                        onClick={() =>
                          window.open(state.result?.mediaLink, '_blank')
                        }
                      >
                        <Preview width={12} color={theme.colors.gray[5]} />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Change file" withArrow>
                      <ActionIcon variant="default" onClick={openFilePicker}>
                        <Exchange width={12} color={theme.colors.gray[5]} />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                )}
              </>
            }
          />
        </Paper>
      )}
      {state.mode === 'edit' && (
        <input
          type="file"
          name="file"
          accept={accept ? accept?.join(',') : '*'}
          ref={inputRef}
          className={classes.hidden}
          onChange={handleInputChange}
        />
      )}
    </>
  );
};
