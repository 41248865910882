import { useMatch } from '@tanstack/react-location';
import {
  CreateComment,
  CreateCommentParam,
} from '../../../../../services/apis/admin';
import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { RequestGenerics } from '../tabOutlet';
import { showNotification, updateNotification } from '@mantine/notifications';
import { CircleCheck } from 'tabler-icons-react';
import { useRequestComments } from '../../../../../hooks/admin';
import { CommentsView } from '../../../components/comments/comments';

export const Comments = () => {
  const {
    params: { requestId },
  } = useMatch<RequestGenerics>();

  const queryClient = useQueryClient();

  const createComment = useMutation(CreateComment);
  const { comments, isFetching, isLoading, isError } =
    useRequestComments(requestId);

  const handleCreateComment = useCallback(
    (params: CreateCommentParam) => {
      showNotification({
        id: 'create-comment',
        title: 'Adding Comment',
        message: 'adding new comment.',
        loading: true,
        disallowClose: true,
      });
      createComment.mutate(params, {
        onSuccess: () => {
          updateNotification({
            id: 'create-comment',
            title: 'Create Comment',
            message: 'new comment added.',
            icon: <CircleCheck />,
          });
          queryClient.invalidateQueries(['comments', requestId]);
        },
      });
    },
    [createComment, queryClient, requestId]
  );

  return (
    <CommentsView
      comments={comments}
      handleCreateComment={handleCreateComment}
      requestId={requestId}
      isLoadingComments={isLoading}
      isLoadingCreateComments={createComment.isLoading}
    />
  );
};
