import { Avatar, Stack, Center } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { GHDriversLicenseResponseDto } from '../../gh/interface';

export const GHDriversLicenseReport = (props: GHDriversLicenseResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.DriverImage ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64, ${props.DriverImage}`}
            size="lg"
            radius="xl"
            alt=""
          />
        </Center>
      ) : null}

      <RequestDataLayout label="PIN" data={props.PIN ?? 'N/A'} />
      <RequestDataLayout label="Full name" data={props.Name ?? 'N/A'} />
      <RequestDataLayout label="Expiry Date" data={props.ExpiryDate ?? 'N/A'} />
      <RequestDataLayout
        label="Date of Birth"
        data={props.DateOfBirth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Date of Issue"
        data={props.DateOfIssue ?? 'N/A'}
      />
      <RequestDataLayout
        label="Nationality"
        data={props.Nationality ?? 'N/A'}
      />
      <RequestDataLayout
        label="Certificate Date"
        data={props.CertificateDate ?? ''}
      />
      <RequestDataLayout
        label="Processing Center"
        data={props.ProcessingCenter ?? 'N/A'}
      />
      <RequestDataLayout
        label="Class Of License"
        data={props.ClassOfLicence ?? 'N/A'}
      />
      <RequestDataLayout
        label="Certificate of Competence"
        data={props.CertificateOfCompetence ?? 'N/A'}
      />
    </Stack>
  );
};
