import { Box, Paper } from '@mantine/core';
import { ReactNode } from 'react';

import { ChartHeader, IHeaderProps } from '../components/header';

interface IAnalyticsLayout extends IHeaderProps {
  children?: ReactNode;
}

export const AnalyticsLayout = ({ children, ...props }: IAnalyticsLayout) => {
  return (
    <Paper py="md" pl="sm" pr="sm" radius="md" shadow="xl">
      <ChartHeader {...props} />
      <Box pt="xs">{children}</Box>
    </Paper>
  );
};
