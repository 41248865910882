import { Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { TINResponseDto } from '../../ng/interface';

export const TINReport = (props: TINResponseDto) => {
  return (
    <Stack spacing="sm">
      <RequestDataLayout label="Email" data={props.Email ?? 'N/A'} />
      <RequestDataLayout label="TIN" data={props.TIN ?? 'N/A'} />
      <RequestDataLayout label="JITTIN" data={props.JITTIN ?? 'N/A'} />
      <RequestDataLayout label="Phone No" data={props.Phone_No ?? 'N/A'} />
      <RequestDataLayout label="CAC Reg No" data={props.CAC_Reg_No ?? 'N/A'} />
      <RequestDataLayout label="Tax Office" data={props.Tax_Office ?? 'N/A'} />
      <RequestDataLayout
        label="Type of Entity"
        data={props.TypeofEntity ?? 'N/A'}
      />
      <RequestDataLayout
        label="Taxpayer Name"
        data={props.Taxpayer_Name ?? 'N/A'}
      />
    </Stack>
  );
};
