import {
  GetInvitedTeamMember,
  GetWorkspaceMembersApi,
} from './../services/apis/workspace';
import { useLocalStorage } from '@mantine/hooks';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useUserProfile } from '../services/queries/users';

export const useCurrentWorkspace = () => {
  const [workspaceId, setWorkspaceId] = useLocalStorage<string | null>({
    key: 'iverify-workspace-id',
    defaultValue: null,
  });
  const { isLoading, data } = useUserProfile();
  const currentUser = data;
  useEffect(() => {
    if (isLoading) return; // Do nothing while loading
    if (currentUser) {
      //...process the active workspace Id

      if (currentUser.workspaces && currentUser.workspaces.length) {
        const existingWorkspace = currentUser.workspaces.find(
          (wrk) => wrk.workspaceId === workspaceId
        );
        if (!existingWorkspace || !workspaceId) {
          const value = currentUser.workspaces[0].workspaceId;
          setWorkspaceId(value);
        }
      }
    }
  }, [workspaceId, currentUser, setWorkspaceId, isLoading]);

  const workspaceDetails = useMemo(() => {
    if (!!currentUser && workspaceId) {
      const workspaceUser = currentUser.workspaces.find(
        (wrk) => wrk.workspaceId === workspaceId
      );
      return workspaceUser;
    }
    return null;
  }, [currentUser, workspaceId]);

  return {
    workspaceId,
    setWorkspaceId,
    workspaceDetails: workspaceDetails?.workspace,
    workspaceUserRole: workspaceDetails?.mainRole,
  };
};

export const useGetWorkspaceInvites = (workspaceId: string) => {
  const queryKey = ['invites', workspaceId];
  const { isError, isLoading, data, error, isFetching } = useQuery(
    queryKey,
    () => GetInvitedTeamMember(workspaceId),
    {
      enabled: !!workspaceId,
    }
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data || [],
    error,
    queryKey,
  };
};
export const useGetWorkspaceMembers = (workspaceId: string) => {
  const queryKey = ['workspaceMembers', workspaceId];
  const { isError, isLoading, data, error, isFetching } = useQuery(
    queryKey,
    () => GetWorkspaceMembersApi(workspaceId),
    {
      enabled: !!workspaceId,
    }
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data || [],
    error,
    queryKey,
  };
};
