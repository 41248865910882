import {
  Stack,
  Box,
  Text,
  Group,
  TextInput,
  Textarea,
  Select,
  Button,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useLocation } from '@tanstack/react-location';
import { VoterIcon } from '../../../../../../../assets/icons';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { ServiceSampleImageAccordion } from '../../../../../../../components/primitives/cards/services/serviceSampleImage';
import { NINFormData } from '../../../../ng/identityVerification/subRoutes/nin/containers/ninForm';
import { IssuedTimelineType } from '../votersCard';
interface VotersCardFormProps {
  form: UseFormReturnType<NINFormData>;
  onSelect: React.Dispatch<React.SetStateAction<IssuedTimelineType | null>>;
  renderIf?: boolean;
}

export const GHVotersCardForm = ({
  form,
  onSelect,
  renderIf,
}: VotersCardFormProps) => {
  const { history } = useLocation();

  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Voter ID - Ghana"
          onClickBack={() => history.back()}
          withBackArrow
          withBackground
          icon={<VoterIcon width={42} height={42} />}
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button color="dark" variant="outline" onClick={() => history.back()}>
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Group direction="column" px="lg" grow spacing="md" pt="md">
        <ServiceSampleImageAccordion
          title="Sample Voter ID"
          image="https://storage.googleapis.com/iverify-public/sample-ids/gh-new-votersid.png"
        />
        <Text size="sm">
          Voter ID verification request provides you with the owner's first and
          last name, date of birth, etc.
        </Text>
        <TextInput
          required
          label="VIN"
          placeholder="Enter Voter ID number"
          {...form.getInputProps('idNumber')}
        />
        <Select
          label="When was this card issued?"
          placeholder="Choose issued timeline"
          data={[
            { value: 'beforeJune2020', label: 'BEFORE JUNE 2020' },
            {
              value: 'afterJune2020',
              label: 'AFTER JUNE 2020 (New Voter Card)',
            },
          ]}
          onChange={(e: IssuedTimelineType) => onSelect(e)}
          required
        />
        <Textarea label="Description" {...form.getInputProps('description')} />
      </Group>
    </RequestLayout>
  );
};
