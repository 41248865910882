import { Box, Group, Navbar, Text } from '@mantine/core';
import { ReactNode } from 'react';
import logo from '../../assets/iverify-logo-white.png';
import HeadwayWidget from '../../components/controls/widget/widget';
import HeaderMenu from '../../components/primitives/headerMenu/headerMenu';
import { IHeaderLinksMenu } from '../../components/primitives/headerMenu/headerMenu.interface';
import CountrySelector from './headerDropdown/countrySelector';
import UserOptions from './headerDropdown/userOptions';

const Header = ({
  links,
  rightSideNode,
}: {
  links: IHeaderLinksMenu;
  rightSideNode?: ReactNode;
}) => {
  return (
    <Navbar
      height={'auto'}
      sx={(theme) => ({
        backgroundColor: theme.colors.blue[8],
        padding: '0px 32px',
      })}
      py="md"
    >
      <Group position="apart">
        <Group spacing="xl">
          <img src={logo} alt="iverifyng" width={95} height={32} />
          <div style={{ marginLeft: 25 }}>
            <HeaderMenu links={links} />
          </div>
        </Group>
        <Group align="center" spacing="xl">
          {rightSideNode}
          <HeadwayWidget
            id="widget-external-trigger"
            account="7zrDQ7"
            badgePosition="top-right"
            widgetPosition="bottom-left"
            trigger
          >
            <Text
              size="sm"
              component="span"
              color="white"
              style={{ cursor: 'pointer' }}
              className="HW_badge_container HW_widget_component_widget-external-trigger"
            >
              What's New?
            </Text>
          </HeadwayWidget>
          <Box mb={-6}>
            <CountrySelector />
          </Box>
          <UserOptions />
        </Group>
      </Group>
    </Navbar>
  );
};
export default Header;
