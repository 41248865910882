import {
  CreateRequestReponseDto,
  CreateGuarantorVerificationDto,
  UpdateGuarantorVerificationDto,
  AdminGuarantorUpdateReportDto,
  GuarantoreVerificationRecordDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';

interface ICreateCGuarantorVerificationDto
  extends CreateGuarantorVerificationDto {
  workspaceId: string;
}
export const CreateGuarantorVerificationApi = async ({
  workspaceId,
  ...data
}: ICreateCGuarantorVerificationDto): Promise<CreateRequestReponseDto> => {
  const url = `/request/guarantors/${workspaceId}/new`;
  return await axiosInstance.post(url, data).then((response) => response.data);
};

export const AdminUpdateGuarantorApi = async (
  data: UpdateGuarantorVerificationDto
): Promise<UpdateGuarantorVerificationDto> => {
  const url = '/admin/requests/guarantor';
  return await axiosInstance.put(url, data).then((response) => response.data);
};

export const AdminUpdateGuarantorReportApi = async (
  data: AdminGuarantorUpdateReportDto
): Promise<GuarantoreVerificationRecordDto> => {
  const url = `/admin/requests/guarantor/reportUrl`;
  return await axiosInstance.patch(url, data).then((response) => response.data);
};

export const AdminRemoveGuarantorReportApi = async (params: {
  guarantorId: string;
}): Promise<GuarantoreVerificationRecordDto> => {
  const url = `/admin/requests/guarantor/${params.guarantorId}/reportUrl`;
  return await axiosInstance.delete(url).then((response) => response.data);
};
