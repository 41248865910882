import {
  Anchor,
  Box,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { Link, useNavigate } from '@tanstack/react-location';
import { withDashboard } from '../../../hoc/withDashboard';
import { ArrowLeft } from 'tabler-icons-react';
import { AuthLayout } from '../../../components/layouts/auth';
import { joiResolver, useForm } from '@mantine/form';
import { ForgotPasswordDto } from '@iverifyng/services/auth';
import { AuthValidatorSchema } from '@iverifyng/validators';
import { useMutation } from 'react-query';
import { ForgotPasswordApi } from '../../../services/apis/users';
import { useCallback } from 'react';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';

function ForgotPassword() {
  const navigate = useNavigate();
  const form = useForm<ForgotPasswordDto>({
    schema: joiResolver(AuthValidatorSchema.FORGOT_PASSWORD),
    initialValues: {
      email: '',
    },
  });
  const forgotPwdMutation = useMutation(ForgotPasswordApi);
  const handleSubmit = useCallback(
    (values: ForgotPasswordDto) => {
      forgotPwdMutation.mutate(values, {
        onSuccess: (response) => {
          showNotification({
            message: response.data['message'],
            color: 'green',
          });
          navigate({
            to: '/auth/verifyotp',
            replace: true,
            search: { userId: response.data['userId'] },
          });
        },
        onError: (error, variables, context) => {
          if (error)
            showNotification({
              message:
                (error as AxiosError<{ message: string }>)?.response?.data
                  ?.message ?? 'Error occurred',
              color: 'red',
            });
        },
      });
    },
    [forgotPwdMutation, navigate]
  );
  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <Stack spacing={8} justify="center" align={'center'} mb="xl">
          <Title order={2}>Forgot Password</Title>
          <Text size="md">
            No worries, we will send you reset intsructions.
          </Text>
        </Stack>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Group direction="column" grow>
            <TextInput
              label="Email"
              placeholder="Email"
              {...form.getInputProps('email')}
            />
          </Group>

          <Group position="apart" mt="xl">
            <Button
              type="submit"
              fullWidth
              disabled={forgotPwdMutation.isLoading}
            >
              Verify Email
            </Button>
          </Group>
        </form>

        <Center mt={'xl'}>
          <Link to={'/auth/login'}>
            <Anchor component="button" type="button" color="blue" size="sm">
              <Center inline>
                <ArrowLeft size={12} />
                <Box ml={5}>{'Back to Login'}</Box>
              </Center>
            </Anchor>
          </Link>
        </Center>
      </Paper>
    </AuthLayout>
  );
}

export default withDashboard(ForgotPassword);
