import { useMatch } from '@tanstack/react-location';
import { useAdminUserDetails } from '../../../../hooks/admin-users';
import { UserGenerics } from '../userTabOutlet';
import { AdminUserDetailView } from '../components/userDetailView';

export const AdminUserDetail = () => {
  const {
    params: { userId },
  } = useMatch<UserGenerics>();
  const { data: user, isLoading, isError } = useAdminUserDetails(userId);

  return (
    <AdminUserDetailView user={user} isError={isError} isLoading={isLoading} />
  );
};
