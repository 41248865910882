import { Accordion, createStyles, Image } from '@mantine/core';

const useStyles = createStyles((theme, _params, getRef) => ({
  control: {
    ref: getRef('control'),
    border: '1px solid transparent',
    borderRadius: theme.radius.sm,
    opacity: 0.6,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },

  item: {
    border: '1px solid transparent',
    borderRadius: theme.radius.sm,
    borderColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[3],
  },

  itemOpened: {
    border: '1px solid transparent',
    borderRadius: theme.radius.sm,
    borderColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[3],
  },

  content: {
    paddingLeft: 0,
  },
}));

interface IProps {
  title: string;
  image?: string;
}
export const ServiceSampleImageAccordion = ({ title, image }: IProps) => {
  const { classes } = useStyles();

  return (
    <Accordion
      classNames={classes}
      iconPosition="right"
      offsetIcon={false}
      initialItem={0}
      multiple
    >
      <Accordion.Item label={title}>
        <Image src={image} withPlaceholder fit="contain" height={200} />
      </Accordion.Item>
    </Accordion>
  );
};
