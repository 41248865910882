import { LoadingOverlay } from '@mantine/core';
import { useNavigate } from '@tanstack/react-location';
import { useEffect } from 'react';
import { useUserProfile } from '../services/queries/users';

//this HOC checks if user is an admin
//if NOT, user is redirected to customer view

interface IProps {
  children?: React.ReactNode;
}

export function withAdmin<T extends IProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentwithAuth = (props: T) => {
    const navigate = useNavigate();
    const { isLoading, isAdmin, isAgent } = useUserProfile();
    useEffect(() => {
      if (isLoading) return; // Do nothing while loading
      if (!isAdmin && !isAgent) navigate({ to: '/dashboard' });
    }, [isAdmin, isAgent, isLoading, navigate]);

    if (isAdmin || isAgent) return <WrappedComponent {...props} />;

    // Session is being fetched, or no user.
    // If not admin, useEffect() will redirect to customer view
    return <LoadingOverlay visible />;
  };

  ComponentwithAuth.displayName = `withAdmin(${displayName})`;
  return ComponentwithAuth;
}
