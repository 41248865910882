import { AppRBACKeys, appRoleAccessCheck } from '@iverifyng/utils';
import { ReactElement } from 'react';
import AccessDenied from '../notfound/404pages';
import { withAuth } from '../../hoc/withAuth';
import { useUserProfile } from '../../services/queries/users';

interface IPermissionProps {
  action: AppRBACKeys;
  children: ReactElement;
}

const APComp = ({ action, children }: IPermissionProps) => {
  const { roles } = useUserProfile();
  const hasAccess = appRoleAccessCheck(roles, action);
  if (!hasAccess) return <AccessDenied />;
  return children;
};

export const AppRBAC = withAuth(APComp);
