import { Group } from '@mantine/core';
import React from 'react';
import { DashStatCard } from '../cards/dashboard/dashStatCard/dashStatCard';

export const StatsSkeleton = ({ limit = 3 }: { limit?: number }) => {
  if (limit < 1) return null;

  return (
    <Group direction="row">
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <DashStatCard
            key={i}
            stat={{ status: 'CANCELLED', count: 2000 }}
            skeleton
          />
        ))}
    </Group>
  );
};
