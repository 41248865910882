import { AccordionItem, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';
import { EmployeeGuarantorDto } from '@iverifyng/dtos';
import { triggerURLDownload } from '@iverifyng/utils';
import { employeeStatusDescription } from '../../../utils/status';
import { getTenantIDType } from '../../../components/primitives/cards/request/requestCard/helper';
import { EmployeeVerification } from '@prisma/client';
import { RequestDetailsBlock } from '../block';
import { AdminEmployee3DotMenu } from '../../menus/requests/employee';
import dayjs from 'dayjs';

interface IProps {
  index: number;
  record: EmployeeVerification;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const EmployeeAccordionItem = ({ record, index }: IProps) => {
  const guarantors =
    record.guarantors as unknown as Array<EmployeeGuarantorDto>;
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={record.fullName!}
            rightIcon={<AdminEmployee3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={employeeStatusDescription[record.status!]}
              />
            }
          />
          <RequestDetailsBlock title="Biodata">
            <RequestDataLayout
              label="Full Name"
              data={record.fullName ?? 'N/A'}
            />
            {record.otherName && (
              <RequestDataLayout label="Other Name" data={record.otherName} />
            )}
            {record.phoneNo && (
              <RequestDataLayout label="Phone No." data={record.phoneNo} />
            )}
            {record.bvn && <RequestDataLayout label="BVN" data={record.bvn} />}
            <RequestDataLayout
              label={getTenantIDType(record.idType!)}
              data={record.idNumber ?? 'N/A'}
            />
            {record?.dob && (
              <RequestDataLayout
                label="Date of Birth"
                data={dayjs(record.dob).format('YYYY-MM-DD')}
              />
            )}
          </RequestDetailsBlock>
          <RequestDetailsBlock title="Address" spacing={'sm'}>
            <Stack spacing={'xs'}>
              {record.residentialAddress && (
                <RequestDataLayout
                  label="Residential address"
                  data={record.residentialAddress}
                />
              )}
              {record.workAddress && (
                <RequestDataLayout
                  label="Work Address"
                  data={record.workAddress}
                />
              )}
            </Stack>
          </RequestDetailsBlock>
          <RequestDetailsBlock title="Work History">
            <RequestDataLayout
              label="Previous Work Name"
              data={record.previousWorkName ?? 'N/A'}
            />
            <RequestDataLayout
              label="Previous Work Address"
              data={record.previousWorkAddress ?? 'N/A'}
            />
          </RequestDetailsBlock>

          {guarantors.map((guarantor, index) => {
            return (
              <RequestDetailsBlock title={`Guarantor - ${index + 1}`}>
                {guarantor.fullname && (
                  <RequestDataLayout
                    label="Full Name"
                    data={guarantor.fullname}
                  />
                )}
                {guarantor.relationship && (
                  <RequestDataLayout
                    label="Relationship"
                    data={guarantor.relationship}
                  />
                )}
                {guarantor.address && (
                  <RequestDataLayout
                    label="Residential Address"
                    data={guarantor.address}
                  />
                )}
                {guarantor.workAddress && (
                  <RequestDataLayout
                    label="Work Address"
                    data={guarantor.workAddress}
                  />
                )}
                {guarantor.phoneNo && (
                  <RequestDataLayout
                    label="Phone No."
                    data={guarantor.phoneNo}
                  />
                )}
                <RequestDataLayout
                  label={getTenantIDType(guarantor.idType!)}
                  data={guarantor.idNumber ?? 'N/A'}
                />
                {guarantor?.dob && (
                  <RequestDataLayout
                    label="Date of Birth"
                    data={dayjs(guarantor.dob).format('YYYY-MM-DD')}
                  />
                )}
              </RequestDetailsBlock>
            );
          })}
          {record.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
