/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { NG_TINRequestDto } from '@iverifyng/dtos';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { useLocation } from '@tanstack/react-location';
import React from 'react';
import { joiResolver, useForm } from '@mantine/form';
import { Box, Button, Group } from '@mantine/core';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import { ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../hooks/workspace';
import { useCurrentCountry } from '../../../../../../hooks/country';
import { TINRequestForm } from './container/tinForm';
import { TINIcon } from '../../../../../../assets/icons';
import { ServiceCodeTypes } from '@iverifyng/dtos';
import { TINDetailsConfirmation } from './container/tinConfirmation';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../hooks/request';
import { useConsentConfirmation } from '../../../../../../hooks/consent';
import { debounce } from 'lodash';

export interface ITINRequestProps extends NG_TINRequestDto {
  description: string;
}
export const TIN = () => {
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const { history } = useLocation();
  const [review, setReview] = React.useState(true);

  const form = useForm<ITINRequestProps>({
    initialValues: {
      idNumber: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.TIN),
  });

  const handleReview = () => {
    setReview(false);
  };
  const { showConsent } = useConsentConfirmation();
  const TINRequest = useCreateIdentityVerification();

  const { data: serviceCharge } = useServiceCharge({
    code: 'TIN' as ServiceCodeTypes,
    country: country! as ServiceCountry,
  });

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        TINRequest.mutate({
          code: 'TIN' as ServiceCodeTypes,
          country: country as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            description: data.description,
          },
        });
      },
    });
  }, [showConsent, form.values, TINRequest, country, workspaceId]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  const isLoading = React.useMemo(() => {
    return TINRequest.isLoading;
  }, [TINRequest.isLoading]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleReview)}>
      <RequestLayout
        headerFragment={
          <RequestHeader
            text="Tax Identification Number"
            onClickBack={() => (review ? history.back() : setReview(true))}
            withBackArrow
            withBackground
            icon={<TINIcon width={42} height={42} />}
          />
        }
        footerFragment={
          <Group position="apart" grow>
            {review && (
              <Button
                variant="outline"
                color="dark"
                onClick={() => history.back()}
              >
                Cancel request
              </Button>
            )}
            {review ? (
              <Button type="submit">Verify</Button>
            ) : (
              <Button
                type="submit"
                onClick={handleDoubleClick}
                loading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            )}
          </Group>
        }
      >
        <Box sx={() => ({ height: '100%' })}>
          {review ? (
            <TINRequestForm
              introText="TIN verification request provides you with information such as Type of Entity, CAC Registration No, etc."
              form={form}
            />
          ) : (
            <TINDetailsConfirmation
              data={form.values}
              onEditClick={() => setReview(true)}
              billed={{
                currency: serviceCharge?.currency!,
                amount: serviceCharge?.amount!,
              }}
            />
          )}
        </Box>
      </RequestLayout>
    </form>
  );
};
