import { ReactComponent as UserZeroIcon } from '../svg/userZero-icon.svg';
import { ReactComponent as UserDetailZeroIcon } from '../svg/userDetailZero-icon.svg';
import { ReactComponent as LineChartIcon } from '../svg/line-chart-icon.svg';
import { ReactComponent as PieChartIcon } from '../svg/pie-chart-icon.svg';
import { ReactComponent as UserGrowthIcon } from '../svg/growth-icon.svg';
import { ReactComponent as CompletePaymentIcon } from '../svg/payment-icon.svg';
import { ReactComponent as ServiceIcon } from '../svg/service-icon.svg';
import { ReactComponent as RequestIcon } from '../svg/request-icon.svg';
import { ReactComponent as NotFoundIcon } from '../svg/403.svg';

export {
  UserZeroIcon,
  UserDetailZeroIcon,
  LineChartIcon,
  PieChartIcon,
  UserGrowthIcon,
  CompletePaymentIcon,
  ServiceIcon,
  RequestIcon,
  NotFoundIcon,
};
