import { Center, Stack } from '@mantine/core';
import RequestType from '../../../../../components/primitives/cards/request/requestType';
import { requestTypeList } from '../../../../../fakedata/requestData';
import { useNavigate } from '@tanstack/react-location';
import { RequestHeader } from '../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../components/layouts/request/requestLayout';
import { useCurrentCountry } from '../../../../../hooks/country';
import { GetServices } from '../../../../../services/apis/requests';
import EmptyState from '../../../../../components/primitives/emptyState/emptyState';
import { DashboardImage } from '../../../../../assets/icons';
import { useQuery } from 'react-query';
import { RequestTypeSkeleton } from '../../../../../components/primitives/skeletons/requests';

export const ChooseRequest = () => {
  const navigate = useNavigate();
  const { country } = useCurrentCountry();
  const { data, isLoading } = useQuery('services', GetServices);

  const servicesList = requestTypeList[country!] ?? null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Make New Request"
          onCancel={() => navigate({ to: '/request' })}
          withClose
        />
      }
    >
      {isLoading || !data?.length ? (
        <RequestTypeSkeleton limit={6} />
      ) : servicesList ? (
        <Stack p="xl">
          {servicesList.map(
            ({ type, description, icon, pathName, visiblefn }, idx) => {
              if (visiblefn) {
                const isvisible = visiblefn(data!);

                if (!isvisible) return null;
              }
              return (
                <RequestType
                  key={idx}
                  {...{ type }}
                  {...{ description }}
                  {...{ icon }}
                  onClick={() => navigate({ to: pathName })}
                />
              );
            }
          )}
        </Stack>
      ) : (
        <Center p={'xl'} style={{ height: '100%' }}>
          <EmptyState
            title="No Services"
            subTitle="There are no services available. Please check back later, or use the country selector for other services."
            icon={<DashboardImage />}
          />
        </Center>
      )}
    </RequestLayout>
  );
};
