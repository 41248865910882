import {
  Autocomplete,
  Loader,
  SelectItemProps,
  Text,
  Group,
} from '@mantine/core';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
import { GetAdminUsers } from '../../services/apis/admin';

export interface ItemProps extends SelectItemProps {
  value: string;
  label: string;
  id: string;
  email: string;
}

interface IProps {
  defaultValue?: string;
  onItemSelect?: (item: ItemProps) => void;
}

const AutoCompleteItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, email, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Text size="sm">{label}</Text>
        </Group>
        <Text size="xs">{email}</Text>
      </div>
    );
  }
);

export const UsersDropdown = ({ onItemSelect, defaultValue }: IProps) => {
  const [data, setData] = useState<ItemProps[]>([]);
  const [loading, setLoading] = useState(false);
  const handleSearch = useCallback(async (val: string) => {
    // if (val.trim().length === 0) return;
    setLoading(true);
    const users = await GetAdminUsers({ searchterm: val });
    const userDataMapped = users.data.map((user) => ({
      ...user,
      value: `${user.firstName} ${user.lastName}`,
      label: `${user.firstName} ${user.lastName}`,
      id: user.id,
    }));
    setData(userDataMapped);

    setLoading(false);
  }, []);

  //Delay searched packages refetch for 500ms after onchange
  const debouncedSearch = useRef(debounce(handleSearch, 500)).current;
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  return (
    <Autocomplete
      data={data}
      label="Requester"
      defaultValue={defaultValue}
      onChange={debouncedSearch}
      rightSection={loading ? <Loader size={16} /> : null}
      maxDropdownHeight={200}
      onItemSubmit={onItemSelect}
      itemComponent={AutoCompleteItem}
      placeholder="Search by name or email"
    />
  );
};
