import { GetAdminPaymentsQueryDto } from '@iverifyng/dtos';
import { useQuery } from 'react-query';
import { GetUserPaymentsByWorkspaceApi } from '../apis/payments';

export const useGetUserPayments = (
  params: GetAdminPaymentsQueryDto & { workspaceId: string }
) => {
  const queryKey = ['userpayments', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetUserPaymentsByWorkspaceApi(params),
    {
      enabled: !!params.limit && !!params.workspaceId,
    }
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};
