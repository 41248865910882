import { IProps } from './newCriminalCheck';
import { Divider, Stack } from '@mantine/core';
import { useNavigate } from '@tanstack/react-location';
import { RequestLayout } from '../../../..//src/components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../src/components/primitives/cards/request/requestHeader/requestHeader';
import { CreditIcon } from '../../../../src/assets/icons';
import { CommonRequestDetails } from '../../requestDetails/commonInfo';
import { RequestDetailsBlock } from '../../requestDetails/block';

import { CreditCheckAccordionItem } from '../../requestDetails/creditCheck/accordionItem';

export const CreditCheckDetailsView = ({
  data,
  renderIf,
  footerNode,
}: IProps) => {
  const navigate = useNavigate();

  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Credit Check Verification"
          withClose
          withBackground
          icon={<CreditIcon width={42} height={42} />}
          onCancel={() => navigate({ to: '/request' })}
        />
      }
      footerFragment={footerNode}
    >
      <Stack pt="sm" px="lg">
        <CommonRequestDetails {...data} />
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <RequestDetailsBlock title="Request Details">
          {data.creditChecks.map((creditCheck, index) => (
            <CreditCheckAccordionItem
              key={index}
              record={creditCheck}
              index={index}
            />
          ))}
        </RequestDetailsBlock>
      </Stack>
    </RequestLayout>
  );
};
