import {
  createStyles,
  Group,
  Text,
  UnstyledButton,
  UnstyledButtonProps,
} from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import { PDFIcon } from '../../../assets/icons';

const useStyles = createStyles((theme) => ({
  button: {
    '&:hover': {
      color: theme.colors.blue[5],
    },
  },
  addmore: {
    color: theme.colors.blue[5],
    '&:hover': {
      color: theme.colors.blue[3],
    },
  },
}));
export const PDFDownloadButton = (props: UnstyledButtonProps<'button'>) => {
  const { classes } = useStyles();
  return (
    <UnstyledButton {...props} className={classes.button}>
      <Group spacing={'xs'}>
        <PDFIcon width={12} />
        <Text size="xs">{props.children ?? 'Download'}</Text>
      </Group>
    </UnstyledButton>
  );
};

export const AddMoreButton = (
  props: UnstyledButtonProps<'button'> & { text?: string }
) => {
  const { classes } = useStyles();
  return (
    <UnstyledButton {...props} className={classes.addmore}>
      <Group spacing={'xs'}>
        <Plus width={12} />
        <Text size="sm">{props.text ?? 'Add More'}</Text>
      </Group>
    </UnstyledButton>
  );
};
