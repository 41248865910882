import { AccordionItem, Divider, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';
import { TenantGuarantorDto, TenantLandlordDto } from '@iverifyng/dtos';
import { triggerURLDownload } from '@iverifyng/utils';
import { tenantStatusDescription } from '../../../utils/status';
import { getTenantIDType } from '../../../components/primitives/cards/request/requestCard/helper';
import { TenantVerification } from '@prisma/client';
import { RequestDetailsBlock } from '../block';
import { AdminTenant3DotMenu } from '../../menus/requests/tenant';

interface IProps {
  index: number;
  record: TenantVerification;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const TenantAccordionItem = ({ record, index }: IProps) => {
  const guarantors = record.guarantors as unknown as Array<TenantGuarantorDto>;
  const landlords = record?.landlords as unknown as Array<TenantLandlordDto>;
  const guarantorInfo = guarantors?.[0];
  const landlordInfo = landlords?.[0];
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={record.fullName!}
            rightIcon={<AdminTenant3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={tenantStatusDescription[record.status!]}
              />
            }
          />
          <RequestDetailsBlock title="Tenant's Basic Details">
            <RequestDataLayout label="Full Name" data={record.fullName} />
            <RequestDataLayout label="Phone Number" data={record.phoneNo!} />
            <RequestDataLayout
              label={getTenantIDType(record.idType!)}
              data={record.idNumber}
            />
            <RequestDataLayout
              label="Residential Address"
              data={record.residentialAddress}
            />

            <RequestDataLayout label="Work Address" data={record.workAddress} />
            <RequestDataLayout
              label="Spouse Name"
              data={record.spouseName ?? 'N/A'}
            />
            <RequestDataLayout
              label="Reason for relocating"
              data={record.relocationReason ?? 'N/A'}
            />
            <RequestDataLayout
              label="Perform Credit Check?"
              data={record.performCreditCheck ? 'Yes' : 'No'}
            />
          </RequestDetailsBlock>

          <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />

          {guarantorInfo && (
            <RequestDetailsBlock title="Guarantor's Details">
              <RequestDataLayout
                label="Full Name"
                data={guarantorInfo.fullname}
              />
              <RequestDataLayout
                label={getTenantIDType(guarantorInfo.idType!)}
                data={guarantorInfo.idNumber}
              />
              <RequestDataLayout
                label="Residential address"
                data={guarantorInfo.address}
              />
              <RequestDataLayout
                label="Work address"
                data={guarantorInfo.workAddress ?? 'N/A'}
              />
              <RequestDataLayout
                label="Phone Number"
                data={guarantorInfo.phoneNo}
              />
              <RequestDataLayout
                label="Relationship"
                data={guarantorInfo.relationship}
              />
            </RequestDetailsBlock>
          )}

          {landlordInfo && (
            <RequestDetailsBlock title="Landlord's Details">
              <RequestDataLayout
                label="Full Name"
                data={landlordInfo.fullname}
              />
              <RequestDataLayout
                label="Residential address"
                data={landlordInfo.address}
              />
              <RequestDataLayout
                label="Phone Number"
                data={landlordInfo.phoneNo}
              />
            </RequestDetailsBlock>
          )}

          {record.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
