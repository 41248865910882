import {
  Accordion,
  AccordionProps,
  createStyles,
  Group,
  Text,
} from '@mantine/core';

interface IFormListAccordionProps {
  children: React.ReactNode;
}

const useFormListStyle = createStyles((theme, _params, getRef) => {
  const controls = getRef('control');
  return {
    item: {
      border: `1.5px solid ${theme.colors.gray[3]}`,
      borderRadius: theme.radius.md,
    },
    control: {
      padding: 14,
    },
    icon: {
      margin: 0,
      padding: 0,
      color: theme.colors.gray[7],
      width: 20,
      height: 20,
    },
    itemOpened: {
      [`& .${controls}`]: {
        color: theme.colors[theme.primaryColor][6],
      },
    },
  };
});

interface IFormListAccordionLabel {
  listCount: number;
  rightIcon?: React.ReactNode;
  name?: string;
}

export const FormListAccordionLabel = ({
  listCount,
  rightIcon,
  name,
}: IFormListAccordionLabel) => {
  return (
    <Group position="apart">
      <Group style={{ flex: 1 }}>
        <Text lineClamp={1}>{`#${listCount}${name ? `- ${name}` : ''}`}</Text>
      </Group>
      {rightIcon}
    </Group>
  );
};

export const FormListAccordion = ({
  children,
  ...props
}: IFormListAccordionProps & AccordionProps) => {
  const { classes } = useFormListStyle();
  return (
    <Accordion classNames={{ ...classes }} {...props}>
      {children}
    </Accordion>
  );
};
