import { createStyles } from '@mantine/core';

export const useRequestCardStyle = createStyles(
  (
    theme,
    {
      active,
      color,
      skeleton,
    }: { active?: boolean; skeleton?: boolean; color: string }
  ) => ({
    base: {
      borderLeft: !skeleton ? `4px solid ${color}` : 'none',
      cursor: 'pointer',
      backgroundColor: active ? theme.colors.blue[0] : theme.white,
      '&:hover': {
        backgroundColor: !skeleton ? theme.colors.blue[0] : 'none',
      },
    },
  })
);
