import { EmployeeStatusEnum } from '@prisma/client';
import * as Joi from 'joi';
import { PhoneNumberValidator } from './helpers';
import { IdentityValidatorSchema } from './identity';

const SINGLE_GUARANTOR_SCHEMA = Joi.object({
  fullname: Joi.string().required().label("Guarantor's Full name"),
  relationship: Joi.string().optional().label('Relationship with guarantor'),
  address: Joi.string().required().label("Guarantor's Residential Address"),
  workAddress: Joi.string()
    .allow(null, '')
    .optional()
    .label("Guarantor's Work Address"),
  phoneNo: Joi.string()
    .custom(PhoneNumberValidator)
    .required()
    .label("Guarantor's Phone Number"),
  idType: Joi.string()
    .valid('DRIVERS_LICENSE', 'NIN', 'VOTER_ID')
    .required()
    .label('Means of Identification'),
  idNumber: Joi.string()
    .when('idType', {
      switch: [
        { is: 'NIN', then: IdentityValidatorSchema.NIN.extract('idNumber') },
        {
          is: 'DRIVERS_LICENSE',
          then: IdentityValidatorSchema.DRIVERS_LICENSE.extract('idNumber'),
        },
        {
          is: 'VOTER_ID',
          then: IdentityValidatorSchema.VOTER_ID.extract('idNumber'),
        },
      ],
    })
    .required()
    .label('ID Number'),
  dob: Joi.when('idType', {
    switch: [
      {
        is: 'DRIVERS_LICENSE',
        then: Joi.date().iso().required(),
        otherwise: Joi.optional().allow('', null),
      },
    ],
  }).label('Date of Birth'),
});

const SINGLE_EMPLOYEE_SCHEMA = {
  fullName: Joi.string().required().label('Full name'),
  otherName: Joi.string().allow(null, '').optional().label('Other names'),
  bvn: Joi.string().allow(null, '').optional().label('BVN'),
  phoneNo: Joi.string()
    .custom(PhoneNumberValidator)
    .required()
    .label("Employee's Phone Number"),
  dob: Joi.when('idType', {
    switch: [
      {
        is: 'DRIVERS_LICENSE',
        then: Joi.date().iso().required(),
        otherwise: Joi.optional().allow('', null),
      },
    ],
  }).label('Date of Birth'),
  idType: Joi.string()
    .valid('DRIVERS_LICENSE', 'NIN', 'VOTER_ID')
    .required()
    .label('Means of Identification'),
  idNumber: Joi.string()
    .when('idType', {
      switch: [
        { is: 'NIN', then: IdentityValidatorSchema.NIN.extract('idNumber') },
        {
          is: 'DRIVERS_LICENSE',
          then: IdentityValidatorSchema.DRIVERS_LICENSE.extract('idNumber'),
        },
        {
          is: 'VOTER_ID',
          then: IdentityValidatorSchema.VOTER_ID.extract('idNumber'),
        },
      ],
    })
    .required()
    .label('ID Number'),
  residentialAddress: Joi.string().required().label('Residential Address'),
  workAddress: Joi.string().allow(null, '').optional().label('Work Address'),
  previousWorkName: Joi.string()
    .allow(null, '')
    .optional()
    .label('Previous Work Name'),
  previousWorkAddress: Joi.string()
    .allow(null, '')
    .optional()
    .label('Previous Work Address'),
  guarantors: Joi.array()
    .items(SINGLE_GUARANTOR_SCHEMA)
    .min(1)
    .required()
    .label('Guarantors'),
};
const NEW_VERIFICATION_SCHEMA = Joi.object({
  data: Joi.array()
    .items(Joi.object(SINGLE_EMPLOYEE_SCHEMA))
    .min(1)
    .required()
    .label('Employee data'),
});
const UPDATE_VERIFICATION_SCHEMA = Joi.object({
  ...SINGLE_EMPLOYEE_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
}).fork(
  [
    'fullName',
    'otherName',
    'bvn',
    //'dob',
    'idType',
    'idNumber',
    'previousWorkName',
    'previousWorkAddress',
    'guarantors',
  ],
  (schema) => schema.optional()
);
const ADMIN_UPDATE_REPORT = Joi.object({
  employeeId: Joi.string().required().label('Employee ID'),
  reportUrl: Joi.string().uri().required().label('Report URL'),
});

const ADMIN_UPDATE_EMPLOYEE = Joi.object({
  ...SINGLE_EMPLOYEE_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  status: Joi.string()
    .valid(...Object.values(EmployeeStatusEnum))
    .optional()
    .label('Status'),
}).fork(
  [
    'fullName',
    'otherName',
    'bvn',
    //'dob',
    'idType',
    'idNumber',
    'previousWorkName',
    'previousWorkAddress',
    'guarantors',
  ],
  (schema) => schema.optional()
);
export const EmployeeVerificationSchema = {
  NEW_VERIFICATION_SCHEMA,
  UPDATE_VERIFICATION_SCHEMA,
  SINGLE_GUARANTOR_SCHEMA,
  SINGLE_EMPLOYEE_SCHEMA: Joi.object(SINGLE_EMPLOYEE_SCHEMA),
  ADMIN_UPDATE_REPORT,
  ADMIN_UPDATE_EMPLOYEE,
};
