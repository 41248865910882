import {
  NewCertificateVerificationDto,
  CreateRequestReponseDto,
  UpdateCertificateVerificationDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';

interface ICreateCertificateVerificationDto
  extends NewCertificateVerificationDto {
  workspaceId: string;
}
export const CreateCertificateVerificationApi = async ({
  workspaceId,
  ...data
}: ICreateCertificateVerificationDto): Promise<CreateRequestReponseDto> => {
  const url = `/request/certificate/${workspaceId}/new`;
  return await axiosInstance.post(url, data).then((response) => response.data);
};

export const AdminUpdateCertificateApi = async (
  data: UpdateCertificateVerificationDto
): Promise<UpdateCertificateVerificationDto> => {
  const url = '/admin/requests/certificate';
  return await axiosInstance.put(url, data).then((response) => response.data);
};
