import {
  createStyles,
  Group,
  ScrollArea,
  Stack,
  Table,
  Text,
  Skeleton,
  Paper,
  Avatar,
  AvatarsGroup,
  Badge,
  Box,
  Divider,
  TextInput,
  Select,
  Tooltip,
  Center,
} from '@mantine/core';

import { Search } from 'tabler-icons-react';
import { AdminLayout } from '../../../components/layouts/admin';
import { withAdmin } from '../../../hoc/withAdmin';
import { withAuth } from '../../../hoc/withAuth';
import { Fragment, useCallback, useMemo, useState } from 'react';

import { useGetAdminWorkspaces } from '../../../hooks/admin-workspace';
import { Pagination } from '../../../components/primitives/pagination/pagination';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import { AdminWorkspaceDotMenu } from '../../../connected/menus/admin/workspace';
import { FilterLinesIcon } from '../../../assets/icons';
import { getNameInitials } from '@iverifyng/utils';
import { WorkspaceWithUserDto } from '@iverifyng/dtos';
import { AppRBAC } from '../../permission/app';
import { useNavigate } from '@tanstack/react-location';
import { WorkspaceType } from '@prisma/client';
import EmptyState from '../../../components/primitives/emptyState/emptyState';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colors['blue'][8],
    zIndex: 50,
  },
}));

const PAGE_LIMIT = 20;

function AdminWorkspace() {
  const [page, setPage] = useState(1);
  const [value, setValue] = useState('');
  const [workspaceType, setWorkspaceType] = useState<WorkspaceType | null>(
    null
  );
  const { classes } = useStyles();

  const navigate = useNavigate();
  const [searchterm] = useDebouncedValue(value, 800);
  const { data: workspaces, isLoading } = useGetAdminWorkspaces({
    limit: PAGE_LIMIT,
    page,
    searchterm,
    type: workspaceType,
  });
  const totalPages = useMemo(
    (): number => (workspaces ? Math.ceil(workspaces?.count / PAGE_LIMIT) : 0),
    [workspaces]
  );

  const onChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  const pagination = usePagination({
    total: totalPages,
    page,
    onChange,
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setValue(e.target.value);
    setPage(1);
  }, []);

  const handleWorkspaceType = useCallback((value: WorkspaceType | null) => {
    setWorkspaceType(value);
    setPage(1);
  }, []);

  const header = useMemo(
    () => (
      <tr>
        <th style={{ color: '#fff' }}>
          <Group spacing="sm">
            <Text size="sm">Workspace Title</Text>
          </Group>
        </th>
        <th style={{ color: '#fff' }}>Status</th>
        <th style={{ color: '#fff' }}>Members</th>
        <th style={{ color: '#fff' }}>Type</th>
        <th style={{ color: '#fff' }}>Offline Payment?</th>
        <th></th>
      </tr>
    ),
    []
  );

  const rows = useMemo(() => {
    const data = isLoading ? Array(4).fill('*') : workspaces?.data;
    return ((data || []) as Array<WorkspaceWithUserDto>).map((workspace, i) => (
      <tr
        key={i}
        onClick={() =>
          navigate({
            to: `/admin/workspace-detail/${workspace.id}`,
            search: (old) => ({ ...old }),
          })
        }
        style={{ cursor: 'pointer' }}
      >
        <td>
          <Skeleton visible={isLoading}>
            <Group spacing="sm">
              <Text
                sx={() => ({
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 600,
                })}
              >
                {workspace.name}
              </Text>
            </Group>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Badge color={workspace.active ? 'green' : 'orange'}>
              {workspace.active ? 'ACTIVE' : 'INACTIVE'}
            </Badge>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Tooltip
              label={
                workspace?.users ? (
                  <Stack spacing={2}>
                    {workspace?.users?.map((user, key) => (
                      <Fragment key={key}>
                        <Stack spacing={1}>
                          <Text>
                            {[user.user.firstName, user.user.lastName]
                              .filter(Boolean)
                              .join(' ')}{' '}
                          </Text>
                          <Text
                            size="xs"
                            sx={(theme) => ({
                              color: theme.colors['gray'][0],
                              opacity: 0.5,
                            })}
                          >
                            {user.user.email}
                          </Text>
                        </Stack>
                        {key !== workspace.users.length - 1 ? (
                          <Divider
                            sx={(theme) => ({
                              borderColor: theme.colors['dark'][0],
                              opacity: 0.1,
                            })}
                          />
                        ) : null}
                      </Fragment>
                    ))}
                  </Stack>
                ) : null
              }
            >
              <AvatarsGroup limit={3} total={workspace?.users?.length}>
                {workspace.users &&
                  workspace.users.map((user) => (
                    <Avatar src="#">
                      {getNameInitials({
                        name: `${user.user.firstName} ${user.user.lastName} `,
                      })}
                    </Avatar>
                  ))}
              </AvatarsGroup>
            </Tooltip>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Badge color={workspace.type === 'CORPORATE' ? 'grape' : 'blue'}>
              {workspace.type}
            </Badge>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Badge color={workspace.allowOfflinePayment ? 'blue' : 'red'}>
              {workspace.allowOfflinePayment ? 'YES' : 'NO'}
            </Badge>
          </Skeleton>
        </td>
        <td
          style={{
            textAlign: 'right',
          }}
        >
          <Skeleton visible={isLoading}>
            <AdminWorkspaceDotMenu workspace={workspace} />
          </Skeleton>
        </td>
      </tr>
    ));
  }, [isLoading, navigate, workspaces?.data]);

  const showEmptyState =
    !isLoading && (!workspaces?.data || !workspaces?.data?.length);
  return (
    <AppRBAC action={'canViewWorkspace'}>
      <AdminLayout>
        <Stack spacing="xl" px={32}>
          <Text
            pt="xl"
            sx={() => ({
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: 400,
            })}
          >
            Workspace
          </Text>
          <Group>
            <TextInput
              type="search"
              icon={<Search size={20} />}
              placeholder="Search"
              value={value}
              onChange={handleChange}
              sx={() => ({
                width: 300,
              })}
            />
            <Select
              sx={() => ({
                width: 150,
              })}
              icon={<FilterLinesIcon />}
              size="sm"
              clearable
              value={workspaceType}
              onChange={handleWorkspaceType}
              placeholder="Type"
              //styles={{ rightSection: { display: 'none' } }}
              data={[
                { value: 'PERSONAL', label: 'Personal' },
                { value: 'CORPORATE', label: 'Corporate' },
              ]}
            />
          </Group>
          <Paper shadow="xs" withBorder={true} radius="md">
            {showEmptyState ? (
              <Center sx={() => ({ height: 'calc(100vh - 289px)' })}>
                <EmptyState
                  title="Empty Results"
                  subTitle="There are no workspaces available."
                />
              </Center>
            ) : (
              <ScrollArea style={{ height: 'calc(100vh - 289px)' }}>
                <Table
                  horizontalSpacing="xl"
                  verticalSpacing="sm"
                  highlightOnHover
                >
                  <thead className={classes.header}>{header}</thead>
                  <tbody>{rows}</tbody>
                </Table>
              </ScrollArea>
            )}

            {totalPages > 1 ? (
              <Box
                sx={(theme) => ({
                  '&:hover': {
                    backgroundColor: theme.colors['gray'][1],
                  },
                })}
              >
                <Divider />
                <Box px="lg" py={12}>
                  <Pagination
                    current={pagination.active}
                    totalPages={totalPages}
                    onNext={() => {
                      pagination.next();
                    }}
                    onPrevious={() => {
                      pagination.previous();
                    }}
                    color="gray"
                  />
                </Box>
              </Box>
            ) : null}
          </Paper>
        </Stack>
      </AdminLayout>
    </AppRBAC>
  );
}

export default withAuth(withAdmin(AdminWorkspace));
