import { Drawer, Stack } from '@mantine/core';
import { IReportBaseDrawerProps } from './interface';

export const ReportBaseDrawer: React.FC<IReportBaseDrawerProps> = ({
  opened,
  title,
  onCloseDrawer,
  children,
}) => {
  return (
    <Drawer
      styles={{
        root: {
          position: 'relative',
        },
        header: {
          backgroundColor: '#F8F9FA',
          padding: '16px 16px',
          margin: 0,
        },
      }}
      opened={opened}
      onClose={onCloseDrawer}
      title={title}
      position="right"
      size="60%"
      closeOnClickOutside={false}
    >
      <Stack
        py="32px"
        pb={100}
        spacing={'lg'}
        sx={{
          height: '100%',
          maxHeight: '100%',
          position: 'relative',
          overflowY: 'scroll',
        }}
      >
        {children}
      </Stack>
    </Drawer>
  );
};
