import { useLocalStorage } from '@mantine/hooks';
import { useEffect } from 'react';
import { useUserProfile } from '../services/queries/users';

export const useCurrentCountry = () => {
  const [country, setCountry] = useLocalStorage<string | null>({
    key: 'iverify-user-country',
    defaultValue: null,
  });
  const { isLoading, data } = useUserProfile();
  const currentUser = data;
  useEffect(() => {
    if (isLoading) return; // Do nothing while loading
    if (currentUser) {
      if (!country && !!currentUser) {
        setCountry(currentUser.default_country);
      }
    }
  }, [country, currentUser, setCountry, isLoading]);

  return { country, setCountry };
};
