import {
  useAdminRequestDetails,
  useIdentityResponseDetails,
} from '../../../../../hooks/admin';
import { useMatch } from '@tanstack/react-location';
import { RequestGenerics } from '../tabOutlet';

import { RequestDetailView } from '../../../components/detail/detail';

export const RequestDetail = () => {
  const {
    params: { requestId },
  } = useMatch<RequestGenerics>();
  const { error, data: request, isLoading } = useAdminRequestDetails(requestId);

  const {
    data: responseDetail,
    isLoading: responseDetailLoading,
    isFetching: responseDetailIsFetching,
  } = useIdentityResponseDetails({
    identityId: request?.identity?.id as string,
    requestId,
    isEnabled: !!request?.identity?.id && request?.status === 'COMPLETED',
  });

  return (
    <RequestDetailView
      request={request}
      responseDetail={responseDetail}
      responseDetailIsFetching={responseDetailIsFetching}
      isLoadingRequest={isLoading}
      isErrorRequest={error}
      responseDetailLoading={responseDetailLoading}
    />
  );
};
