import {
  Prisma,
  CreditCheck,
  CreditCheckProductCodeEnum,
} from '@prisma/client';

export type NewCreditCheckDto = Omit<Prisma.CreditCheckCreateInput, 'request'>;
export interface CreateCreditCheckDto {
  code: CreditCheckProductCodeEnum;
  data: Array<NewCreditCheckDto>;
  description?: string;
}
export type UpdateCreditCheckDto = Partial<NewCreditCheckDto>;
export type CreditCheckRecordDto = CreditCheck;
export interface AdminCreditCheckUpdateReportDto {
  reportUrl: string;
  creditCheckId: string;
}

export const CreditCheckServiceCodeMap = {
  [CreditCheckProductCodeEnum.COMMERCIAL_DETAILED_REPORT]:
    'CREDIT_CHECK_DETAILED_COMMERCIAL',
  [CreditCheckProductCodeEnum.CONSUMER_DETAILED_REPORT]:
    'CREDIT_CHECK_DETAILED_CONSUMER',
  [CreditCheckProductCodeEnum.XSCORE_CONSUMER_DETAILED_REPORT]:
    'CREDIT_CHECK_XSCORE_DETAILED_CONSUMER',
};

//first central product Id maps
export const CreditCheckProductIdMap = {
  [CreditCheckProductCodeEnum.COMMERCIAL_DETAILED_REPORT]: 47,
  [CreditCheckProductCodeEnum.CONSUMER_DETAILED_REPORT]: 45,
  [CreditCheckProductCodeEnum.XSCORE_CONSUMER_DETAILED_REPORT]: 50,
};

//map description
export const CreditCheckProductDescMap = {
  [CreditCheckProductCodeEnum.COMMERCIAL_DETAILED_REPORT]:
    'Detailed Commercial Credit Report',
  [CreditCheckProductCodeEnum.CONSUMER_DETAILED_REPORT]:
    'Detailed Consumer Report',
  [CreditCheckProductCodeEnum.XSCORE_CONSUMER_DETAILED_REPORT]:
    'XScore - Detailed Consumer Report',
};
