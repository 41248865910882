import { createStyles } from "@mantine/core";




export const useSidebarStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    marginBottom: '8px',
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.radius.sm,
    '&:hover': {
      backgroundColor: theme.colors.blue[0],
      color: theme.colors.blue[5],
    },
  },
  subMenu: {
    fontWeight: 500,
    paddingLeft: 8,
    marginLeft: 30,
    marginBottom: '8px',
    fontSize: theme.fontSizes.sm,
    borderLeft: `1px solid ${theme.colors.gray[3]}`,
  },

  link: {
    padding: `8px ${theme.spacing.sm}px`,
    textDecoration: 'none',
    display: 'block',
    marginBottom: '8px',
    width: '100%',
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.radius.md,
    '&:hover': {
      backgroundColor: theme.colors.blue[0],
      color: theme.colors.blue[5],
    },
  },
  active: {
    backgroundColor: theme.colors.blue[0],
    color: theme.colors.blue[5],
  },
}));