import {
  Box,
  Stack,
  Text,
  Group,
  TextInput,
  Button,
  Select,
  Divider,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { CreditCheckCommercialIcon } from '../../../../../../../../src/assets/icons';
import { ICommercialCreditCheckProps } from '../../interface';
import { enquiryReason } from '../../common/common';
import { RequestDetailsBlock } from '../../../../../../../connected/requestDetails/block';

interface ICommercialCreditCheckRequestForm {
  introText: string;
  form: UseFormReturnType<ICommercialCreditCheckProps>;
  renderIf?: boolean;
  onBack?: () => void;
}
export const CommercialCreditCheckForm = ({
  introText,
  form,
  renderIf,
  onBack,
}: ICommercialCreditCheckRequestForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Commercial Credit Check"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<CreditCheckCommercialIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="red"
            variant="subtle"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Review</Button>
        </Group>
      }
    >
      <Stack spacing="xl" p="24px">
        <Box
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">{introText}</Text>
        </Box>
        <Group direction="column" spacing="md" grow>
          <Select
            label="Select Reason"
            required
            placeholder="Select reason"
            {...form.getListInputProps('data', 0, 'enquiryReason')}
            data={enquiryReason}
          />
          <RequestDetailsBlock title="Business details">
            <TextInput
              label="Business Name"
              placeholder="Enter bussiness name"
              description="Name of Business"
              {...form.getListInputProps('data', 0, 'businessName')}
            />
            <Divider my="xs" label="OR" labelPosition="center" />
            <TextInput
              label="Business Registration Number"
              placeholder="Business registration number"
              description="Registration number of Business"
              {...form.getListInputProps('data', 0, 'businessRegNo')}
            />
            <Divider my="xs" label="OR" labelPosition="center" />
            <TextInput
              label="Business Account Number"
              placeholder="Enter account number"
              description="Account number of Business"
              {...form.getListInputProps('data', 0, 'accountNumber')}
            />
          </RequestDetailsBlock>
        </Group>
      </Stack>
    </RequestLayout>
  );
};
