import { Stack, Box, Text, Group, TextInput, Textarea } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { ITINRequestProps } from '../tin';

interface ITINRequestForm {
  introText: string;
  form: UseFormReturnType<ITINRequestProps>;
}

export const TINRequestForm = ({ introText, form }: ITINRequestForm) => {
  return (
    <Stack spacing="xl" p="24px">
      <Box
        sx={(theme) => ({
          color: theme.colors.gray[7],
        })}
      >
        <Text size="sm">{introText}</Text>
      </Box>

      <Group direction="column" spacing="lg" grow>
        <TextInput
          label="Tax Identification Number"
          placeholder="Enter Tax Identification number"
          {...form.getInputProps('idNumber')}
        />

        <Textarea
          label="Description"
          autosize
          minRows={4}
          {...form.getInputProps('description')}
        />
      </Group>
    </Stack>
  );
};
