import { Badge, Stack, Text } from '@mantine/core';
import { StatusBadge } from '../../../../components/primitives/statusBadge/statusBadge';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { RequestStatus } from '@prisma/client';
import { RequestBodySkeleton } from '../../../../components/primitives/skeletons/requestDetail';
import { useStatusColor } from '../../../../hooks/useStatusColor';
import { GetUserResponseDto } from '@iverifyng/dtos';

type Status = { status: RequestStatus; statusName: string };
interface IAdminUserDetailViewProps {
  user?: GetUserResponseDto;
  isLoading?: boolean;
  isError?: unknown;
}

export const AdminUserDetailView = ({
  user,
  isLoading,
  isError,
}: IAdminUserDetailViewProps) => {
  const emailVerified: Status = user?.emailVerified
    ? { status: 'COMPLETED', statusName: 'Verified' }
    : { status: 'REJECTED', statusName: 'Unverified' };

  const userStatus: Status = user?.deactivate
    ? { status: 'REJECTED', statusName: 'Inactive' }
    : { status: 'COMPLETED', statusName: 'Active' };

  const statusColor = useStatusColor(userStatus.status);

  if (isError || isLoading) return <RequestBodySkeleton skeleton={true} />;

  return (
    <Stack px="xl">
      <RequestDataLayout label="First Name" data={user?.firstName} />
      <RequestDataLayout label="Last Name" data={user?.lastName} />
      <RequestDataLayout
        label="Email"
        data={
          <Text size="sm" sx={(theme) => ({ color: theme.colors.gray[4] })}>
            {user?.email}
          </Text>
        }
      />
      <RequestDataLayout
        label="Email verified?"
        data={
          <StatusBadge status={emailVerified.status} radius="sm">
            {emailVerified.statusName}
          </StatusBadge>
        }
      />
      <RequestDataLayout
        label="Status"
        data={
          <Text component="span" size="sm" color={statusColor}>
            {userStatus.statusName}
          </Text>
        }
      />
      <RequestDataLayout
        label="Phone number"
        data={user?.phoneNo ? user?.phoneNo : 'N/A'}
      />
      <RequestDataLayout
        label="Roles"
        data={user?.appRoles.map((role) => (
          <Badge color="gray" key={role}>
            <Text transform="capitalize" size="xs" align="center" color="gray">
              {role}
            </Text>
          </Badge>
        ))}
      />
    </Stack>
  );
};
