import { customAlphabet } from 'nanoid';

export const generateRandomCode = (len = 12) => {
  const alphabet =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const nanoid = customAlphabet(alphabet, len);
  const code = nanoid().toUpperCase();
  return code;
};

export const generateRandomNumber = (len = 12) => {
  const alphabet = '0123456789';
  const nanoid = customAlphabet(alphabet, len);
  const code = nanoid().toUpperCase();
  return code;
};

export function detectMimeType(b64: string) {
  const signatures: Record<string, string> = {
    JVBERi0: 'application/pdf',
    R0lGODdh: 'image/gif',
    R0lGODlh: 'image/gif',
    iVBORw0KGgo: 'image/png',
    '/9j/': 'image/jpg',
  };
  for (const s in signatures) {
    if (b64.indexOf(s) === 0) {
      return signatures[s];
    }
  }

  return undefined;
}

export const base64MimeType = (encoded: string): string | null => {
  //attempt to get from possible starting values
  const detectMime = detectMimeType(encoded);
  if (detectMime) return detectMime;

  let result = null;
  if (typeof encoded !== 'string') return result;
  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  if (mime && mime.length) {
    result = mime[1];
  }
  return result;
};

export const decodeBase64String = (b64: string) => {
  return Buffer.from(b64, 'base64').toString('ascii');
};

export const getNameInitials = (params: {
  name: string | undefined;
  maxInitials?: number;
}) => {
  if (!params.name) return '';
  return params.name
    .split(/\s/)
    .map((part) => part.substring(0, 1).toUpperCase())
    .filter((value) => !!value)
    .slice(0, params.maxInitials)
    .join('')
    .toUpperCase();
};

export const formatFilename = (name: string) => {
  //default: /[&\/\\#,+()$~%.'":*?<>{}]/g
  return name.replace(/[&/\\#, +()$~%'":*?<>{}]/g, '_'); //replace all special characters with _ except .
};

export const RelationshipsList = [
  'Wife',
  'Husband',
  'Mother',
  'Father',
  'Daughter',
  'Son',
  'Sister',
  'Brother',
  'Aunt',
  'Uncle',
  'Niece',
  'Nephew',
  'Cousin (female)',
  'Cousin (male)',
  'Grandmother',
  'Grandfather',
];

export const getPaginationSkip = (params: { page: number; limit: number }) => {
  const page = Number(params.page ?? 1);
  const limit = Number(params.limit ?? 100);
  const skip = page > 1 ? (page - 1) * limit : 0;
  return { skip, limit };
};
