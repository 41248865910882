import { PropertyForm } from './containers/propertyForm';
import { useLocation } from '@tanstack/react-location';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { NINPage } from '../identityVerification/subRoutes/nin/nin';
import { useServiceCharge } from '../../../../../hooks/request';
import { ServiceCountry } from '@prisma/client';
import { PropertyFormConfirmation } from './containers/propertyConfirmation';
import { useCurrentCountry } from '../../../../../hooks/country';
import { formList, useForm } from '@mantine/form';
import { IPropertyFormListProps } from './containers/interface';
import { PropertyVerificationSchema } from '@iverifyng/validators';
import { useCreatePropertyVerification } from '../../../../../services/mutations/property';
import { useCurrentWorkspace } from '../../../../../hooks/workspace';
import { showNotification } from '@mantine/notifications';
import { debounce } from 'lodash';

export const PropertyVerification = () => {
  const [page, setPage] = useState<NINPage>('form');
  const { history } = useLocation();
  const { country } = useCurrentCountry();
  const { workspaceId } = useCurrentWorkspace();

  const form = useForm({
    // schema: joiResolver(PropertyVerificationSchema.NEW_VERIFICATION_SCHEMA),
    initialValues: {
      data: formList<IPropertyFormListProps>([
        {
          country: '',
          street: '',
          city: '',
          state: '',
          type: '',
          documentUrl: '',
        },
      ]),
    },
  });

  const noOfProperties = useMemo(
    () => form?.values?.data.length,
    [form?.values?.data]
  );
  const CreateProperty = useCreatePropertyVerification();

  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'PROPERTY_VERIFICATION',
    country: country! as ServiceCountry,
    quantity: noOfProperties ?? 1,
  });

  const handleReview = useCallback(() => {
    const { error } =
      PropertyVerificationSchema.NEW_VERIFICATION_SCHEMA.validate(form.values);
    if (error) {
      showNotification({
        color: 'red',
        message: error?.message,
        title: 'Validation Error',
      });
      return;
    }
    setPage('verify');
  }, [form.values]);

  const handleSubmit = useCallback(() => {
    CreateProperty.mutate({
      workspaceId: workspaceId!,
      data: form.values.data.map((d) => ({
        country: d.country,
        street: d.street,
        city: d.city,
        state: d.state,
        type: d.type,
        documentUrl: d.documentUrl,
      })),
    });
  }, [CreateProperty, form.values, workspaceId]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  useEffect(() => {
    if (page === 'verify' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <>
      <PropertyForm
        onBack={() => history.back()}
        onSubmit={handleReview}
        renderIf={page === 'form'}
        form={form}
      />
      <PropertyFormConfirmation
        data={form.values}
        billed={serviceCharge}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        loading={CreateProperty.isLoading}
        renderIf={page === 'verify'}
      />
    </>
  );
};
