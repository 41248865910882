import { AdminLayout } from '../../../components/layouts/admin';
import { Group, Stack, Text, Box, Center, SimpleGrid } from '@mantine/core';
import { withAuth } from '../../../hoc/withAuth';
import { withAdmin } from '../../../hoc/withAdmin';
import { AdminBarChart } from './charts/barchart';
import AdminAreaChart from './charts/LineChart';
import { SegmentedChart } from './charts/SegmentedChart';
import { AnalyticsLayout } from './components/analyticslayout';
import {
  useAnalyticsAreaRequests,
  useAnalyticsAreaServiceTypes,
  useAnalyticsPayments,
  useAnalyticsPieRequests,
  useAnalyticsPieServiceTypes,
  useAnalyticsUserGrowth,
  useCompletedPayment,
} from '../../../hooks/admin-analytics';
import { DateRangeKey, dateRanges } from '@iverifyng/utils';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import {
  BarChartLoader,
  PieChartLoader,
} from '../../../components/primitives/skeletons/chart';
import EmptyState from '../../../components/primitives/emptyState/emptyState';
import {
  CompletePaymentIcon,
  RequestIcon,
  ServiceIcon,
  UserGrowthIcon,
} from '../../../assets/icons';
import { AdminPieChart } from './charts/piechart';
import { AdminAreaResponseDto } from '@iverifyng/dtos';
import { AppRBAC } from '../../permission/app';

type DateType = [Date | null, Date | null];
const customDateDefault = {
  user: false,
  payment: false,
  request: false,
  service: false,
  completed: false,
};
const AdminAnalytics = () => {
  const [userDate, setUserDate] = useState<DateType>([null, null]);
  const [paymentsDate, setPaymentsDate] = useState<DateType>([null, null]);
  const [requestsDate, setRequestsDate] = useState<DateType>([null, null]);
  const [serviceTypesDate, setServiceTypesDate] = useState<DateType>([
    null,
    null,
  ]);
  const [completedDate, setCompletedDate] = useState<DateType>([null, null]);
  const [customDates, setCustomDates] = useState(() => customDateDefault);

  const { data: usersGrowth, isLoading: isUsersLoading } =
    useAnalyticsUserGrowth({
      startdate: userDate[0] ? dayjs(userDate[0]).format() : null,
      enddate: userDate[1] ? dayjs(userDate[1]).format() : null,
    });

  const { data: payments, isLoading: isPaymentLoading } = useAnalyticsPayments({
    startdate: paymentsDate[0] ? dayjs(paymentsDate[0]).format() : null,
    enddate: paymentsDate[1] ? dayjs(paymentsDate[1]).format() : null,
  });

  const { data: completedPayments, isLoading: isCompletedPaymentsLoading } =
    useCompletedPayment({
      startdate: completedDate[0] ? dayjs(completedDate[0]).format() : null,
      enddate: completedDate[1] ? dayjs(completedDate[1]).format() : null,
    });

  const { data: areaRequests, isLoading: isAreaRequestsLoading } =
    useAnalyticsAreaRequests({
      startdate: requestsDate[0] ? dayjs(requestsDate[0]).format() : null,
      enddate: requestsDate[1] ? dayjs(requestsDate[1]).format() : null,
    });

  const { data: pieRequests, isLoading: isPieRequestsLoading } =
    useAnalyticsPieRequests({
      startdate: requestsDate[0] ? dayjs(requestsDate[0]).format() : null,
      enddate: requestsDate[1] ? dayjs(requestsDate[1]).format() : null,
    });

  const { data: areaServiceTypes, isLoading: isAreaServiceTypesLoading } =
    useAnalyticsAreaServiceTypes({
      startdate: serviceTypesDate[0]
        ? dayjs(serviceTypesDate[0]).format()
        : null,
      enddate: serviceTypesDate[1] ? dayjs(serviceTypesDate[1]).format() : null,
    });
  const { data: pieServiceTypes, isLoading: isPieServiceTypesLoading } =
    useAnalyticsPieServiceTypes({
      startdate: serviceTypesDate[0]
        ? dayjs(serviceTypesDate[0]).format()
        : null,
      enddate: serviceTypesDate[1] ? dayjs(serviceTypesDate[1]).format() : null,
    });

  const getFilterDateRange = useCallback((filter: DateRangeKey): DateType => {
    const dateRange = dateRanges[filter];
    return [dateRange?.startDateTime ?? null, dateRange?.endDateTime ?? null];
  }, []);

  return (
    <AppRBAC action={'canViewAnalytics'}>
      <AdminLayout>
        <Stack
          spacing="xl"
          px={32}
          pb={32}
          sx={() => ({
            height: 'calc(100vh - 77.19px)',
            overflow: 'scroll',
          })}
        >
          <Text
            pt="xl"
            sx={() => ({
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: 500,
            })}
          >
            Analytics
          </Text>
          <Group
            sx={{ maxWidth: '100%', flexWrap: 'nowrap' }}
            align="flex-start"
          >
            <Box sx={{ width: '50%' }}>
              <AnalyticsLayout
                key="User Growth"
                label="User Growth"
                dateRange={userDate}
                dateRangeDisabled={!customDates.user}
                onDateRangeChange={(val) => setUserDate(val)}
                onFilterChange={(filterValue) => {
                  if (filterValue === 'custom') {
                    return setCustomDates((prev) => ({ ...prev, user: true }));
                  }
                  const date = getFilterDateRange(filterValue as DateRangeKey);
                  setCustomDates((prev) => ({ ...prev, user: false }));
                  return setUserDate(date);
                }}
              >
                {isUsersLoading ? (
                  <BarChartLoader />
                ) : usersGrowth && usersGrowth.length > 0 ? (
                  <AdminBarChart
                    data={usersGrowth!}
                    fillColor="#008AFF"
                    valKey="value"
                    xKey="label"
                    label="User Growth"
                  />
                ) : (
                  <Center style={{ height: 400 }}>
                    <EmptyState
                      icon={<UserGrowthIcon />}
                      subTitle="No user growth data was found."
                    />
                  </Center>
                )}
              </AnalyticsLayout>
            </Box>
            <Box sx={{ width: '50%' }}>
              <AnalyticsLayout
                label="Completed payment"
                dateRangeDisabled={!customDates.completed}
                onDateRangeChange={(val) => setCompletedDate(val)}
                onFilterChange={(filterValue) => {
                  if (filterValue === 'custom') {
                    return setCustomDates((prev) => ({
                      ...prev,
                      completed: true,
                    }));
                  }
                  const date = getFilterDateRange(filterValue as DateRangeKey);
                  setCustomDates((prev) => ({ ...prev, completed: false }));
                  return setCompletedDate(date);
                }}
              >
                {isCompletedPaymentsLoading ? (
                  <BarChartLoader />
                ) : completedPayments && completedPayments.length > 0 ? (
                  <AdminBarChart
                    data={completedPayments!}
                    fillColor="#005399"
                    valKey="value"
                    xKey="label"
                    label="Completed payment"
                  />
                ) : (
                  <Center style={{ height: 400 }}>
                    <EmptyState
                      icon={<CompletePaymentIcon />}
                      subTitle="No completed payment data was found."
                    />
                  </Center>
                )}
              </AnalyticsLayout>
            </Box>
          </Group>
          <SimpleGrid cols={3}>
            <SegmentedChart
              label="Requests"
              dateRange={requestsDate}
              dateRangeDisabled={!customDates.request}
              onDateRangeChange={(val) => setRequestsDate(val)}
              onFilterChange={(filterValue) => {
                if (filterValue === 'custom') {
                  return setCustomDates((prev) => ({ ...prev, request: true }));
                }
                const date = getFilterDateRange(filterValue as DateRangeKey);
                setCustomDates((prev) => ({ ...prev, request: false }));
                return setRequestsDate(date);
              }}
              firstNode={
                isPieRequestsLoading ? (
                  <PieChartLoader />
                ) : pieRequests && pieRequests.length > 0 ? (
                  <AdminPieChart
                    data={pieRequests}
                    dataKey="value"
                    nameKey="label"
                  />
                ) : (
                  <Center style={{ height: 300 }}>
                    <EmptyState
                      icon={<RequestIcon />}
                      subTitle="No request data was found."
                    />
                  </Center>
                )
              }
              secondNode={
                isAreaRequestsLoading ? (
                  <BarChartLoader height={300} />
                ) : areaRequests && areaRequests.length > 0 ? (
                  <AdminAreaChart
                    xKey="label"
                    areaData={areaRequests.map((request) => ({
                      label: request.label,
                      total: request.total,
                      ...request.value,
                    }))}
                    tipLabel="Requests"
                  />
                ) : (
                  <Center style={{ height: 300 }}>
                    <EmptyState
                      icon={<RequestIcon />}
                      subTitle="No request data was found."
                    />
                  </Center>
                )
              }
            />

            <SegmentedChart
              label="Services"
              dateRange={serviceTypesDate}
              dateRangeDisabled={!customDates.service}
              onDateRangeChange={(val) => setServiceTypesDate(val)}
              onFilterChange={(filterValue) => {
                if (filterValue === 'custom') {
                  return setCustomDates((prev) => ({ ...prev, service: true }));
                }
                const date = getFilterDateRange(filterValue as DateRangeKey);
                setCustomDates((prev) => ({ ...prev, service: false }));
                return setServiceTypesDate(date);
              }}
              firstNode={
                isPieServiceTypesLoading ? (
                  <PieChartLoader />
                ) : pieServiceTypes && pieServiceTypes.length > 0 ? (
                  <AdminPieChart
                    data={pieServiceTypes}
                    dataKey="value"
                    nameKey="name"
                    isDoughnut
                  />
                ) : (
                  <Center style={{ height: 300 }}>
                    <EmptyState
                      icon={<ServiceIcon />}
                      subTitle="No service type data was found."
                    />
                  </Center>
                )
              }
              secondNode={
                isAreaServiceTypesLoading ? (
                  <BarChartLoader height={300} />
                ) : areaServiceTypes && areaServiceTypes.length > 0 ? (
                  <AdminAreaChart
                    xKey="label"
                    areaData={(areaServiceTypes as AdminAreaResponseDto).map(
                      (service) => ({
                        label: service.label,
                        total: service.total,
                        ...service.value,
                      })
                    )}
                    tipLabel="Service Types"
                  />
                ) : (
                  <Center style={{ height: 300 }}>
                    <EmptyState
                      icon={<ServiceIcon />}
                      subTitle="No service type data was found."
                    />
                  </Center>
                )
              }
            />
            <AnalyticsLayout
              label="Payments"
              dateRange={paymentsDate}
              dateRangeDisabled={!customDates.payment}
              onDateRangeChange={(val) => setPaymentsDate(val)}
              onFilterChange={(filterValue) => {
                if (filterValue === 'custom') {
                  return setCustomDates((prev) => ({ ...prev, payment: true }));
                }
                const date = getFilterDateRange(filterValue as DateRangeKey);
                setCustomDates((prev) => ({ ...prev, payment: false }));
                return setPaymentsDate(date);
              }}
            >
              {isPaymentLoading ? (
                <PieChartLoader />
              ) : payments && payments.length > 0 ? (
                <AdminPieChart
                  data={payments}
                  dataKey="count"
                  nameKey="status"
                />
              ) : (
                <Center style={{ height: 300 }}>
                  <EmptyState
                    icon={<CompletePaymentIcon />}
                    subTitle="No service type data was found."
                  />
                </Center>
              )}
            </AnalyticsLayout>
          </SimpleGrid>
        </Stack>
      </AdminLayout>
    </AppRBAC>
  );
};

export default withAuth(withAdmin(AdminAnalytics));
