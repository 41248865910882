import { useCurrentCountry } from '../../../hooks/country';
import React, { useMemo } from 'react';
import { ZANationalID } from '../routes/za/identityVerification/nationalId/nationalId';
import { KENationalID } from '../routes/ke/identityVerification/nationalId/nationalId';
import { UGNationalID } from '../routes/ug/identityVerification/nationalId/nationalId';

export const SharedNationalID = () => {
  const { country } = useCurrentCountry();

  const renderDLContainer = useMemo(() => {
    switch (country) {
      case 'KE':
        return <KENationalID />;
      case 'ZA':
        return <ZANationalID />;
      case 'UG':
        return <UGNationalID />;

      default:
        return null;
    }
  }, [country]);

  return renderDLContainer;
};
