import { InviteWorkspaceMemberRequestDto } from '@iverifyng/dtos';
import { getNameInitials, workspaceRoleDescription } from '@iverifyng/utils';
import { WorkspaceValidatorSchema } from '@iverifyng/validators';
import {
  Stack,
  Text,
  Title,
  Group,
  Button,
  Paper,
  ScrollArea,
  Table,
  Box,
  Modal,
  TextInput,
  NativeSelect,
  LoadingOverlay,
  SegmentedControl,
  Select,
  Avatar,
} from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { Workspace, WorkspacePermission } from '@prisma/client';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TransactionZeroIcon } from '../../assets/icons';
import { WorkspaceRBAC } from '../../containers/permission/workspace';
import {
  useCurrentWorkspace,
  useGetWorkspaceInvites,
  useGetWorkspaceMembers,
} from '../../hooks/workspace';
import {
  DeleteWorkspaceInvite,
  DeleteWorkspaceMember,
  InviteTeamMemberApi,
  UpdateWorkspaceMemberRole,
} from '../../services/apis/workspace';
import { useUserProfile } from '../../services/queries/users';
import EmptyState from '../primitives/emptyState/emptyState';

//workspace members permission
export const selectPermissionData = [
  { value: 'OWNER', label: 'Owner' },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'VIEWER', label: 'Viewer' },
  { value: 'REQUESTER', label: 'Requester' },
];

interface IChangeRole {
  defaultRole: WorkspacePermission;
  onRoleChange: (value: WorkspacePermission) => void;
  disabled: boolean;
}
const ChangeUserRole = ({
  defaultRole,
  onRoleChange,
  disabled,
}: IChangeRole) => {
  const [role, setRole] = useState(defaultRole);
  return (
    <Select
      value={role}
      data={selectPermissionData}
      variant="unstyled"
      disabled={disabled}
      onChange={(value: WorkspacePermission) => {
        setRole(value);
        onRoleChange(value);
      }}
    />
  );
};

type TActiveTab = 'active' | 'inactive';
const TeamMemberForm = ({ workspace }: { workspace: Workspace }) => {
  const [opened, setOpened] = useState(false);
  const [activeTab, setActiveTab] = useState<TActiveTab>('active');
  const queryClient = useQueryClient();
  const {
    data: invitedTeamMembers,
    isLoading: inviteeLoading,
    queryKey: wsInviteQueryKey,
  } = useGetWorkspaceInvites(workspace.id);
  const {
    data: workspaceMembers,
    isLoading: TeamMemberLoading,
    queryKey: wsMembersQueryKey,
  } = useGetWorkspaceMembers(workspace.id);
  const { data: currentUser } = useUserProfile();

  const form = useForm<InviteWorkspaceMemberRequestDto>({
    schema: joiResolver(WorkspaceValidatorSchema.INVITE_MEMBER),
    initialValues: {
      email: '',
      role: 'REQUESTER',
    },
  });

  // invite member mutation
  const inviteMember = useMutation(InviteTeamMemberApi, {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Invite',
        message: 'New invite submitted.',
      });
      setOpened(false);
      setActiveTab('inactive');
      queryClient.invalidateQueries(wsInviteQueryKey);
    },
    onError: (error) => {
      showNotification({
        color: 'red',
        title: 'Invite',
        message:
          (error as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Error occurred',
      });
    },
  });

  const onSubmit = useCallback(() => {
    const data = form.values;
    inviteMember.mutate({
      workspaceId: workspace.id,
      email: data.email,
      role: data.role,
    });
  }, [inviteMember, form.values, workspace?.id]);

  const deleteInvitedMember = useMutation(DeleteWorkspaceInvite, {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Invite',
        message: 'Invited user deleted.',
      });
      queryClient.invalidateQueries(wsInviteQueryKey);
    },
  });

  const handleDeleteInvitedMember = useCallback(
    (inviteeId: string) => {
      deleteInvitedMember.mutate({
        WorkspaceId: workspace.id,
        inviteId: inviteeId,
      });
    },
    [deleteInvitedMember, workspace?.id]
  );

  const deleteWorkspaceMember = useMutation(DeleteWorkspaceMember, {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Member',
        message: 'Workspace member deleted.',
      });
      queryClient.invalidateQueries(wsMembersQueryKey);
    },
  });

  const handleDeleteWorkspacedMember = useCallback(
    (memberUserId: string) => {
      deleteWorkspaceMember.mutate({
        WorkspaceId: workspace.id,
        userId: memberUserId,
      });
    },
    [deleteWorkspaceMember, workspace?.id]
  );

  //update workspace member permission
  const updatePermission = useMutation(UpdateWorkspaceMemberRole, {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Roles & Permission',
        message: 'Workspace role updated.',
      });
      queryClient.invalidateQueries(wsMembersQueryKey);
    },
    onError: (error) => {
      showNotification({
        color: 'red',
        message:
          (error as AxiosError<{ message: string }>)?.response?.data?.message ||
          'Error occurred',
      });
    },
  });

  return (
    <WorkspaceRBAC action="canViewWorkspaceMembers">
      <Stack spacing={'xl'}>
        <Group position="apart">
          <Title order={2}>Workspace Members</Title>
          <Button onClick={() => setOpened(true)}>Invite Members</Button>
        </Group>
        <Modal
          centered
          opened={opened}
          onClose={() => setOpened(false)}
          title={<Text>Invite Workspace Member</Text>}
        >
          <form onSubmit={form.onSubmit(onSubmit)}>
            <Box sx={() => ({ display: 'flex', flexDirection: 'column' })}>
              <TextInput
                type="email"
                placeholder="Enter email address"
                {...form.getInputProps('email')}
                rightSectionWidth={150}
                rightSection={
                  <NativeSelect
                    data={selectPermissionData}
                    placeholder="Select permission"
                    styles={{
                      input: {
                        fontWeight: 500,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                    {...form.getInputProps('role')}
                  />
                }
              />
              {form.values.role ? (
                <Text
                  mt={10}
                  sx={(theme) => ({
                    color: `${theme.colors.gray[6]}`,
                    fontSize: theme.fontSizes.sm,
                  })}
                >
                  {workspaceRoleDescription[form.values.role]}
                </Text>
              ) : null}
              <Box
                sx={() => ({
                  marginTop: '24px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                })}
              >
                <Button
                  type="submit"
                  disabled={inviteMember.isLoading}
                  loading={inviteMember.isLoading}
                >
                  Send Invite
                </Button>
              </Box>
            </Box>
          </form>
        </Modal>

        <div>
          <div>
            <SegmentedControl
              value={activeTab}
              onChange={(data: TActiveTab) => setActiveTab(data)}
              data={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' },
              ]}
            />
          </div>

          {activeTab === 'active' && (
            <div>
              <Paper shadow="xs" withBorder={true} radius="md" mt="md">
                {TeamMemberLoading ? (
                  <div style={{ height: 200, position: 'relative' }}>
                    <LoadingOverlay visible />
                  </div>
                ) : (
                  <ScrollArea>
                    <Table
                      horizontalSpacing="xl"
                      verticalSpacing="sm"
                      highlightOnHover
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th style={{ width: 150 }}>Role</th>
                          <th style={{ width: 100 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {workspaceMembers.map((member) => (
                          <tr key={member.id}>
                            <td>
                              <Group spacing="sm">
                                <Avatar radius="xl" color={'blue'}>
                                  {getNameInitials({
                                    name: `${member.user.firstName} ${member.user.lastName} `,
                                  })}
                                </Avatar>
                                <div>
                                  <Text size="sm" weight={500}>
                                    {`${member.user.firstName} ${member.user.lastName} `}
                                  </Text>
                                  <Text size="sm" weight={500} color="gray">
                                    {member.user.email}
                                  </Text>
                                </div>
                              </Group>
                            </td>
                            <td>
                              <ChangeUserRole
                                defaultRole={member.mainRole}
                                disabled={member.userId === currentUser?.id}
                                onRoleChange={(role) =>
                                  updatePermission.mutate({
                                    role,
                                    userId: member.userId,
                                    workspaceId: member.workspaceId,
                                  })
                                }
                              />
                            </td>
                            <td>
                              {member.userId === currentUser?.id ? null : (
                                <Button
                                  color="dark"
                                  variant="subtle"
                                  size="xs"
                                  onClick={() =>
                                    handleDeleteWorkspacedMember(member.userId)
                                  }
                                >
                                  Delete
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ScrollArea>
                )}
              </Paper>
            </div>
          )}
          {activeTab === 'inactive' && (
            <div>
              {invitedTeamMembers && invitedTeamMembers.length > 0 ? (
                <Paper shadow="xs" withBorder={true} radius="md" mt="md">
                  {inviteeLoading ? (
                    <div style={{ height: 200, position: 'relative' }}>
                      <LoadingOverlay visible />
                    </div>
                  ) : (
                    <ScrollArea>
                      <Table
                        horizontalSpacing="xl"
                        verticalSpacing="sm"
                        highlightOnHover
                      >
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Role</th>
                            <th style={{ width: 100 }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {invitedTeamMembers.map((member) => (
                            <tr key={member.id}>
                              <td>
                                <Text size="sm" weight={500} color="gray">
                                  {member.email}
                                </Text>
                              </td>
                              <td>
                                <Text size="sm" weight={500} color="gray">
                                  {member.role}
                                </Text>
                              </td>
                              <td>
                                <Button
                                  color="dark"
                                  variant="subtle"
                                  size="xs"
                                  onClick={() =>
                                    handleDeleteInvitedMember(member.id)
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </ScrollArea>
                  )}
                </Paper>
              ) : (
                <EmptyState
                  icon={<TransactionZeroIcon />}
                  title="Pending Invites"
                  subTitle="There are no pending or unaccepted invites for your workspace"
                />
              )}
            </div>
          )}
        </div>
      </Stack>
    </WorkspaceRBAC>
  );
};

export const TeamMembers = () => {
  const { workspaceDetails } = useCurrentWorkspace();

  if (!workspaceDetails) return <LoadingOverlay visible />;
  return <TeamMemberForm workspace={workspaceDetails!} />;
};
