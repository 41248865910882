import { AddressVerificationStatusEnum } from '@prisma/client';
import * as Joi from 'joi';
import { PhoneNumberValidator } from './helpers';

const SINGLE_ADDRESS_SCHEMA = {
  fullName: Joi.string().required().label('Full name'),
  phoneNo: Joi.string()
    .custom(PhoneNumberValidator)
    .required()
    .label('Phone number'),

  address: Joi.string().required().label('Address'),
};
const NEW_VERIFICATION_SCHEMA = Joi.object({
  data: Joi.array()
    .items(Joi.object(SINGLE_ADDRESS_SCHEMA))
    .min(1)
    .required()
    .label('Address data'),
});
const UPDATE_VERIFICATION_SCHEMA = Joi.object({
  ...SINGLE_ADDRESS_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
}).fork(['fullName', 'phoneNo', 'address'], (schema) => schema.optional());
const ADMIN_UPDATE_REPORT = Joi.object({
  addressId: Joi.string().required().label('Address ID'),
  reportUrl: Joi.string().uri().required().label('Report URL'),
});

const ADMIN_UPDATE_ADDRESS = Joi.object({
  ...SINGLE_ADDRESS_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  status: Joi.string()
    .valid(...Object.values(AddressVerificationStatusEnum))
    .optional()
    .label('Status'),
}).fork(['fullName', 'phoneNo', 'address'], (schema) => schema.optional());

export const AddressVerificationSchema = {
  NEW_VERIFICATION_SCHEMA,
  UPDATE_VERIFICATION_SCHEMA,
  ADMIN_UPDATE_REPORT,
  ADMIN_UPDATE_ADDRESS,
};
