import { Center, Group, Stack, Title, Text } from '@mantine/core';
import { ReactNode } from 'react';

interface EmptyStateProps {
  icon?: ReactNode;
  title?: string;
  subTitle?: string;
  button?: ReactNode;
}

const EmptyState = ({ title, icon, subTitle, button }: EmptyStateProps) => {
  return (
    <Center>
      <Group direction="column" position="center" spacing={'xl'}>
        {icon}
        <Stack spacing={4} align="center">
          <Title order={2}>{title}</Title>
          <Text align="center">{subTitle}</Text>
        </Stack>
        {button}
      </Group>
    </Center>
  );
};

export default EmptyState;
