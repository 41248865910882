import { useCurrentCountry } from '../../../hooks/country';
import React from 'react';
import { VotersCard } from '../routes/ng/identityVerification/subRoutes/votersCard/votersCard';
import { GHVotersCard } from '../routes/gh/identityVerification/votersCard/votersCard';

export const SharedVotersCard = () => {
  const { country } = useCurrentCountry();

  if (country === 'NG') return <VotersCard />;

  if (country === 'GH') return <GHVotersCard />;

  return null;
};
