import { createStyles } from '@mantine/core';
import { ActiveTab } from './headerMenu';

export const useHeaderMenuStyles = createStyles(
  (theme, { activeTab }: { activeTab: ActiveTab }) => ({
    bgHover: {
      '&:hover': {
        backgroundColor: theme.colors.blue[7],
      },
    },
    active: {
      backgroundColor: theme.colors.blue[7],
    },
  })
);
