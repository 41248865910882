import { Stack, Box, Text, Group, TextInput, Textarea } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { ICACRequestProps } from '../cac';

interface ICACRequestForm {
  introText: string;
  form: UseFormReturnType<ICACRequestProps>;
}

export const CACRequestForm = ({ introText, form }: ICACRequestForm) => {
  return (
    <Stack spacing="xl" p="24px">
      <Box
        sx={(theme) => ({
          color: theme.colors.gray[7],
        })}
      >
        <Text size="sm">{introText}</Text>
      </Box>

      <Group direction="column" spacing="lg" grow>
        <TextInput
          label="RC Number"
          placeholder="Enter RC number"
          {...form.getInputProps('idNumber')}
          required
        />

        <TextInput
          label="Company Name"
          placeholder="Enter company name"
          {...form.getInputProps('company')}
          required
        />

        <Textarea
          label="Description"
          autosize
          minRows={4}
          {...form.getInputProps('description')}
        />
      </Group>
    </Stack>
  );
};
