import {
  Text,
  Group,
  TextInput,
  Textarea,
  Button,
  Stack,
  Checkbox,
} from '@mantine/core';
import { RequestHeader } from '../../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { NINIcon } from '../../../../../../../../assets/icons/index';
import { RequestLayout } from '../../../../../../../../components/layouts/request/requestLayout';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { NG_NINRequestDto } from '@iverifyng/dtos';
import { ServiceSampleImageAccordion } from '../../../../../../../../components/primitives/cards/services/serviceSampleImage';

export interface NINFormData extends NG_NINRequestDto {
  description: string;
}

interface INinFormProps {
  form: UseFormReturnType<NINFormData>;
  renderIf?: boolean;
  onBack?: () => void;
  showWithSlip?: boolean;
  withSlip?: boolean;

  onSlipCheck?: (value: boolean) => void;
}

export const NINForm = ({
  form,
  onBack,
  withSlip,
  onSlipCheck,
  showWithSlip,
  renderIf,
}: INinFormProps) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="National Identity Number"
          supportingText="NIN"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<NINIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Stack justify="space-between" py="md" sx={() => ({ minHeight: '100%' })}>
        <Group direction="column" px="lg" grow spacing="md">
          <ServiceSampleImageAccordion
            title="Sample NIN Slip"
            image="https://storage.googleapis.com/iverify-public/sample-ids/ng-nin.png"
          />
          <Text color="gray" size="sm">
            NIN verification request provides you with the owner's first and
            last name, date of birth, etc.
          </Text>
          <TextInput
            label="National Identity Number (NIN)"
            placeholder="Enter NIN"
            {...form.getInputProps('idNumber')}
            required
          />
          <Textarea
            label="Description"
            {...form.getInputProps('description')}
          />
          {showWithSlip && (
            <Checkbox
              label="Do you want the NIN slip attached to the report?"
              checked={withSlip}
              onChange={(e) => {
                if (onSlipCheck) {
                  onSlipCheck(e.target.checked);
                }
              }}
            />
          )}
        </Group>
      </Stack>
    </RequestLayout>
  );
};
