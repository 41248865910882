import { CreateTenantAgentReportDto } from '@iverifyng/dtos';
import {
  Stack,
  Textarea,
  AccordionItem,
  Group,
  Button,
  Divider,
  Card,
} from '@mantine/core';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import {
  ReportAccordionLabel,
  ReportFromAccordion,
} from '../../../../../components/controls/accordions/reportAccordion/requestDetails';
import { ReportFormHeader } from '../../../../../components/primitives/report/reporHeader';
import { ReportFormInputs } from '../../../../menus/requests/container/form/form';
import { AddMoreButton } from '../../../../../components/primitives/button/download';
import { RequestDetailsBlock } from '../../../../requestDetails/block';

interface IApplicantDetailsFormProps {
  control: Control<CreateTenantAgentReportDto>;
}

interface FormItemType extends IApplicantDetailsFormProps {
  index: number;
}

const GuarantorsName = ({ control, index }: FormItemType) => {
  return (
    <RequestDetailsBlock title={`Guarantor ${index + 1}`}>
      <Stack>
        <ReportFormInputs
          label="Full Name"
          input={
            <Group>
              <Controller
                control={control}
                name={`applicant.guarantors.${index}.fullName.value`}
                render={({ field }) => <Textarea sx={{ flex: 1 }} {...field} />}
              />
              <Controller
                control={control}
                name={`applicant.guarantors.${index}.fullName.comment`}
                render={({ field }) => <Textarea sx={{ flex: 1 }} {...field} />}
              />
            </Group>
          }
        />
        <ReportFormInputs
          label="Residential Address"
          input={
            <Group>
              <Controller
                control={control}
                name={`applicant.guarantors.${index}.address.value`}
                render={({ field }) => <Textarea sx={{ flex: 1 }} {...field} />}
              />
              <Controller
                control={control}
                name={`applicant.guarantors.${index}.address.comment`}
                render={({ field }) => <Textarea sx={{ flex: 1 }} {...field} />}
              />
            </Group>
          }
        />
        <ReportFormInputs
          label="Work Address"
          input={
            <Group>
              <Controller
                control={control}
                name={`applicant.guarantors.${index}.workAddress.value`}
                render={({ field }) => <Textarea sx={{ flex: 1 }} {...field} />}
              />
              <Controller
                control={control}
                name={`applicant.guarantors.${index}.workAddress.comment`}
                render={({ field }) => <Textarea sx={{ flex: 1 }} {...field} />}
              />
            </Group>
          }
        />
      </Stack>
    </RequestDetailsBlock>
  );
};

export const TenantApplicantDetailsForm = ({
  control,
}: IApplicantDetailsFormProps) => {
  const {
    fields: guarantorsNameFields,
    append: appendGuarantorsName,
    remove: removeGuarantorsName,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `applicant.guarantors`, // unique name for your Field Array
  });

  return (
    <ReportFromAccordion iconPosition="right" initialItem={0} multiple>
      <AccordionItem label={<ReportAccordionLabel title="Details Presented" />}>
        <Group position="apart" spacing={1} mb="7px">
          <ReportFormHeader title="Item" />
          <ReportFormHeader title="Personal Data" />
          <ReportFormHeader title="Comments" />
        </Group>
        <Stack spacing={'md'}>
          <ReportFormInputs
            label="Tenant's Name"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.fullName.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.fullName.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />

          <ReportFormInputs
            label="Residential address"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.residentialAddress.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.residentialAddress.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />
          <ReportFormInputs
            label="Company Name / Position"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.companyName.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.companyName.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />
          <ReportFormInputs
            label="Company Address"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.companyAddress.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.companyAddress.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />

          <ReportFormInputs
            label="Phone Number"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.phoneNo.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.phoneNo.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />
          <ReportFormInputs
            label="Spouse name"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.spouseName.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.spouseName.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />

          <ReportFormInputs
            label="Means of Identification"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.meansOfId.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.meansOfId.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />
          <ReportFormInputs
            label="Landlord's info"
            input={
              <Group>
                <Controller
                  control={control}
                  name="applicant.landlordInfo.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="applicant.landlordInfo.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />

          <RequestDetailsBlock title="GUARANTORS">
            <Card shadow="lg">
              <Stack py="sm">
                {guarantorsNameFields.length > 0
                  ? guarantorsNameFields.map((item, index) => {
                      return (
                        <Stack key={item.id}>
                          <GuarantorsName control={control} index={index} />
                          <Group position="left" pb="sm">
                            <Button
                              size="xs"
                              variant="light"
                              color={'red'}
                              onClick={() => removeGuarantorsName(index)}
                            >
                              Delete
                            </Button>
                          </Group>
                          <Divider
                            sx={(theme) => ({
                              borderColor: theme.colors['gray'][1],
                            })}
                          />
                        </Stack>
                      );
                    })
                  : null}
                <Group position="left">
                  <AddMoreButton
                    text="Add Guarantor"
                    onClick={() =>
                      appendGuarantorsName({
                        fullName: { value: '', comment: '' },
                        address: { value: '', comment: '' },
                        workAddress: { value: '', comment: '' },
                      })
                    }
                  />
                </Group>
              </Stack>
            </Card>
          </RequestDetailsBlock>
        </Stack>
      </AccordionItem>
    </ReportFromAccordion>
  );
};
