import {
  CreateRequestReponseDto,
  CreateTenantVerificationDto,
  AdminTenantUpdateReportDto,
  TenanteVerificationRecordDto,
  UpdateTenantVerificationDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';

interface ICreateTenantVerificationDto extends CreateTenantVerificationDto {
  workspaceId: string;
}

export const CreateTenantVerificationApi = async ({
  workspaceId,
  ...data
}: ICreateTenantVerificationDto): Promise<CreateRequestReponseDto> => {
  const url = `/request/tenants/${workspaceId}/new`;
  return await axiosInstance.post(url, data).then((response) => response.data);
};

export const AdminUpdateTenantReportApi = async (
  data: AdminTenantUpdateReportDto
): Promise<TenanteVerificationRecordDto> => {
  const url = `/admin/requests/tenant/reportUrl`;
  return await axiosInstance.patch(url, data).then((response) => response.data);
};

export const AdminRemoveTenantReportApi = async (params: {
  tenantId: string;
}): Promise<TenanteVerificationRecordDto> => {
  const url = `/admin/requests/tenant/${params.tenantId}/reportUrl`;
  return await axiosInstance.delete(url).then((response) => response.data);
};

export const AdminUpdateTenantApi = async (
  data: UpdateTenantVerificationDto
): Promise<UpdateTenantVerificationDto> => {
  const url = '/admin/requests/tenant';
  return await axiosInstance.put(url, data).then((response) => response.data);
};
