import { Stack } from '@mantine/core';
import { useMatch } from '@tanstack/react-location';
import { AdminWorkspaceCard } from '../../../../components/primitives/cards/admin/workspace/workspaceCard';
import { RequestBodySkeleton } from '../../../../components/primitives/skeletons/requestDetail';
import { useAdminUserWorkspaces } from '../../../../hooks/admin-workspace';
import { UserGenerics } from '../userTabOutlet';

export const AdminUserWorkspace = () => {
  const {
    params: { userId },
  } = useMatch<UserGenerics>();

  const { data, isLoading, isError } = useAdminUserWorkspaces(userId);

  // console.log(workspaces, 'workspaces');

  if (isError || isLoading) return <RequestBodySkeleton skeleton={true} />;

  return (
    <Stack px="xs" spacing="sm">
      {!!data &&
        data.map((d) => (
          <AdminWorkspaceCard key={d.workspaceId} workspace={d.workspace} />
        ))}
    </Stack>
  );
};
