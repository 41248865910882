/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { CACIcon } from '../../../../../../assets/icons/request';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { useLocation } from '@tanstack/react-location';
import React from 'react';
import { joiResolver, useForm } from '@mantine/form';
import { Box, Button, Group } from '@mantine/core';
import { NG_CACRequestDto, ServiceCodeTypes } from '@iverifyng/dtos';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import { CACRequestForm } from './container/cacForm';
import { CACDetailsConfirmation } from './container/cacConfirmation';
import { ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../hooks/workspace';
import { useCurrentCountry } from '../../../../../../hooks/country';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../hooks/request';
import { useConsentConfirmation } from '../../../../../../hooks/consent';
import { debounce } from 'lodash';

export interface ICACRequestProps extends NG_CACRequestDto {
  description: string;
}

export const CAC = () => {
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const { history } = useLocation();
  const [review, setReview] = React.useState(true);

  const form = useForm<ICACRequestProps>({
    initialValues: {
      idNumber: '',
      company: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.CAC),
  });

  const { showConsent } = useConsentConfirmation();
  const CACRequest = useCreateIdentityVerification();

  const { data: serviceCharge } = useServiceCharge({
    code: 'CAC' as ServiceCodeTypes,
    country: country! as ServiceCountry,
  });

  const handleReview = React.useCallback(() => {
    setReview(false);
  }, []);

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        CACRequest.mutate({
          code: 'CAC',
          country: country as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            company: data.company,
            description: data.description,
          },
        });
      },
    });
  }, [CACRequest, country, form.values, showConsent, workspaceId]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  const isLoading = React.useMemo(() => {
    return CACRequest.isLoading;
  }, [CACRequest.isLoading]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleReview)}>
      <RequestLayout
        headerFragment={
          <RequestHeader
            text="Corporate Affairs Commission"
            onClickBack={() => (review ? history.back() : setReview(true))}
            withBackArrow
            withBackground
            icon={<CACIcon width={42} height={42} />}
          />
        }
        footerFragment={
          <Group position="apart" grow>
            {review && (
              <Button
                variant="outline"
                color="dark"
                onClick={() => history.back()}
              >
                Cancel request
              </Button>
            )}
            {review ? (
              <Button type="submit">Verify</Button>
            ) : (
              <Button
                type="submit"
                onClick={handleDoubleClick}
                loading={isLoading}
                disabled={isLoading}
              >
                Submit
              </Button>
            )}
          </Group>
        }
      >
        <Box sx={() => ({ height: '100%' })}>
          {review ? (
            <CACRequestForm
              introText="CAC verification request provides you with the company's verified name, active status, shareholders info."
              form={form}
            />
          ) : (
            <CACDetailsConfirmation
              data={form.values}
              onEditClick={() => setReview(true)}
              billed={{
                currency: serviceCharge?.currency!,
                amount: serviceCharge?.amount!,
              }}
            />
          )}
        </Box>
      </RequestLayout>
    </form>
  );
};
