import { Box, Stack } from '@mantine/core';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';
import { DataInfo } from '../../../../../../../components/primitives/details/dataInfo';
import { ITINRequestProps } from '../tin';

interface ITINDetailsInfoprops {
  data: ITINRequestProps;
  onEditClick: () => void;
  billed?: IServiceCharge;
}

export const TINDetailsConfirmation = ({
  data,
  onEditClick,
  billed,
}: ITINDetailsInfoprops) => {
  return (
    <Stack justify="space-between" sx={() => ({ height: '100%' })} spacing={0}>
      <Box px="lg">
        <DataInfo
          title="Tax Identification Number"
          description={data.idNumber}
          icon={true}
          onEditClick={onEditClick}
        />

        <DataInfo
          title="Description"
          description={data.description}
          icon={true}
          onEditClick={onEditClick}
        />
      </Box>

      <Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Box>
    </Stack>
  );
};
