import { createStyles } from '@mantine/core';

export const useDatePickerStyle = createStyles((theme) => ({
  input: {
    backgroundColor: theme.colors.gray[0],
    width: 300,
  },
  datePickerStyle: {
    backgroundColor: theme.colors.gray[0],
  },
  unstyledInput: {
    minWidth: 250,
    '&:hover': {
      borderRadius: 4,
      background: theme.colors.gray[1],
    },
  },
}));
