import { Avatar, Center, Image, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { NINResponseDto } from '../../ng/interface';

export const NINReport = (props: NINResponseDto) => {
  return (
    <Stack spacing="sm">
      <Center pt="xs">
        <Avatar
          src={`data:image/jpeg;base64,${props.photo}`}
          radius="xl"
          size="lg"
          alt=""
        />
      </Center>
      {props.slip ? (
        <RequestDataLayout
          label="Slip"
          data={<Image src={`data:image/jpeg;base64,${props.slip}`} alt="" />}
        />
      ) : null}
      <RequestDataLayout label="Title" data={props.title ?? 'N/A'} />
      <RequestDataLayout label="Surname" data={props.surname ?? 'N/A'} />
      <RequestDataLayout label="Firstname" data={props.firstname ?? 'N/A'} />
      <RequestDataLayout label="Middlename" data={props.middlename ?? 'N/A'} />
      <RequestDataLayout label="Gender" data={props.gender ?? 'N/A'} />
      <RequestDataLayout label="Place" data={props.place ?? 'N/A'} />
      <RequestDataLayout label="Birthdate" data={props.birthdate ?? 'N/A'} />
      <RequestDataLayout
        label="Nationality"
        data={props.nationality ?? 'N/A'}
      />
      <RequestDataLayout
        label="Telephone no"
        data={props.telephoneno ?? 'N/A'}
      />
      <RequestDataLayout
        label="Birth country"
        data={props.birthcountry ?? 'N/A'}
      />
      <RequestDataLayout
        label="Marital status"
        data={props.maritalstatus ?? 'N/A'}
      />
      <RequestDataLayout
        label="Educational level"
        data={props.educationallevel ?? 'N/A'}
      />
      <RequestDataLayout
        label="Spoken language"
        data={props.nspokenlang ?? 'N/A'}
      />
      <RequestDataLayout
        label="Residence status"
        data={props.residencestatus ?? 'N/A'}
      />
      <RequestDataLayout
        label="Residence Address Line1"
        data={props.residence_AddressLine1 ?? 'N/A'}
      />
      <RequestDataLayout label="Residence Town" data={props.residence_town} />
      <RequestDataLayout label="Residence LGA" data={props.residence_lga} />
      <RequestDataLayout
        label="Residence state"
        data={props.residence_state ?? 'N/A'}
      />
      {/* <RequestDataLayout
        label="NOK lastname"
        data={props.nok_lastname ?? 'N/A'}
      />
      <RequestDataLayout
        label="NOK firstname"
        data={props.nok_firstname ?? 'N/A'}
      />
      <RequestDataLayout label="NOK LGA" data={props.nok_lga ?? 'N/A'} />
      <RequestDataLayout label="NOK Town" data={props.nok_town} />
      <RequestDataLayout label="NOK State" data={props.nok_state ?? 'N/A'} />
      <RequestDataLayout
        label="NOK Address 1"
        data={props.nok_address1 ?? 'N/A'}
      />
      <RequestDataLayout
        label="NOK Address 2"
        data={props.nok_address2 ?? 'N/A'}
      />
      <RequestDataLayout
        label="NOK Postal Code"
        data={props.nok_postalcode ?? 'N/A'}
      /> */}
    </Stack>
  );
};
