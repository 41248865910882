import { TenantVerificationSchema } from '@iverifyng/validators';
import {
  AccordionItem,
  Stack,
  Group,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import { useModals } from '@mantine/modals';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import RequestDataLayout from '../../../../../../components/layouts/request/requestDataLayout';
import { RequestDetailsBlock } from '../../../../../../connected/requestDetails/block';
import { useEffect, useState } from 'react';
import { AlertTriangle, Edit, Trash } from 'tabler-icons-react';
import { ITenantFormItemProps } from '../interface';

interface ISingleTenantAccordionProps {
  index: number;
  formvalues: ITenantFormItemProps;
  onEdit?: (index: number) => void;
  onRemove?: (index: number) => void;
}

export const SingleTenantAccordion = ({
  index,
  formvalues,
  onEdit,
  onRemove,
}: ISingleTenantAccordionProps) => {
  const modals = useModals();
  const [hasError, setHasError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const { error } = TenantVerificationSchema.NEW_VERIFICATION_SCHEMA.validate(
      {
        data: [formvalues],
      }
    );
    setHasError(error?.message);
  }, [hasError, formvalues]);

  return (
    <FormListAccordion
      key={index}
      iconPosition="right"
      initialItem={index}
      multiple
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            rightIcon={
              <Group spacing={4}>
                {hasError && (
                  <Tooltip label={hasError}>
                    <ActionIcon variant="light" color={'yellow'}>
                      <AlertTriangle size={16} />
                    </ActionIcon>
                  </Tooltip>
                )}

                <ActionIcon
                  color="gray"
                  variant="light"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    if (onEdit) onEdit(index);
                  }}
                >
                  <Edit size={16} />
                </ActionIcon>

                <ActionIcon
                  color="red"
                  variant="light"
                  disabled={index === 0}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    modals.openConfirmModal({
                      title: 'Delete',
                      children: 'Are you sure you want to delete this item?',
                      labels: {
                        confirm: 'Confirm',
                        cancel: 'Cancel',
                      },
                      onConfirm: () => {
                        if (onRemove) {
                          onRemove(index);
                        }
                      },
                    });
                  }}
                >
                  <Trash size={16} />
                </ActionIcon>
              </Group>
            }
          />
        }
      >
        <Stack spacing={'md'}>
          <RequestDetailsBlock title="Tenant's Basic Details">
            <RequestDataLayout label="Full Name" data={formvalues.fullName!} />
            <RequestDataLayout
              label="Phone Number"
              data={formvalues.phoneNo!}
            />

            <RequestDataLayout
              label="Means of Identification"
              data={formvalues.idType!}
            />

            <RequestDataLayout label="ID Number" data={formvalues.idNumber!} />
            <RequestDataLayout
              label="Work Address"
              data={formvalues.workAddress!}
            />
            <RequestDataLayout
              label="Residential Address"
              data={formvalues.residentialAddress!}
            />
            <RequestDataLayout
              label="Spouse Name"
              data={formvalues.spouseName! ?? 'N/A'}
            />
            <RequestDataLayout
              label="Reason for relocating"
              data={formvalues.relocationReason! ?? 'N/A'}
            />
            <RequestDataLayout
              label="Perform Credit Check?"
              data={formvalues.performCreditCheck ? 'Yes' : 'No'}
            />
          </RequestDetailsBlock>
          <RequestDetailsBlock title="Guarantor's Details">
            <RequestDataLayout
              label="Full Name"
              data={formvalues.guarantors[0].fullname!}
            />
            <RequestDataLayout
              label="Phone Number"
              data={formvalues.guarantors[0].phoneNo!}
            />
            <RequestDataLayout
              label="Means of Identification"
              data={formvalues.guarantors[0].idType!}
            />
            <RequestDataLayout
              label="ID Number"
              data={formvalues.guarantors[0].idNumber!}
            />

            <RequestDataLayout
              label="Residential address"
              data={formvalues.guarantors[0].address!}
            />
            <RequestDataLayout
              label="Work address"
              data={formvalues.guarantors[0].workAddress ?? 'N/A'}
            />
            <RequestDataLayout
              label="Relationship"
              data={formvalues.guarantors[0].relationship!}
            />
          </RequestDetailsBlock>
          <RequestDetailsBlock title="Landlord's Details">
            <RequestDataLayout
              label="Full Name"
              data={formvalues.landlords[0].fullname!}
            />
            <RequestDataLayout
              label="Address"
              data={formvalues.landlords[0].address! ?? 'N/A'}
            />
            <RequestDataLayout
              label="Phone Number"
              data={formvalues.landlords[0].phoneNo!}
            />
          </RequestDetailsBlock>
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
