/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  useRequestDetailById,
  useResponseDetailByIdentityId,
} from '../../../hooks/request';
import { useCallback, useEffect, useMemo } from 'react';
import { useMatch } from '@tanstack/react-location';
import { RequestDetailSkeleton } from '../../../components/primitives/skeletons/requestDetail';
import { useQueryClient } from 'react-query';
import { useInterval } from '@mantine/hooks';
import { CriminalCheck } from './category/criminalCheck';
import { IdentityRequestDetail } from '../../../../src/connected/responseDetails/ng/identity';
import { SharedResponseDetail } from '../../admin/request/containers/interface';
import saveAs from 'file-saver';
import { CertificateRequestDetails } from './category/certificate';
import { TenantRequestDetails } from './category/tenant';
import { useRetryPaymentMutation } from '../../../services/mutations/payments';
import { GuarantorRequestDetails } from './category/guarantor';
import { EmployeeRequestDetails } from './category/employyee';
import { PropertyRequestDetails } from './category/property';
import { CreditCheckRequestDetails } from './category/creditCheck';
import { PreviousWorkRequestDetails } from './category/previouswork';
import { AddressRequestDetails } from './category/address';

export const RequestDetail = () => {
  const {
    params: { requestId },
  } = useMatch();

  const queryClient = useQueryClient();
  const refetchInterval = useInterval(
    () =>
      queryClient.invalidateQueries(['request', requestId], { stale: true }),
    5000
  );

  const { data, isLoading, isError } = useRequestDetailById(requestId);

  //   const billed = useMemo(
  //     () => ({
  //       currency: data?.payment?.currency!,
  //       amount: Number(data?.payment?.amount!),
  //     }),
  //     [data?.payment?.amount, data?.payment?.currency]
  //   );

  //refetch the data when Pending or Ongoing Verification
  //this will ensure the data is updated in UI
  useEffect(() => {
    const status = data?.status;
    const isPendingOrOngoing =
      status && ['PENDING', 'ONGOING_VERIFICATION'].includes(status);
    const isIdentityRequest = data?.service?.category === 'IDENTITY';
    if (isPendingOrOngoing && isIdentityRequest) {
      refetchInterval.start();
    } else {
      refetchInterval.stop();
    }
  }, [data?.service?.category, data?.status, refetchInterval]);

  const {
    data: responseDetail,
    isLoading: responseDetailLoading,
    isFetching: responseDetailIsFetching,
  } = useResponseDetailByIdentityId({
    identityId: data?.identity?.id as string,
    isEnabled: !!data?.identity?.id && data?.status === 'COMPLETED',
  });

  const retryPaymentMutation = useRetryPaymentMutation();

  const responseDetailData = useMemo(() => {
    return responseDetail?.['FullData'];
  }, [responseDetail]);

  const downloadReportPdf = useCallback(() => {
    const pdfUrl = data?.identity?.reportPdfUrl;
    if (!pdfUrl) return;

    const fileName = pdfUrl.split('/').pop();
    saveAs(pdfUrl, fileName);
  }, [data?.identity?.reportPdfUrl]);
  const retryPayment = useCallback(() => {
    if (!data) return;
    retryPaymentMutation.mutate({ trxRef: data?.payment?.trxRef! });
  }, [data, retryPaymentMutation]);
  const shouldRetryPayment = useMemo(() => {
    return data?.status === 'PENDING' && data?.payment?.status === 'PENDING';
  }, [data?.payment?.status, data?.status]);

  const hasPdfUrl = !!data?.identity?.reportPdfUrl;
  const serviceCategory = data?.service?.category;
  if (!isLoading && !data) return null;
  if (isLoading || isError) return <RequestDetailSkeleton />;

  return (
    <div style={{ height: '100%' }}>
      {serviceCategory === 'IDENTITY' && (
        <IdentityRequestDetail
          request={data!}
          responseDetails={responseDetailData as SharedResponseDetail}
          responseDetailLoading={
            responseDetailLoading || responseDetailIsFetching
          }
          onDownloadReport={hasPdfUrl ? downloadReportPdf : undefined}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'CRIMINAL_RECORD' && (
        <CriminalCheck
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'CERTIFICATE' && (
        <CertificateRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'TENANT' && (
        <TenantRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'GUARANTOR' && (
        <GuarantorRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'EMPLOYEE' && (
        <EmployeeRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'PROPERTY' && (
        <PropertyRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'CREDIT_CHECK' && (
        <CreditCheckRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'PREVIOUS_WORK' && (
        <PreviousWorkRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
      {serviceCategory === 'ADDRESS' && (
        <AddressRequestDetails
          request={data!}
          onPaymentRetry={shouldRetryPayment ? retryPayment : undefined}
          disableRetryBtn={retryPaymentMutation.isLoading}
          disableDownloadBtn={data?.status !== 'COMPLETED'}
        />
      )}
    </div>
  );
};
