import { Box, Navbar } from '@mantine/core';
import { withAuth } from '../../hoc/withAuth';
import { DashboardLayout } from '../../components/layouts/dashboard';
import { Outlet } from '@tanstack/react-location';
import { SidebarLinks } from '../../components/primitives/sidebarLinks/sidebarLinks';
import { WorkspaceRBACKeys } from '@iverifyng/utils';

const settingsData: Array<{
  label: string;
  link?: string;
  action?: WorkspaceRBACKeys;
  initiallyOpen?: boolean;
  children?: Array<{ label: string; link: string; action?: WorkspaceRBACKeys }>;
}> = [
  {
    label: 'Profile',
    link: '/settings/profile',
  },
  {
    label: 'Password',
    link: '/settings/password',
  },
  {
    label: 'Notification',
    link: '/settings/notification',
  },
  {
    label: 'Workspace',
    action: 'canViewWorkspace',

    initiallyOpen: true,
    children: [
      {
        label: 'Details',
        link: '/settings/workspace/details',
        // action: 'canViewWorkspaceMember',
      },
      {
        label: 'Members',
        link: '/settings/workspace/members',
        // action: 'canViewWorkspaceMember',
      },
    ],
  },
];

function UserSettings() {
  return (
    <DashboardLayout>
      <Box
        py="lg"
        sx={() => ({
          backgroundColor: '#FFF',
          display: 'flex',
          justifyContent: 'center',
          height: 'calc(100vh - 77.19px)',
          overflow: 'scroll',
        })}
      >
        <Box
          sx={() => ({
            backgroundColor: '#FFF',
            display: 'flex',
            width: '100%',
            maxWidth: '1000px',
            gap: 84,
          })}
        >
          <Navbar
            p="md"
            sx={() => ({
              borderRight: 'none',
              maxWidth: '200px',
              height: '100%',
            })}
          >
            <Navbar.Section>
              {settingsData.map((item) => (
                <SidebarLinks
                  key={item.label}
                  link={item.link ?? ''}
                  label={item.label}
                  isOpened={item.initiallyOpen}
                  subLinks={item.children}
                  action={item?.action}
                />
              ))}
            </Navbar.Section>
          </Navbar>
          <Box style={{ flex: 1 }} p="md">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}
export default withAuth(UserSettings);
