/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import * as React from 'react';
import { Box } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { useLocation } from '@tanstack/react-location';

import { DLRequestForm } from './container/DLRequestForm';
import dayjs from 'dayjs';
import { NG_DriversLicenseRequestDto, ServiceCodeTypes } from '@iverifyng/dtos';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import { useCurrentWorkspace } from '../../../../../../../hooks/workspace';
import { useCurrentCountry } from '../../../../../../../hooks/country';
import { ServiceCountry } from '@prisma/client';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../../hooks/request';
import { debounce } from 'lodash';
import { DLDetailsConfirmation } from './container/DLDetailsConfirmation';
import { useConsentConfirmation } from '../../../../../../../hooks/consent';

type DriverLicensePage = 'form' | 'verify';
export interface IDLRequestProps extends NG_DriversLicenseRequestDto {
  description: string;
}

export const DriversLicense = () => {
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const [page, setPage] = React.useState<DriverLicensePage>('form');
  const { history } = useLocation();

  const form = useForm<IDLRequestProps>({
    initialValues: {
      idNumber: '',
      firstName: '',
      lastName: '',
      description: '',
      dob: new Date().toDateString(),
    },
    schema: joiResolver(IdentityValidatorSchema.DRIVERS_LICENSE),
  });

  const { showConsent } = useConsentConfirmation();
  const driverLicenseRequest = useCreateIdentityVerification();
  const { data: serviceCharge } = useServiceCharge({
    code: 'DRIVERS_LICENSE' as ServiceCodeTypes,
    country: country! as ServiceCountry,
  });

  const handleVerify = React.useCallback(() => {
    setPage('verify');
  }, []);

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        driverLicenseRequest.mutate({
          code: 'DRIVERS_LICENSE' as ServiceCodeTypes,
          country: country as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            firstName: data.firstName,
            lastName: data.lastName,
            idNumber: data.idNumber,
            dob: dayjs(data.dob).format('YYYY-MM-DD'),
            description: data.description,
          },
        });
      },
    });
  }, [showConsent, form.values, driverLicenseRequest, country, workspaceId]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  const isLoading = React.useMemo(() => {
    return driverLicenseRequest.isLoading;
  }, [driverLicenseRequest.isLoading]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <Box sx={() => ({ height: '100%' })}>
        <DLRequestForm
          {...{ form }}
          onBack={() => history.back()}
          renderIf={page === 'form'}
        />

        <DLDetailsConfirmation
          loading={isLoading}
          {...{ form }}
          onSubmit={handleDoubleClick}
          data={form.values}
          onEditClick={() => setPage('form')}
          billed={{
            currency: serviceCharge?.currency!,
            amount: serviceCharge?.amount!,
          }}
          onBack={() => setPage('form')}
          renderIf={page === 'verify'}
        />
      </Box>
    </form>
  );
};
