import { withAdmin } from '../../../../hoc/withAdmin';
import { withAuth } from '../../../../hoc/withAuth';
import React, { useCallback, useMemo, useState } from 'react';
import { AdminLayout } from '../../../../components/layouts/admin';
import {
  MakeGenerics,
  Outlet,
  useLocation,
  useNavigate,
  useMatches,
} from '@tanstack/react-location';
import {
  GetWorkspaceUserResponseDto,
  GetWorkspaceUsersResponseDto,
} from '@iverifyng/dtos';
import {
  ActionIcon,
  Box,
  Center,
  Grid,
  Group,
  Skeleton,
  Stack,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core';
import { StatsSkeleton } from '../../../../components/primitives/skeletons/stats';
import { DashCard } from '../../../../components/primitives/cards/dashboard/dashStatCard/dashStatCard';
import { ArrowLeft, Search } from 'tabler-icons-react';
import { useTabStyle } from '../../request/request.styles';
import { RequestLayout } from '../../../../components/layouts/request/requestLayout';
import { Pagination } from '../../../../components/primitives/pagination/pagination';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import {
  useAdminGetWorkspace,
  useAdminWorkspaceRequests,
  useGetAdminWorkspaceMembers,
} from '../../../../hooks/admin-workspace';
import lodash from 'lodash-es';
import EmptyState from '../../../../components/primitives/emptyState/emptyState';
import { AgentZeroIcon, RequestZeroIcon } from '../../../../assets/icons';
import { AdminRequestCard } from '../../../../components/primitives/cards/admin/adminRequestCard/adminRequestCard';
import { getRequestCardIcon } from '../../../../components/primitives/cards/request/requestCard/helper';
import { AdminRequestLoader } from '../../../../components/primitives/skeletons/agent';
import { UserCard } from '../../../../components/primitives/cards/admin/users/userCard';
import { AdminUserLoader } from '../../../../components/primitives/skeletons/user';
import { useAdminWorkspaceStatsQuery } from '../../../../hooks/admin-analytics';
import { AppRBAC } from '../../../permission/app';

type TabID = 'requests' | 'team_members';
const tabs: Record<TabID, string> = {
  requests: 'requests',
  team_members: 'team members',
};

export type WorkspaceGenerics = MakeGenerics<{
  LoaderData: {
    workspace: GetWorkspaceUserResponseDto;
  };
  Search: {
    page: number;
    searchterm: string;
  };
}>;

const PAGE_LIMIT = 20;

const WorkspaceDetail = () => {
  const { current } = useLocation<WorkspaceGenerics>();
  const navigate = useNavigate();
  const matches = useMatches<WorkspaceGenerics>();
  const [page, setPage] = useState(() => current.search.page ?? 1);
  const [value, setValue] = useState(() => current.search.searchterm ?? '');
  const [searchterm] = useDebouncedValue(value, 800);
  const { classes } = useTabStyle();

  const lastItem = matches?.[matches?.length - 1];
  const workspaceId = lastItem?.params?.['workspaceId'];
  const detailId = lastItem?.params?.['detailId'];
  const tabId = lastItem?.params?.['tabId'];
  const matchedDetailId = detailId ?? null;
  const matchedTabId = tabId ?? 'requests';
  const tabArray = Object.keys(tabs);
  const activeTab = useMemo(() => {
    const current = tabArray.indexOf(tabId as TabID);
    return current;
  }, [tabArray, tabId]);

  //workspace details
  const { data: workspace, isLoading: isWorkspaceLoading } =
    useAdminGetWorkspace(workspaceId);

  //workspace stats
  const {
    data: workspaceStats,
    isLoading: isWorkspaceStatsLoading,
    isFetching: isWorkspaceStatsFetching,
  } = useAdminWorkspaceStatsQuery(workspaceId);

  const {
    data: requests,
    isLoading,
    isFetching,
  } = useAdminWorkspaceRequests({
    limit: PAGE_LIMIT,
    page,
    searchterm,
    workspaces: [workspaceId],
  });

  const {
    data: members,
    isLoading: isMembersLoading,
    isFetching: isMembersFetching,
  } = useGetAdminWorkspaceMembers(workspaceId);

  const onTabChange = useCallback(
    (active: number, tabKey: string) => {
      navigate({
        to: `/admin/workspace-detail/${workspaceId}/${tabKey.toLocaleLowerCase()}` as string,
      });
    },
    [navigate, workspaceId]
  );

  const handleRouteNavigate = useCallback(
    (params: Record<string, unknown>) => {
      navigate({
        search: (old) => {
          return lodash.pickBy(
            {
              ...old,
              ...params,
            },
            lodash.identity
          );
        },
        replace: true,
      });
    },
    [navigate]
  );

  const totalPages = useMemo(
    (): number => (requests ? Math.ceil(requests.count / PAGE_LIMIT) : 0),
    [requests]
  );

  const onChange = useCallback(
    (page: number) => {
      setPage(page);
      handleRouteNavigate({ page });
    },
    [handleRouteNavigate]
  );

  const pagination = usePagination({
    total: totalPages,
    page,
    onChange,
  });

  const filteredMembers = useMemo<
    GetWorkspaceUsersResponseDto | undefined
  >(() => {
    const filtered = members?.filter(
      (member) =>
        member.user.firstName?.toLowerCase().startsWith(searchterm) ||
        member.user.lastName?.toLowerCase().startsWith(searchterm)
    );
    return searchterm ? filtered : members;
  }, [members, searchterm]);

  const isEmpty = useMemo(() => {
    if (isFetching || isLoading) return false;
    if ((!requests?.count && !requests?.count) || requests?.data.length < 1)
      return true;
    return false;
  }, [isFetching, isLoading, requests?.count, requests?.data]);

  const isMemberEmpty = useMemo(() => {
    if (isMembersFetching || isMembersLoading) return false;
    if (filteredMembers && filteredMembers?.length <= 0) return true;
    return false;
  }, [isMembersFetching, isMembersLoading, filteredMembers]);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      setValue(e.target.value);
      setPage(1);
      await handleRouteNavigate({ searchterm: e.target.value, page: 1 });
    },
    [handleRouteNavigate]
  );

  return (
    <AppRBAC action="canViewWorkspace">
      <AdminLayout>
        <Box py="xl" px={32}>
          <Stack spacing="lg">
            <Group position="apart" align="center">
              <Group>
                <ActionIcon
                  variant="hover"
                  color="gray"
                  onClick={() => navigate({ to: '/admin/workspace' })}
                >
                  <Skeleton visible={isWorkspaceLoading}>
                    <ArrowLeft />
                  </Skeleton>
                </ActionIcon>
                <Text size="xl" weight={400}>
                  <Skeleton visible={isWorkspaceLoading}>
                    {workspace?.name}
                  </Skeleton>
                </Text>
              </Group>

              <Group align="center">
                {isWorkspaceStatsLoading || isWorkspaceStatsFetching ? (
                  <StatsSkeleton />
                ) : !isWorkspaceStatsFetching && !!workspaceStats ? (
                  <Group>
                    {workspaceStats?.map((stat, idx) => (
                      <DashCard
                        key={idx}
                        size="sm"
                        detail={{ count: stat.value, text: stat.label }}
                      />
                    ))}
                  </Group>
                ) : null}
              </Group>
            </Group>
          </Stack>
        </Box>
        <Box>
          <Box
            sx={(theme) => ({
              borderTop: '1px',
              borderBottom: '1px',
              border: ` 1px Solid ${theme.colors['gray'][2]}`,
            })}
          >
            <Tabs
              color="blue"
              classNames={classes}
              variant="unstyled"
              px="xl"
              py={4}
              active={activeTab}
              onTabChange={onTabChange}
              tabPadding="md"
            >
              {Object.entries(tabs).map(([key, value]) => (
                <Tabs.Tab tabKey={key} label={value} key={key} />
              ))}
            </Tabs>
          </Box>
          <Grid
            sx={() => ({
              height: 'calc(100vh - 237.78px)',
              width: '100%',
            })}
            gutter={0}
            columns={12}
          >
            <Grid.Col
              sx={(theme) => ({
                height: 'calc(100vh - 237.78px)',
                overflowY: 'auto',
                borderRight: `1px solid ${theme.colors['gray'][2]}`,
              })}
              span={4}
            >
              <RequestLayout
                headerFragment={
                  <Box px={32} py="sm">
                    <TextInput
                      icon={<Search size={20} />}
                      placeholder="Search requests"
                      value={value}
                      onChange={handleChange}
                    />
                  </Box>
                }
                footerFragment={
                  activeTab <= 0 && totalPages > 1 ? (
                    <Pagination
                      current={pagination.active}
                      totalPages={totalPages}
                      onNext={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        pagination.next();
                      }}
                      onPrevious={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        pagination.previous();
                      }}
                      color="gray"
                    />
                  ) : null
                }
              >
                {activeTab <= 0 && (
                  <Box>
                    {isEmpty ? (
                      <Center pt="xl" sx={{ height: '100%' }}>
                        <EmptyState
                          icon={<RequestZeroIcon />}
                          subTitle="Requests empty!"
                        />
                      </Center>
                    ) : !!requests?.data && !isLoading ? (
                      <>
                        {requests.data.map((request, i) => (
                          <AdminRequestCard
                            key={i}
                            active={matchedDetailId === request.id}
                            request={{
                              id: request.id,
                              status: request.status,
                              text: request?.service.description,
                              supportingText: [
                                request.id.toUpperCase(),
                                request.description,
                              ]
                                .filter(Boolean)
                                .join(' - '),
                              name: request.service.description!,
                              price: Number(request.payment?.amount),
                              currency: request.payment?.currency,
                              date: request.createdAt,
                              requester: {
                                firstName: request?.requester?.user?.firstName,
                                lastName: request?.requester?.user?.lastName,
                              },
                            }}
                            renderIcon={<>{getRequestCardIcon(request)}</>}
                            onClick={() =>
                              navigate({
                                to: `/admin/workspace-detail/${workspaceId}/${matchedTabId}/${request.id}`,
                                search: (old) => ({ ...old }),
                              })
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <AdminRequestLoader />
                    )}
                  </Box>
                )}
                {activeTab === 1 && (
                  <Box>
                    {isMemberEmpty ? (
                      <Center pt="xl" sx={{ height: '100%' }}>
                        <EmptyState
                          icon={<AgentZeroIcon />}
                          subTitle="No assigned agent yet"
                        />
                      </Center>
                    ) : !!filteredMembers?.length && !isMembersLoading ? (
                      <>
                        {filteredMembers.map((member, i) => (
                          <UserCard
                            key={i}
                            active={matchedDetailId === member.user.id}
                            user={member.user}
                            onClick={() =>
                              navigate({
                                to: `/admin/workspace-detail/${workspaceId}/${matchedTabId}/${member.user.id}/details`,
                                search: (old) => ({ ...old }),
                              })
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <AdminUserLoader limit={10} />
                    )}
                  </Box>
                )}
              </RequestLayout>
            </Grid.Col>

            <Grid.Col
              span={4}
              sx={(theme) => ({
                height: '100%',
                borderRight: `1px solid ${theme.colors['gray'][2]}`,
              })}
            >
              <Outlet />
            </Grid.Col>

            <Grid.Col
              span={4}
              style={{
                minHeight: '100%',
                width: '100%',
                overflowY: 'auto',
              }}
            ></Grid.Col>
          </Grid>
        </Box>
      </AdminLayout>
    </AppRBAC>
  );
};

export default withAuth(withAdmin(WorkspaceDetail));
