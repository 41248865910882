import { Box } from '@mantine/core';
import { useState } from 'react';
import { FileUploader } from '../../components/controls/fileuploader/fileuploader';
import { withAuth } from '../../hoc/withAuth';

export type FileObj = {
  file: File;
  url: string;
};

const FileUpload = () => {
  const [uploadUrl, setUploadUrl] = useState<string>();

  console.log('uploadUrl === ', uploadUrl);
  return (
    <Box p={'lg'} sx={() => ({ width: 400 })}>
      <FileUploader instantUpload onSuccess={(url) => setUploadUrl(url)} />
    </Box>
  );
};

export default withAuth(FileUpload);
