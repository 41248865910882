import {
  ServiceCountry,
  CertificateStatusEnum,
  CertificateTypeEnum,
} from '@prisma/client';
import * as Joi from 'joi';

const SINGLE_CERTIFICATE_SCHEMA = {
  fullName: Joi.string().required().label('Full name'),
  consentFormUrl: Joi.string()
    .uri()
    .optional()
    .allow(null, '')
    .label('Consent Form'),
  consentFile: Joi.any().optional(), //for frontend
  certificateUrl: Joi.string().uri().required().label('Certificate'),
  certificateFile: Joi.any().optional(), //for frontend
  type: Joi.string()
    .valid(...Object.values(CertificateTypeEnum))
    .required()
    .label('Type'),
  matricNumber: Joi.when('type', {
    is: 'UNIVERSITY',
    then: Joi.string().required(),
    otherwise: Joi.string().optional().allow(null, ''),
  }),
  university: Joi.when('type', {
    switch: [
      {
        is: 'UNIVERSITY',
        then: Joi.object({
          name: Joi.string().required().label('Name'),
          country: Joi.string().optional().label('Country'),
          domains: Joi.array().items(Joi.string()).optional().label('Domain'),
          alpha_two_code: Joi.string().optional().label('Alpha code'),
        }).required(),
        otherwise: Joi.object().optional(),
      },
    ],
  }).label('University'),
};
const NEW_VERIFICATION_SCHEMA = Joi.object({
  country: Joi.string()
    .valid(...Object.values(ServiceCountry))
    .optional(),
  data: Joi.array()
    .items(Joi.object(SINGLE_CERTIFICATE_SCHEMA))
    .min(1)
    .required()
    .label('Certificate data'),
  description: Joi.string().allow(null, '').optional().label('Description'),
});

const UPDATE_VERIFICATION_SCHEMA = Joi.object({
  ...SINGLE_CERTIFICATE_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
}).fork(
  ['fullName', 'consentFormUrl', 'certificateUrl', 'university', 'type'],
  (schema) => schema.optional()
);

const ADMIN_UPDATE_REQUEST = Joi.object({
  ...SINGLE_CERTIFICATE_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  reportUrl: Joi.when('status', {
    switch: [
      {
        is: 'VERIFIED',
        then: Joi.string().uri().required(),
        otherwise: Joi.string().uri().optional().allow(null),
      },
    ],
  }).label('Report URL'),
  status: Joi.string()
    .valid(...Object.values(CertificateStatusEnum))
    .optional()
    .label('Status'),
}).fork(
  ['fullName', 'consentFormUrl', 'certificateUrl', 'university', 'type'],
  (schema) => schema.optional()
);

export const CertificateVerificationSchema = {
  NEW_VERIFICATION_SCHEMA,
  UPDATE_VERIFICATION_SCHEMA,
  ADMIN_UPDATE_REQUEST,
};
