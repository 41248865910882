/* eslint-disable no-extra-boolean-cast */
import { Box, Button, Center, Loader, Skeleton, Stack } from '@mantine/core';
import { useNavigate } from '@tanstack/react-location';
import { CloudDownload } from 'tabler-icons-react';
import { CACIcon } from '../../../assets/icons';
import FormAccordion from '../../../components/controls/formAccordion/formAccordion';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import { RequestLayout } from '../../../components/layouts/request/requestLayout';
import { AlertCard } from '../../../components/primitives/cards/alerts/alerts';
import { RequestHeader } from '../../../components/primitives/cards/request/requestHeader/requestHeader';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../components/primitives/price/servicecharge';
import { DataInfo } from '../../../components/primitives/details/dataInfo';
import { RequestStatusTypes } from '@iverifyng/dtos';
import { CACResponseDto } from './interface';
import { CACReport } from '../reports/ng/cac';

export interface IDetailsDataProps<T> {
  idNumber: string;
  status: RequestStatusTypes;
  description: string;
  billed: IServiceCharge;
  responseDetails: T;
  renderIf?: boolean;
  skeleton?: boolean;
  responseDetailLoading?: boolean;
  onDownloadReport?: (() => void) | undefined;
}

export const CACDetails = ({
  status,
  description,
  billed,
  idNumber,
  skeleton = false,
  responseDetails,
  responseDetailLoading,
  renderIf,
  onDownloadReport,
}: IDetailsDataProps<CACResponseDto>) => {
  const navigate = useNavigate();

  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <Skeleton visible={skeleton}>
          <RequestHeader
            text="Coprate Affairs Commission"
            withClose
            withBackground
            icon={<CACIcon width={42} height={42} />}
            onCancel={() => navigate({ to: '/request' })}
          />
        </Skeleton>
      }
      footerFragment={
        <Skeleton visible={skeleton}>
          <Button
            fullWidth
            leftIcon={<CloudDownload />}
            disabled={status === 'PENDING' || !onDownloadReport}
            onClick={onDownloadReport}
          >
            Download Report
          </Button>
        </Skeleton>
      }
    >
      <Stack spacing="xs" pt="sm" px="lg">
        <Skeleton visible={skeleton}>
          <AlertCard alert={status as RequestStatusTypes} />
        </Skeleton>

        <Box
          py="xs"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
          })}
        >
          <Skeleton visible={skeleton}>
            <ServiceCharge
              serviceCharge={{
                currency: billed?.currency,
                amount: +billed?.amount,
              }}
            />
          </Skeleton>
        </Box>
        <Skeleton visible={skeleton}>
          <DataInfo
            icon={false}
            title="Description"
            description={description}
          />
        </Skeleton>
      </Stack>
      {!skeleton && (
        <FormAccordion
          title="Provided Information"
          content={<RequestDataLayout label="RC/N" data={idNumber} />}
        />
      )}

      {status === 'COMPLETED' && responseDetails && !skeleton ? (
        <FormAccordion
          title="ID Authority Response"
          content={responseDetails ? <CACReport {...responseDetails} /> : null}
        />
      ) : status === 'COMPLETED' && responseDetailLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : null}
    </RequestLayout>
  );
};
