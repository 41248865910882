import { ActionIcon, Avatar, Box, Button, Tabs } from '@mantine/core';
import { useCallback, useEffect, useMemo } from 'react';
import {
  MakeGenerics,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from '@tanstack/react-location';
import { useQueryClient } from 'react-query';
import { useInterval } from '@mantine/hooks';
import { useTabStyle } from '../request/request.styles';
import { RequestBodySkeleton } from '../../../components/primitives/skeletons/requestDetail';
import { useAdminUserDetails } from '../../../hooks/admin-users';
import { GetUserResponseDto } from '@iverifyng/dtos';
import { getNameInitials } from '@iverifyng/utils';
import { RequestHeader } from '../../../components/primitives/cards/request/requestHeader/requestHeader';
import { AdminUserDotMenu } from '../../../connected/menus/admin/user';

type TabID = 'details' | 'workspace';
const tabs: Record<TabID, string> = {
  details: 'details',
  workspace: 'workspace',
};

export type UserGenerics = MakeGenerics<{
  LoaderData: {
    user: GetUserResponseDto;
  };
}>;

export const AdminUserTabOutlet = () => {
  const {
    params: { userId },
  } = useMatch<UserGenerics>();
  const { error, data: user, isLoading } = useAdminUserDetails(userId);

  const { current } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const refetchInterval = useInterval(
    () => queryClient.invalidateQueries(['adminUser', userId], { stale: true }),
    5000
  );

  const tabArray = Object.keys(tabs);
  const path = current.pathname.split('/').pop();

  const activeTab = useMemo(() => {
    const current = tabArray.indexOf(path as TabID);
    return current;
  }, [path, tabArray]);

  const { classes } = useTabStyle();

  //refetch the data when Pending or Ongoing Verification
  //this will ensure the data is updated in UI
  // useEffect(() => {
  //   const status = request?.status;
  //   const isPendingOrOngoing =
  //     status && ['PENDING', 'ONGOING_VERIFICATION'].includes(status);
  //   const isIdentityRequest = request?.service?.category === 'IDENTITY';
  //   if (isPendingOrOngoing && isIdentityRequest) {
  //     refetchInterval.start();
  //   } else {
  //     refetchInterval.stop();
  //   }
  // }, [request?.service?.category, request?.status, refetchInterval]);

  const onTabChange = useCallback(
    (active: number, tabKey: string) => {
      navigate({
        to: `/admin/users/${userId}/${tabKey.toLocaleLowerCase()}` as string,
      });
    },
    [navigate, userId]
  );

  return (
    <Box>
      <Box>
        {isLoading || error ? (
          <RequestBodySkeleton type="header" skeleton={true} />
        ) : (
          <RequestHeader
            icon={
              user?.firstName && (
                <Avatar src="" alt={user.firstName!} radius="xl" color="indigo">
                  {getNameInitials({
                    name: `${user.firstName} ${user.lastName}`,
                    maxInitials: 2,
                  })}
                </Avatar>
              )
            }
            text={`${user?.firstName} ${user?.lastName}`}
            rightNode={<AdminUserDotMenu user={user!} />}
          />
        )}
      </Box>
      <Tabs
        color="blue"
        classNames={classes}
        variant="unstyled"
        px="xl"
        py="sm"
        active={activeTab}
        onTabChange={onTabChange}
        tabPadding="md"
      >
        {Object.entries(tabs).map(([key, value]) => (
          <Tabs.Tab tabKey={key} label={value} key={key} />
        ))}
      </Tabs>
      <Box
        sx={() => ({
          flex: 1,
          overflowY: 'hidden',
          height: 'calc(100vh - 208px)',
        })}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
