import { useSetState } from '@mantine/hooks';
import { useCallback } from 'react';
import { googleUpload } from '../services/file-upload/hook';
import {
  IStorageEmitterError,
  IStorageEmitterSuccess,
} from '../services/file-upload/interface';

export interface IUploadState {
  mode?: 'read' | 'edit';
  file?: File | null;
}

interface IOnUpload {
  file: File;
  instantUpload?: boolean;
  onSuccess: (url: string, file: File) => void;
}
interface IFormState extends IUploadState {
  percent?: number;
  status?: 'failed' | 'success' | 'pending' | 'uploading';
  error?: IStorageEmitterError | null;
  result?: IStorageEmitterSuccess | null;
  abortController?: AbortController;
  onSuccess?: ((url: string, file: File) => void) | null;
}
export const useFileUploadState = ({ mode = 'edit', file }: IUploadState) => {
  const [state, setState] = useSetState<IFormState>({
    mode,
    file,
    error: null,
    result: null,
    percent: 0,
    status: 'pending',
    onSuccess: null,
  });
  const removeFile = useCallback(
    () => setState({ file: null, status: 'pending' }),
    [setState]
  );
  const stopUpload = useCallback(
    () => state.abortController?.abort(),
    [state.abortController]
  );
  const processUpload = useCallback(
    async (params?: IOnUpload) => {
      const thefile = params?.file || state?.file;
      if (!thefile) return;

      const abortController = new AbortController();
      setState({
        status: 'uploading',
        file: thefile,
        percent: 0,
        abortController,
      });
      await googleUpload({
        file: thefile,
        abortController,
        onError: (error) => setState({ error, status: 'failed', percent: 0 }),
        onPercent: (percent) => setState({ percent }),
        onSuccess: (result) => {
          setState({ status: 'success', percent: 100, result });
          if (state.onSuccess) {
            state.onSuccess(result.mediaLink, thefile);
          } else {
            if (params && params.onSuccess) {
              params.onSuccess(result.mediaLink, thefile);
            }
          }
        },
      });
    },
    [setState, state]
  );
  const upload = useCallback(
    async (params: IOnUpload) => {
      setState({ ...params });
      if (params.instantUpload) {
        await processUpload(params);
      }
    },
    [processUpload, setState]
  );
  return { state, upload, stopUpload, removeFile, processUpload };
};
