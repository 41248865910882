import { Button, Group, Menu, Text } from '@mantine/core';
import { Link, useLocation } from '@tanstack/react-location';
import { useUserProfile } from '../../../services/queries/users';
import { ChevronDown } from 'tabler-icons-react';
import { IHeaderLinksMenu } from './headerMenu.interface';
import { useHeaderMenuStyles } from './headerMenu.style';
import { appRoleAccessCheck } from '@iverifyng/utils';

export type ActiveTab =
  | 'dashboard'
  | 'request'
  | 'payments'
  | 'requests'
  | 'workspace'
  | 'users'
  | 'analytics';

const HeaderMenu = ({ links }: { links: IHeaderLinksMenu }) => {
  const { roles } = useUserProfile();
  const { current } = useLocation();
  const paths = current.pathname.split('/');
  const activeTab: ActiveTab = (paths.pop() as ActiveTab) ?? 'dashboard';

  const { classes, cx } = useHeaderMenuStyles({ activeTab });

  return (
    <Group>
      {Object.entries(links).map(([key, value], index) => {
        const canView = value.action
          ? appRoleAccessCheck(roles, value.action)
          : true;
        const hasChildren = !!value.children;
        if (!canView) return null;

        if (hasChildren) {
          return (
            <Menu
              key={index}
              control={
                <Button
                  variant="subtle"
                  rightIcon={<ChevronDown size={20} color="white" />}
                  className={cx(classes.bgHover)}
                >
                  <Text
                    size="sm"
                    color={'white'}
                    weight={500}
                    transform="uppercase"
                  >
                    {value.name}
                  </Text>
                </Button>
              }
              trigger="hover"
              //exitTransitionDuration={0}
            >
              {value.children &&
                Object.entries(value.children).map(
                  ([childKey, childValue], menuIndex) => (
                    <Menu.Item
                      component={Link}
                      to={childValue.path}
                      key={`${index}-${menuIndex}`}
                    >
                      <Text size="sm" weight={500} transform="capitalize">
                        {childValue.name}
                      </Text>
                    </Menu.Item>
                  )
                )}
            </Menu>
          );
        }

        return (
          <Link to={value.path} key={index}>
            {({ isActive }) => (
              <Button
                variant="subtle"
                className={cx(classes.bgHover, {
                  [classes.active]: isActive,
                })}
              >
                <Text
                  size="sm"
                  color={'white'}
                  weight={500}
                  transform="uppercase"
                >
                  {value.name}
                </Text>
              </Button>
            )}
          </Link>
        );
      })}
    </Group>
  );
};
export default HeaderMenu;
