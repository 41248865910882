import { Box, Button, Group, Stack } from '@mantine/core';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { CreditCheckCommercialIcon } from '../../../../../../../../src/assets/icons';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';

import RequestDataLayout from '../../../../../../../components/layouts/request/requestDataLayout';
import { ArrowLeft } from 'tabler-icons-react';
import { ICommercialCreditCheckProps } from '../../interface';
import { RequestDetailsBlock } from '../../../../../../../connected/requestDetails/block';

interface ICriminalCheckConfirmationProps {
  data: ICommercialCreditCheckProps;
  renderIf?: boolean;
  onBack?: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  billed?: IServiceCharge;
}

export const CommercialCreditCheckConfirmation = ({
  data,
  billed,
  renderIf,
  isLoading,
  onBack,
  onSubmit,
}: //   onSubmit,
ICriminalCheckConfirmationProps) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Commercial Credit Check"
          supportingText="Review"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<CreditCheckCommercialIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="subtle"
            onClick={() => {
              if (onBack) onBack();
            }}
            leftIcon={<ArrowLeft width={16} height={16} />}
          >
            Go back
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack
        justify="space-between"
        sx={() => ({ height: '100%' })}
        spacing={0}
      >
        <Box>
          <Box px="lg" my="md">
            <Stack spacing={'md'}>
              {data.data.map((item, index) => (
                <RequestDetailsBlock
                  title="Commercial Credit Check details"
                  key={index}
                >
                  <Stack spacing="sm">
                    <RequestDataLayout
                      label="Enquiry Reason"
                      data={item.enquiryReason!}
                    />
                    {item.businessName && (
                      <RequestDataLayout
                        label="Business Name"
                        data={item.businessName}
                      />
                    )}
                    {item?.businessRegNo && (
                      <RequestDataLayout
                        label="Business Registration Number"
                        data={item.businessRegNo}
                      />
                    )}
                    {item?.accountNumber && (
                      <RequestDataLayout
                        label="Business Account Number"
                        data={item.accountNumber}
                      />
                    )}
                  </Stack>
                </RequestDetailsBlock>
              ))}
            </Stack>
          </Box>
        </Box>

        <Box>
          {!!billed && (
            <ServiceCharge
              serviceCharge={{
                currency: billed?.currency,
                amount: +billed?.amount,
              }}
              icon
              withBackground
            />
          )}
        </Box>
      </Stack>
    </RequestLayout>
  );
};
