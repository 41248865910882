import { MantineNumberSize, Stack, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

interface IProps {
  title: ReactNode;
  spacing?: MantineNumberSize;
  children?: React.ReactNode;
}
export const RequestDetailsBlock = ({
  title,
  children,
  spacing = 'md',
}: IProps) => {
  return (
    <Stack spacing={spacing}>
      <Text
        transform="uppercase"
        size="xs"
        weight={500}
        sx={(theme) => ({
          color: theme.colors.gray[6],
        })}
      >
        {title}
      </Text>
      {children}
    </Stack>
  );
};
