import { ActionIcon, Box, Center, Group, Stack, Textarea } from '@mantine/core';
import { CreateCommentParam } from '../../../../services/apis/admin';
import React from 'react';
import { Send } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import { Comment } from '../../../../components/primitives/comment/comment';
import { CommentLoader } from '../../../../components/primitives/skeletons/comment';
import EmptyState from '../../../../components/primitives/emptyState/emptyState';
import { CommentZeroIcon } from '../../../../assets/icons';
import { GetRequestCommentsDto } from '@iverifyng/dtos';

interface ICommentsProps {
  handleCreateComment: (params: CreateCommentParam) => void;
  requestId: string;
  comments: GetRequestCommentsDto | undefined;
  isLoadingComments?: boolean;
  isLoadingCreateComments?: boolean;
}
export const CommentsView = ({
  comments,
  requestId,
  isLoadingComments,
  handleCreateComment,
  isLoadingCreateComments,
}: ICommentsProps) => {
  const { getInputProps, onSubmit, values, setValues } = useForm({
    initialValues: {
      message: '',
    },
  });

  const loader = (
    <Center pt="xl">
      <EmptyState icon={<CommentZeroIcon />} subTitle="No Comment yet" />
    </Center>
  );

  return (
    <Stack
      sx={() => ({
        height: '100%',
        overflow: 'hidden',
      })}
    >
      <Stack
        spacing={0}
        sx={() => ({
          flex: 1,
          overflow: 'scroll',
          height: '100%',
        })}
      >
        {isLoadingComments && !comments ? (
          <CommentLoader />
        ) : comments && comments.length > 0 ? (
          comments?.map((comment) => (
            <Comment
              key={comment.id}
              date={comment.createdAt}
              message={comment.message}
              fullName={`${comment?.user?.firstName} ${comment?.user?.lastName} `}
            />
          ))
        ) : !isLoadingComments || (comments && comments.length <= 0) ? (
          loader
        ) : (
          loader
        )}
      </Stack>

      <Box
        py="md"
        px="lg"
        sx={(theme) => ({
          position: 'sticky',
          borderTop: `1px solid ${theme.colors.gray[2]}`,
          bottom: 0,
        })}
      >
        <form
          onSubmit={onSubmit(() => {
            handleCreateComment({
              comment: {
                message: values.message,
              },
              requestId,
            });
            setValues({ message: '' });
          })}
        >
          <Group>
            <Textarea
              {...getInputProps('message')}
              placeholder="Add comment"
              sx={(theme) => ({
                flex: 1,
                fontSize: 14,
                backgroundColor: theme.colors.gray[0],
              })}
            />

            <ActionIcon
              type="submit"
              loading={isLoadingCreateComments}
              color="blue"
              variant="filled"
              size="lg"
            >
              <Send size={24} />
            </ActionIcon>
          </Group>
        </form>
      </Box>
    </Stack>
  );
};
