import { promisify } from '@iverifyng/utils';
import GoogleStorageUploader from './googleStorageUploader';
import { IStorageUploadFunc } from './interface';

const controller = new AbortController();
export async function googleUpload({
  file,
  onError,
  onPercent,
  onSuccess,
  abortController,
}: IStorageUploadFunc) {
  // Abort request
  const storageUploader = new GoogleStorageUploader(
    file,
    abortController ?? controller
  );
  storageUploader.on('progress', onPercent);
  storageUploader.on('error', onError);
  storageUploader.on('done', onSuccess);

  //generate google storage urls
  const [err] = await promisify(storageUploader.generateUrls());
  if (err) throw new Error('Failed to generate Urls');

  //upload file with mediaLink in chunks
  const [fErr, fResult] = await promisify(storageUploader.uploadFile());
  //if (fErr) throw new Error('Error with file upload');
  console.log({ fErr, fResult });

  return fResult;
}
