import { AdminDashboardResponseDto } from '@iverifyng/dtos';
import {
  createStyles,
  Group,
  Paper,
  Text,
  ThemeIcon,
  SimpleGrid,
} from '@mantine/core';
import {
  ArrowUpRight,
  ArrowDownRight,
  ExclamationMark,
} from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

interface StatsGridIconsProps {
  data: AdminDashboardResponseDto;
  showDiff?: boolean;
}

export function StatsGridIcons({ data, showDiff }: StatsGridIconsProps) {
  const { classes } = useStyles();

  const calcDiffPercentage = (previous: number, current: number) => {
    if (Number(previous) === 0) return undefined;

    let percentage = 0;
    if (previous !== 0) {
      if (current !== 0) {
        percentage = ((Number(current) - Number(previous)) / previous) * 100;
      } else {
        percentage = previous * 100;
      }
    } else {
      percentage = -current * 100;
    }
    return percentage;
  };

  const stats = data.map((stat) => {
    const percentage = calcDiffPercentage(
      Number(stat?.previous),
      Number(stat?.current)
    );
    const indeterminate = percentage === undefined;
    const increased = !indeterminate && percentage >= 0;
    const DiffIcon = indeterminate
      ? ExclamationMark
      : increased
      ? ArrowUpRight
      : ArrowDownRight;

    return (
      <Paper withBorder p="md" radius="md" key={stat.label}>
        <Group position="apart">
          <div>
            <Text
              color="dimmed"
              transform="uppercase"
              weight={700}
              size="xs"
              className={classes.label}
            >
              {stat.label}
            </Text>
            <Text weight={700} size="xl">
              {String(stat.value)}
            </Text>
          </div>
          {showDiff && (
            <ThemeIcon
              color="gray"
              variant="light"
              sx={(theme) => ({
                color:
                  indeterminate || increased
                    ? theme.colors.teal[6]
                    : theme.colors.red[6],
              })}
              size={38}
              radius="md"
            >
              <DiffIcon size={28} strokeWidth={1.5} />
            </ThemeIcon>
          )}
        </Group>
        {showDiff && (
          <Text color="dimmed" size="sm" mt="md">
            <Text
              component="span"
              color={indeterminate || increased ? 'teal' : 'red'}
              weight={700}
            >
              {indeterminate ? 0 : Math.abs(percentage).toFixed()}%
            </Text>{' '}
            {indeterminate
              ? 'indeterminate change compared to last month'
              : increased
              ? 'increase compared to last month'
              : 'decrease compared to last month'}
          </Text>
        )}
      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid cols={5} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {stats}
      </SimpleGrid>
    </div>
  );
}
