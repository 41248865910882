import { Center, Group, Stack, Text, Title } from '@mantine/core';
import React, { ReactNode } from 'react';

interface NotFoundProps {
  icon?: ReactNode;
  title?: string;
  subTitle?: string;
  button?: ReactNode;
}

const NotFound = ({ icon, title, subTitle, button }: NotFoundProps) => {
  return (
    <Center>
      <Group direction="column" position="center" spacing={'xl'}>
        {icon}
        <Stack spacing={4} align="center">
          <Title order={2}>{title}</Title>
          <Text align="center">{subTitle}</Text>
        </Stack>
        {button}
      </Group>
    </Center>
  );
};

export default NotFound;
