import {
  useAdminRequestDetails,
  useAdminWorkspaceUser,
} from '../../../../../hooks/admin';
import { useMatch } from '@tanstack/react-location';
import { RequestGenerics } from '../tabOutlet';

import { AdminServiceRequesterDetailsView } from '../../../components/requester/requester';

export const AdminServiceRequesterDetails = () => {
  const {
    params: { requestId },
  } = useMatch<RequestGenerics>();
  const { error, data: request, isLoading } = useAdminRequestDetails(requestId);
  const {
    error: requesterError,
    user: requester,
    isLoading: loadingRequester,
  } = useAdminWorkspaceUser(
    request?.requesterId as string,
    request?.workspaceId as string
  );

  return (
    <AdminServiceRequesterDetailsView
      request={request}
      isLoading={isLoading}
      error={error}
      requester={requester}
      loadingRequester={loadingRequester}
      requesterError={requesterError}
    />
  );
};
