import { useQuery } from "react-query";
import { GetAdminAnalyticsDashboard } from "../services/apis/admin";

export const useGetAdminAnalyticsDashboard = () => {
  const queryKey = ['analytics'];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAdminAnalyticsDashboard(),
   
  );
  return {
     isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
  };
}
