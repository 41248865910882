import { useLocation } from '@tanstack/react-location';
import { useCallback, useEffect, useState } from 'react';
import { formList, useForm } from '@mantine/form';
import { GuarantorVerificationSchema } from '@iverifyng/validators';
import React from 'react';
import { useServiceCharge } from '../../../../../../src/hooks/request';
import { useCurrentCountry } from '../../../../../../src/hooks/country';
import { ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../src/hooks/workspace';
import { GuarantorVerificationForm } from './container/form';
import { GuarantorVerificationReview } from './container/review';
import { showNotification } from '@mantine/notifications';
import { IGuarantorVerifyFormProps } from './interface';
import { useConsentConfirmation } from '../../../../../hooks/consent';
import { useCreateGuarantorVerification } from '../../../../../services/mutations/guarantor';
import { debounce } from 'lodash';

type TPageState = 'form' | 'verify';

export const GuarantorVerification = () => {
  const [page, setPage] = useState<TPageState>('form');
  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const { showConsent } = useConsentConfirmation();
  const form = useForm<IGuarantorVerifyFormProps>({
    initialValues: {
      data: formList([
        {
          fullName: '',
          phoneNo: '',
          address: '',
          workAddress: '',
          relationship: '',
          idType: '',
          idNumber: '',
        },
      ]),
    },
  });

  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'GUARANTOR_VERIFICATION',
    country: country! as ServiceCountry,
    quantity: form?.values?.data?.length ?? 1,
  });

  const handleVerify = useCallback(() => {
    const { error } =
      GuarantorVerificationSchema.NEW_VERIFICATION_SCHEMA.validate(form.values);
    if (error) {
      showNotification({ color: 'red', message: error.message });
      return;
    }
    setPage('verify');
  }, [form.values]);

  const createGuarantor = useCreateGuarantorVerification();
  const handleSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const formvalues = form.values;
        createGuarantor.mutate({
          workspaceId: workspaceId!,
          data: formvalues.data,
        });
      },
    });
  }, [createGuarantor, form.values, showConsent, workspaceId]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  useEffect(() => {
    if (page === 'verify' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <GuarantorVerificationForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Verify the legitimacy and existence of provided guarantors."
      />
      <GuarantorVerificationReview
        data={form.values}
        isLoading={createGuarantor.isLoading}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        renderIf={page === 'verify'}
        billed={serviceCharge}
      />
    </form>
  );
};
