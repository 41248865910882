import { Avatar, Stack, Center } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { DriversLicenseResponseDto } from '../../ng/interface';

export const DriversLicenseReport = (props: DriversLicenseResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.img ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64, ${props.img}`}
            size="lg"
            radius="xl"
            alt=""
          />
        </Center>
      ) : null}

      <RequestDataLayout label="Sex" data={props.sex ?? ''} />
      <RequestDataLayout label="Full name" data={props.name ?? 'N/A'} />
      <RequestDataLayout label="First Name" data={props.lastName ?? 'N/A'} />
      <RequestDataLayout label="Last Name" data={props.firstName ?? 'N/A'} />
      <RequestDataLayout label="Other Name" data={props.otherName ?? 'N/A'} />
      <RequestDataLayout
        label="Date of Birth"
        data={props.dateOfBirth ?? 'N/A'}
      />
      <RequestDataLayout label="Expiry Date" data={props.expiryDate ?? 'N/A'} />
      <RequestDataLayout label="Issue Date" data={props.issueDate ?? 'N/A'} />
      <RequestDataLayout label="Provider" data={props.provider ?? 'N/A'} />
      <RequestDataLayout
        label="State of issue"
        data={props.stateOfIssue ?? 'N/A'}
      />
    </Stack>
  );
};
