import axios, { AxiosStatic } from 'axios';
import axiosInstance from '../axios';
import { IFileUrlReq, IUploadUrlReq } from './interface';

export default class FileUploadService {
  axios: AxiosStatic;

  constructor() {
    this.axios = axios;
  }

  public async generateUploadUrl(query: IUploadUrlReq) {
    return await axiosInstance
      .post('/authexternal/google/storage/public/generateUploadUrl', query)
      .then((response) => response.data);
  }

  public async completeFileUpload(query: IFileUrlReq) {
    const { url, file } = query;
    return await axios.put(url, file).then((response) => response.data);
  }
}
