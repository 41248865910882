import { useCurrentCountry } from '../../../hooks/country';
import React, { useMemo } from 'react';
import { GHDriversLicense } from '../routes/gh/identityVerification/driversLicense/driversLicense';
import { DriversLicense } from '../routes/ng/identityVerification/subRoutes/driversLicense/driversLicense';

export const SharedDriversLicense = () => {
  const { country } = useCurrentCountry();

  const renderDLContainer = useMemo(() => {
    switch (country) {
      case 'GH':
        return <GHDriversLicense />;
      case 'NG':
        return <DriversLicense />;
      default:
        return null;
    }
  }, [country]);

  return renderDLContainer;
};
