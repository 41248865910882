import { Box, Collapse, UnstyledButton } from '@mantine/core';
import { useCallback, useState } from 'react';
import { Link } from '@tanstack/react-location';
import { useSidebarStyles } from './sidebarLinks.style';
import { WorkspaceRBACKeys, workspaceRoleAccessCheck } from '@iverifyng/utils';
import { useCurrentWorkspace } from '../../../hooks/workspace';

interface LinkItem {
  label: string;
  link: string;
  isOpened?: boolean;
  action?: WorkspaceRBACKeys;
}
interface LinksGroupProps extends LinkItem {
  subLinks?: Array<LinkItem>;
}

export const SidebarLinks = ({
  label,
  link,
  subLinks,
  isOpened,
  action,
}: LinksGroupProps) => {
  const [opened, setOpened] = useState(isOpened || false);
  const { workspaceUserRole: role } = useCurrentWorkspace();
  const hasChildren = Array.isArray(subLinks) && subLinks.length > 0;
  const { classes, cx } = useSidebarStyles();

  const items = (hasChildren ? subLinks : []).map((link) => {
    const canView = link.action
      ? workspaceRoleAccessCheck(role!, link.action)
      : true;
    if (!canView) return null;
    return (
      <Link to={link.link} style={{ textDecoration: 'none' }} key={link.label}>
        {({ isActive }) => (
          <UnstyledButton
            className={cx(classes.link, { [classes.active]: isActive })}
          >
            {link.label}
          </UnstyledButton>
        )}
      </Link>
    );
  });

  const defaultButton = useCallback(
    (isRouteActive: boolean) => {
      const canView = action ? workspaceRoleAccessCheck(role!, action) : true;
      if (!canView) return null;
      return (
        <UnstyledButton
          className={cx(classes.control, {
            [classes.active]: isRouteActive,
          })}
          onClick={hasChildren ? () => setOpened(!opened) : () => null}
        >
          {label}
        </UnstyledButton>
      );
    },
    [
      action,
      classes.active,
      classes.control,
      cx,
      hasChildren,
      label,
      opened,
      role,
    ]
  );

  if (hasChildren) {
    const canView = action ? workspaceRoleAccessCheck(role!, action) : true;
    if (!canView) return null;
    return (
      <>
        {defaultButton(false)}
        <Collapse in={opened}>
          <Box className={classes.subMenu}>{items}</Box>
        </Collapse>
      </>
    );
  }

  return (
    <div>
      <Link to={link} style={{ textDecoration: 'none' }}>
        {({ isActive }) => defaultButton(isActive && !hasChildren)}
      </Link>
    </div>
  );
};
