import {
  CreateEmployeeAgentReportDto,
  EmployeeGuarantorDto,
  AgentReportClientDataDto,
  TenantGuarantorDto,
  TenantLandlordDto,
  CreateTenantAgentReportDto,
  CreateAddressAgentReportDto,
} from '@iverifyng/dtos';
import {
  EmployeeVerification,
  TenantVerification,
  AddressVerification,
  Workspace,
} from '@prisma/client';

export const getInitialValuesForAgentEmployeeForm = (
  clientData: AgentReportClientDataDto | undefined,
  agentReport: CreateEmployeeAgentReportDto | undefined,
  workspace: Workspace | undefined
): CreateEmployeeAgentReportDto => {
  if (agentReport) return agentReport;

  const applicantInfo = clientData as EmployeeVerification;
  const guarantors = (applicantInfo?.guarantors.length
    ? applicantInfo.guarantors
    : []) as unknown as Array<EmployeeGuarantorDto>;
  return {
    applicant: {
      companyAddress: { value: applicantInfo?.workAddress ?? '' },
      companyName: { value: '' },
      designation: { value: '' },
      fullName: { value: applicantInfo?.fullName ?? '' },
      guarantors: guarantors.map((guarantor) => ({
        fullName: {
          value: guarantor?.fullname ?? '',
        },
        address: {
          value: guarantor?.address ?? '',
        },
        workAddress: {
          value: guarantor?.address ?? '',
        },
      })),
      meansOfId: {
        value: [applicantInfo?.idType, applicantInfo?.idNumber]
          .filter(Boolean)
          .join(', '),
      },
      phoneNo: { value: applicantInfo?.phoneNo ?? '' },
      previousWork: { value: applicantInfo?.previousWorkName ?? '' },
      residentialAddress: { value: applicantInfo?.residentialAddress ?? '' },
    },
    searchReport: {
      fullName: { value: applicantInfo?.fullName ?? '' },
      residentialAddress: { value: applicantInfo?.residentialAddress ?? '' },
      guarantors: guarantors.map((guarantor) => ({
        fullName: {
          value: guarantor?.fullname ?? '',
        },
        address: {
          value: guarantor?.address ?? '',
        },
        workAddress: {
          value: guarantor?.address ?? '',
        },
      })),
    },
    client: {
      value: workspace?.name ?? '',
    },
  };
};

export const getInitialValuesForAgentTenantForm = (
  clientData: AgentReportClientDataDto | undefined,
  agentReport: CreateTenantAgentReportDto | undefined,
  workspace: Workspace | undefined
): CreateTenantAgentReportDto => {
  if (agentReport) return agentReport;

  const applicantInfo = clientData as TenantVerification;
  const guarantors = (applicantInfo?.guarantors.length
    ? applicantInfo.guarantors
    : []) as unknown as Array<TenantGuarantorDto>;
  const landlords = (applicantInfo?.landlords.length
    ? applicantInfo.landlords
    : []) as unknown as Array<TenantLandlordDto>;
  return {
    applicant: {
      companyAddress: { value: applicantInfo?.workAddress ?? '' },
      fullName: { value: applicantInfo?.fullName ?? '' },
      guarantors: guarantors.map((guarantor) => ({
        fullName: {
          value: guarantor?.fullname ?? '',
        },
        address: {
          value: guarantor?.address ?? '',
        },
        workAddress: {
          value: guarantor?.address ?? '',
        },
      })),
      meansOfId: {
        value: [applicantInfo?.idNumber, applicantInfo?.idType]
          .filter(Boolean)
          .join(', '),
      },
      phoneNo: { value: applicantInfo?.phoneNo ?? '' },
      residentialAddress: { value: applicantInfo?.residentialAddress ?? '' },
      spouseName: { value: applicantInfo?.spouseName ?? '' },
      landlordInfo: {
        value:
          landlords
            .map((landlord) =>
              [
                landlord?.['fullname'],
                landlord?.['address'],
                landlord?.['phoneNo'],
                landlord?.['idType'],
                landlord?.['idNumber'],
              ]
                .filter(Boolean)
                .join('\n')
            )
            .join('\n\n') ?? '',
      },
    },
    searchReport: {
      fullName: { value: applicantInfo?.fullName ?? '' },
      residentialAddress: { value: applicantInfo?.residentialAddress ?? '' },
      guarantors: guarantors.map((guarantor) => ({
        fullName: {
          value: guarantor?.fullname ?? '',
        },
        address: {
          value: guarantor?.address ?? '',
        },
        workAddress: {
          value: guarantor?.address ?? '',
        },
      })),
    },
    client: {
      value: workspace?.name ?? '',
    },
  };
};
export const getInitialValuesForAgentAddressForm = (
  clientData: AgentReportClientDataDto | undefined,
  agentReport: CreateAddressAgentReportDto | undefined,
  workspace: Workspace | undefined
): CreateAddressAgentReportDto => {
  if (agentReport) return agentReport;

  const applicantInfo = clientData as AddressVerification;
  return {
    applicant: {
      fullName: { value: applicantInfo?.fullName ?? '' },
      address: { value: applicantInfo?.address ?? '' },
      phoneNo: { value: applicantInfo?.phoneNo ?? '' },
    
     
    },
    searchReport: {
      fullName: { value: applicantInfo?.fullName ?? '' },
      address: { value: applicantInfo?.address ?? '' },
    },
    client: {
      value: workspace?.name ?? '',
    },
  };
};
