import {
  Paper,
  Stack,
  Title,
  Text,
  Center,
  Group,
  Button,
  useMantineTheme,
} from '@mantine/core';
import { AuthLayout } from '../../../components/layouts/auth';
import {
  MakeGenerics,
  Navigate,
  useSearch,
  Link,
} from '@tanstack/react-location';
import { StarCheckIcon } from '../../../assets/icons';

type MyLocationGenerics = MakeGenerics<{
  Search: {
    status: 'success' | 'failed';
  };
}>;

export default function AccountVerified() {
  const theme = useMantineTheme();
  const search = useSearch<MyLocationGenerics>();
  if (!search.status) return <Navigate to={'/dashboard'} />;
  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <Center style={{ marginBottom: 20 }}>
          <StarCheckIcon
            color={
              search.status !== 'success'
                ? theme.colors.red[5]
                : theme.colors.blue[5]
            }
          />
        </Center>
        <Stack spacing={8} justify="center" align={'center'} mb="xl">
          <Title order={2}>
            {search.status !== 'success' ? 'Unverified' : 'Verified'}
          </Title>
          <Text size="md" align="center">
            {search.status !== 'success'
              ? 'Your email address could not be verified'
              : 'You have successfully verified your email.'}
          </Text>
        </Stack>
        <Group position="apart" mt="xl">
          <Button type="submit" fullWidth component={Link} to="/dashboard">
            Go to Dashboard
          </Button>
        </Group>
      </Paper>
    </AuthLayout>
  );
}
