import axios from 'axios';
import { signOut } from 'firebase/auth';
import { getFirebaseAuth } from './firebase';
//API Instance
const axiosInstance = axios.create({
  //baseURL: environment.apiBaseUrl,
  baseURL: `${process.env['NX_APP_API_URL']}/api`,
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    // get the authentication token from local storage if it exists
    const accessToken = await getFirebaseAuth.currentUser?.getIdToken();
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      signOut(getFirebaseAuth);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
