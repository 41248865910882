import { useAdminRequestDetails } from '../../../../../hooks/admin';
import { useMatch } from '@tanstack/react-location';
import { AdminRequestPaymentDetailsView } from '../../../components/payment/payment';
import { WorkspaceGenerics } from '../workspaceDetail';

export const WorkspaceRequestPaymentDetails = () => {
  const {
    params: { detailId },
  } = useMatch<WorkspaceGenerics>();
  const { error, data: request, isLoading } = useAdminRequestDetails(detailId);

  return (
    <AdminRequestPaymentDetailsView
      request={request}
      isLoading={isLoading}
      error={error}
    />
  );
};
