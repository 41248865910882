import { Button } from '@mantine/core';
import { Link } from '@tanstack/react-location';
import { NotFoundIcon } from '../../assets/icons';
// import { DashboardLayout } from '../../components/layouts/dashboard';
import { withAuth } from '../../hoc/withAuth';
import { useUserProfile } from '../../services/queries/users';
import NotFound from './404';

const AccessDenied = () => {
  const { isAgent } = useUserProfile();
  return (
    <NotFound
      title="Access Denied"
      subTitle="You lack permission to access this page"
      icon={<NotFoundIcon />}
      button={
        <Link to={isAgent ? '/admin/requests/all' : '/dashboard'}>
          <Button>{isAgent ? 'Back to Requests' : 'Back to Dashboard'}</Button>
        </Link>
      }
    />
  );
};
export default withAuth(AccessDenied);
