import {
  AddressVerificationRecordDto,
  AdminAddressUpdateReportDto,
  CreateAddressVerificationDto,
  CreateRequestReponseDto,
  UpdatePreviousWorkVerificationDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';

interface ICreateAddressVerificationDto extends CreateAddressVerificationDto {
  workspaceId: string;
}

export const CreateAddressVerificationApi = async ({
  workspaceId,
  ...data
}: ICreateAddressVerificationDto): Promise<CreateRequestReponseDto> => {
  const url = `/request/address/${workspaceId}/new`;
  return await axiosInstance.post(url, data).then((response) => response.data);
};

export const AdminUpdateAddressReportApi = async (
  data: AdminAddressUpdateReportDto
): Promise<AddressVerificationRecordDto> => {
  const url = `/admin/requests/address/reportUrl`;
  return await axiosInstance.patch(url, data).then((response) => response.data);
};

export const AdminRemoveAddressReportApi = async (params: {
  addressId: string;
}): Promise<CreateAddressVerificationDto> => {
  const url = `/admin/requests/address/${params.addressId}/reportUrl`;
  return await axiosInstance.delete(url).then((response) => response.data);
};

export const AdminUpdateAddressApi = async (
  data: UpdatePreviousWorkVerificationDto
): Promise<CreateAddressVerificationDto> => {
  const url = '/admin/requests/address';
  return await axiosInstance.put(url, data).then((response) => response.data);
};
