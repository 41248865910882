import {
  AdminCreditCheckUpdateReportDto,
  CreateCreditCheckDto,
  CreateRequestReponseDto,
  CreditCheckRecordDto,
  UpdateCreditCheckDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';

interface ICreateCreditCheckVerificationDto extends CreateCreditCheckDto {
  workspaceId: string;
}

export const CreateCreditCheckVerificationApi = async ({
  workspaceId,
  ...data
}: ICreateCreditCheckVerificationDto): Promise<CreateRequestReponseDto> => {
  const url = `/request/creditcheck/${workspaceId}/new`;
  return await axiosInstance.post(url, data).then((response) => response.data);
};

export const AdminUpdateCreditCheckReportApi = async (
  data: AdminCreditCheckUpdateReportDto
): Promise<CreditCheckRecordDto> => {
  const url = `/admin/requests/creditcheck/reportUrl`;
  return await axiosInstance.patch(url, data).then((response) => response.data);
};

export const AdminRemoveCreditCheckReportApi = async (params: {
  creditCheckId: string;
}): Promise<CreditCheckRecordDto> => {
  const url = `/admin/requests/creditcheck/${params.creditCheckId}/reportUrl`;
  return await axiosInstance.delete(url).then((response) => response.data);
};

export const AdminUpdateCreditCheckApi = async (
  data: UpdateCreditCheckDto
): Promise<CreditCheckRecordDto> => {
  const url = '/admin/requests/creditcheck';
  return await axiosInstance.put(url, data).then((response) => response.data);
};
