/* eslint-disable @typescript-eslint/no-var-requires */
import { Prisma } from '@prisma/client';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayjs = require('dayjs');
const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

type LabelResponse = 'day' | 'week' | 'month';
export const getAnalyticsQueryPeriodByDateRange = (
  startDate: Date,
  endDate: Date
): LabelResponse => {
  const difference = dayjs(endDate).diff(dayjs(startDate), 'months');
  if (difference > 0 && difference <= 3) return 'week';
  if (difference > 3) return 'month';
  return 'day';
};

export const getAnalyticsDateQueryByLabel = (
  label: LabelResponse,
  column: string
) => {
  if (label === 'week')
    return Prisma.sql`extract(week from DATE_TRUNC('week',"${column}"))`;
  if (label === 'month')
    return Prisma.sql`extract(month from DATE_TRUNC('month',"${column}"))`;
  return Prisma.sql`extract(day from DATE_TRUNC('day',"${column}"))`;
};

export interface AnayticsSQLItem {
  label: string | number;
  value?: string | number;
}

export function formatAnalyticsQueryResponse<T>(
  data: Array<T & AnayticsSQLItem>,
  label: LabelResponse
) {
  if (!data || !data.length) return data;
  return data.map((d) => {
    if (label === 'week') return { ...d, label: `Week ${d.label}` };
    if (label === 'day')
      return { ...d, label: dayjs(d.label).format('DD MMM, YYYY') };
    if (label === 'month')
      return { ...d, label: dayjs(d.label).format('MMM YYYY') };
    return d;
  });
}

export const getAnalyticsDatePeriod = (
  startDate: Date | string,
  label: LabelResponse
) => {
  if (label === 'week') return `Week ${dayjs(startDate).week()}`;
  if (label === 'day') return dayjs(startDate).format('DD MMM, YYYY');
  return dayjs(startDate).format('MMM YYYY');
};

export const getEndDateBasedOnPeriod = (
  startDate: Date | string,
  label: LabelResponse
) => {
  return dayjs(startDate).endOf(label).toDate();
};
