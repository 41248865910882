import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { ValidateCriminalCheckIcon } from '../../../../../../../../src/assets/icons';
import {
  AccordionItem,
  ActionIcon,
  Box,
  Button,
  Group,
  InputWrapper,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import { FileUploader } from '../../../../../../../components/controls/fileuploader/fileuploader';
import { Trash } from 'tabler-icons-react';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { FormList } from '@mantine/form/lib/form-list/form-list';
import { MIME_TYPES } from '@iverifyng/utils';
import { GenderEnum } from '@prisma/client';
import { AddMoreButton } from '../../../../../../../components/primitives/button/download';

export interface IFormListData {
  subject_name: string;
  registry_report_url: string;
  file?: File | null;
  gender?: GenderEnum;
}

export interface IValidateCriminalFormList {
  country: string;
  description: string;
  data: FormList<IFormListData>;
}

interface IValidateCriminalRecordForm {
  introText: string;
  form: UseFormReturnType<IValidateCriminalFormList>;
  renderIf?: boolean;
  onBack?: () => void;
}

const CriminalCheckFormItem = ({
  form,
  index,
}: {
  index: number;
  form: UseFormReturnType<IValidateCriminalFormList>;
}) => {
  return (
    <Group direction="column" spacing="md" grow>
      <TextInput
        autoFocus
        label="Name"
        placeholder="Enter applicant name"
        required
        {...form.getListInputProps('data', index, 'subject_name')}
      />
      <Select
        label="Gender"
        placeholder="Select gender"
        {...form.getListInputProps('data', index, 'gender')}
        data={[
          {
            label: 'Male',
            value: 'MALE',
          },
          {
            label: 'Female',
            value: 'FEMALE',
          },
        ]}
      />
      <InputWrapper label="Upload Certificate" required>
        <FileUploader
          file={form.values.data[index].file}
          accept={[MIME_TYPES.pdf]}
          instantUpload
          onSuccess={(url, file) =>
            form.setListItem('data', index, {
              ...form.values.data[index],
              file,
              registry_report_url: url,
            })
          }
        />
      </InputWrapper>
    </Group>
  );
};

export const ValidateCriminalCheckForm = ({
  introText,
  form,
  renderIf,
  onBack,
}: IValidateCriminalRecordForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Validate Criminal Record"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<ValidateCriminalCheckIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="red"
            variant="subtle"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Review</Button>
        </Group>
      }
    >
      <Stack spacing="xl" p="24px">
        <Box
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">{introText}</Text>
        </Box>
        {form.values.data.length > 1 ? (
          <Stack spacing="lg">
            {form.values.data.map((item, index) => (
              <FormListAccordion
                iconPosition="right"
                key={index}
                initialItem={0}
                multiple
              >
                <AccordionItem
                  label={
                    <FormListAccordionLabel
                      listCount={index + 1}
                      rightIcon={
                        <ActionIcon
                          onClick={() => {
                            form.removeListItem('data', index);
                          }}
                        >
                          <Trash color="gray" size={16} />
                        </ActionIcon>
                      }
                    />
                  }
                >
                  <CriminalCheckFormItem form={form} index={index} />
                </AccordionItem>
              </FormListAccordion>
            ))}
          </Stack>
        ) : (
          <CriminalCheckFormItem form={form} index={0} />
        )}
        <Group position="left">
          <AddMoreButton
            onClick={() => {
              form.addListItem('data', {
                subject_name: '',
                registry_report_url: '',
                gender: undefined,
              });
            }}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
