/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  Anchor,
  Button,
  Center,
  Checkbox,
  Group,
  Paper,
  PasswordInput,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { Link, useNavigate } from '@tanstack/react-location';
import { withDashboard } from '../../../hoc/withDashboard';
import { useCallback } from 'react';
import { AuthLayout } from '../../../components/layouts/auth';
import { joiResolver, useForm } from '@mantine/form';
import { AuthValidatorSchema } from '@iverifyng/validators';
import { CreateUserDto } from '@iverifyng/services/auth';
import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';
import { SignUpUserApi } from '../../../services/apis/users';
import { AxiosError } from 'axios';

function SignUp() {
  const navigate = useNavigate();
  const form = useForm<CreateUserDto>({
    schema: joiResolver(AuthValidatorSchema.SIGNUP),
    initialValues: {
      accountType: 'PERSONAL',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      acceptTerms: false,
    },
  });
  const signUpMutation = useMutation(SignUpUserApi);
  const handleSubmit = useCallback(
    (values: CreateUserDto) => {
      const { error } = AuthValidatorSchema.SIGNUP.validate(values);
      if (error) {
        showNotification({ message: error.message, color: 'red' });
        return;
      }

      signUpMutation.mutate(values, {
        onSuccess: () => {
          showNotification({
            title: 'Account created!',
            message: 'We have sent a verification link to your email address.',
            color: 'green',
          });
          navigate({ to: '/auth/login' });
        },
        onError: (error) => {
          showNotification({
            title: 'Error',
            message:
              (error as AxiosError<{ message: string }>)?.response?.data
                ?.message ?? 'We could not process your request at this time.',
            color: 'red',
          });
        },
      });
      console.log({ values, error });
    },
    [navigate, signUpMutation]
  );
  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <Stack spacing={8} justify="center" align={'center'} mb="xl">
          <Title order={2}>Create New Account</Title>
          <Text size="md">Gain access to freedom.</Text>
        </Stack>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Group direction="column" grow>
            <SegmentedControl
              size="md"
              color="blue"
              data={[
                { label: 'Personal', value: 'PERSONAL' },
                { label: 'Corporate', value: 'CORPORATE' },
              ]}
              fullWidth
              {...form.getInputProps('accountType')}
            />
            {form.values.accountType === 'CORPORATE' ? (
              <TextInput
                label="Business Name"
                placeholder="Business Name"
                {...form.getInputProps('businessName')}
              />
            ) : null}

            <Group direction="row" grow align={'flex-start'}>
              <TextInput
                label="First Name"
                placeholder="First Name"
                {...form.getInputProps('firstName')}
              />

              <TextInput
                label="Last Name"
                placeholder="Last Name"
                {...form.getInputProps('lastName')}
              />
            </Group>
            <TextInput
              label="Email"
              placeholder="Email"
              {...form.getInputProps('email')}
            />

            <PasswordInput
              label="Password"
              placeholder="Password"
              {...form.getInputProps('password')}
            />
          </Group>

          <Stack spacing={1}>
            <Group position="apart" mt="xl">
              <Checkbox
                label={
                  <Text size="sm">
                    I agree to both{' '}
                    <Anchor
                      color="blue"
                      size="sm"
                      href="https://storage.googleapis.com/iverify-public/forms/consent/terms-of-use-01082022.docx.pdf"
                      target={'_blank'}
                    >
                      {'Terms of Service'}
                    </Anchor>{' '}
                    and{' '}
                    <Anchor
                      color="blue"
                      size="sm"
                      href="https://storage.googleapis.com/iverify-public/forms/consent/privacy-policy-01082022.pdf"
                      target={'_blank'}
                    >
                      {'Privacy Policy'}
                    </Anchor>
                  </Text>
                }
                {...form.getInputProps('acceptTerms', { type: 'checkbox' })}
              />
            </Group>
            {form.errors['acceptTerms'] && (
              <Text color="red" size="sm">
                {form.errors['acceptTerms']}
              </Text>
            )}
          </Stack>

          <Group position="apart" mt="xl">
            <Button
              type="submit"
              fullWidth
              disabled={signUpMutation.isLoading}
              loading={signUpMutation.isLoading}
            >
              Submit
            </Button>
          </Group>
        </form>
      </Paper>
      <Center>
        <Text size="sm">
          Already have an account?{' '}
          <Link to={'/auth/login'}>
            <Anchor component="button" type="button" color="blue" size="sm">
              {'Login now'}
            </Anchor>
          </Link>
        </Text>
      </Center>
    </AuthLayout>
  );
}

export default withDashboard(SignUp);
