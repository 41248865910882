/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useLocation } from '@tanstack/react-location';
import { joiResolver, useForm } from '@mantine/form';
import { NINForm, NINFormData } from './containers/ninForm';
import { NINFormConfirmation } from './containers/ninConfirmation';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../../hooks/request';
import { useCurrentWorkspace } from '../../../../../../../hooks/workspace';
import { useCurrentCountry } from '../../../../../../../hooks/country';
import { ServiceCountry } from '@prisma/client';
import { useConsentConfirmation } from '../../../../../../../hooks/consent';
import { getServiceValueByProps } from '../../../../../../../utils/service';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { GetServices } from '../../../../../../../services/apis/requests';
import { useCallback, useMemo, useState } from 'react';

export type NINPage = 'form' | 'verify';
export const NIN = () => {
  const { workspaceId } = useCurrentWorkspace();
  const [page, setPage] = useState<NINPage>('form');
  const [withSlip, setWithSlip] = useState(false);
  const { history } = useLocation();
  const { country } = useCurrentCountry();

  const form = useForm<NINFormData>({
    initialValues: {
      idNumber: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.NIN),
  });

  const { showConsent } = useConsentConfirmation();
  const createIdentity = useCreateIdentityVerification();
  const { data: serviceCharge } = useServiceCharge({
    code: withSlip ? 'NIN_SLIP' : 'NIN',
    country: country! as ServiceCountry,
  });
  const { data: service } = useQuery('services', GetServices);

  const showWithSlip = useMemo(() => {
    if (service) {
      const serviceVisibility = getServiceValueByProps(
        'NIN_SLIP',
        service!,
        'visible'
      );
      if (serviceVisibility) return serviceVisibility as boolean;

      return !!serviceVisibility;
    }
    return true;
  }, [service]);

  const onSubmit = useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        createIdentity.mutate({
          code: withSlip ? 'NIN_SLIP' : 'NIN',
          country: country! as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            description: data.description,
          },
        });
      },
    });
  }, [
    country,
    createIdentity,
    form.values,
    showConsent,
    withSlip,
    workspaceId,
  ]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });
  const onVerify = useCallback(() => {
    setPage('verify');
  }, []);

  const onWithSlipCheck = useCallback((value: boolean) => {
    setWithSlip(value);
  }, []);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(onVerify)}>
      <NINForm
        {...{ form }}
        {...{ withSlip }}
        showWithSlip={showWithSlip}
        onBack={() => history.back()}
        onSlipCheck={onWithSlipCheck}
        renderIf={page === 'form'}
      />
      <NINFormConfirmation
        {...{ form }}
        onSubmit={handleDoubleClick}
        data={form.values}
        loading={createIdentity.isLoading}
        billed={serviceCharge}
        onEdit={() => setPage('form')}
        onBack={() => setPage('form')}
        renderIf={page === 'verify'}
      />
    </form>
  );
};
