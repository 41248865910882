// interface IEmployeeVerificationProps {}
import { Button, Group, Stack, Text } from '@mantine/core';
import { useLocation } from '@tanstack/react-location';
import { VerificationType } from '../../../../../components/primitives/cards/employee/employeeType';
import {
  EmployeeIcon,
  MutlipleEmployeeIcon,
  SingleEmloyeeIcon,
} from '../../../../../assets/icons';

import { RequestLayout } from '../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModals } from '@mantine/modals';
import { formList, joiResolver, useForm } from '@mantine/form';
import { EmployeeMainModal } from '../../../../../connected/modals/requests/employee/employeeMainModal';
import {
  CreateEmployeeVerificationDto,
  EmployeeGuarantorDto,
  ServiceCodeTypes,
} from '@iverifyng/dtos';
import { EmployeeVerificationSchema } from '@iverifyng/validators';
import { useServiceCharge } from '../../../../../hooks/request';
import { ServiceCountry } from '@prisma/client';
import { useCurrentCountry } from '../../../../../hooks/country';
import { useCreateEmployeeVerification } from '../../../../../services/mutations/employee';
import { useCurrentWorkspace } from '../../../../../hooks/workspace';
import { MultipleEmployeeModal } from '../../../../../connected/modals/requests/employee/multipleEmployeeModal';
import { SingleEmployeeDetails } from './singleEmployeeDetails/singleEmployee';
import { MultipleEmployeeDetails } from './multipleEmployeeDetails/multipleEmployeeDetails';
import { debounce, delay } from 'lodash';

type IReviewPage = 'select-employee-type' | 'single-review' | 'multiple-review';
export const verificationTypes = [
  { icon: <SingleEmloyeeIcon />, text: 'Single', value: 'single' },
  { icon: <MutlipleEmployeeIcon />, text: 'Multiple', value: 'multiple' },
];

export const EmployeeVerification = () => {
  const { country } = useCurrentCountry();
  const { workspaceId } = useCurrentWorkspace();
  const [reviewPage, setReviewPage] = useState<IReviewPage>(
    'select-employee-type'
  );

  const form = useForm<CreateEmployeeVerificationDto>({
    schema: joiResolver(EmployeeVerificationSchema.NEW_VERIFICATION_SCHEMA),
    initialValues: {
      data: formList([
        {
          fullName: '',
          idType: '',
          guarantors: formList<EmployeeGuarantorDto>([]),
        },
      ]),
    },
  });
  const modals = useModals();
  const createEmployee = useCreateEmployeeVerification();
  const [active, setActive] = useState('');
  const { history } = useLocation();

  const noOfEmployees = useMemo(
    () => form?.values?.data.length,
    [form?.values?.data]
  );
  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'EMPLOYEE_VERIFICATION' as ServiceCodeTypes,
    country: country! as ServiceCountry,
    quantity: noOfEmployees ?? 1,
  });

  const handleSubmit = useCallback(() => {
    createEmployee.mutate({
      workspaceId: workspaceId!,
      data: form.values.data,
    });
  }, [createEmployee, form.values.data, workspaceId]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  const handleRemoveEmployee = useCallback(
    (index: number) => {
      const data = form.values.data.filter((_, i) => i !== index);
      form.setFieldValue('data', data);
    },
    [form]
  );

  useEffect(() => {
    if (
      ['single-review', 'multiple-review'].includes(reviewPage) &&
      noOfEmployees
    ) {
      delay(() => {
        refetch();
      }, 2000);
    }
  }, [noOfEmployees, reviewPage, refetch]);

  const openEmployeeModal = useCallback(
    ({
      children,
      modalId = 'single-request',
    }: {
      modalId: string;
      children: React.ReactNode;
    }) => {
      modals.openModal({
        id: modalId,
        title: null,
        size: 'xl',
        centered: true,
        withCloseButton: false,
        closeOnClickOutside: false,
        children,
      });
    },
    [modals]
  );

  const MultipleEmployees = useMemo(() => {
    return (
      <MultipleEmployeeDetails
        renderIf={reviewPage === 'multiple-review'}
        form={form}
        billed={serviceCharge}
        onRemoveDetails={handleRemoveEmployee}
        editClick={(index) =>
          openEmployeeModal({
            modalId: 'single-request',
            children: (
              <EmployeeMainModal
                initialValues={form.values.data[index]}
                closeModal={() => {
                  modals.closeModal('single-request');
                  setReviewPage('multiple-review');
                }}
                onSubmit={(values) => {
                  form.setListItem('data', index, values as never);
                }}
                onClose={() => modals.closeModal('single-request')}
              />
            ),
          })
        }
      />
    );
  }, [
    form,
    handleRemoveEmployee,
    modals,
    openEmployeeModal,
    reviewPage,
    serviceCharge,
  ]);

  return (
    <RequestLayout
      headerFragment={
        <>
          {reviewPage === 'select-employee-type' && (
            <RequestHeader
              text="OneView"
              icon={<EmployeeIcon width={42} height={42} />}
              withBackground
              onClickBack={() => history.back()}
              withBackArrow
            />
          )}
          {reviewPage === 'single-review' && (
            <RequestHeader
              text="OneView - Single Verification"
              icon={
                <SingleEmloyeeIcon width={42} height={42} color="#008AFF" />
              }
              withBackground
              onClickBack={() => history.back()}
              withBackArrow
            />
          )}
          {reviewPage === 'multiple-review' && (
            <RequestHeader
              text="OneView - Multiple Verification"
              icon={
                <MutlipleEmployeeIcon width={42} height={42} color="#008AFF" />
              }
              withBackground
              onClickBack={() => history.back()}
              withBackArrow
            />
          )}
        </>
      }
      footerFragment={
        reviewPage !== 'select-employee-type' && (
          <Group position="apart" grow>
            <Button
              color="dark"
              variant="outline"
              onClick={() => history.back()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleDoubleClick}
              disabled={createEmployee.isLoading}
              loading={createEmployee.isLoading}
            >
              Submit
            </Button>
          </Group>
        )
      }
    >
      {MultipleEmployees}

      <SingleEmployeeDetails
        serviceCharge={serviceCharge}
        form={form}
        renderIf={reviewPage === 'single-review'}
        onEditClick={() =>
          openEmployeeModal({
            modalId: 'single-request',
            children: (
              <EmployeeMainModal
                initialValues={form.values.data[0]}
                closeModal={() => {
                  modals.closeModal('single-request');
                  setReviewPage('single-review');
                }}
                onSubmit={(values) => {
                  form.setListItem('data', 0, values as never);
                }}
                onClose={() => modals.closeModal('single-request')}
              />
            ),
          })
        }
      />

      {reviewPage === 'select-employee-type' && (
        <Stack spacing="xl" p="24px">
          <Text size="sm" sx={(theme) => ({ color: theme.colors['gray'][7] })}>
            Verify your employees, suppliers, partners, and others by filling
            the details below.
          </Text>
          <Text weight={'bolder'}>
            How many verifications are you looking to conduct?
          </Text>
          <Group position="apart">
            <Group position="apart">
              {verificationTypes.map((type, index) => (
                <VerificationType
                  key={index}
                  icon={type.icon}
                  actionText={type.text}
                  isActive={active === type.value}
                  onClick={() => {
                    setActive(type.value);

                    if (type.value === 'single') {
                      openEmployeeModal({
                        modalId: 'single-request',
                        children: (
                          <EmployeeMainModal
                            initialValues={form.values.data[index]}
                            closeModal={() => {
                              modals.closeModal('single-request');
                              setReviewPage('single-review');
                            }}
                            onSubmit={(values) => {
                              form.setListItem('data', index, values as never);
                            }}
                            onClose={() => modals.closeModal('single-request')}
                          />
                        ),
                      });
                    }

                    if (type.value === 'multiple') {
                      modals.openModal({
                        id: 'multile-request',
                        title: 'OneView - Multiple Verification',
                        centered: true,
                        closeOnClickOutside: false,
                        children: (
                          <MultipleEmployeeModal
                            closeModal={() => {
                              modals.closeModal('multipe-request');
                              setReviewPage('multiple-review');
                            }}
                            onFileParsed={async (values) => {
                              if (values.length) {
                                form.setFieldValue('data', formList(values));
                              }
                            }}
                          />
                        ),
                      });
                    }
                  }}
                />
              ))}
            </Group>
          </Group>
        </Stack>
      )}
    </RequestLayout>
  );
};
