import { Box, Button } from '@mantine/core';
import { useNavigate } from '@tanstack/react-location';
import { PropsWithChildren } from 'react';
import { SwitchHorizontal } from 'tabler-icons-react';
import Header from '../../connected/header/header';
import { withAuth } from '../../hoc/withAuth';
import { AdminHeaderLinks } from './common';

export const AdminLayout = ({ children }: PropsWithChildren<unknown>) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        minHeight: '100vh',
        borderRadius: theme.radius.xs,
        backgroundColor: theme.colors.gray[0],
        overflow: 'hidden',
      })}
    >
      <Header
        links={AdminHeaderLinks}
        rightSideNode={
          <Button
            leftIcon={<SwitchHorizontal width={12} />}
            variant="subtle"
            color="grape"
            onClick={() => navigate({ to: '/dashboard' })}
          >
            Switch to Customer
          </Button>
        }
      />
      {children}
    </Box>
  );
};

export default withAuth(AdminLayout);
