// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Button,
  Accordion,
  Group,
  TextInput,
  Center,
  Paper,
} from '@mantine/core';
import { withAuth } from '../../hoc/withAuth';
import {
  AccordionLabel,
  RequestAccordion,
} from '../../components/controls/accordions/requestAccordion/requestAccordion';
import { CalendarEvent, CaretDown, Search } from 'tabler-icons-react';
import { RequestCard } from '../../components/primitives/cards/request/requestCard/requestCard';
import { useMemo, useState } from 'react';
import { DateRangePicker } from '@mantine/dates';
import {
  useNavigate,
  Outlet,
  Link,
  useMatchRoute,
} from '@tanstack/react-location';
import { useDatePickerStyle } from './request.style';
import { useQuery } from 'react-query';
import {
  GetMyRequestsApi,
  GetMyTeamRequestsApi,
} from '../../services/apis/requests';
import { useCurrentWorkspace } from '../../hooks/workspace';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import {
  getRequestCardIcon,
  getRequestCardSupportText,
  getRequestCardText,
} from '../../components/primitives/cards/request/requestCard/helper';
import pluralize from 'pluralize';
import { Pagination } from '../../components/primitives/pagination/pagination';
import { useUserProfile } from '../../services/queries/users';
import { RequestSkeleton } from '../../components/primitives/skeletons/requests';
import { showNotification } from '@mantine/notifications';
import EmptyState from '../../components/primitives/emptyState/emptyState';
import { RequestZeroIcon } from '../../assets/icons';
import { DashboardLayout } from '../../components/layouts/dashboard';
import { WorkspaceRBAC } from '../permission/workspace';

const PAGE_LIMIT = 10;
function Request() {
  const matchRoute = useMatchRoute();
  const matches = matchRoute({ to: '/request/details/:requestId' });
  const matchedRequestId = matches ? matches['requestId'] : null;
  const { workspaceId } = useCurrentWorkspace();
  const { workspaces: userWorkspaces } = useUserProfile();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 800);

  const [page, onPageChange] = useState(1);
  const {
    isFetching,
    isError,
    data: myRequests,
  } = useQuery(
    ['requests/mine', page, debouncedSearchTerm],
    () =>
      GetMyRequestsApi({
        page,
        limit: PAGE_LIMIT,
        workspaceId: workspaceId!,
        searchterm: debouncedSearchTerm,
      }),
    {
      retry: false,
      enabled: !!workspaceId,
      onError: () => {
        showNotification({
          color: 'red',
          title: 'Your Requests',
          message:
            'Error occurred while fetching your requests. Please try again.',
        });
      },
    }
  );
  const totalPages = useMemo(
    () => (myRequests ? Math.ceil(myRequests.count / PAGE_LIMIT) : 0),
    [myRequests]
  );
  const pagination = usePagination({
    total: totalPages,
    page,
    onChange: onPageChange,
  });
  /** Begin Team */
  const [teamPage, onChangeTeamPage] = useState(1);
  const {
    isFetching: isFetchingTeamData,
    isError: isErrorTeamData,
    data: teamRequests,
  } = useQuery(
    ['requests/team', teamPage],
    () =>
      GetMyTeamRequestsApi({
        page: teamPage,
        limit: PAGE_LIMIT,
        workspaceId: workspaceId!,
      }),
    {
      enabled:
        userWorkspaces && workspaceId ? userWorkspaces.length > 1 : false,
      retry: false,
      onError: () => {
        showNotification({
          color: 'red',
          title: 'Team Requests',
          message:
            'Error occurred while fetching team requests. Please try again.',
        });
      },
    }
  );
  const totalTeamPages = useMemo(
    () => (teamRequests ? Math.ceil(teamRequests.count / PAGE_LIMIT) : 0),
    [teamRequests]
  );
  const teamPagination = usePagination({
    total: totalTeamPages,
    page: teamPage,
    onChange: onChangeTeamPage,
  });
  const showTeamAccordion = useMemo(() => {
    return !!teamRequests && teamRequests?.count >= 1;
  }, [teamRequests]);

  /** End Team */
  // const [date, setDate] = useState<[Date | null, Date | null]>([null, null]);

  const { classes } = useDatePickerStyle();
  const navigate = useNavigate();

  //if (isLoading || isFetching || isIdle) return <LoadingOverlay visible />;
  //if (isLoadingTeamData) return <LoadingOverlay visible />;
  const isEmpty = useMemo(() => {
    if (isFetching || isFetchingTeamData) return false;
    if (!teamRequests?.count && !myRequests?.count) return true;
    return false;
  }, [isFetching, isFetchingTeamData, myRequests, teamRequests]);
  return (
    <DashboardLayout>
      <div style={{ display: 'flex', height: 'calc(100vh - 77.19px)' }}>
        <div style={{ flex: 1 }}>
          <Group position="apart" py="xl" px={32}>
            <Group>
              <TextInput
                icon={<Search size={20} />}
                placeholder="Search by ID, Service code"
                classNames={{
                  input: classes.input,
                }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {/* <div>
                <DateRangePicker
                  icon={<CalendarEvent />}
                  placeholder="Search by Date Range"
                  value={date}
                  onChange={setDate}
                  classNames={{ input: classes.datePickerStyle }}
                />
              </div> */}
            </Group>
            <WorkspaceRBAC action="canMakeRequest">
              <Button
                onClick={() => navigate({ to: '/request/new' })}
                color="dark"
                variant="outline"
              >
                New Request
              </Button>
            </WorkspaceRBAC>
          </Group>
          <div
            style={{ flex: 1, overflow: 'auto', height: 'calc(100vh - 145px)' }}
          >
            {isEmpty ? (
              <Paper m="xl" shadow="xs" withBorder={true} radius="md">
                <Center style={{ height: 'calc(100vh - 216px)' }}>
                  <EmptyState
                    title="No Request yet"
                    subTitle="Make a new request for verification, and find your requests here at any time."
                    button={
                      <WorkspaceRBAC action="canMakeRequest">
                        <Link to="/request/new">
                          <Button type="submit" size="md">
                            Make a new request
                          </Button>
                        </Link>
                      </WorkspaceRBAC>
                    }
                    icon={<RequestZeroIcon />}
                  />
                </Center>
              </Paper>
            ) : (
              <RequestAccordion initialItem={0} multiple>
                <Accordion.Item
                  icon={<CaretDown fill="white" size={20} color={'white'} />}
                  label={
                    <AccordionLabel
                      title="your request"
                      subtitle={`${myRequests?.count ?? 0} ${pluralize(
                        'request',
                        myRequests?.count ?? 0
                      )} `}
                      rightNode={
                        totalPages > 1 ? (
                          <Pagination
                            current={pagination.active}
                            totalPages={totalPages}
                            onNext={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              pagination.next();
                            }}
                            onPrevious={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              pagination.previous();
                            }}
                          />
                        ) : null
                      }
                    />
                  }
                  key="1"
                >
                  {isFetching || isError ? (
                    <RequestSkeleton limit={10} />
                  ) : myRequests?.data ? (
                    myRequests.data.map((request) => (
                      <RequestCard
                        key={request.id}
                        request={{
                          id: request.id,
                          status: request.status,
                          //text: getRequestCardText(request),
                          supportingText: getRequestCardSupportText(request),
                          name: request.service.description!,
                          price: Number(request.payment?.amount),
                          currency: request.payment?.currency,
                          date: request.createdAt,
                        }}
                        renderIcon={<>{getRequestCardIcon(request)}</>}
                        onClick={() =>
                          navigate({ to: `/request/details/${request.id}` })
                        }
                        active={matchedRequestId === request.id}
                      />
                    ))
                  ) : null}
                </Accordion.Item>

                {showTeamAccordion ? (
                  <Accordion.Item
                    icon={<CaretDown fill="white" size={20} color={'white'} />}
                    label={
                      <AccordionLabel
                        title="team request"
                        subtitle={`${teamRequests?.count ?? 0} ${pluralize(
                          'request',
                          teamRequests?.count ?? 0
                        )} `}
                        rightNode={
                          totalTeamPages > 1 ? (
                            <Pagination
                              current={teamPagination.active}
                              totalPages={totalTeamPages}
                              onNext={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                teamPagination.next();
                              }}
                              onPrevious={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                teamPagination.previous();
                              }}
                            />
                          ) : null
                        }
                      />
                    }
                    key="2"
                  >
                    {isFetchingTeamData || isErrorTeamData ? (
                      <RequestSkeleton limit={10} />
                    ) : teamRequests?.data ? (
                      teamRequests.data.map((request) => (
                        <RequestCard
                          request={{
                            id: request.id,
                            status: request.status,
                            text: getRequestCardText(request),
                            supportingText: request.description!,
                            name: request.service.description!,
                            price: Number(request.payment?.amount),
                            currency: request.payment?.currency,
                            date: request.createdAt,
                          }}
                          renderIcon={<>{getRequestCardIcon(request)}</>}
                          key={request.id}
                          onClick={() =>
                            navigate({ to: `/request/details/${request.id}` })
                          }
                          active={matchedRequestId === request.id}
                        />
                      ))
                    ) : null}
                  </Accordion.Item>
                ) : null}
              </RequestAccordion>
            )}
          </div>
        </div>

        <Outlet />
      </div>
    </DashboardLayout>
  );
}

export default withAuth(Request);
