import { Box, Text, Group, Stack } from '@mantine/core';
import { ReactNode } from 'react';

interface IReportHeaderFormPops {
  label: string;
  input: ReactNode;
}

export const ReportHeaderForm = ({ label, input }: IReportHeaderFormPops) => {
  return (
    <Group>
      <Text
        sx={() => ({
          width: '25%',
        })}
      >
        {label}
      </Text>
      <Box
        sx={() => ({
          flexGrow: 1,
        })}
      >
        {input}
      </Box>
    </Group>
  );
};

export const ReportFormInputs = ({ label, input }: IReportHeaderFormPops) => {
  return (
    <Stack>
      <Group align={'flex-start'}>
        <Box sx={{ flex: 1 }}>
          <Text weight={500}>{label}</Text>
        </Box>
        <Box
          sx={() => ({
            flexGrow: 1,
            flex: 2,
          })}
        >
          {input}
        </Box>
      </Group>
    </Stack>
  );
};
