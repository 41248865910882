import { Avatar, Center, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { GHPassportResponseDto } from '../../gh/interface';

export const GHPassportReport = (props: GHPassportResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.Picture ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64,${props.Picture}`}
            radius="xl"
            size="lg"
            alt=""
          />
        </Center>
      ) : null}
      <RequestDataLayout label="Gender" data={props.Gender ?? 'N/A'} />
      <RequestDataLayout label="Last name" data={props.LastName ?? 'N/A'} />
      <RequestDataLayout label="First Name" data={props.FirstName ?? 'N/A'} />
      <RequestDataLayout label="Middle Name" data={props.MiddleName ?? 'N/A'} />
      <RequestDataLayout label="Issue Date" data={props.IssueDate ?? 'N/A'} />
      <RequestDataLayout label="Expiry Date" data={props.ExpiryDate ?? 'N/A'} />
      <RequestDataLayout label="Passport No" data={props.PassportNo ?? 'N/A'} />
      <RequestDataLayout
        label="Date of Birth"
        data={props.DateOfBirth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Polling Station"
        data={props.Nationality ?? 'N/A'}
      />
      <RequestDataLayout
        label="Place of Birth"
        data={props.PlaceOfBirth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Place of Issue"
        data={props.PlaceOfIssue ?? 'N/A'}
      />
    </Stack>
  );
};
