import { Box, Stack } from '@mantine/core';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';
import { DataInfo } from '../../../../../../../components/primitives/details/dataInfo';
import { ICACRequestProps } from '../cac';

interface ICACDetailsInfoprops {
  data: ICACRequestProps;
  onEditClick: () => void;
  billed?: IServiceCharge;
}

export const CACDetailsConfirmation = ({
  data,
  onEditClick,
  billed,
}: ICACDetailsInfoprops) => {
  return (
    <Stack justify="space-between" sx={() => ({ height: '100%' })} spacing={0}>
      <Box px="lg">
        <DataInfo
          title="RC Number"
          description={data.idNumber}
          icon={true}
          onEditClick={onEditClick}
        />
        <DataInfo
          title="company Name"
          description={data.company}
          icon={true}
          onEditClick={onEditClick}
        />
        <DataInfo
          title="Description"
          description={data.description}
          icon={true}
          onEditClick={onEditClick}
        />
      </Box>

      <Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Box>
    </Stack>
  );
};
