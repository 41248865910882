import { getNameInitials } from '@iverifyng/utils';
import { Avatar, Box, createStyles, Group, Radio, Text } from '@mantine/core';
import { Workspace } from '@prisma/client';
import React from 'react';
import { Plus } from 'tabler-icons-react';

interface IWorkspaceBlockProps {
  workspace: Workspace;
  workspaceId?: string;
  onChange?: (id: string) => void;
}

const useBlockStyle = createStyles((theme) => ({
  block: {
    height: 60,
    minWidth: '100%',
    border: `1px solid ${theme.colors['gray'][3]}`,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    paddingInline: theme.spacing.md,
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.colors['gray'][5],
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: theme.colors['gray'][3],
    borderRadius: '50%',
  },
}));

export const WorkspaceBlock = React.forwardRef<
  HTMLInputElement,
  IWorkspaceBlockProps
>(({ workspace, workspaceId, onChange }, ref) => {
  const { classes } = useBlockStyle();
  return (
    <Group
      onClick={() => {
        if (onChange) onChange(workspace.id);
      }}
      className={classes.block}
    >
      <Radio
        value={workspace.id}
        checked={workspace.id === workspaceId}
        ref={ref}
        name="change-workspace"
      />
      <Group>
        {workspace.name && (
          <Avatar alt={workspace.name!} radius="xl" color="indigo">
            {getNameInitials({
              name: workspace.name,
              maxInitials: 2,
            })}
          </Avatar>
        )}
        <Box>
          <Text size="sm" weight={600}>
            {workspace.name}
          </Text>
          <Text size="xs">{workspace.type}</Text>
        </Box>
      </Group>
    </Group>
  );
});

type IAddNewWorkspaceBlock = Omit<IWorkspaceBlockProps, 'workspace'>;

export const AddNewWorkspaceBlock = React.forwardRef<
  HTMLInputElement,
  IAddNewWorkspaceBlock
>(({ workspaceId, onChange }, ref) => {
  const { classes } = useBlockStyle();
  const id = 'add-new';
  return (
    <Group
      onClick={() => {
        if (onChange) onChange(id);
      }}
      className={classes.block}
    >
      <Radio
        value={'add-new'}
        ref={ref}
        checked={workspaceId === id}
        name="change-workspace"
      />
      <Group>
        <Box className={classes.iconContainer}>
          <Plus />
        </Box>
        <Text size="sm" weight={600}>
          Add new Workspace
        </Text>
      </Group>
    </Group>
  );
});
