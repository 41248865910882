import { RequestBodySkeleton } from '../../../../components/primitives/skeletons/requestDetail';
import { useMemo } from 'react';
import { IdentityRequestDetail } from './category/identity';
import { Box } from '@mantine/core';
import { CriminalCheck } from './category/criminal-check';
import { AdminCertificateDetails } from './category/certificate';
import { AdminTenantDetails } from './category/tenant';
import { AdminGuarantorDetails } from './category/guarantor';
import { AdminEmployeeDetails } from './category/employee';
import { AdminPropertyDetails } from './category/property';
import { GetRequestResponseDto } from '@iverifyng/dtos';
import { SharedResponseDetail } from '../../request/containers/interface';
import { AdminCreditCheckDetails } from '../../request/containers/detail/category/creditCheck';
import { AdminPreviousWorkDetails } from './category/previous-work';
import { AdminAddressDetails } from './category/address';

interface IRequestDetail {
  request?: GetRequestResponseDto;
  responseDetail: Record<string, unknown>;
  isLoadingRequest?: boolean;
  isErrorRequest?: unknown;
  responseDetailLoading?: boolean;
  responseDetailIsFetching?: boolean;
}
export const RequestDetailView = ({
  request,
  responseDetail,
  responseDetailIsFetching,
  isLoadingRequest,
  isErrorRequest,
  responseDetailLoading,
}: IRequestDetail) => {
  const responseDetailData = useMemo(() => {
    return responseDetail?.['FullData'];
  }, [responseDetail]);

  //   console.log(responseDetailData, 'responseDetailData');

  const serviceCategory = request?.service?.category;

  if (!isLoadingRequest && !request) return null;
  if (isLoadingRequest || isErrorRequest)
    return <RequestBodySkeleton skeleton={true} />;
  return (
    <Box
      style={{
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      {serviceCategory === 'IDENTITY' && (
        <IdentityRequestDetail
          request={request!}
          responseDetails={responseDetailData as SharedResponseDetail}
          responseDetailLoading={
            responseDetailLoading || responseDetailIsFetching
          }
        />
      )}
      {serviceCategory === 'CRIMINAL_RECORD' && (
        <CriminalCheck data={request!} />
      )}
      {serviceCategory === 'CERTIFICATE' && (
        <AdminCertificateDetails data={request!} />
      )}
      {serviceCategory === 'TENANT' && <AdminTenantDetails data={request!} />}

      {serviceCategory === 'GUARANTOR' && (
        <AdminGuarantorDetails data={request!} />
      )}

      {serviceCategory === 'EMPLOYEE' && (
        <AdminEmployeeDetails data={request!} />
      )}
      {serviceCategory === 'PROPERTY' && (
        <AdminPropertyDetails data={request!} />
      )}
      {serviceCategory === 'CREDIT_CHECK' && (
        <AdminCreditCheckDetails data={request!} />
      )}
      {serviceCategory === 'PREVIOUS_WORK' && (
        <AdminPreviousWorkDetails data={request!} />
      )}
      {serviceCategory === 'ADDRESS' && <AdminAddressDetails data={request!} />}
    </Box>
  );
};
