import * as Joi from 'joi';

const UPDATE_SERVICE_SCHEMA = Joi.object({
  name: Joi.string().min(5).optional().label('Name'),
  description: Joi.string().min(5).optional().label('Description'),
  costAmount: Joi.number().min(1).optional().label('Service Cost'),
  costCurrency: Joi.string().optional().label('Service Currency'),
  visible: Joi.boolean().optional().label('Visible'),
  available: Joi.boolean().optional().label('Available'),
});

export const ServicesValidationSchema = { UPDATE_SERVICE_SCHEMA };
