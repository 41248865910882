import {
  Box,
  Stack,
  Group,
  ActionIcon,
  AccordionItem,
  Tooltip,
  Alert,
} from '@mantine/core';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import { Trash, Edit, AlertTriangle } from 'tabler-icons-react';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import {
  CreateEmployeeVerificationDto,
  NewEmployeeVerificationDto,
} from '@iverifyng/dtos';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../components/primitives/price/servicecharge';
import { EmployeeReviewAccordionItem } from '../singleEmployeeDetails/accordionItem';
import { EmployeeVerificationSchema } from '@iverifyng/validators';
import { useEffect, useState } from 'react';
import { useModals } from '@mantine/modals';

interface IMultipleEmployeeDetailsProps {
  form: UseFormReturnType<CreateEmployeeVerificationDto>;
  editClick?: (index: number) => void;
  onRemoveDetails?: (index: number) => void;
  renderIf?: boolean;
  billed?: IServiceCharge;
}

export const MultipleEmployeeDetails = ({
  form,
  editClick,
  renderIf,
  billed,
  onRemoveDetails,
}: IMultipleEmployeeDetailsProps) => {
  if (!renderIf) return null;
  return (
    <Stack justify="space-between" style={{ height: '100%' }}>
      <Box px="lg" mt={'md'}>
        <Stack spacing={20}>
          {form.values.data.map((item, index) => (
            <SingleEmployeeAccordion
              key={index}
              item={item}
              index={index}
              editClick={editClick}
              onRemoveDetails={onRemoveDetails}
            />
          ))}
        </Stack>
      </Box>
      <Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Box>
    </Stack>
  );
};

interface ISingleProps {
  index: number;
  item: NewEmployeeVerificationDto;
  editClick?: (index: number) => void;
  onRemoveDetails?: (index: number) => void;
}
const SingleEmployeeAccordion = ({
  index,
  item,
  editClick,
  onRemoveDetails,
}: ISingleProps) => {
  const modals = useModals();
  const [hasError, setHasError] = useState<string | undefined>(undefined);
  useEffect(() => {
    const { error } =
      EmployeeVerificationSchema.SINGLE_EMPLOYEE_SCHEMA.validate(item);
    setHasError(error?.message);
  }, [hasError, item]);

  return (
    <FormListAccordion
      key={index}
      iconPosition="right"
      initialItem={index}
      multiple
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            rightIcon={
              <Group spacing={4}>
                {hasError && (
                  <Tooltip label={hasError}>
                    <ActionIcon variant="light" color={'yellow'}>
                      <AlertTriangle size={16} />
                    </ActionIcon>
                  </Tooltip>
                )}
                <ActionIcon
                  color="gray"
                  variant="light"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    if (editClick) editClick(index);
                  }}
                >
                  <Edit size={16} />
                </ActionIcon>
                <ActionIcon
                  color="red"
                  variant="light"
                  disabled={index === 0}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    modals.openConfirmModal({
                      title: 'Delete',
                      children: 'Are you sure you want to delete this item?',
                      labels: { confirm: 'Confirm', cancel: 'Cancel' },
                      onConfirm: () => {
                        if (onRemoveDetails) onRemoveDetails(index);
                      },
                    });
                  }}
                >
                  <Trash size={16} />
                </ActionIcon>
              </Group>
            }
          />
        }
      >
        {hasError && (
          <Alert icon={<AlertTriangle size={16} />} color="yellow">
            {hasError}
          </Alert>
        )}
        <EmployeeReviewAccordionItem {...item} />
      </AccordionItem>
    </FormListAccordion>
  );
};
