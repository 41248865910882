import { UserCard } from '../cards/admin/users/userCard';

export const AdminUserLoader = ({ limit = 7 }: { limit?: number }) => {
  return (
    <>
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <UserCard
            key={i}
            skeleton
            user={{
              id: '1',
              firebaseId: '1',
              firstName: 'Jane',
              lastName: 'Doe',
              email: 'string',
              emailVerified: false,
              verificationCode: 'xCti0',
              phoneNo: '',
              appRoles: ['ADMIN'],
              default_country: 'NG',
              createdAt: new Date(),
              updatedAt: new Date(),
              deactivate: false,
              notify_new_request: false,
              notify_new_activity: false,
              notify_weekly_newsletter: false,
              notify_news_updates: false,
            }}
          />
        ))}
    </>
  );
};
