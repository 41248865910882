import { createStyles } from '@mantine/core';

export const useRequestAccordionStyle = createStyles((theme) => ({
  item: {
    backgroundColor: theme.colors['blue'][8],
  },
  itemOpened: {
    background: theme.white,
  },
  control: {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: theme.colors['blue'][8],
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: theme.colors['blue'][7],
    },
  },
  content: {
    backgroundColor: theme.white,
    padding: '0px 0px',
  },
  contentInner: {
    padding: '0px 0px',
  },
}));
