import { AccordionItem, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';
import { CertificateVerificationRecordDto } from '@iverifyng/dtos';
import { IUniversity, triggerURLDownload } from '@iverifyng/utils';
import { certificateStatusDescription } from '../../../utils/status';
import { AdminCertificate3DotMenu } from '../../menus/requests/certificate';

interface IProps {
  index: number;
  record: CertificateVerificationRecordDto;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const CertificateAccordionItem = ({ record, index }: IProps) => {
  const university = record.university as unknown as IUniversity;
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={record.fullName!}
            rightIcon={<AdminCertificate3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout label="Full Name" data={record.fullName} />
          <RequestDataLayout label="Certificate Type" data={record.type} />
          {record.type === 'UNIVERSITY' && (
            <Stack spacing="sm">
              <RequestDataLayout
                label="University"
                data={[university?.['name'], university?.['country']]
                  .filter(Boolean)
                  .join(', ')}
              />
              <RequestDataLayout
                label="Matric Number"
                data={record.matricNumber}
              />
            </Stack>
          )}
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={certificateStatusDescription[record.status!]}
              />
            }
          />

          <RequestDataLayout
            label="Certificate"
            data={
              <PDFDownloadButton
                disabled={!record.certificateUrl}
                onClick={() => triggerURLDownload(record.certificateUrl!)}
              />
            }
          />
          {record?.consentFormUrl && (
            <RequestDataLayout
              label="Consent Form"
              data={
                <PDFDownloadButton
                  disabled={!record.consentFormUrl}
                  onClick={() => triggerURLDownload(record.consentFormUrl!)}
                />
              }
            />
          )}

          {record.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
