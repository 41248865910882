/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useLocation } from '@tanstack/react-location';
import * as React from 'react';

import { joiResolver, useForm } from '@mantine/form';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../hooks/request';
import { useCurrentWorkspace } from '../../../../../../hooks/workspace';
import { useCurrentCountry } from '../../../../../../hooks/country';
import { ServiceCountry } from '@prisma/client';
import { KE_AlienCardRequestDto } from '@iverifyng/dtos';
import { KEAlienCardForm } from './containers/alienCardForm';
import { KEAlienCardFormConfirmation } from './containers/alienCardConfirmation';
import { useConsentConfirmation } from '../../../../../../hooks/consent';
import { debounce } from 'lodash';

export interface AlienCardFormData extends KE_AlienCardRequestDto {
  description: string;
}

type KEAlienCardPage = 'form' | 'verify';

export const KEAlienCard = () => {
  const { workspaceId } = useCurrentWorkspace();
  const [page, setPage] = React.useState<KEAlienCardPage>('form');
  const { history } = useLocation();
  const { country } = useCurrentCountry();

  const form = useForm<AlienCardFormData>({
    initialValues: {
      idNumber: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.KE_ALIEN_CARD),
  });

  const { showConsent } = useConsentConfirmation();
  const createIdentity = useCreateIdentityVerification();
  const { data: serviceCharge } = useServiceCharge({
    code: 'ALIEN_CARD',
    country: country! as ServiceCountry,
  });

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        createIdentity.mutate({
          code: 'ALIEN_CARD',
          country: country! as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            description: data.description,
          },
        });
      },
    });
  }, [country, createIdentity, form.values, showConsent, workspaceId]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  const onVerify = React.useCallback(() => {
    setPage('verify');
  }, []);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(onVerify)}>
      <KEAlienCardForm
        {...{ form }}
        onBack={() => history.back()}
        renderIf={page === 'form'}
      />
      <KEAlienCardFormConfirmation
        {...{ form }}
        onSubmit={handleDoubleClick}
        data={form.values}
        loading={createIdentity.isLoading}
        billed={serviceCharge}
        onEdit={() => setPage('form')}
        onBack={() => setPage('form')}
        renderIf={page === 'verify'}
      />
    </form>
  );
};
