import * as Joi from 'joi';
export const AuthValidatorSchema = {
  PRE_AUTH_CHECK: Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
  }),
  SIGNUP: Joi.object({
    firstName: Joi.string().required().label('First Name'),
    lastName: Joi.string().required().label('Last Name'),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    password: Joi.string().min(6).required().label('Password'),
    accountType: Joi.valid('CORPORATE', 'PERSONAL').label('Account Type'),
    businessName: Joi.string()
      .when('accountType', {
        is: 'CORPORATE',
        then: Joi.string().required(),
        otherwise: Joi.string().optional(),
      })
      .label('Business Name'),
    acceptTerms: Joi.boolean()
      .invalid(false)
      .required()
      .label('Terms of Service')
      .messages({
        'any.required': 'Terms of Service must be checked',
        'any.invalid': 'Terms of Service must be checked',
      }),
  }),
  LOGIN: Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    password: Joi.string().required().label('Password'),
  }),
  FORGOT_PASSWORD: Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email')
      .messages({
        //'string.empty': 'Email is required',
        //'string.email': 'Email is invalid',
      }),
  }),

  RESEND_VERIFICATION_CODE: Joi.object({
    userId: Joi.string().required().label('User ID'),
  }),
  VERIFY_ACCOUNT_CODE: Joi.object({
    userId: Joi.string().required().label('User ID'),
    code: Joi.string().required().label('Verification Code'),
  }),
  RESET_PASSWORD: Joi.object({
    userId: Joi.string().required().label('User ID'),
    code: Joi.string().required().label('Verification Code'),
    newPassword: Joi.string().min(6).required().label('New Password'),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref('newPassword'))
      .label('Password Confirmation')
      .messages({
        'any.only': 'Password Confirmation must match',
      }),
  }),
  CHANGE_PASSWORD: Joi.object({
    currentPassword: Joi.string().required().label('Current Password'),
    newPassword: Joi.string().min(6).required().label('New Password'),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref('newPassword'))
      .label('Password Confirmation')
      .messages({
        'any.only': 'Password Confirmation must match',
      }),
  }),
};
