import * as Joi from 'joi';

const KeyValueSchema = Joi.object({
  value: Joi.string().optional().allow(null, ''),
  comment: Joi.string().optional().allow(null, ''),
});
const GuarantorSchema = Joi.object({
  fullName: KeyValueSchema,
  address: KeyValueSchema,
  workAddress: KeyValueSchema,
});
const VisualSchema = Joi.object({
  description: Joi.string().optional().allow(null, ''),
  imageUrl: Joi.string().uri().optional(),
});
const CommonCreateReportSchema = {
  client: KeyValueSchema.optional().label('Client'),
  observation: KeyValueSchema.optional().label('Observation'),
  visuals: Joi.array().items(VisualSchema).optional().label('Visuals'),
  geolocation: Joi.object({
    longitude: Joi.number().required().label('Longitude'),
    latitude: Joi.number().required().label('Latitude'),
  })
    .optional()
    .label('Geolocation'),
};
const CommonApplicantSchema = {
  fullName: KeyValueSchema.optional().label('Name'),
  residentialAddress: KeyValueSchema.optional().label('Address'),
  phoneNo: KeyValueSchema.optional().label('Phone Number'),
  meansOfId: KeyValueSchema.optional().label('Means of ID'),
  searchDate: KeyValueSchema.optional().label('Search Date'),
  guarantors: Joi.array()
    .items(GuarantorSchema)
    .optional()
    .label('Applicant Guarantor'),
};
const CommonSearchReportSchema = {
  fullName: KeyValueSchema.optional().label('Name'),
  residentialAddress: KeyValueSchema.optional().label('Address'),
  socialMediaName: KeyValueSchema.optional().label('Social Media Name'),
  guarantors: Joi.array()
    .items(GuarantorSchema)
    .optional()
    .label('Search Report Guarantor'),
};
const CREATE_EMPLOYEE_REPORT = Joi.object({
  ...CommonCreateReportSchema,
  applicant: Joi.object({
    ...CommonApplicantSchema,
    designation: KeyValueSchema.optional().label('Designation'),
    previousWork: KeyValueSchema.optional().label('Previous Work'),
  }).label('Applicant Info'),
  searchReport: Joi.object({
    ...CommonSearchReportSchema,
    employmentReport: KeyValueSchema.optional().label('Employment Report'),
  })
    .optional()
    .label('Search Report'),
});

const CREATE_TENANT_REPORT = Joi.object({
  ...CommonCreateReportSchema,
  applicant: Joi.object({
    ...CommonApplicantSchema,
    companyName: KeyValueSchema.optional().label('Company Name'),
    companyAddress: KeyValueSchema.optional().label('Company Address'),
    spouseName: KeyValueSchema.optional().label('Spouse Name'),
    email: KeyValueSchema.optional().label('Email'),
    landlordInfo: KeyValueSchema.optional().label('Landlord Info'),
  }).label('Applicant Info'),
  searchReport: Joi.object({
    ...CommonSearchReportSchema,
    companyAddress: KeyValueSchema.optional().label('Company Address'),
    landlordReview: KeyValueSchema.optional().label('Landlord Review'),
  })
    .optional()
    .label('Search Report'),
});

const CREATE_ADDRESS_REPORT = Joi.object({
  ...CommonCreateReportSchema,
  applicant: Joi.object({
    ...CommonApplicantSchema,
    address: KeyValueSchema.optional().label('Address'),
  }).label('Applicant Info'),
  searchReport: Joi.object({
    ...CommonSearchReportSchema,
address: KeyValueSchema.optional().label('Address'),
  }).optional().label('Search Report')
});

const CREATE_REPORT_SCHEMA = Joi.object({
  serviceCode: Joi.string().required().label('Service Code'),
  requestId: Joi.string().required().label('Request ID'),
  requestItemId: Joi.string().required().label('Item ID'),
  data: Joi.when('serviceCode', {
    switch: [{ is: 'EMPLOYEE', then: CREATE_EMPLOYEE_REPORT }],
  }).label('DATA'),
});

const FIND_REPORT_SCHEMA = Joi.object({
  serviceCode: Joi.string().label('Service Code'),
  requestItemId: Joi.string().label('Item ID'),
});

export const AgentReportValidationSchema = {
  CREATE_EMPLOYEE_REPORT,
  CREATE_TENANT_REPORT,
  CREATE_ADDRESS_REPORT,
  CREATE_REPORT_SCHEMA,
  FIND_REPORT_SCHEMA,
};
