import { useLocation } from '@tanstack/react-location';
import { useCallback, useState } from 'react';
import { formList, useForm } from '@mantine/form';
import { CreditCheckSchema } from '@iverifyng/validators';
import { useServiceCharge } from '../../../../../../../src/hooks/request';
import { useCurrentCountry } from '../../../../../../../src/hooks/country';
import { CreditCheckProductCodeEnum, ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../../src/hooks/workspace';
import { ConsumerCreditCheckForm } from './container/consumerCreditCheckForm';
import { IConsumerCheckFormList } from '../interface';
import { ConsumerCreditCheckConfirmation } from './container/consumerCreditCheckConfrimatiom';
import { useCreateCreditCheckVerification } from '../../../../../../services/mutations/creditCheck';
import dayjs from 'dayjs';
import { showNotification } from '@mantine/notifications';
import { debounce } from 'lodash';

type ConsumerCreditCheckPage = 'form' | 'verify';

export const ConsumerCreditCheck = () => {
  const [page, setPage] = useState<ConsumerCreditCheckPage>('form');
  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();
  const form = useForm({
    initialValues: {
      code: '',
      data: formList<IConsumerCheckFormList>([
        {
          enquiryReason: '',
          consumerName: '',
          accountNumber: '',
          identification: '',
          dateOfBirth: '',
        },
      ]),
      description: 'Sample Consumer Credit Report',
    },
  });

  const { data: serviceCharge } = useServiceCharge({
    code:
      form.values.code === 'CONSUMER_DETAILED_REPORT'
        ? 'CREDIT_CHECK_DETAILED_CONSUMER'
        : 'CREDIT_CHECK_XSCORE_DETAILED_CONSUMER',
    country: country! as ServiceCountry,
  });

  const createCreditCheck = useCreateCreditCheckVerification();

  const handleVerify = useCallback(() => {
    const { error } = CreditCheckSchema.NEW_VERIFICATION_SCHEMA.validate(
      form.values
    );
    if (error) {
      showNotification({
        color: 'red',
        title: 'Validation Error',
        message: error.message,
      });
      return;
    }
    setPage('verify');
  }, [form.values]);

  const handleSubmit = useCallback(() => {
    createCreditCheck.mutate({
      workspaceId: workspaceId!,
      code: form.values.code as CreditCheckProductCodeEnum,
      data: form.values.data.map((d) => ({
        enquiryReason: d.enquiryReason,
        consumerName: d.consumerName,
        identification: d.identification,
        dateOfBirth: d.dateOfBirth
          ? dayjs(d.dateOfBirth).format('MM/DD/YYYY')
          : '',
        accountNumber: d?.accountNumber,
      })),
      description: form.values.description,
    });
  }, [
    createCreditCheck,
    form.values.code,
    form.values.data,
    form.values.description,
    workspaceId,
  ]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <ConsumerCreditCheckForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Consumer credit check are for individuals. You can search by person's Name and Date of birth, or BVN, or Account Number."
      />
      <ConsumerCreditCheckConfirmation
        data={form.values}
        isLoading={createCreditCheck.isLoading}
        onBack={() => setPage('form')}
        onSubmit={handleDoubleClick}
        renderIf={page === 'verify'}
        billed={serviceCharge}
      />
    </form>
  );
};
