import { Center, Stack, Text } from '@mantine/core';
import { creditCheckRequestList } from '../../../../../fakedata/requestData';
import * as React from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import RequestType from '../../../../../components/primitives/cards/request/requestType';
import { RequestHeader } from '../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../components/layouts/request/requestLayout';

import EmptyState from '../../../../../components/primitives/emptyState/emptyState';
import { DashboardImage } from '../../../../../assets/icons';
import { useQuery } from 'react-query';
import { GetServices } from '../../../../../services/apis/requests';
import { RequestTypeSkeleton } from '../../../../../components/primitives/skeletons/requests';

export const CreditCheck = () => {
  const navigate = useNavigate();
  const { history } = useLocation();
  const { data, isLoading } = useQuery('services', GetServices);

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Credit Check Verification"
          supportingText="Entity"
          onClickBack={() => history.back()}
          withBackArrow
        />
      }
    >
      {isLoading || !data?.length ? (
        <RequestTypeSkeleton limit={2} />
      ) : creditCheckRequestList ? (
        <Stack p="xl">
          <Text size="md">Choose the entity you are trying to verify.</Text>
          {creditCheckRequestList.map(
            ({ type, description, pathName, abbrev, icon, visiblefn }, idx) => {
              if (visiblefn) {
                const isvisible = visiblefn(data!);

                if (!isvisible) return null;
              }
              return (
                <RequestType
                  key={idx}
                  {...{ type }}
                  {...{ description }}
                  abbrev={abbrev}
                  icon={icon}
                  onClick={() => navigate({ to: pathName })}
                />
              );
            }
          )}
        </Stack>
      ) : (
        <Center p={'xl'} style={{ height: '100%' }}>
          <EmptyState
            title="No Service"
            subTitle="There are no credit check verification services available. Please check back later."
            icon={<DashboardImage />}
          />
        </Center>
      )}
    </RequestLayout>
  );
};
