import { GetUserResponseDto } from '@iverifyng/dtos';
import {
  Button,
  Group,
  InputWrapper,
  MultiSelect,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { AdminUpdateUserDetailsApi } from '../../../services/apis/admin';
import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Prisma } from '@prisma/client';
import { PhoneNumberInput } from '../../../components/controls/phoneInput/phoneInput';

export const appRoles = [
  {
    value: 'WORKSPACE',
    label: 'Workspace',
  },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'AGENT', label: 'Agent' },
];

interface IAdminEditUserDetailProps {
  user: GetUserResponseDto;
  closeModal?: () => void;
}

export const AdminEditUserDetailModal = ({
  user,
  closeModal,
}: IAdminEditUserDetailProps) => {
  const queryClient = useQueryClient();

  const form = useForm<Prisma.UserUpdateInput>({
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      phoneNo: user?.phoneNo ?? '',
      appRoles: user?.appRoles,
    },
  });

  const refreshUserDetails = useCallback(
    (userId: string) => {
      queryClient.invalidateQueries(['adminUserDetail', userId]);
    },
    [queryClient]
  );

  const updateUser = useMutation(AdminUpdateUserDetailsApi);

  const handleSubmit = useCallback(
    (data: Prisma.UserUpdateInput) => {
      const payload = { data, userId: user.id };

      updateUser.mutate(payload, {
        onSuccess: () => {
          showNotification({
            color: 'blue',
            message: 'User Updated',
          });
          refreshUserDetails(user.id);
          if (closeModal) closeModal();
        },
        onError: (error) => {
          showNotification({
            color: 'blue',
            message:
              (error as AxiosError<{ message: string }>).response?.data
                .message ?? 'Error occurred',
          });
        },
      });
    },
    [closeModal, refreshUserDetails, updateUser, user.id]
  );

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group spacing={'md'} direction="column" grow>
        <Group spacing={'md'} grow>
          <TextInput
            label="First Name"
            placeholder="Enter new first new"
            {...form.getInputProps('firstName')}
          />
          <TextInput
            label="Last Name"
            placeholder="Enter new last new"
            {...form.getInputProps('lastName')}
          />
        </Group>
        <Group spacing={'md'} grow>
          <TextInput
            label="Email"
            placeholder="abc@gmail.com"
            value={user?.email}
            disabled
          />
          <InputWrapper label="Phone Number" placeholder="Enter phone number">
            <PhoneNumberInput {...form.getInputProps('phoneNo')} />
          </InputWrapper>
        </Group>
        <Group spacing={'md'} grow>
          <MultiSelect
            label="App roles"
            multiple
            searchable
            data={appRoles}
            maxDropdownHeight={280}
            {...form.getInputProps('appRoles')}
          />
        </Group>

        <Group position="right" mt={'md'}>
          <Button
            type="submit"
            disabled={updateUser.isLoading}
            loading={updateUser.isLoading}
          >
            Save Changes
          </Button>
        </Group>
      </Group>
    </form>
  );
};
