import { ActionIcon, Box, Group, Loader, Text } from '@mantine/core';
import { ArrowLeft, X } from 'tabler-icons-react';

interface IRequestHeaderProps {
  icon?: React.ReactNode;
  text: React.ReactNode;
  supportingText?: string;
  withBackground?: boolean;
  withBackArrow?: boolean;
  onClickBack?: () => void;
  withClose?: boolean;
  onCancel?: () => void;
  showLoader?: boolean;
  rightNode?: React.ReactNode;
}

export const RequestHeader = ({
  icon,
  text,
  supportingText,
  withBackground,
  withBackArrow,
  onClickBack,
  withClose,
  onCancel,
  showLoader,
  rightNode,
}: IRequestHeaderProps) => {
  return (
    <Box
      py={withBackground ? 'xs' : 'lg'}
      px="lg"
      sx={(theme) => ({
        backgroundColor: withBackground ? theme.colors.gray[1] : 'transparent',
        borderBottom: withBackground
          ? 'none'
          : `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <Group position="apart">
        <Group spacing="md">
          {withBackArrow && (
            <ActionIcon
              onClick={() => {
                if (onClickBack) {
                  onClickBack();
                }
              }}
              variant="hover"
            >
              <ArrowLeft size={20} />
            </ActionIcon>
          )}
          {icon}
          <Text weight={600}>
            {text}{' '}
            {supportingText && (
              <Text
                component="span"
                size="xs"
                sx={(theme) => ({
                  color: theme.colors.gray[6],
                })}
              >{` (${supportingText})`}</Text>
            )}
          </Text>
          {showLoader && <Loader variant="dots" size="xs" />}
        </Group>
        {withClose && (
          <ActionIcon onClick={onCancel} variant="hover">
            <X size={20} />
          </ActionIcon>
        )}
        {!!rightNode && rightNode}
      </Group>
    </Box>
  );
};
