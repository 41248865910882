import { RequestStatusTypes } from '@iverifyng/dtos';
import { Box, Card, Group, Skeleton, Text } from '@mantine/core';
import {
  useStatusColor,
  useStatusText,
} from '../../../../../hooks/useStatusColor';
import React from 'react';

type Stat = {
  count: number;
  status: RequestStatusTypes;
};
interface IDashStatCardProps {
  stat: Stat;
  skeleton?: boolean;
  size?: 'base' | 'sm';
}

export const DashStatCard = ({
  stat,
  skeleton = false,
  size = 'base',
}: IDashStatCardProps) => {
  const { count, status } = stat;

  const color = useStatusColor(status);
  const text = useStatusText(status);

  if (status === 'AWAITING_ASSIGNMENT') return null;
  return (
    <Card
      py={0}
      px={0}
      shadow="sm"
      radius="md"
      sx={() => ({
        width: 'max-content',
      })}
    >
      <Skeleton
        visible={skeleton}
        px={size === 'sm' ? 'lg' : 'md'}
        py={size === 'sm' ? 'xs' : 'sm'}
      >
        <Group spacing="xs" align="center">
          <Box
            sx={(theme) => ({
              backgroundColor: `${theme.colors[color][6]}`,
              borderRadius: '50%',
              padding: '5px',
            })}
          />
          <Text
            transform="capitalize"
            weight={500}
            size={size === 'sm' ? 'xs' : 'md'}
            sx={(theme) => ({ color: theme.colors['gray'][8] })}
          >
            {text}
          </Text>
        </Group>
        <Text
          size={size === 'sm' ? 'md' : 'xl'}
          weight={600}
          sx={(theme) => ({
            color: theme.colors['gray'][9],
            lineHeight: '30px',
          })}
        >
          {count}
        </Text>
      </Skeleton>
    </Card>
  );
};

interface IDashCardProps extends Omit<IDashStatCardProps, 'stat'> {
  detail: {
    count: number | string;
    text: string;
  };
}

export const DashCard = ({
  detail,
  size = 'base',
  skeleton = false,
}: IDashCardProps) => {
  const { count, text } = detail;
  return (
    <Card
      py={0}
      px={0}
      shadow="sm"
      radius="md"
      sx={() => ({
        width: 'max-content',
      })}
    >
      <Skeleton
        visible={skeleton}
        px={size === 'sm' ? 'lg' : 'md'}
        py={size === 'sm' ? 'xs' : 'sm'}
      >
        <Group spacing="xs" align="center">
          <Text
            transform="capitalize"
            weight={500}
            size={size === 'sm' ? 'xs' : 'md'}
            sx={(theme) => ({ color: theme.colors['gray'][8] })}
          >
            {text}
          </Text>
        </Group>
        <Text
          size={size === 'sm' ? 'md' : 'xl'}
          weight={600}
          sx={(theme) => ({
            color: theme.colors['gray'][9],
            lineHeight: '30px',
          })}
        >
          {count}
        </Text>
      </Skeleton>
    </Card>
  );
};
