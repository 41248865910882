import { AccordionItem, Button, Group, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { CriminalCheckAdminMenu } from '../../menus/requests/criminalcheck';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { FileUploader } from '../../../components/controls/fileuploader/fileuploader';
import { PDFDownloadButton } from '../../../components/primitives/button/download';
import { CriminalRecordDto } from '@iverifyng/dtos';
import { MIME_TYPES, triggerURLDownload } from '@iverifyng/utils';
import { useModals } from '@mantine/modals';
import { criminalCheckStatusDescription } from '../../../utils/status';

interface IProps {
  index: number;
  record: CriminalRecordDto;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const CriminalCheckAccordionItem = ({
  record: check,
  index,
  onFileUploadSuccess,
}: IProps) => {
  const modals = useModals();
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={check.subject_name}
            rightIcon={<CriminalCheckAdminMenu record={check} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout label="Name" data={check.subject_name} />
          <RequestDataLayout label="Gender" data={check.gender} />
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={check.status}
                statusName={criminalCheckStatusDescription[check.status]}
              />
            }
          />

          <RequestDataLayout
            label="Applicant Report"
            data={
              check.status === 'DOCUMENT_REQUIRED' ||
              !check.registry_report_url ? (
                <Button
                  size="xs"
                  variant="subtle"
                  onClick={() => {
                    //setOpened(true);
                    modals.openModal({
                      id: 'file-uploader',
                      title: 'Upload Report',

                      centered: true,
                      children: (
                        <Group direction="column" grow>
                          <FileUploader
                            accept={[MIME_TYPES.pdf]}
                            instantUpload={true}
                            onSuccess={onFileUploadSuccess}
                          />
                        </Group>
                      ),
                    });
                  }}
                >
                  Upload file
                </Button>
              ) : (
                <PDFDownloadButton
                  disabled={!check.registry_report_url}
                  onClick={() => triggerURLDownload(check.registry_report_url!)}
                />
              )
            }
          />
          {check.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!check.validated_report_url}
                  onClick={() =>
                    triggerURLDownload(check.validated_report_url!)
                  }
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
