import { Button, Center, Divider, Loader, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../../components/layouts/request/requestDataLayout';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { GetRequestResponseDto } from '@iverifyng/dtos';
import { getRequestTypeLabel } from '../../../../../components/primitives/cards/request/requestCard/helper';
import { SharedReport } from '../../sharedReport/sharedReport';
import EmptyState from '../../../../../components/primitives/emptyState/emptyState';
import { ResponseZeroIcon } from '../../../../../assets/icons';
import { Download } from 'tabler-icons-react';
import { AdminRequestLayout } from '../../../../../components/layouts/admin-request';
import { CommonRequestDetails } from '../../../../../connected/requestDetails/commonInfo';
import { RequestDetailsBlock } from '../../../../../connected/requestDetails/block';
import { SharedResponseDetail } from '../../../request/containers/interface';
dayjs.extend(advancedFormat);

export interface IDetailsProps {
  request: GetRequestResponseDto;
  responseDetailLoading?: boolean;
  responseDetails?: SharedResponseDetail;
}

export const IdentityRequestDetail = ({
  request,
  responseDetails,
  responseDetailLoading,
}: IDetailsProps) => {
  const { status, identity } = request;

  return (
    <AdminRequestLayout
      footerFragment={
        status === 'COMPLETED' && identity.reportPdfUrl ? (
          <Button
            leftIcon={<Download />}
            color="green"
            onClick={() => window.open(identity.reportPdfUrl!, '_blank')}
            fullWidth
          >
            Download Report
          </Button>
        ) : null
      }
    >
      <Stack pb={'lg'}>
        <CommonRequestDetails {...request} />
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <RequestDetailsBlock title="Request Details">
          <RequestDataLayout
            label={getRequestTypeLabel(request)!}
            data={identity?.idNumber ? identity?.idNumber : null}
          />
        </RequestDetailsBlock>
        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />
        <RequestDetailsBlock title="Response Details">
          {status === 'COMPLETED' && responseDetails ? (
            <SharedReport {...{ request }} {...{ responseDetails }} />
          ) : status === 'COMPLETED' && responseDetailLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <EmptyState
              icon={<ResponseZeroIcon />}
              subTitle="Response details not available"
            />
          )}
        </RequestDetailsBlock>
      </Stack>
    </AdminRequestLayout>
  );
};
