import * as firebase from 'firebase/app';
import * as firebaseAuth from 'firebase/auth';

const config = {
  apiKey: process.env['NX_FIREBASE_API_KEY'],
  authDomain: process.env['NX_FIREBASE_AUTH_DOMAIN'],
};

const tenantId = process.env['NX_FIREBASE_TENANT'] as string;
const firebaseApp = firebase.initializeApp(config);
const getFirebaseAuth = firebaseAuth.getAuth(firebaseApp);
getFirebaseAuth.tenantId = tenantId; //the current tenant. VERY IMPORTANT

export { firebaseApp, getFirebaseAuth };
export default firebaseApp;
