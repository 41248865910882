import React from 'react';
import { ChevronRight, ChevronLeft } from 'tabler-icons-react';
import { ActionIcon, Group, Text } from '@mantine/core';

interface IPaginationProps {
  current: number;
  totalPages: number;
  onNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onPrevious: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  color?: string;
}

export const Pagination = ({
  current,
  totalPages,
  onNext,
  onPrevious,
  color = 'white',
}: IPaginationProps) => {
  return (
    <Group position="apart">
      <ActionIcon
        size={'sm'}
        disabled={current === 1}
        onClick={onPrevious}
        variant={color === 'white' ? 'light' : 'default'}
      >
        <ChevronLeft color="black" />
      </ActionIcon>
      <Group>
        <Text color={color} size="sm">
          Page {current} of {totalPages}
        </Text>
      </Group>
      <ActionIcon
        size={'sm'}
        onClick={onNext}
        disabled={current === totalPages}
        variant={color === 'white' ? 'light' : 'default'}
      >
        <ChevronRight color="black" />
      </ActionIcon>
    </Group>
  );
};
