import { FileImg, FilePdf, FileDoc, FileGen } from '../assets/icons';

export const getIconByFileExtension = (extension: string) => {
  const ext = extension.toLowerCase();
  if (['png', 'jpg', 'jpeg', 'svg', 'gif'].includes(ext)) return FileImg;
  if (['pdf'].includes(ext)) return FilePdf;
  if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'].includes(ext))
    return FileDoc;
  return FileGen;
};
