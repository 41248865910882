import axios from 'axios';
import * as qs from 'query-string';
export interface IUniversity {
  web_pages: Array<string>;
  name: string;
  alpha_two_code: string;
  'state-province': string | null;
  domains: Array<string>;
  country: string;
}
export const getUniversities = async () => {
  const universities = await axios
    .get<Array<IUniversity>>(
      'https://raw.githubusercontent.com/Hipo/university-domains-list/master/world_universities_and_domains.json'
    )
    .then((response) => response.data);
  return universities;
};

export interface FindUniversityDto {
  name?: string;
  country?: string;
}
export const findUniversities = async (query: FindUniversityDto) => {
  const url = qs.stringifyUrl({
    url: 'http://universities.hipolabs.com/search',
    query: { ...(query || {}) },
  });
  return await axios
    .get<Array<IUniversity>>(url)
    .then((response) => response.data);
};
