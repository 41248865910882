import { Box, Center, Grid, Stack, Text, TextInput } from '@mantine/core';
import { AdminLayout } from '../../../components/layouts/admin';
import { withAdmin } from '../../../hoc/withAdmin';
import { withAuth } from '../../../hoc/withAuth';
import React, { useCallback, useMemo, useState } from 'react';
import {
  MakeGenerics,
  Outlet,
  useLocation,
  useMatchRoute,
  useNavigate,
} from '@tanstack/react-location';
import { useAdminUsers } from '../../../hooks/admin-users';
import { UserCard } from '../../../components/primitives/cards/admin/users/userCard';
import EmptyState from '../../../components/primitives/emptyState/emptyState';
import { UserZeroIcon } from '../../../assets/icons';
import { Search } from 'tabler-icons-react';
import { useDebouncedValue, usePagination } from '@mantine/hooks';
import lodash from 'lodash-es';
import { AdminUserLoader } from '../../../components/primitives/skeletons/user';
import { RequestLayout } from '../../../components/layouts/request/requestLayout';
import { Pagination } from '../../../components/primitives/pagination/pagination';
import { AppRBAC } from '../../permission/app';

export type LocationGenerics = MakeGenerics<{
  Search: {
    page: number;
    searchterm: string;
  };
}>;
const PAGE_LIMIT = 10;
const usersPermissionAction = 'canViewUsers';

const Users = () => {
  const { current } = useLocation<LocationGenerics>();

  const [value, setValue] = useState(() => current.search.searchterm ?? '');
  const [searchterm] = useDebouncedValue(value, 800);
  const [page, setPage] = useState(() => current.search.page ?? 1);

  const {
    data: users,
    isLoading,
    isFetching,
  } = useAdminUsers({
    limit: PAGE_LIMIT,
    searchterm,
    page,
  });

  const navigate = useNavigate();
  const matchRoute = useMatchRoute();
  const matchUserRoute = matchRoute({
    to: `/admin/users/:userId/:tabId`,
  });
  const matchUserId = matchUserRoute?.['userId'];

  const isEmpty = useMemo(() => {
    if (isFetching || isLoading) return false;
    if (!users?.count || users?.count <= 0) return true;
    return false;
  }, [isFetching, isLoading, users?.count]);

  const handleRouteNavigate = useCallback(
    (params: Record<string, unknown>) => {
      navigate({
        search: (old) => {
          return lodash.pickBy(
            {
              ...old,
              ...params,
            },
            lodash.identity
          );
        },
        replace: true,
      });
    },
    [navigate]
  );

  const totalPages = useMemo(
    (): number => (users ? Math.ceil(users.count / PAGE_LIMIT) : 0),
    [users]
  );

  const onChange = useCallback(
    (page: number) => {
      setPage(page);
      handleRouteNavigate({ page });
    },
    [handleRouteNavigate]
  );

  const pagination = usePagination({
    total: totalPages,
    page,
    onChange,
  });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      setValue(e.target.value);
      setPage(1);
      handleRouteNavigate({ searchterm: e.target.value, page: 1 });
    },
    [handleRouteNavigate]
  );

  return (
    <AppRBAC action={usersPermissionAction}>
      <AdminLayout>
        <div style={{ height: 'calc(100vh - 77.19px)' }}>
          <Stack
            spacing="xl"
            px={32}
            py="xl"
            sx={(theme) => ({
              backgroundColor: 'white',
              borderBottom: `1px solid ${theme.colors['gray'][4]}`,
            })}
          >
            <Text
              sx={() => ({
                fontSize: '24px',
                lineHeight: '32px',
                fontWeight: 500,
              })}
            >
              Users
            </Text>
          </Stack>
          <Grid
            sx={() => ({
              height: 'calc(100vh - 158.19px)',
              width: '100%',
              backgroundColor: '#fff',
            })}
            gutter={0}
            columns={10}
          >
            <Grid.Col
              sx={(theme) => ({
                height: '100%',
                overflowY: 'auto',
                borderRight: `1px solid ${theme.colors['gray'][4]}`,
              })}
              span={3}
            >
              <RequestLayout
                headerFragment={
                  <Box px={32} py="sm">
                    <TextInput
                      icon={<Search size={20} />}
                      placeholder="Search user"
                      value={value}
                      onChange={handleChange}
                    />
                  </Box>
                }
                footerFragment={
                  totalPages > 1 ? (
                    <Pagination
                      current={pagination.active}
                      totalPages={totalPages}
                      onNext={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        pagination.next();
                      }}
                      onPrevious={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        pagination.previous();
                      }}
                      color="gray"
                    />
                  ) : null
                }
              >
                {isEmpty ? (
                  <Center pt="xl" sx={{ height: '100%' }}>
                    <EmptyState
                      icon={<UserZeroIcon />}
                      subTitle="You have no user at the moment."
                    />
                  </Center>
                ) : !!users?.data && !isLoading ? (
                  <>
                    {users.data.map((user, i) => (
                      <UserCard
                        key={i}
                        active={matchUserId === user.id}
                        user={user}
                        onClick={() =>
                          navigate({
                            to: `/admin/users/${user.id}/details`,
                            search: (old) => ({ ...old }),
                          })
                        }
                      />
                    ))}
                  </>
                ) : (
                  <AdminUserLoader limit={10} />
                )}
              </RequestLayout>
            </Grid.Col>
            <Grid.Col
              span={3}
              sx={(theme) => ({
                height: '100%',
                borderRight: `1px solid ${theme.colors['gray'][4]}`,
              })}
            >
              <Outlet />
            </Grid.Col>
            <Grid.Col
              span={4}
              style={{
                minHeight: '100%',
                width: '100%',
                overflowY: 'auto',
              }}
            ></Grid.Col>
          </Grid>
        </div>
      </AdminLayout>
    </AppRBAC>
  );
};

export default withAuth(withAdmin(Users));
