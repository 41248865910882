import * as React from 'react';
import { joiResolver, useForm } from '@mantine/form';
import { VotersCardForm } from '../../../../../../../components/votersCardRequest/votersCardForm';
import { VotersCardDetailsConfirmation } from '../../../../../../../components/votersCardRequest/votersCardDetails';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import { NINFormData } from '../nin/containers/ninForm';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../../hooks/request';
import { useCurrentCountry } from '../../../../../../../hooks/country';
import { ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../../hooks/workspace';
import { useConsentConfirmation } from '../../../../../../../hooks/consent';
import { debounce } from 'lodash';

export const VotersCard = () => {
  const [verify, setVerify] = React.useState(true);
  const { country } = useCurrentCountry();

  const form = useForm<NINFormData>({
    initialValues: {
      idNumber: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.VOTER_ID),
  });

  const { showConsent } = useConsentConfirmation();
  const createIdentity = useCreateIdentityVerification();
  const { workspaceId } = useCurrentWorkspace();
  const { data: serviceCharge } = useServiceCharge({
    code: 'VOTER_ID',
    country: country! as ServiceCountry,
  });

  const handleVerify = () => {
    setVerify(false);
  };

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        createIdentity.mutate({
          code: 'VOTER_ID',
          country: country! as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            description: data.description,
          },
        });
      },
    });
  }, [country, createIdentity, form.values, showConsent, workspaceId]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <VotersCardForm form={form} renderIf={verify} />
      <VotersCardDetailsConfirmation
        requestDetails={form.values.idNumber}
        requestDescription={form.values.description}
        onEditClick={() => setVerify(true)}
        price={serviceCharge!}
        renderIf={!verify}
        onSubmit={handleDoubleClick}
        isSubmitting={createIdentity.isLoading}
      />
    </form>
  );
};
