import {
  AccordionItem,
  LoadingOverlay,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import {
  ReportAccordionLabel,
  ReportFromAccordion,
} from '../../../components/controls/accordions/reportAccordion/requestDetails';
import React, { useCallback, useEffect } from 'react';
import { ReportHeaderForm } from '../../menus/requests/container/form/form';
import { useForm, Controller } from 'react-hook-form';
import {
  AddressVerificationRecordDto,
  CreateAddressAgentReportDto,
} from '@iverifyng/dtos';
import { VisualsForm } from './forms/visualsForm';
import { useAgentCreateReport } from '../../../services/mutations/agent';
import { useFindAgentReport } from '../../../services/queries/agents';
import { getInitialValuesForAgentAddressForm } from '../../../utils/agents';
import dayjs from 'dayjs';
import { AgentReportDrawerFooter } from './common';
import { AddressApplicantDetailsForm } from './forms/address/applicantDetailsForm';
import { AddressSearchReportForm } from './forms/address/searchReportForm';

interface IProps {
  onCancel: () => void;
  record: AddressVerificationRecordDto;
}

export const AddressReportFormDrawer = ({
  onCancel,
  record,
}: IProps): JSX.Element => {
  const { control, handleSubmit, setValue, reset, formState } =
    useForm<CreateAddressAgentReportDto>({
      mode: 'onChange',
      defaultValues: {},
    });

  const createReport = useAgentCreateReport();
  const {
    data,
    isLoading,
    refetch: refetchReport,
  } = useFindAgentReport({
    requestItemId: record?.id,
    serviceCode: 'ADDRESS',
  });

  useEffect(() => {
    if (isLoading) return;
    if (data) {
      reset(
        getInitialValuesForAgentAddressForm(
          data?.clientData,
          data?.agentReport?.report,
          data?.workspace
        )
      );
    }
  }, [data, isLoading, reset, setValue]);

  const handleSave = useCallback(
    (data: CreateAddressAgentReportDto) => {
      createReport.mutate(
        {
          serviceCode: 'ADDRESS',
          requestId: record.requestId,
          requestItemId: record.id,
          data: {
            client: data.client,
            applicant: {
              fullName: data.applicant?.fullName,
              phoneNo: data.applicant?.phoneNo,
              address: data.applicant?.address,
              searchDate: data.applicant?.searchDate,
            },
            searchReport: {
              // socialMediaName: data.searchReport?.socialMediaName,
              fullName: data.searchReport?.fullName,
              address: data.searchReport?.address,
            },
            observation: data.observation,
            visuals: data.visuals,
          },
        },
        {
          onSuccess(data, variables, context) {
            refetchReport();
          },
        }
      );
    },
    [createReport, record.id, record.requestId, refetchReport]
  );

  if (isLoading) return <LoadingOverlay visible />;
  return (
    <React.Fragment>
      <Stack px={'md'} sx={() => ({ width: '50%' })}>
        <ReportHeaderForm
          label="Client:"
          input={
            <Controller
              name="client.value"
              control={control}
              render={({ field }) => (
                <TextInput placeholder="client name" {...field} />
              )}
            />
          }
        />
        <ReportHeaderForm
          label="Date of search:"
          input={
            <Controller
              name="applicant.searchDate.value"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  placeholder="Select report date"
                  value={value ? dayjs(value).toDate() : new Date()}
                  onChange={onChange}
                />
              )}
            />
          }
        />
      </Stack>
      <Stack spacing={24} mb={64} px={'md'}>
        <AddressApplicantDetailsForm control={control} />
        <AddressSearchReportForm control={control} />
        <ReportFromAccordion iconPosition="right" initialItem={0} multiple>
          <AccordionItem label={<ReportAccordionLabel title="Observation" />}>
            <Controller
              control={control}
              name="observation.value"
              render={({ field }) => (
                <Textarea
                  label="Comment"
                  variant="default"
                  placeholder="Enter text"
                  {...field}
                />
              )}
            />
          </AccordionItem>
        </ReportFromAccordion>
        <VisualsForm control={control} setValue={setValue} />
      </Stack>

      <AgentReportDrawerFooter
        onCancel={onCancel}
        onSave={handleSubmit(handleSave)}
        pdfDisabled={!data?.agentReport?.report}
        requestItemId={record?.id}
        saveDisabled={!formState.isDirty || isLoading || createReport.isLoading}
        saveLoading={createReport.isLoading}
        serviceCode="ADDRESS"
      />
    </React.Fragment>
  );
};
