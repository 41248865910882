import { Roles, WorkspacePermission } from '@prisma/client';

export type WorkspaceRBACKeys =
  | 'canViewWorkspace'
  | 'canViewWorkspaceMembers'
  | 'canMakeRequest';
const workspaceRoleActions: Record<WorkspaceRBACKeys, WorkspacePermission[]> = {
  canViewWorkspace: ['OWNER', 'ADMIN'],
  canViewWorkspaceMembers: ['ADMIN', 'OWNER'],
  canMakeRequest: ['ADMIN', 'OWNER', 'REQUESTER'],
};

export type AppRBACKeys =
  | 'canViewUsers'
  | 'canViewServices'
  | 'canViewPayments'
  | 'canViewMore'
  | 'canViewRequests'
  | 'canViewDashboard'
  | 'canViewWorkspace'
  | 'canViewService'
  | 'canViewAnalytics';
const appRoleActions: Record<AppRBACKeys, Roles[]> = {
  canViewUsers: ['ADMIN'],
  canViewServices: ['ADMIN'],
  canViewAnalytics: ['ADMIN'],
  canViewPayments: ['ADMIN'],
  canViewMore: ['ADMIN'],
  canViewRequests: ['ADMIN', 'AGENT'],
  canViewDashboard: ['ADMIN'],
  canViewWorkspace: ['ADMIN'],
  canViewService: ['ADMIN'],
};

// app level roles
export const appRoleAccessCheck = (
  userRoles: Array<Roles>,
  action: AppRBACKeys
) => {
  const actionRoles = appRoleActions[action];
  if (!actionRoles) return false;
  return actionRoles.some((role) => userRoles.includes(role));
};

// workspace level roles
export const workspaceRoleAccessCheck = (
  workspaceRole: WorkspacePermission,
  action: WorkspaceRBACKeys
) => {
  const roleActions = workspaceRoleActions[action];
  if (!roleActions) return true;
  return roleActions.some((role) => workspaceRole === role);
};
