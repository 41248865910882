import { AccordionItem, Divider, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';

import { triggerURLDownload } from '@iverifyng/utils';
import { previousWorkStatusDescription } from '../../../utils/status';

import { PreviousWorkAddressVerification } from '@prisma/client';
import { RequestDetailsBlock } from '../block';

import { AdminPreviousWork3DotMenu } from '../../menus/requests/previouswork';

interface IProps {
  index: number;
  record: PreviousWorkAddressVerification;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const PreviousWorkAccordionItem = ({ record, index }: IProps) => {
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={record.fullName!}
            rightIcon={<AdminPreviousWork3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={previousWorkStatusDescription[record.status!]}
              />
            }
          />
          <RequestDetailsBlock title="Previous work Details">
            <RequestDataLayout label="Full Name" data={record.fullName} />
            <RequestDataLayout label="Phone Number" data={record.phoneNo!} />

            <RequestDataLayout label="Company Name" data={record.companyName} />
            <RequestDataLayout
              label="Previous Work Address"
              data={record.previousWorkAddress}
            />
          </RequestDetailsBlock>

          <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />

          {record.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
