import { ActionIcon, Group, Menu } from '@mantine/core';
import { useUserProfile } from '../../../services/queries/users';
import { DotsVertical } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import { AdminGetServicesDto } from '@iverifyng/dtos';
import { AdminEditServiceDetailModal } from '../../modals/admin/services/adminEditService';
import { useAdminServicesUpdateMtn } from '../../../services/mutations/services';

interface IServicesProps {
  service: AdminGetServicesDto;
  markVisibility: boolean;
  markAvailability: boolean;
}

export const AdminServicesDotMenu = ({
  markVisibility,
  markAvailability,
  service,
}: IServicesProps) => {
  const { isAdmin } = useUserProfile();
  const modals = useModals();
  const updateService = useAdminServicesUpdateMtn();

  return (
    <Group spacing={0} position="right">
      <Menu
        transition="pop"
        withArrow
        placement="end"
        size={'md'}
        onClick={(event) => event.stopPropagation()}
        control={
          <ActionIcon>
            <DotsVertical color="grey" size={16} />
          </ActionIcon>
        }
      >
        <Menu.Item
          disabled={!isAdmin}
          onClick={() =>
            modals.openModal({
              id: 'edit Service',
              title: 'Edit Serivice',
              children: (
                <AdminEditServiceDetailModal
                  service={service}
                  closeModal={() => {
                    modals.closeModal('edit Service');
                  }}
                />
              ),
            })
          }
        >
          Edit Service
        </Menu.Item>
        <Menu.Item
          disabled={!isAdmin}
          color={markVisibility ? 'red' : 'green'}
          onClick={() =>
            modals.openConfirmModal({
              id: 'mark visibility',
              centered: true,
              title: 'Service Visibility',
              children: `Are you sure you want to make this service ${
                service.visible ? 'invisible' : 'visible'
              }?`,
              labels: { confirm: 'Yes', cancel: 'No' },
              onConfirm: () =>
                updateService.mutate({
                  id: service.id,
                  data: {
                    visible: !service.visible,
                  },
                }),
            })
          }
        >
          Mark as {markVisibility ? 'invisible' : 'visible'}
        </Menu.Item>
        <Menu.Item
          disabled={!isAdmin}
          color={markAvailability ? 'red' : 'green'}
          onClick={() =>
            modals.openConfirmModal({
              id: 'mark-availability',
              centered: true,
              title: 'Service Availability',
              children: `Are you sure you want to make this service ${
                service.available ? 'unavailable' : 'available'
              }?`,
              labels: { confirm: 'Yes', cancel: 'No' },
              onConfirm: () =>
                updateService.mutate({
                  id: service.id,
                  data: {
                    available: !service.available,
                  },
                }),
            })
          }
        >
          Mark as {markAvailability ? 'unavailable' : 'available'}
        </Menu.Item>
      </Menu>
    </Group>
  );
};
