import { RequestStatusTypes } from '@iverifyng/dtos';
import { Box, Group, Notification, Text, Stack } from '@mantine/core';
import { useMemo } from 'react';
import { AlertTriangle } from 'tabler-icons-react';
import { useStatusColor } from '../../../../hooks/useStatusColor';
import { useAlertStyle } from './alert.styles';

interface IAlertCardProps {
  alert?: RequestStatusTypes;
}

export const AlertCard = ({ alert }: IAlertCardProps) => {
  const { classes } = useAlertStyle({ color: useStatusColor(alert) });
  const text = useMemo(() => {
    switch (alert) {
      case 'COMPLETED':
        return 'Your request has been completed.';
      case 'REJECTED':
        return 'Your request was rejected.';
      case 'PENDING':
        return 'Your request is been processed.';
      case 'ONGOING_VERIFICATION':
        return 'Your request is undergoing verification.';
      case 'CANCELLED':
        return 'Your request was cancelled.';
      default:
        return 'We are unable to ascertain the current state of your request.';
    }
  }, [alert]);

  return (
    <Box className={classes.root} role="alert">
      <Text size="sm" weight={500}>
        {`${text}`}
      </Text>
    </Box>
  );
};

export const WarningAlertCard = (props: {
  title: string;
  description: string;
}) => {
  return (
    <Notification
      disallowClose
      color="orange"
      title={
        <Group
          spacing={8}
          sx={(theme) => ({
            color: theme.colors.yellow[8],
          })}
        >
          <AlertTriangle />
          <Text size="md">{props.title}</Text>
        </Group>
      }
    >
      <Stack spacing={16} ml={32}>
        <Text size="xs">{props.description}</Text>
      </Stack>
    </Notification>
  );
};
