import { SimpleGrid, Skeleton } from '@mantine/core';

export const AdminDashLoader = ({ amount = 7 }: { amount?: number }) => {
  return (
    <SimpleGrid
      px="xl"
      py="lg"
      cols={5}
      spacing="lg"
      breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
    >
      {Array(amount)
        .fill('*')
        .map((_, index) => (
          <Skeleton key={index} visible={true} height={124.81} />
        ))}
    </SimpleGrid>
  );
};
