/* eslint-disable react/jsx-no-useless-fragment */
import {
  Card,
  createStyles,
  Group,
  Skeleton,
  Stack,
  Text,
} from '@mantine/core';
import { cloneElement, ReactNode } from 'react';

interface IRequestTypeProps {
  icon?: ReactNode;
  type: string;
  description: string;
  abbrev?: string;
  skeleton?: boolean;
  onClick?: () => void;
}

const useStyles = createStyles((theme) => ({
  outline: {
    '&:hover': {
      outline: '2px  solid #F0F2F4',
      cursor: 'pointer',
    },
  },
}));

const RequestType = ({
  icon,
  type,
  description,
  abbrev,
  skeleton = false,
  onClick,
}: IRequestTypeProps) => {
  const { classes } = useStyles();
  return (
    <Card
      withBorder
      shadow={'sm'}
      className={classes.outline}
      p="sm"
      onClick={onClick}
    >
      <Skeleton visible={skeleton}>
        <Group>
          {icon && cloneElement(<>{icon}</>, { width: 42, height: 42 })}
          <Stack spacing={2} justify="flex-start" style={{ flex: 1 }}>
            <Text weight={600} size="sm">
              {type}
              {abbrev && (
                <Text
                  component="span"
                  size="sm"
                  sx={(theme) => ({
                    color: theme.colors.gray[4],
                  })}
                >{` (${abbrev})`}</Text>
              )}
            </Text>
            <Text size="sm" color="dimmed" lineClamp={1}>
              {description}
            </Text>
          </Stack>
        </Group>
      </Skeleton>
    </Card>
  );
};

export default RequestType;
