import { useMatch } from '@tanstack/react-location';
import { RequestGenerics } from '../tabOutlet';
import { AgentsView } from '../../../components/agents/agents';

export const Agents = () => {
  const {
    params: { requestId },
  } = useMatch<RequestGenerics>();

  return <AgentsView requestId={requestId} />;
};
