import { ValidateCriminalRecord } from '../../../../connected/responseDetails/ng/validateCriminalRecord';
import { NewCriminalCheckResponse } from '../../../../connected/responseDetails/ng/newCriminalCheck';
import { useMemo } from 'react';
import { Button } from '@mantine/core';
import { CloudDownload, Repeat } from 'tabler-icons-react';
import { INonIdentityResponseProps } from '../../../../connected/responseDetails/interface';

export const CriminalCheck = ({
  request,
  onDownloadReport,
  onPaymentRetry,
  disableDownloadBtn,
  disableRetryBtn,
}: INonIdentityResponseProps) => {
  const footerNode = useMemo(() => {
    return onPaymentRetry ? (
      <Button
        fullWidth
        leftIcon={<Repeat />}
        onClick={onPaymentRetry}
        disabled={disableRetryBtn}
      >
        Retry Payment
      </Button>
    ) : (
      <Button
        fullWidth
        leftIcon={<CloudDownload />}
        disabled={disableDownloadBtn || !onDownloadReport}
        onClick={onDownloadReport}
      >
        Download Report
      </Button>
    );
  }, [disableDownloadBtn, disableRetryBtn, onDownloadReport, onPaymentRetry]);
  return (
    <>
      <NewCriminalCheckResponse
        data={request}
        renderIf={[
          'CRIMINAL_RECORD_CHECK_EMPLOYMENT',
          'CRIMINAL_RECORD_CHECK_IMMIGRATION',
          'CRIMINAL_RECORD_CHECK'
        ].includes(request.service.serviceCode)}
        footerNode={footerNode}
      />
      <ValidateCriminalRecord
        data={request}
        renderIf={request.service.serviceCode === 'VALIDATE_CRIMINAL_RECORD'}
        footerNode={footerNode}
      />
    </>
  );
};
