import { Avatar, Center, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { GHSSNITResponseDto } from '../../gh/interface';

export const GHSNNITReport = (props: GHSSNITResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.Photo ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64,${props.Photo}`}
            radius="xl"
            size="lg"
            alt=""
          />
        </Center>
      ) : null}
      <RequestDataLayout label="Gender" data={props.Sex ?? 'N/A'} />
      <RequestDataLayout label="FSS No" data={props.FSSNo ?? 'N/A'} />
      <RequestDataLayout label="Full Name" data={props.FullName ?? 'N/A'} />
      <RequestDataLayout
        label="Date of Birth"
        data={props.BirthDate ?? 'N/A'}
      />
      <RequestDataLayout label="Card Serial" data={props.CardSerial ?? 'N/A'} />
    </Stack>
  );
};
