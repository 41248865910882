import { useModals } from '@mantine/modals';
import { useCallback } from 'react';
import { ConsentConfirmationModal } from '../connected/modals/requests/consent/consentConfirmationModal';

interface IShowConsentProps {
  onCancel?: () => void;
  onConfirm?: () => void;
}
export const useConsentConfirmation = () => {
  const modals = useModals();
  const showConsent = useCallback(
    ({ onCancel, onConfirm }: IShowConsentProps) => {
      modals.openConfirmModal({
        title: 'Consent to Verification Data',
        closeOnEscape: false,
        closeOnClickOutside: false,
        children: <ConsentConfirmationModal />,
        labels: {
          confirm: 'Yes, I have consent',
          cancel: `No, I don't have consent`,
        },
        onCancel: onCancel,
        onConfirm: onConfirm,
      });
    },
    [modals]
  );
  return { showConsent };
};
