import { RequestCard } from '../cards/request/requestCard/requestCard';
import RequestType from '../cards/request/requestType';

export const RequestSkeleton = ({ limit = 10 }: { limit?: number }) => {
  if (limit < 1) return null;
  return (
    <>
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <RequestCard
            key={i}
            skeleton
            request={{
              id: '1',
              name: 'NIN Slip Verification - Nigeria',
              text: '#0000000000000',
              supportingText: 'Sample description to generate skeleton',
              price: 0,
              date: new Date(),
            }}
          />
        ))}
    </>
  );
};

export const RequestTypeSkeleton = ({ limit = 3 }: { limit?: number }) => {
  if (limit < 1) return null;
  return (
    <>
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <RequestType
            key={i}
            type="Identity Verification"
            description="NIN, Driver's License, Voter ID, CAC, etc"
            skeleton={true}
          />
        ))}
    </>
  );
};
