import {
  Anchor,
  Button,
  Center,
  Checkbox,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm, joiResolver } from '@mantine/form';
import { Link } from '@tanstack/react-location';
import { LoginFormDto } from '../../../dto/auth';
import { useCallback, useMemo } from 'react';
import { AuthLayout } from '../../../components/layouts/auth';
import { AuthValidatorSchema } from '@iverifyng/validators';
import { getFirebaseAuth } from '../../../services/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { showNotification } from '@mantine/notifications';
import { withDashboard } from '../../../hoc/withDashboard';
import { useMutation } from 'react-query';
import { PreAuthCheckApi } from '../../../services/apis/users';
import { PreAuthCheckDto } from '@iverifyng/services/auth';

function Login() {
  // const [showResendLink, setShowResendLink] = useState<boolean>(false);
  const form = useForm<LoginFormDto>({
    schema: joiResolver(AuthValidatorSchema.LOGIN),
    initialValues: {
      email: '',
      password: '',
    },
  });
  const preAuthCheckMutation = useMutation((values: PreAuthCheckDto) =>
    PreAuthCheckApi(values)
  );
  const loginMutation = useMutation(
    (formvalues: LoginFormDto) =>
      signInWithEmailAndPassword(
        getFirebaseAuth,
        formvalues.email,
        formvalues.password
      ),
    {
      onError: (error: never, variables, context) => {
        function getErrorMessage(code: string) {
          switch (code) {
            case 'auth/user-not-found':
              return 'User not found';
            case 'auth/wrong-password':
              return 'Invalid password';
            default:
              return 'Error occurred';
          }
        }
        showNotification({
          message: getErrorMessage(error['code']),
          color: 'red',
        });
      },
    }
  );
  const handleSubmit = useCallback(
    (values: LoginFormDto) => {
      //check if user already exists
      preAuthCheckMutation.mutate(
        { email: values.email },
        {
          onSuccess: (response) => {
            if (response.data.deactivated) {
              showNotification({
                message:
                  'Unfortunately, login access has been disabled on this account. Please contact support.',
                color: 'red',
              });
              return;
            }
            if (!response.data.exists) {
              showNotification({
                message: 'Found no account linked to the provided email.',
                color: 'red',
              });
              return;
            }
            if (!response.data.emailVerified) {
              showNotification({
                message: 'Please verify your email address to proceed.',
                color: 'red',
              });
              // return setShowResendLink(true);
            }
            loginMutation.mutate(values);
          },
        }
      );
    },
    [loginMutation, preAuthCheckMutation]
  );

  const isSignInLoading = useMemo(() => {
    return loginMutation.isLoading || preAuthCheckMutation.isLoading;
  }, [loginMutation.isLoading, preAuthCheckMutation.isLoading]);
  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <Stack spacing={8} justify="center" align={'center'} mb="xl">
          <Title order={2}>Welcome back!</Title>
          <Text size="md">Sign in to your account to continue.</Text>
        </Stack>

        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Group direction="column" grow>
            <TextInput
              label="Email"
              placeholder="Email"
              {...form.getInputProps('email')}
            />

            <PasswordInput
              label="Password"
              placeholder="Password"
              {...form.getInputProps('password')}
            />
          </Group>

          <Group position="apart" mt="xl">
            <Checkbox label="Remember Password" />
            <Link to={'/auth/forgotpassword'}>
              <Anchor component="button" type="button" color="blue" size="sm">
                {'Forgot Password'}
              </Anchor>
            </Link>
          </Group>

          <Group position="apart" mt="xl">
            <Button
              type="submit"
              fullWidth
              loading={isSignInLoading}
              disabled={isSignInLoading}
            >
              Sign In
            </Button>
          </Group>
          {/* {showResendLink && (
            <Center mt="xs">
              <Text size="sm">
                Not in inbox or spam?{' '}
                <Link to={'/auth/reverifyemail'}>
                  <Anchor
                    component="button"
                    type="button"
                    color="blue"
                    size="sm"
                  >
                    {'Resend'}
                  </Anchor>
                </Link>
              </Text>
            </Center>
          )} */}
        </form>
      </Paper>
      <Center>
        <Text size="sm">
          Don't have an account?{' '}
          <Link to={'/auth/signup'}>
            <Anchor component="button" type="button" color="blue" size="sm">
              {'Create account'}
            </Anchor>
          </Link>
        </Text>
      </Center>
    </AuthLayout>
  );
}

export default withDashboard(Login);
