import { AccordionItem, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';
import { GuarantoreVerificationRecordDto } from '@iverifyng/dtos';
import { triggerURLDownload } from '@iverifyng/utils';
import { guarantorStatusDescription } from '../../../utils/status';
import { AdminGuarantor3DotMenu } from '../../menus/requests/guarantor';
import { getTenantIDType } from '../../../components/primitives/cards/request/requestCard/helper';

interface IProps {
  index: number;
  record: GuarantoreVerificationRecordDto;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const GuarantorAccordionItem = ({ record, index }: IProps) => {
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={record.fullName!}
            rightIcon={<AdminGuarantor3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={guarantorStatusDescription[record.status!]}
              />
            }
          />
          <RequestDataLayout label="Full Name" data={record.fullName} />
          <RequestDataLayout label="Phone No" data={record.phoneNo} />
          <RequestDataLayout
            label={getTenantIDType(record.idType!)}
            data={record.idNumber}
          />
          <RequestDataLayout
            label="Residential Address"
            data={record.address}
          />
          <RequestDataLayout label="Work Address" data={record.workAddress} />

          {record.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
