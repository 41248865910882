import { Group, Button, Stack, Box } from '@mantine/core';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import * as React from 'react';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../../components/primitives/price/servicecharge';
import { ZANationalIDFormData } from './nationalIdForm';
import { DataInfo } from '../../../../../../../components/primitives/details/dataInfo';
import { NINIcon } from '../../../../../../../assets/icons/index';

interface IZANationalIDConfirmationProps {
  data: ZANationalIDFormData;
  billed?: IServiceCharge;
  loading?: boolean;
  onBack?: () => void;
  onEdit?: () => void;
  onSubmit?: () => void;
  renderIf?: boolean;
}

export const ZANationalIDConfirmation = ({
  data,
  billed,
  onBack,
  onEdit,
  loading,
  onSubmit,
  renderIf,
}: IZANationalIDConfirmationProps) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="National ID Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          withBackArrow
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel request
          </Button>
          <Button
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
            loading={loading}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack justify="space-between" sx={() => ({ height: '100%' })}>
        <Box>
          <RequestHeader
            text="National ID Verification"
            icon={<NINIcon width={42} height={42} />}
            withBackground
          />
          <Box px="lg">
            <DataInfo
              title="National Identity Number"
              description={data.idNumber}
              icon={true}
              onEditClick={onEdit}
            />
            <DataInfo
              title="Description"
              description={data.description}
              icon={true}
              onEditClick={onEdit}
            />
          </Box>
        </Box>
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Stack>
    </RequestLayout>
  );
};
