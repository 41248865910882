import { IEmployeeCSVDataType } from './interface';

export const getIdDetailsFromCSV = (data: IEmployeeCSVDataType) => {
  if (data?.driversLicence)
    return { type: 'DRIVERS_LICENSE', number: data?.driversLicence };
  if (data?.nin) return { type: 'NIN', number: data?.nin };
  if (data?.votersId) return { type: 'VOTER_ID', number: data?.votersId };
  return { type: '', number: '' };
};

export const getGuarantor1IdDetailsFromCSV = (data: IEmployeeCSVDataType) => {
  if (data?.guarantor1_driversLicence)
    return { type: 'DRIVERS_LICENSE', number: data?.guarantor1_driversLicence };
  if (data?.guarantor1_nin)
    return { type: 'NIN', number: data?.guarantor1_nin };
  if (data?.guarantor1_votersId)
    return { type: 'VOTER_ID', number: data?.guarantor1_votersId };
  return { type: '', number: '' };
};

export const getGuarantor2IdDetailsFromCSV = (data: IEmployeeCSVDataType) => {
  if (data?.guarantor2_driversLicence)
    return { type: 'DRIVERS_LICENSE', number: data?.guarantor2_driversLicence };
  if (data?.guarantor2_nin)
    return { type: 'NIN', number: data?.guarantor2_nin };
  if (data?.guarantor2_votersId)
    return { type: 'VOTER_ID', number: data?.guarantor2_votersId };
  return { type: '', number: '' };
};
