import { Button } from '@mantine/core';
import { GuarantorDetailsView } from '../../../../connected/responseDetails/ng/guarantor';
import { useMemo } from 'react';
import { CloudDownload, Repeat } from 'tabler-icons-react';
import { INonIdentityResponseProps } from '../../../../connected/responseDetails/interface';

export const GuarantorRequestDetails = ({
  request,
  onDownloadReport,
  onPaymentRetry,
  disableDownloadBtn,
  disableRetryBtn,
}: INonIdentityResponseProps) => {
  const footerNode = useMemo(() => {
    return onPaymentRetry ? (
      <Button
        fullWidth
        leftIcon={<Repeat />}
        onClick={onPaymentRetry}
        disabled={disableRetryBtn}
      >
        Retry Payment
      </Button>
    ) : (
      <Button
        fullWidth
        leftIcon={<CloudDownload />}
        disabled={disableDownloadBtn || !onDownloadReport}
        onClick={onDownloadReport}
      >
        Download Report
      </Button>
    );
  }, [disableDownloadBtn, disableRetryBtn, onDownloadReport, onPaymentRetry]);
  return (
    <GuarantorDetailsView
      data={request}
      renderIf={true}
      footerNode={footerNode}
    />
  );
};
