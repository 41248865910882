import { GetServiceChargeQueryDto } from '@iverifyng/dtos';
import { showNotification, updateNotification } from '@mantine/notifications';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  GetRequestByIdApi,
  GetResponseDetailByIdentiyId,
  GetServiceCharge,
  GetUncompletedRequestsApi,
  GetUserDashboardRequestsAggregate,
  PostIdentityVerificationRequest,
  PostNewCriminalCheckRequest,
  PostNewValidateCriminalCheckRequest,
} from '../services/apis/requests';
import { useAdminGetWorkspace } from './admin-workspace';

export const useCreateIdentityVerification = () => {
  const { history } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(PostIdentityVerificationRequest, {
    onMutate: () => {
      showNotification({
        id: 'request-submitted',
        title: 'New Identity Verification',
        message: 'Creating your new service request.',
        loading: true,
      });
    },
    onSuccess: (data) => {
      updateNotification({
        id: 'request-submitted',
        message: 'Request created. Redirecting...',
      });

      //redirect to payment page
      if (data.paymentUrl) {
        history.push(data.paymentUrl);
        return;
      }

      //redirect to request details
      navigate({ to: `/request/details/${data.requestId}` });
      //invalidate queries list on page 1
      queryClient.invalidateQueries(['requests/mine', 1]);
    },
    onError: () => {
      showNotification({
        id: 'request-submitted',
        color: 'red',
        title: 'Request',
        message:
          'Error occurred while sending your requests. Please try again.',
      });
    },
  });
};

export const useServiceCharge = (query: GetServiceChargeQueryDto) => {
  const { isError, isLoading, data, error, refetch } = useQuery(
    'serviceCharge',
    () => GetServiceCharge(query)
  );
  return {
    isLoading,
    isError,
    data: data ?? undefined,
    error,
    refetch,
    serviceCharge: data ? data?.amount : null,
  };
};

export const useRequestDetailById = (requestId: string) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['request', requestId],
    () => GetRequestByIdApi(requestId),
    {
      enabled: !!requestId,
    }
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
    requestId: data ? data?.id : null,
  };
};

export const useResponseDetailByIdentityId = ({
  identityId,
  isEnabled,
}: {
  identityId: string;
  isEnabled: boolean;
}) => {
  const { isError, isLoading, data, isFetching, error } = useQuery(
    ['responseDetail', identityId],
    () => GetResponseDetailByIdentiyId(identityId),
    { enabled: isEnabled }
  );

  return {
    isLoading,
    isError,
    isFetching,
    data: (data as Record<string, unknown>) ?? undefined,
    error,
  };
};

export const useUncompletedRequests = (workspaceId: string) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['uncompletedRequest', workspaceId],
    () => GetUncompletedRequestsApi(workspaceId),
    {
      enabled: !!workspaceId,
    }
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
  };
};

export const useUserDashboardRequestsAggregate = (workspaceId: string) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['dashboardRequestAggregate', workspaceId],
    () => GetUserDashboardRequestsAggregate(workspaceId),
    {
      enabled: !!workspaceId,
    }
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
  };
};

export const useCreateNewCriminalRecordCheck = (workspaceId: string) => {
  const { history } = useLocation();
  const { data: workspace, } =
    useAdminGetWorkspace(workspaceId);
  return useMutation(PostNewCriminalCheckRequest, {
    onMutate: () => {
      showNotification({
        id: 'request-submitted',
        title: 'New Criminal Record Check',
        message: 'Creating your new service request.',
        loading: true,
      });
    },
    onSuccess: (data) => {
      updateNotification({
        id: 'request-submitted',
        message: workspace?.allowOfflinePayment ? 'This request is successful, please check your email ' : 'Request created. Redirecting...',
      });

      //redirect to payment page
      if (data.paymentUrl) {
        history.push(data.paymentUrl);
      } else {
        history.push('/request/new/criminal-record');
      }


    },
    onError: () => {
      showNotification({
        id: 'request-submitted',
        color: 'red',
        title: 'Criminal Request Error',
        message:
          'Error occurred while sending your requests. Please try again.',
      });
    },
  });
};

export const useCreateNewValidateCriminalRecordCheck = (workspaceId: string) => {
  const { history } = useLocation();
  const { data: workspace, } =
    useAdminGetWorkspace(workspaceId);
  return useMutation(PostNewValidateCriminalCheckRequest, {
    onMutate: () => {
      showNotification({
        id: 'request-submitted',
        title: 'Validate Criminal Record Certificate',
        message: 'Creating your new service request.',
        loading: true,
      });
    },
    onSuccess: (data) => {
      updateNotification({
        id: 'request-submitted',
        message: workspace?.allowOfflinePayment ? 'This request is successful, please check your email ' : 'Request created. Redirecting...',

      });

      //redirect to payment page
      if (data.paymentUrl) {
        history.push(data.paymentUrl);
      } else {
        history.push('/request/new/criminal-record');
      }
    },
    onError: () => {
      showNotification({
        id: 'request-submitted',
        color: 'red',
        title: 'Criminal Request Error',
        message:
          'Error occurred while sending your requests. Please try again.',
      });
    },
  });
};
