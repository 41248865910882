import { Input, MantineSize } from '@mantine/core';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

interface IProps {
  size?: MantineSize;
  value: string;
  onChange: (value: string) => void;
}
export const PhoneNumberInput = ({ size, value, onChange }: IProps) => {
  return (
    <Input component="div" size={size}>
      <PhoneInput
        inputComponent={Input}
        value={value}
        onChange={(value) => onChange(value as string)}
        numberInputProps={{ variant: 'unstyled' }}
        defaultCountry="NG"
        international={false}
      />
    </Input>
  );
};
