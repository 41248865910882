import {
  Box,
  Stack,
  Text,
  Group,
  TextInput,
  Button,
  AccordionItem,
  ActionIcon,
  InputWrapper,
  Anchor,
  Select,
} from '@mantine/core';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import { Trash } from 'tabler-icons-react';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { CertificateIcon } from '../../../../../../../src/assets/icons';
import { AddMoreButton } from '../../../../../../components/primitives/button/download';
import { ICertificateFormProps } from '../interface';
import { FileUploader } from '../../../../../../components/controls/fileuploader/fileuploader';
import { MIME_TYPES } from '@iverifyng/utils';
import { UniversitiesDropdown } from './university';
import { CertificateTypeEnum } from '@prisma/client';
interface ICriminalCheckRequestForm {
  introText: string;
  form: UseFormReturnType<ICertificateFormProps>;
  renderIf?: boolean;
  onBack?: () => void;
}

const FormItem = ({
  form,
  index,
}: {
  index: number;
  form: UseFormReturnType<ICertificateFormProps>;
}) => {
  const formdata = form.values.data[index];
  return (
    <Group direction="column" spacing="md" grow>
      <TextInput
        label="Full Name"
        placeholder="Enter applicant name"
        required
        autoFocus
        {...form.getListInputProps('data', index, 'fullName')}
      />
      <Select
        label="Certificate Type"
        required
        data={Object.values(CertificateTypeEnum).map((value) => ({
          label: value,
          value,
        }))}
        {...form.getListInputProps('data', index, 'type')}
      />
      {formdata.type === 'UNIVERSITY' ? (
        <Group direction="column" spacing="md" grow>
          <TextInput
            label="Matric Number"
            placeholder="Enter applicant matric number"
            required
            {...form.getListInputProps('data', index, 'matricNumber')}
          />
          <InputWrapper
            label="University"
            required
            {...form.getListInputProps('data', index, 'university')}
          >
            <UniversitiesDropdown
              defaultValue={form.values.data[index]?.university?.name}
              onItemSelect={(item) => {
                const university = {
                  name: item.createNew === 'true' ? item.value! : item.name!,
                  domains: item.createNew === 'true' ? [] : item.domains,
                  alpha_two_code:
                    item.createNew === 'true' ? 'N/A' : item.alpha_two_code,
                  country: item.createNew === 'true' ? 'N/A' : item.country,
                };
                form.setListItem('data', index, {
                  ...form.values.data[index],
                  university,
                });
              }}
            />
          </InputWrapper>
        </Group>
      ) : null}

      <InputWrapper required label="Upload Certificate">
        <FileUploader
          file={form.values.data[index].certificateFile}
          accept={[MIME_TYPES.pdf]}
          instantUpload
          onSuccess={(url, file) =>
            form.setListItem('data', index, {
              ...form.values.data[index],
              certificateFile: file,
              certificateUrl: url,
            })
          }
        />
      </InputWrapper>
      <InputWrapper
        label={
          <Group position="apart" sx={() => ({ flex: 1 })}>
            <InputWrapper label="Upload Consent" children={null} />
            <Anchor
              href="https://storage.googleapis.com/iverify-public/forms/consent/certificate-consent-form.pdf"
              target="_blank"
            >
              <Text size="sm">Download consent form</Text>
            </Anchor>
          </Group>
        }
        styles={{ label: { display: 'flex' } }}
      >
        <FileUploader
          file={form.values.data[index].consentFile}
          accept={[MIME_TYPES.pdf]}
          instantUpload
          onSuccess={(url, file) =>
            form.setListItem('data', index, {
              ...form.values.data[index],
              consentFile: file,
              consentFormUrl: url,
            })
          }
        />
      </InputWrapper>
    </Group>
  );
};
export const CertificateVerificationForm = ({
  introText,
  form,
  renderIf,
  onBack,
}: ICriminalCheckRequestForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Certificate Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<CertificateIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Review</Button>
        </Group>
      }
    >
      <Stack spacing="xl" p="24px">
        <Box
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">{introText}</Text>
        </Box>

        {form.values.data.length > 1 ? (
          <Stack spacing="lg">
            {form.values.data.map((item, index) => (
              <FormListAccordion
                key={index}
                iconPosition="right"
                initialItem={0}
                multiple
              >
                <AccordionItem
                  label={
                    <FormListAccordionLabel
                      listCount={index + 1}
                      rightIcon={
                        <ActionIcon
                          onClick={() => {
                            form.removeListItem('data', index);
                          }}
                        >
                          <Trash color="gray" size={16} />
                        </ActionIcon>
                      }
                    />
                  }
                >
                  <FormItem form={form} index={index} />
                </AccordionItem>
              </FormListAccordion>
            ))}
          </Stack>
        ) : (
          <FormItem form={form} index={0} />
        )}
        <Group position="left">
          <AddMoreButton
            onClick={() => {
              form.addListItem('data', {
                fullName: '',
                type: 'UNIVERSITY',
                matricNumber: '',
              });
            }}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
