import { AccordionItem, Box, Button, Group, Stack } from '@mantine/core';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../components/primitives/price/servicecharge';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { PropertyIcon } from '../../../../../../../src/assets/icons';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import RequestDataLayout from '../../../../../../components/layouts/request/requestDataLayout';
import { IAddressFormProps } from '../interface';

interface IAddressConfirmationProps {
  data: IAddressFormProps;
  renderIf?: boolean;
  onBack?: () => void;
  isLoading: boolean;
  onSubmit: () => void;
  billed?: IServiceCharge;
}

export const AddressVerificationReview = ({
  data,
  billed,
  renderIf,
  isLoading,
  onBack,
  onSubmit,
}: IAddressConfirmationProps) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Address Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<PropertyIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Go back
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack
        justify="space-between"
        sx={() => ({ height: '100%' })}
        spacing={0}
      >
        <Box px="lg" my="md">
          <Stack spacing={'md'}>
            {data.data.map((item, index) => (
              <FormListAccordion
                iconPosition="right"
                initialItem={0}
                multiple
                key={index}
              >
                <AccordionItem
                  label={<FormListAccordionLabel listCount={index + 1} />}
                >
                  <Stack spacing="sm">
                    <RequestDataLayout label="Full Name" data={item.fullName} />
                    <RequestDataLayout
                      label="Phone Number"
                      data={item.phoneNo}
                    />
                    <RequestDataLayout
                      label="Company Name"
                      data={item.address}
                    />
                  </Stack>
                </AccordionItem>
              </FormListAccordion>
            ))}
          </Stack>
        </Box>

        <Box>
          {!!billed && (
            <ServiceCharge
              serviceCharge={{
                currency: billed?.currency,
                amount: +billed?.amount,
              }}
              icon
              withBackground
            />
          )}
        </Box>
      </Stack>
    </RequestLayout>
  );
};
