import {
  AddressVerificationStatusEnum,
  CertificateStatusEnum,
  CreditCheckStatus,
  CriminalRecordStatusEnum,
  EmployeeStatusEnum,
  GuarantorStatusEnum,
  PreviousWorkVerificationStatusEnum,
  PropertyStatusEnum,
  TenantStatusEnum,
} from '@prisma/client';

export const criminalCheckStatusDescription = {
  [CriminalRecordStatusEnum.DOCUMENT_REQUIRED]: 'Document Required',
  [CriminalRecordStatusEnum.PENDING]: 'Processing',
  [CriminalRecordStatusEnum.REJECTED]: 'Rejected',
  [CriminalRecordStatusEnum.UNVERIFIED]: 'Unverified',
  [CriminalRecordStatusEnum.VERIFIED]: 'Verified',
};

export const certificateStatusDescription = {
  [CertificateStatusEnum.INCOMPLETE]: 'Incomplete',
  [CertificateStatusEnum.PROCESSING]: 'Processing',
  [CertificateStatusEnum.REJECTED]: 'Rejected',
  [CertificateStatusEnum.UNVERIFIED]: 'Unverified',
  [CertificateStatusEnum.VERIFIED]: 'Verified',
};
export const tenantStatusDescription = {
  [TenantStatusEnum.INCOMPLETE]: 'Incomplete',
  [TenantStatusEnum.PROCESSING]: 'Processing',
  [TenantStatusEnum.REJECTED]: 'Rejected',
  [TenantStatusEnum.UNVERIFIED]: 'Unverified',
  [TenantStatusEnum.VERIFIED]: 'Verified',
};
export const guarantorStatusDescription = {
  [GuarantorStatusEnum.INCOMPLETE]: 'Incomplete',
  [GuarantorStatusEnum.PROCESSING]: 'Processing',
  [GuarantorStatusEnum.REJECTED]: 'Rejected',
  [GuarantorStatusEnum.UNVERIFIED]: 'Unverified',
  [GuarantorStatusEnum.VERIFIED]: 'Verified',
};

export const employeeStatusDescription = {
  [EmployeeStatusEnum.INCOMPLETE]: 'Incomplete',
  [EmployeeStatusEnum.PROCESSING]: 'Processing',
  [EmployeeStatusEnum.VERIFIED]: 'Verified',
  [EmployeeStatusEnum.UNVERIFIED]: 'Unverified',
  [EmployeeStatusEnum.REJECTED]: 'Rejected',
  [EmployeeStatusEnum.CANCELLED]: ' Cancelled',
};
export const propertyStatusDescription = {
  [PropertyStatusEnum.INCOMPLETE]: 'Incomplete',
  [PropertyStatusEnum.PROCESSING]: 'Processing',
  [PropertyStatusEnum.VERIFIED]: 'Verified',
  [PropertyStatusEnum.UNVERIFIED]: 'Unverified',
  [PropertyStatusEnum.REJECTED]: 'Rejected',
  [PropertyStatusEnum.CANCELLED]: ' Cancelled',
};

export const creditCheckStatusDescription = {
  [CreditCheckStatus.INCOMPLETE]: 'Incomplete',
  [CreditCheckStatus.PROCESSING]: 'Processing',
  [CreditCheckStatus.REJECTED]: 'Rejected',
  [CreditCheckStatus.UNVERIFIED]: 'Unverified',
  [CreditCheckStatus.VERIFIED]: 'Verified',
};
export const previousWorkStatusDescription = {
  [PreviousWorkVerificationStatusEnum.INCOMPLETE]: 'Incomplete',
  [PreviousWorkVerificationStatusEnum.PROCESSING]: 'Processing',
  [PreviousWorkVerificationStatusEnum.REJECTED]: 'Rejected',
  [PreviousWorkVerificationStatusEnum.UNVERIFIED]: 'Unverified',
  [PreviousWorkVerificationStatusEnum.VERIFIED]: 'Verified',
};

export const addressStatusDescription = {
  [AddressVerificationStatusEnum.INCOMPLETE]: 'Incomplete',
  [AddressVerificationStatusEnum.PROCESSING]: 'Processing',
  [AddressVerificationStatusEnum.REJECTED]: 'Rejected',
  [AddressVerificationStatusEnum.UNVERIFIED]: 'Unverified',
  [AddressVerificationStatusEnum.VERIFIED]: 'Verified',
};
