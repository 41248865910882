import { ServicesValidationSchema } from '@iverifyng/validators';
import { Button, Group, Textarea, TextInput } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { Prisma } from '@prisma/client';
import { AdminGetServiceDto } from '@iverifyng/dtos';
import { AdminUpdateServiceApi } from '../../../../services/apis/admin';

interface IAdminEditWorkspaceDetailModal {
  service: AdminGetServiceDto;
  closeModal?: () => void;
}

export const AdminEditServiceDetailModal = ({
  service,
  closeModal,
}: IAdminEditWorkspaceDetailModal) => {
  const queryClient = useQueryClient();

  const form = useForm<Prisma.ServiceUpdateInput>({
    schema: joiResolver(ServicesValidationSchema.UPDATE_SERVICE_SCHEMA),
    initialValues: {
      name: service?.name ?? '',
      description: service?.description ?? '',
      costAmount: service?.costAmount ?? '',
    },
  });

  const updateServiceDetail = useMutation(AdminUpdateServiceApi, {
    onSuccess: () => {
      showNotification({
        color: 'blue',
        message: 'Service Updated',
      });
      queryClient.invalidateQueries('adminServices');
      if (closeModal) closeModal();
    },
    onError: (error) => {
      showNotification({
        color: 'blue',
        message:
          (error as AxiosError<{ message: string }>).response?.data.message ??
          'Error occurred',
      });
    },
  });

  const handleSubmit = useCallback(
    (values: Prisma.ServiceUpdateInput) => {
      updateServiceDetail.mutate({
        id: service.id,
        data: {
          ...values,
        },
      });
    },

    [service.id, updateServiceDetail]
  );

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Group spacing={'md'} direction="column" grow>
        <TextInput
          label="Service Name"
          placeholder="Edit Service Name"
          {...form.getInputProps('name')}
        />

        <TextInput
          label="Service Amount"
          placeholder="Edit service amount"
          {...form.getInputProps('costAmount')}
        />
        <Textarea
          label="Description"
          rows={2}
          placeholder="Enter Service description"
          {...form.getInputProps('description')}
        />
        <Group position="right" mt={'md'}>
          <Button
            type="submit"
            disabled={updateServiceDetail.isLoading}
            loading={updateServiceDetail.isLoading}
          >
            Save Changes
          </Button>
        </Group>
      </Group>
    </form>
  );
};
