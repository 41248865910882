import { AdminPropertyUpdateReportDto, CreatePropertyVerificationDto, CreateRequestReponseDto, PropertyVerificationRecordDto, UpdatePropertyVerificationDto } from "@iverifyng/dtos";
import axiosInstance from "../axios";


interface ICreateNewPropertyVerificationDto
  extends CreatePropertyVerificationDto {
  workspaceId: string;
}
export const CreatePropertyVerificationApi = async ({
  workspaceId,
  ...data
}: ICreateNewPropertyVerificationDto): Promise<CreateRequestReponseDto> => {
  const url = `request/properties/${workspaceId}/new`;
  return await axiosInstance.post(url, data).then((response) => response.data);
};


export const AdminUpdatePropertyReportApi = async (
  data: AdminPropertyUpdateReportDto
): Promise<PropertyVerificationRecordDto> => {
  const url = `/admin/requests/property/reportUrl`;
  return await axiosInstance.patch(url, data).then((response) => response.data);
};

export const AdminRemovePropertyReportApi = async (params: {
  employeeId: string;
}): Promise<PropertyVerificationRecordDto> => {
  const url = `/admin/requests/property/${params.employeeId}/reportUrl`;
  return await axiosInstance.delete(url).then((response) => response.data);
};



export const AdminUpdatePropertyApi = async (
  data: UpdatePropertyVerificationDto
): Promise<UpdatePropertyVerificationDto> => {
  const url = '/admin/requests/property';
  return await axiosInstance.put(url, data).then((response) => response.data);
};
