import { Text, Group, TextInput, Textarea, Button, Stack } from '@mantine/core';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { PassportIcon } from '../../../../../../../assets/icons/index';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { ServiceSampleImageAccordion } from '../../../../../../../components/primitives/cards/services/serviceSampleImage';
import { IntlPassportFormData } from '../passport';

interface IntlPassportFormProps {
  form: UseFormReturnType<IntlPassportFormData>;
  renderIf?: boolean;
  onBack?: () => void;
}

export const GHPassportForm = ({
  form,
  onBack,
  renderIf,
}: IntlPassportFormProps) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="International Passport"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<PassportIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Stack justify="space-between" py="md" sx={() => ({ minHeight: '100%' })}>
        <Group direction="column" px="lg" grow spacing="md">
          <ServiceSampleImageAccordion
            title="Sample Intl Passport Slip"
            image="https://storage.googleapis.com/iverify-public/sample-ids/gh-passport.png"
          />
          <Text color="gray" size="sm">
            International Passport verification request provides you with the
            owner's first and last name, date of birth, etc.
          </Text>
          <TextInput
            label="International Passport Number"
            placeholder="Enter ID Number"
            {...form.getInputProps('idNumber')}
            required
          />
          <Textarea
            label="Description"
            {...form.getInputProps('description')}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
