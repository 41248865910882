import { AdminDashboardResponseDto } from '@iverifyng/dtos';
import { Group } from '@mantine/core';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';
import { IHeaderProps } from '../components/header';

interface IChartProps extends IHeaderProps {
  data: AdminDashboardResponseDto | Array<{ value: number; name: string }>;
  xKey: string;
  valKey: string;
  xLabel?: string;
  fillColor?: string;
}
export const AdminBarChart = ({
  data,
  xKey,
  valKey,
  xLabel,
  fillColor,
  ...props
}: IChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={600}
        height={300}
        data={data}
        barSize={28}
        barCategoryGap={20}
        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}
      >
        <XAxis
          dataKey={xKey}
          axisLine={false}
          tickLine={false}
          interval={0}
          height={80}
          tick={({ x, y, payload }) => (
            <Text
              width={90}
              x={x}
              y={y + 15}
              verticalAnchor="start"
              textAnchor="middle"
            >
              {payload.value}
            </Text>
          )}
        />
        <YAxis axisLine={false} tickLine={false} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend
          align="center"
          content={
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Group position="center" align="center" spacing="xs">
                <div
                  style={{
                    backgroundColor: fillColor ?? '#4D80C6',
                    minWidth: 18,
                    minHeight: 18,
                  }}
                />
                <Text
                  scaleToFit
                  verticalAnchor="middle"
                  textAnchor="middle"
                  transform="capitalize"
                >
                  {props.label}
                </Text>
              </Group>
            </div>
          }
        />
        <Bar dataKey={valKey} radius={0}>
          {data.map((entry, index) => (
            <Cell key={index} fill={fillColor ?? '#4D80C6'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
