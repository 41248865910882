/* eslint-disable no-extra-boolean-cast */
import {
  Box,
  Button,
  Center,
  Input,
  Loader,
  Menu,
  Stack,
  Text,
  createStyles,
} from '@mantine/core';
import EmptyState from '../../../../components/primitives/emptyState/emptyState';
import React, { useCallback, useMemo, useState } from 'react';
import { AgentZeroIcon } from '../../../../assets/icons';
import {
  useAdminRequestDetails,
  useAvailableAgents,
  useRequestAssignedAgents,
} from '../../../../hooks/admin';
import {
  useDisclosure,
  useDebouncedValue,
  useClickOutside,
} from '@mantine/hooks';
import { AgentCard } from '../../../../components/primitives/cards/admin/agentCard/agentCard';
import { AgentLoader } from '../../../../components/primitives/skeletons/agent';
import { User } from '@prisma/client';
import { CircleCheck, Plus } from 'tabler-icons-react';
import { GetAgentResponseDto } from '@iverifyng/dtos';
import { useMutation, useQueryClient } from 'react-query';
import {
  AssignAgentToRequest,
  UnAssignAgentFromRequest,
} from '../../../../services/apis/admin';
import { showNotification, updateNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useModals } from '@mantine/modals';
import { AssignAgentModal } from './assignAgentModal';

const useMenuStyle = createStyles({
  body: {
    width: 'calc(33.33vw - 102px)',
  },
});

interface IAgentsProps {
  requestId: string;
}
export const AgentsView = ({ requestId }: IAgentsProps) => {
  const { data: request } = useAdminRequestDetails(requestId);
  const queryClient = useQueryClient();
  const modals = useModals();
  const {
    data: agents,
    isLoading: assignedAgentsLoading,
    isFetching: fetchingAssignedAgents,
  } = useRequestAssignedAgents(requestId);
  const assignAgent = useMutation(AssignAgentToRequest);
  const unassignAgent = useMutation(UnAssignAgentFromRequest);
  const handleAgentAssign = useCallback(
    (agentId: string, comment: string) => {
      showNotification({
        id: 'assign-agent',
        title: ' Assigning Agent',
        message: 'assigning agent',
        loading: true,
        disallowClose: true,
      });
      assignAgent.mutate(
        {
          data: [{ userId: agentId, comment }],
          requestId,
        },
        {
          onSuccess: () => {
            updateNotification({
              id: 'assign-agent',
              title: 'Agent Assigned',
              message: 'new agent assigned',
              icon: <CircleCheck />,
            });
            queryClient.invalidateQueries(['requestAssignedAgents', requestId]);
          },
          onError: (error) => {
            updateNotification({
              id: 'assign-agent',
              title: 'Error',
              message:
                (error as AxiosError<{ message?: string }>)?.response?.data
                  .message ?? 'Error occurred with assigning agent',
              icon: <CircleCheck />,
            });
          },
        }
      );
    },
    [assignAgent, queryClient, requestId]
  );
  const handleUnAgentAssign = useCallback(
    (agentId: string) => {
      showNotification({
        id: 'unassign-agent',
        message: 'Processing request...',
        loading: true,
        disallowClose: true,
      });
      unassignAgent.mutate(
        {
          users: [agentId],
          requestId,
        },
        {
          onSuccess: () => {
            updateNotification({
              id: 'unassign-agent',
              message: 'Agent unassigned!',
              icon: <CircleCheck />,
            });
            queryClient.invalidateQueries(['requestAssignedAgents', requestId]);
          },
        }
      );
    },
    [unassignAgent, queryClient, requestId]
  );

  const [value, setValue] = useState('');
  const [opened, handlers] = useDisclosure(false);
  const ref = useClickOutside(() => handlers.close);
  const { data: allAgents, isLoading, isFetching } = useAvailableAgents();
  const [debounced] = useDebouncedValue(value, 800);
  const { classes } = useMenuStyle();
  const isIdentity = !!request && request.service.category === 'IDENTITY';
  const filteredAgents = useMemo<User[] | undefined>(() => {
    const filtered = allAgents?.filter(
      (user) =>
        user.firstName?.toLowerCase().startsWith(debounced) ||
        user.lastName?.toLowerCase().startsWith(debounced)
    );
    return !!debounced ? filtered : allAgents;
  }, [allAgents, debounced]);
  async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  return (
    <Stack px="xl">
      <Text size="sm">
        Field agents are responsible for working on requests. Use this section
        to assign service requests to agents.
      </Text>
      <Menu
        ref={ref}
        placement="start"
        opened={opened}
        onOpen={handlers.open}
        onClose={handlers.close}
        onFocusCapture={handlers.open}
        onBlurCapture={handlers.close}
        classNames={classes}
        control={
          <Input
            placeholder="Search agent"
            disabled={isIdentity}
            size="md"
            onChange={handleChange}
            rightSection={isLoading || isFetching ? <Loader size="sm" /> : null}
          />
        }
      >
        {isFetching || isLoading ? (
          <AgentLoader limit={2} />
        ) : !!filteredAgents && !isFetching ? (
          filteredAgents?.map((user) => {
            const agentIsAssigned = agents?.some(
              (agent) => agent.userId === user.id
            );
            return (
              <Menu.Item
                key={user.id}
                disabled={agentIsAssigned}
                rightSection={
                  <Button
                    leftIcon={<Plus size={20} />}
                    variant="subtle"
                    disabled={assignAgent.isLoading || agentIsAssigned}
                    onClick={() =>
                      modals.openModal({
                        id: 'assign-agent',
                        title: `Assign ${user.firstName?.toUpperCase()} to this request`,
                        children: (
                          <AssignAgentModal
                            submitting={assignAgent.isLoading}
                            disableSubmit={assignAgent.isLoading}
                            onConfirm={(comment) => {
                              modals.closeModal('assign-agent');
                              handleAgentAssign(user.id, comment);
                            }}
                          />
                        ),
                      })
                    }
                  >
                    Assign
                  </Button>
                }
              >
                <Box>
                  <Text size="sm" weight={500}>
                    {`${user.firstName}  ${user.lastName}`}
                  </Text>
                  <Text
                    size="sm"
                    sx={(theme) => ({
                      color: agentIsAssigned
                        ? theme.colors.gray[3]
                        : theme.colors.gray[7],
                    })}
                  >
                    {user.email}
                  </Text>
                </Box>
              </Menu.Item>
            );
          })
        ) : (
          <Text
            px="xs"
            sx={(theme) => ({
              color: theme.colors.gray[6],
            })}
          >
            {`No results for: ${debounced}, try again`}
          </Text>
        )}
      </Menu>

      {isIdentity ? (
        <Center pt="xl">
          <EmptyState
            icon={<AgentZeroIcon />}
            subTitle="Agent functionality not available for this service type."
          />
        </Center>
      ) : (
        <>
          {(!agents && !assignedAgentsLoading) ||
            (agents && agents?.length <= 0 && (
              <Center pt="xl">
                <EmptyState
                  icon={<AgentZeroIcon />}
                  subTitle="No assigned agent yet"
                />
              </Center>
            ))}
          {(assignedAgentsLoading && !agents) ||
          (fetchingAssignedAgents && !agents) ? (
            <AgentLoader />
          ) : (
            agents &&
            !assignedAgentsLoading &&
            agents.map((agent: GetAgentResponseDto) => (
              <AgentCard
                key={agent.id}
                user={agent.user}
                comment={agent?.comment}
                renderRightSection={(agentId) => (
                  <Button
                    variant="subtle"
                    color="red"
                    disabled={unassignAgent.isLoading}
                    onClick={() => handleUnAgentAssign(agentId)}
                  >
                    Remove
                  </Button>
                )}
              />
            ))
          )}
        </>
      )}
    </Stack>
  );
};
