import {
  Menu,
  Group,
  Avatar,
  Text,
  Anchor,
  Divider,
  createStyles,
  Loader,
} from '@mantine/core';
import {
  ChevronDown,
  ChevronRight,
  Settings,
  User,
  Logout,
  Check,
  SwitchHorizontal,
} from 'tabler-icons-react';
import { Link, useNavigate } from '@tanstack/react-location';
import { signOut } from 'firebase/auth';
import { getFirebaseAuth } from '../../../services/firebase';
//import { useAuthState } from 'react-firebase-hooks/auth';
import { useUserProfile } from '../../../services/queries/users';
import { getNameInitials, workspaceRoleAccessCheck } from '@iverifyng/utils';
import { useMemo } from 'react';
import { useCurrentWorkspace } from '../../../hooks/workspace';
import { showNotification } from '@mantine/notifications';
import { useModals } from '@mantine/modals';
import { CreateWorkspaceForm } from '../../modals/workspace/createWorkspace';
import { SwitchWorkspaceModal } from '../modals/SwitchWorkspaceModal';

const useStyles = createStyles((theme) => ({
  userMenu: {
    padding: '4px 8px',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.blue[7],
    },
  },
}));

const UserOptions = () => {
  const modals = useModals();
  const { data: user, isLoading, fullName } = useUserProfile();
  //const [user, loading] = useAuthState(getFirebaseAuth);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { workspaceId, setWorkspaceId, workspaceDetails, workspaceUserRole } =
    useCurrentWorkspace();

  const canViewWorkspace = workspaceRoleAccessCheck(
    workspaceUserRole!,
    'canViewWorkspace'
  );

  const userAccounts = useMemo(() => {
    if (isLoading) return null;
    return (user?.workspaces || []).map((item) => {
      return (
        <Menu.Item
          key={item.id}
          rightSection={
            workspaceId === item.workspaceId ? <Check size={16} /> : null
          }
          onClick={() => {
            setWorkspaceId(item.workspaceId);
            showNotification({
              message: 'Workspace switched. Refreshing data...',
            });
            setTimeout(() => {
              navigate({ to: '/dashboard' });
            }, 2000);
          }}
        >
          {item.workspace.name}
        </Menu.Item>
      );
    });
  }, [isLoading, navigate, setWorkspaceId, user?.workspaces, workspaceId]);

  if (isLoading) return <Loader variant="dots" color="white" />;
  return (
    <Menu
      closeOnItemClick={true}
      placement="center"
      size={215}
      control={
        <Group position="center" className={classes.userMenu}>
          <Avatar src="" size="sm" alt={fullName!} radius="xl" color={'blue'}>
            {getNameInitials({ name: fullName!, maxInitials: 2 })}
          </Avatar>
          <div style={{ flex: 1 }}>
            <Text size="xs" color={'white'} weight="bolder">
              {fullName}
            </Text>

            <Text color="lime" size="xs">
              {workspaceDetails?.name}
            </Text>
          </div>
          <ChevronDown size={16} color={'white'} />
        </Group>
      }
    >
      <Menu.Item icon={<User size={16} />}>
        <Anchor
          size="sm"
          variant="text"
          component={Link}
          to={'/settings/profile'}
        >
          Profile
        </Anchor>
      </Menu.Item>
      {canViewWorkspace ? (
        <Menu.Item icon={<Settings size={16} />}>
          <Anchor
            size="sm"
            variant="text"
            component={Link}
            to={'/settings/workspace/details'}
          >
            Workspace settings
          </Anchor>
        </Menu.Item>
      ) : null}

      {userAccounts && userAccounts.length > 0 ? (
        <Menu.Item
          onClick={() =>
            modals.openModal({
              id: 'switch-user-workspace',
              title: 'Switch Workspace',
              size: 'md',
              children: (
                <SwitchWorkspaceModal
                  workspaces={user?.workspaces}
                  isLoading={isLoading}
                  onWorkspaceChange={(workspaceId) => {
                    if (workspaceId === 'add-new') {
                      modals.openModal({
                        id: 'create-workspace',
                        title: 'Create New Workspace',
                        children: (
                          <CreateWorkspaceForm
                            closeModal={() =>
                              modals.closeModal('create-workspace')
                            }
                          />
                        ),
                      });
                    } else {
                      setWorkspaceId(workspaceId);
                      modals.closeModal('switch-user-workspace');
                      showNotification({
                        message: 'Workspace switched. Refreshing data...',
                      });
                      setTimeout(() => {
                        navigate({ to: '/dashboard' });
                      }, 500);
                    }
                  }}
                />
              ),
            })
          }
        >
          <Group position="apart">
            <SwitchHorizontal size={16} />
            <Text size="sm">Switch Workspace</Text>
            <ChevronRight size={16} />
          </Group>
        </Menu.Item>
      ) : null}

      <Divider />
      <Menu.Item
        icon={<Logout size={16} />}
        onClick={() => signOut(getFirebaseAuth)}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
};
export default UserOptions;
