/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useLocation } from '@tanstack/react-location';
import * as React from 'react';

import { joiResolver, useForm } from '@mantine/form';
import {
  ZANationalIDForm,
  ZANationalIDFormData,
} from './containers/nationalIdForm';
import { ZANationalIDConfirmation } from './containers/nationalIdConfirmation';
import { IdentityValidatorSchema } from '@iverifyng/validators';
import {
  useCreateIdentityVerification,
  useServiceCharge,
} from '../../../../../../hooks/request';
import { useCurrentWorkspace } from '../../../../../../hooks/workspace';
import { useCurrentCountry } from '../../../../../../hooks/country';
import { ServiceCountry } from '@prisma/client';
import { useConsentConfirmation } from '../../../../../../hooks/consent';
import { debounce } from 'lodash';

type NationalIDPage = 'form' | 'verify';
export const ZANationalID = () => {
  const { workspaceId } = useCurrentWorkspace();
  const [page, setPage] = React.useState<NationalIDPage>('form');
  const [withPhoto, setWithPhoto] = React.useState(true);
  const { history } = useLocation();
  const { country } = useCurrentCountry();

  const form = useForm<ZANationalIDFormData>({
    initialValues: {
      idNumber: '',
      description: '',
    },
    schema: joiResolver(IdentityValidatorSchema.ZA_NATIONAL_ID),
  });

  const { showConsent } = useConsentConfirmation();
  const createIdentity = useCreateIdentityVerification();
  const { data: serviceCharge } = useServiceCharge({
    code: withPhoto ? 'NATIONAL_ID' : 'NATIONAL_ID_NO_PHOTO',
    country: country! as ServiceCountry,
  });

  const onSubmit = React.useCallback(() => {
    showConsent({
      onConfirm: () => {
        const data = form.values;
        createIdentity.mutate({
          code: withPhoto ? 'NATIONAL_ID' : 'NATIONAL_ID_NO_PHOTO',
          country: country! as ServiceCountry,
          workspaceId: workspaceId!,
          params: {
            idNumber: data.idNumber,
            description: data.description,
          },
        });
      },
    });
  }, [
    country,
    createIdentity,
    form.values,
    showConsent,
    withPhoto,
    workspaceId,
  ]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  const onVerify = React.useCallback(() => {
    setPage('verify');
  }, []);

  const onWithPhotoCheck = React.useCallback((value: boolean) => {
    setWithPhoto(value);
  }, []);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(onVerify)}>
      <ZANationalIDForm
        {...{ form }}
        {...{ withPhoto }}
        onBack={() => history.back()}
        renderIf={page === 'form'}
        onPhotoCheck={onWithPhotoCheck}
      />
      <ZANationalIDConfirmation
        {...{ form }}
        onSubmit={handleDoubleClick}
        data={form.values}
        loading={createIdentity.isLoading}
        billed={serviceCharge}
        onEdit={() => setPage('form')}
        onBack={() => setPage('form')}
        renderIf={page === 'verify'}
      />
    </form>
  );
};
