import React from 'react';
import { Avatar, Group, Skeleton, Text, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IRequestCardProps } from '../../request/requestCard/requestCard';
import { User } from '@prisma/client';
import { getNameInitials } from '@iverifyng/utils';
import { useStatusColor } from '../../../../../hooks/useStatusColor';
import { useUserCardStyle } from './userCard.style';
dayjs.extend(advancedFormat);

interface IUserCardProps
  extends Omit<IRequestCardProps, 'request' | 'renderIcon'> {
  user: User;
  imageUrl?: string;
}

export const UserCard = ({
  active,
  user,
  imageUrl,
  skeleton = false,
  onClick,
}: IUserCardProps) => {
  const statusColor = useStatusColor(user.deactivate ? 'REJECTED' : 'SUCCESS');
  const { classes } = useUserCardStyle({
    active,
    skeleton,
  });

  return (
    <Group
      position="apart"
      px={32}
      py="xs"
      className={classes.base}
      onClick={() => {
        if (onClick) onClick(user.id);
      }}
      sx={() => ({ flex: 1 })}
    >
      <Group sx={() => ({ flex: 1 })}>
        <Skeleton
          circle={true}
          visible={skeleton}
          p={0}
          m={0}
          height={40}
          width={40}
        >
          {!!imageUrl ||
            (user.firstName && (
              <Avatar
                src={imageUrl}
                alt={user.firstName!}
                radius="xl"
                color="indigo"
              >
                {getNameInitials({
                  name: `${user.firstName} ${user.lastName}`,
                  maxInitials: 2,
                })}
              </Avatar>
            ))}
        </Skeleton>
        <Skeleton
          visible={skeleton}
          sx={() => ({ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' })}
        >
          <Stack spacing={skeleton ? 'xs' : 0} sx={() => ({ flex: 1 })}>
            <Text size="sm" weight={500} lineClamp={1}>
              {`${user.firstName}  ${user.lastName}`}
            </Text>
            <Text
              size="sm"
              lineClamp={1}
              sx={(theme) => ({
                display: 'block',
                color: theme.colors.gray[7],
              })}
            >
              {user.email}
            </Text>
          </Stack>
        </Skeleton>
      </Group>

      <Stack spacing={skeleton ? 'xs' : 0}>
        <Skeleton visible={skeleton}>
          <Text size="xs" sx={(theme) => ({ color: theme.colors.gray[6] })}>
            {dayjs(user.createdAt).format('Do MMM, YYYY')}
          </Text>
        </Skeleton>
        <Skeleton visible={skeleton} sx={{ textAlign: 'end' }}>
          <Text component="span" size="xs" color={statusColor}>
            {user.deactivate ? 'Inactive' : 'Active'}
          </Text>
        </Skeleton>
      </Stack>
    </Group>
  );
};
