import { CriminalRecordCheckSchema } from '@iverifyng/validators';
import { formList, joiResolver, useForm } from '@mantine/form';
import { useLocation } from '@tanstack/react-location';
import { useCurrentCountry } from '../../../../../../hooks/country';
import { useCallback, useState, useEffect } from 'react';
import {
  IFormListData,
  ValidateCriminalCheckForm,
} from './container/validateCriminalCheckForm';
import { ValidateCriminalCheckConfirmation } from './container/validateCriminalCheckConfirmation';
import {
  useCreateNewValidateCriminalRecordCheck,
  useServiceCharge,
} from '../../../../../../hooks/request';
import { ServiceCountry } from '@prisma/client';
import { useCurrentWorkspace } from '../../../../../../hooks/workspace';
import { debounce } from 'lodash';

type ValidateCriminalCheckPage = 'form' | 'verify';

export const ValidateCriminalCheck = () => {
  const [page, setPage] = useState<ValidateCriminalCheckPage>('form');
  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();

  const form = useForm({
    schema: joiResolver(CriminalRecordCheckSchema.NEW_VALIDATE_REQUEST),
    initialValues: {
      country: country!,
      description: '',
      data: formList<IFormListData>([
        {
          subject_name: '',
          registry_report_url: '',
          gender: undefined,
          file: null,
        },
      ]),
    },
  });

  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'VALIDATE_CRIMINAL_RECORD',
    country: country! as ServiceCountry,
    quantity: form?.values?.data?.length ?? 1,
  });

  const handleVerify = useCallback(() => {
    setPage('verify');
  }, []);

  const postNewValidateCriminalCheck = useCreateNewValidateCriminalRecordCheck(
    workspaceId!
  );

  const handleSubmit = useCallback(() => {
    postNewValidateCriminalCheck.mutate({
      workspaceId: workspaceId!,
      country: country as ServiceCountry,
      data: form.values.data.map((data) => ({
        subject_name: data.subject_name,
        registry_report_url: data.registry_report_url,
        gender: data.gender,
      })),
    });
  }, [form.values.data, postNewValidateCriminalCheck, workspaceId, country]);

  const handleDoubleClick = debounce(handleSubmit, 300, { leading: true });

  useEffect(() => {
    if (page === 'verify' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(handleVerify)}>
      <ValidateCriminalCheckForm
        form={form}
        renderIf={page === 'form'}
        onBack={() => history.back()}
        introText="Verify an existing criminal record certificate to be sure it was issued from the registry."
      />
      <ValidateCriminalCheckConfirmation
        {...{ form }}
        renderIf={page === 'verify'}
        data={form.values}
        onSubmit={handleDoubleClick}
        onBack={() => setPage('form')}
        isLoading={postNewValidateCriminalCheck.isLoading}
        billed={serviceCharge}
      />
    </form>
  );
};
