/* eslint-disable no-extra-boolean-cast */
import axios, { AxiosRequestConfig } from 'axios';
import CustomEventEmitter from '../events/event-emitter';
import {
  IGenerateUploadUrlsRes,
  IStorageEmitterError,
  IStoragePartDto,
  IStorageUploadResult,
} from './interface';
import FileUploadService from './service';
import { promisify } from '@iverifyng/utils';

const fileUploadService = new FileUploadService();
export default class GoogleStorageUploader extends CustomEventEmitter {
  done: boolean;
  file: File;
  chunkSize: number;
  chunkIndex: number;
  currentChunkStartByte: number;
  currentEndStartByte: number;
  storageParts: IStoragePartDto[];
  storageUrlsResult: IGenerateUploadUrlsRes | undefined;
  abortController: AbortController;

  constructor(file: File, abortController: AbortController) {
    super();
    this.file = file;
    this.abortController = abortController;
    this.done = false;
    this.chunkSize = 5 * 1024 * 1024;
    this.chunkIndex = 0;
    this.currentChunkStartByte = 0;
    this.currentEndStartByte = 0;
    this.storageUrlsResult = undefined;
    this.storageParts = [];
  }

  private getPercentage(event: ProgressEvent, file: File): number {
    if (file.size <= this.chunkSize) {
      const percentage = Math.ceil((event.loaded / event.total) * 100);
      return percentage;
    }
    return 0;
  }

  private blobToFile(theBlob: Blob, mainFile: File) {
    //create new file from blob
    return new File([theBlob], mainFile.name, {
      lastModified: mainFile.lastModified,
    });
  }

  public async generateUrls() {
    const [err, result] = await promisify<Error, IGenerateUploadUrlsRes>(
      fileUploadService.generateUploadUrl({
        filename: this.file.name,
      })
    );

    if (err) {
      this.emit<IStorageEmitterError>('error', {
        done: false,
        response: err,
        code: 'FAILED',
      });
      return Promise.reject(err);
    }

    this.storageUrlsResult = result;
    return Promise.resolve(result);
  }

  private getChunkEnd(start: number): number {
    if (start + this.chunkSize > this.file.size) {
      return this.file.size;
    } else {
      return start + this.chunkSize;
    }
  }

  public async uploadFile(
    axiosParams?: AxiosRequestConfig
  ): Promise<IStorageUploadResult> {
    //check that we already have s3 generated urls
    if (!this.storageUrlsResult || !this.storageUrlsResult.url) {
      const nErr = new Error('Google storage media URLs were not found');
      this.emit<IStorageEmitterError>('error', {
        done: false,
        response: nErr,
        code: 'FAILED',
      });
      return { hasError: true, error: nErr };
    }

    //const mappedUrls = Object.values(this.storageUrlsResult.url);
    const nextStorageurl = this.storageUrlsResult.url;

    // calculate the end of the byte range
    const end = this.getChunkEnd(this.currentChunkStartByte);
    const next_slice = this.currentChunkStartByte + this.chunkSize;
    const totalChunks = Math.ceil(this.file.size / this.chunkSize);
    const isMultipleChunks = totalChunks > 1;
    const chunk: Blob = this.file.slice(this.currentChunkStartByte, end);
    // if (isMultipleChunks) {
    //   chunk = this.file.slice(this.currentChunkStartByte, end); //google requires a cushion of 1
    // }
    const newChunkFile = this.blobToFile(chunk, this.file);

    //create the promise
    const requestHeaders = isMultipleChunks
      ? {
          'Content-Type': 'application/octet-stream',
          'Content-Range': `bytes ${this.currentChunkStartByte}-${end - 1}/${
            this.file.size
          }`,
        }
      : undefined;
    const promise = new Promise((resolve, reject) => {
      (async () => {
        //delete axios.defaults.headers.put['Content-Type'];
        const [error, apiResult] = await promisify(
          axios.put(nextStorageurl, newChunkFile, {
            ...axiosParams,
            headers: requestHeaders,
            signal: this.abortController.signal,
            onUploadProgress: (event: ProgressEvent) => {
              console.log({ event });
              const percentComplete = this.getPercentage(event, this.file);
              if (!!percentComplete) this.emit('progress', percentComplete);
            },
          })
        );

        let responseHeaders = apiResult?.headers;
        if (error) {
          if (axios.isAxiosError(error)) {
            //google multiple chunks returns a 308
            if (error.response?.status !== 308) {
              reject(error);
            } else {
              responseHeaders = error.response.headers;
            }
          }
        }

        if (next_slice < this.file.size) {
          const size_done = this.currentChunkStartByte + this.chunkSize;
          const percent_done = Math.floor((size_done / this.file.size) * 100);
          this.emit('progress', percent_done);

          this.chunkIndex =
            this.chunkIndex < totalChunks ? this.chunkIndex + 1 : totalChunks;

          //this.currentChunkStartByte = next_slice;

          const range = responseHeaders?.['range'];
          if (range) {
            this.currentChunkStartByte = +range.substring(
              range.indexOf('-') + 1,
              range.length
            );
          }

          this.done = false;
          resolve({ done: this.done, apiResult });
        } else {
          this.emit('progress', 100); //100% complete
          this.done = true;
          resolve({ done: this.done, apiResult: apiResult?.data });
        }
      })();
    });

    //process the promise
    const [err, result] = await promisify(promise);
    if (err) {
      if (axios.isAxiosError(err)) {
        //google multiple chunks returns a 308
        if (err.response?.status === 308) {
          return this.uploadFile(axiosParams);
        }
      }

      if (axios.isCancel(err)) {
        console.log('Axios Cancelled Request');
      }
      this.emit<IStorageEmitterError>('error', {
        done: false,
        response: err,
        code: 'FAILED',
      });
      return { hasError: true, error: err };
    }

    //process next chunk
    if (!result?.done) return await this.uploadFile(axiosParams);

    //chunk is finally complete
    //const mediaLink = result.apiResult.mediaLink;
    this.emit('done', {
      done: true,
      code: 'SUCCESS',
      response: this.storageUrlsResult,
      mediaLink: this.storageUrlsResult.fileUrl,
    });
    return {
      done: true,
      hasError: false,
      mediaLink: this.storageUrlsResult.fileUrl,
    };
  }
}
