import { Accordion, AccordionProps, createStyles } from '@mantine/core';
import { ReactNode } from 'react';

interface IFormAccordion {
  title: string;
  content: ReactNode;
}

const useStyles = createStyles((theme, _params, getRef) => ({
  icon: { ref: getRef('icon') },
  control: {
    ref: getRef('control'),

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  item: {
    transition: `box-shadow 150ms ${theme.transitionTimingFunction}`,
    border: '1px solid transparent',
    borderRadius: theme.radius.sm,
  },
  itemOpened: {
    [`& .${getRef('control')}`]: {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

function FormAccordionStyled(props: AccordionProps) {
  const { classes } = useStyles();
  return <Accordion iconPosition="right" classNames={classes} {...props} />;
}

const FormAccordion = ({ title, content }: IFormAccordion) => {
  return (
    <FormAccordionStyled initialItem={0} multiple>
      <Accordion.Item label={title}>{content}</Accordion.Item>
    </FormAccordionStyled>
  );
};
export default FormAccordion;
