import {
  GetAllMyTeamRequestsDto,
  GetAllMyTeamRequestsResponseDto,
  GetMyRequestsQueryDto,
  GetMyRequestsResponseDto,
  GetRequestResponseDto,
  GetServiceChargeQueryDto,
  GetServiceChargeResponseDto,
  GetUserDashboardRequestsAggregateResponseDto,
  IdentityVerificationDto,
  CreateRequestReponseDto,
  NewCriminalRecordCheckDto,
  NewValidateCriminalRecordDto,
  UpdateCriminalRecordDto,
} from '@iverifyng/dtos';
import { Service } from '@prisma/client';
import qs from 'query-string';
import axiosInstance from '../axios';

//get user's requests
export const GetMyRequestsApi = async ({
  workspaceId,
  ...query
}: GetMyRequestsQueryDto): Promise<GetMyRequestsResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/requests/${workspaceId}/mine/all`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

// post new identity verification
export const PostIdentityVerificationRequest = (
  payload: IdentityVerificationDto
): Promise<CreateRequestReponseDto> => {
  return axiosInstance
    .post('/requests/new/id-verification', payload)
    .then((response) => response.data);
};

//get user's team requests
export const GetMyTeamRequestsApi = async ({
  workspaceId,
  ...query
}: GetAllMyTeamRequestsDto): Promise<GetAllMyTeamRequestsResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/requests/${workspaceId}/mine/team`,
    query: { ...query },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetServiceCharge = async (
  query: GetServiceChargeQueryDto
): Promise<GetServiceChargeResponseDto> => {
  const url = '/requests/new/getCost';
  return await axiosInstance.post(url, query).then((response) => response.data);
};

export const GetRequestByIdApi = async (
  requestId: string
): Promise<GetRequestResponseDto> => {
  const url = `/requests/details/${requestId}`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetResponseDetailByIdentiyId = async (
  identityId: string
): Promise<unknown> => {
  const url = `/requests/identity/responseDetails/${identityId}`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetUncompletedRequestsApi = async (
  workspaceId: string
): Promise<GetAllMyTeamRequestsResponseDto> => {
  const url = `/requests/userDashboard/workspace/${workspaceId}/all`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetUserDashboardRequestsAggregate = async (
  workspaceId: string
): Promise<GetUserDashboardRequestsAggregateResponseDto> => {
  const url = `/requests/userDashboard/workspace/${workspaceId}/stats/aggregate`;
  return await axiosInstance.get(url).then((response) => response.data);
};

interface IPostNewCriminalCheckRequestDto extends NewCriminalRecordCheckDto {
  workspaceId: string;
}
export const PostNewCriminalCheckRequest =  async ({
  workspaceId,
  ...data
}: IPostNewCriminalCheckRequestDto): Promise<CreateRequestReponseDto> => {
  return axiosInstance
    .post(`/requests/${workspaceId}/new/criminalRecordCheck`, data)
    .then((response) => response.data);
};

interface IPostNewValideteCriminalCheckRequestDto
  extends NewValidateCriminalRecordDto {
  workspaceId: string;
}
export const PostNewValidateCriminalCheckRequest = async ({
  workspaceId,
  ...data
}: IPostNewValideteCriminalCheckRequestDto) => {
  return axiosInstance
    .post(`/requests/${workspaceId}/new/validateCriminalRecord`, data)
    .then((response) => response.data);
};

interface IUpdateNewCriminalCheckRequest extends UpdateCriminalRecordDto {
  workspaceId: string;
  requestId: string;
}

export const UpdateNewCriminalCheckRequest = async ({
  workspaceId,
  requestId,
  ...data
}: IUpdateNewCriminalCheckRequest) => {
  return axiosInstance
    .put(`/requests/criminalcheck/${requestId}/workspace/${workspaceId}`, data)
    .then((response) => response.data);
};

// get all services
export const GetServices = async (): Promise<Service[]> => {
  return await axiosInstance.get('/services').then((response) => response.data);
};
