import * as fileSize from 'filesize';
export const getFileObjFromList = async (files: FileList | null) => {
  // Convert the FileList into an array and iterate
  const fileslist = Array.from(files || []).map(async (file) => {
    const url = window.URL.createObjectURL(file);
    return { file, url };
  });

  // At this point you'll have an array of results
  const result = await Promise.all(fileslist);
  return result;
};

export const triggerURLDownload = (url: string, fileName = '') => {
  if (!url) return;

  const a = document.createElement('a');
  a.download = fileName;
  a.href = url;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
};

//excludes certain file types from a list of files
//types can be: image, video, application etc
export const filterFilesByType = (files: File[], types: string[]) => {
  const checker = (file: File) =>
    types.some((element) => file.type.includes(element));
  return files.filter(checker);
};

export const formatSizeToUnits = (size: number): string => {
  const sizeFn = fileSize.partial({ base: 10, standard: 'jedec' });
  return sizeFn(size).toUpperCase();
};

export const fileExtension = (
  filename: string,
  options?: {
    preserveCase?: boolean;
  }
) => {
  if (!filename) return '';
  const ext = (/[^./\\]*$/.exec(filename) || [''])[0];
  return options?.preserveCase ? ext : ext.toUpperCase();
};

export const MIME_TYPES = {
  png: 'image/png',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  webp: 'image/webp',
  mp4: 'video/mp4',
  zip: 'application/zip',
  csv: 'text/csv',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  exe: 'application/vnd.microsoft.portable-executable',
};

export const IMAGE_MIME_TYPE =
  'image/png, image/gif, image/jpeg, image/svg+xml, image/webp';
export const PDF_MIME_TYPE = 'application/pdf';
export const MS_WORD_MIME_TYPE =
  'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const MS_EXCEL_MIME_TYPE =
  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const MS_POWERPOINT_MIME_TYPE =
  'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';
