import { Group, Stack, Title, Text, LoadingOverlay } from '@mantine/core';
import { string } from 'joi';
import { useUserProfile } from '../../services/queries/users';

interface IIntroText {
  text: string;
  heading: string;
}

const IntroText = ({text, heading}: IIntroText) => {
  const { isLoading, error, fullName } = useUserProfile();

  if (isLoading) return <LoadingOverlay visible />;
  if (error) return <div>Error occured</div>;
  return (
    <Group direction="column" pb="xl">
      <Stack spacing={4}>
        <Title order={2}>{`${heading} ${fullName} 🎉`}</Title>
        <Text>
          {text}
        </Text>
      </Stack>
    </Group>
  );
};

export default IntroText;

//You can easily verify documents and customer's data with iVerify.
