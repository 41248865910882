import { CreateAddressAgentReportDto } from '@iverifyng/dtos';
import { Stack, Textarea, AccordionItem, Group } from '@mantine/core';
import { Control, Controller } from 'react-hook-form';
import {
  ReportAccordionLabel,
  ReportFromAccordion,
} from '../../../../../components/controls/accordions/reportAccordion/requestDetails';
import { ReportFormHeader } from '../../../../../components/primitives/report/reporHeader';
import { ReportFormInputs } from '../../../../menus/requests/container/form/form';

interface ISearchReportFormProps {
  control: Control<CreateAddressAgentReportDto>;
}

export const AddressSearchReportForm = ({
  control,
}: ISearchReportFormProps) => {
  return (
    <ReportFromAccordion iconPosition="right" initialItem={0} multiple>
      <AccordionItem label={<ReportAccordionLabel title="Search Report" />}>
        <Group position="apart" spacing={1} mb="7px">
          <ReportFormHeader title="Item" />
          <ReportFormHeader title="Personal Data" />
          <ReportFormHeader title="Comments" />
        </Group>
        <Stack spacing={'md'}>
          <ReportFormInputs
            label="Applicant's Name"
            input={
              <Group>
                <Controller
                  control={control}
                  name="searchReport.fullName.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="searchReport.fullName.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />
          <ReportFormInputs
            label="Name on Social Media"
            input={
              <Group>
                <Controller
                  control={control}
                  name="searchReport.socialMediaName.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="searchReport.socialMediaName.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />
          <ReportFormInputs
            label="Address"
            input={
              <Group>
                <Controller
                  control={control}
                  name="searchReport.address.value"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
                <Controller
                  control={control}
                  name="searchReport.address.comment"
                  render={({ field }) => (
                    <Textarea sx={{ flex: 1 }} {...field} />
                  )}
                />
              </Group>
            }
          />
        </Stack>
      </AccordionItem>
    </ReportFromAccordion>
  );
};
