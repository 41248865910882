import { Box, Center, Container, Stack } from '@mantine/core';
import { PropsWithChildren } from 'react';
import logo from '../../assets/iverify-logo.png';

export const AuthLayout = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        minHeight: '100vh',
        backgroundColor: theme.colors.gray[1],
      })}
      pt="xl"
      pb="xl"
    >
      <Container>
        <Stack
          sx={(theme) => ({
            margin: '0 auto',
            maxWidth: 420,
            paddingTop: 40,
            backgroundColor: theme.colors.gray[1],
          })}
          spacing="xl"
        >
          <Center>
            <img src={logo} alt="iverifyng" width={148} />
          </Center>
          {children}
        </Stack>
      </Container>
    </Box>
  );
};
