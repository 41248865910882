import { Group, Menu } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { IdentityRequest } from '@prisma/client';
import { useUserProfile } from '../../../services/queries/users';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { FileUpload } from 'tabler-icons-react';
import { AdminChangeIdentityReportModal } from '../../modals/requests/identity/adminEditIdentity';
import { AdminDeleteRequest } from '../../../services/apis/admin';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useNavigate } from '@tanstack/react-location';

interface IProps {
  record: IdentityRequest;
}
export const AdminIdentityHeader3DotMenu = ({ record }: IProps) => {
  const { isAdmin, isAgent } = useUserProfile();
  const modals = useModals();

  const queryClient = useQueryClient();
  const refreshRequest = useCallback(
    (requestId: string) => {
      queryClient.invalidateQueries(['request', requestId]);
    },
    [queryClient]
  );

  if (!isAdmin && !isAgent) return null;
  return (
    <Group spacing={0} position="right">
      <Menu
        transition="pop"
        withArrow
        placement="end"
        onClick={(event) => event.stopPropagation()}
      >
        <Menu.Item
          icon={<FileUpload size={16} />}
          onClick={() =>
            modals.openModal({
              id: 'updatereport',
              title: `Update Report - ${record?.requestId?.toUpperCase()}`,
              closeOnClickOutside: false,
              closeOnEscape: false,
              children: (
                <AdminChangeIdentityReportModal
                  record={record}
                  closeModal={() => {
                    modals.closeModal('updatereport');
                    refreshRequest(record.requestId);
                  }}
                />
              ),
            })
          }
        >
          Update Report
        </Menu.Item>
      </Menu>
    </Group>
  );
};
