import { Avatar, Box, Tabs } from '@mantine/core';
import { getRequestCardIcon } from '../../../../components/primitives/cards/request/requestCard/helper';
import { RequestHeader } from '../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { useCallback, useEffect, useMemo } from 'react';
import {
  MakeGenerics,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from '@tanstack/react-location';
import { useQueryClient } from 'react-query';
import { useInterval } from '@mantine/hooks';
import { RequestBodySkeleton } from '../../../../components/primitives/skeletons/requestDetail';
import { GetRequestResponseDto } from '@iverifyng/dtos';
import { useAdminRequestDetails } from '../../../../hooks/admin';
import { useTabStyle } from '../../request/request.styles';
import { useAdminUserDetails } from '../../../../../src/hooks/admin-users';
import { getNameInitials } from '@iverifyng/utils';
import { AdminUserDotMenu } from '../../../../../src/connected/menus/admin/user';

type TabID = 'details' | 'agents' | 'comments' | 'payment' | 'requester';
const tabs: Record<TabID, string> = {
  details: 'details',
  requester: 'requester',
  payment: 'payment',
  agents: 'agents',
  comments: 'comments',
};

const userTabs: Record<string, string> = {
  details: 'details',
};

export type RequestGenerics = MakeGenerics<{
  LoaderData: {
    request: GetRequestResponseDto;
  };
}>;

export const AdminWorkspaceTabOutlet = () => {
  const {
    params: { workspaceId, tabId, detailId },
  } = useMatch<RequestGenerics>();
  const { error, data: request, isLoading } = useAdminRequestDetails(detailId);

  const {
    error: isUserError,
    data: user,
    isLoading: isUserLoading,
  } = useAdminUserDetails(detailId);

  const { current } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const refetchInterval = useInterval(() => {
    if (tabId === 'requests') {
      return queryClient.invalidateQueries(['request', detailId], {
        stale: true,
      });
    }
    return queryClient.invalidateQueries(['adminUser', detailId], {
      stale: true,
    });
  }, 5000);

  const tabArray = Object.keys(tabs);
  const tabByTabId = tabId === 'team_members' ? userTabs : tabs;
  const path = current.pathname.split('/').pop();

  const activeTab = useMemo(() => {
    const current =
      tabId === 'team_members' ? 0 : tabArray.indexOf(path as TabID);
    return current;
  }, [path, tabArray, tabId]);

  // const { data: request, isLoading, isFetching } = useRequestDetails(requestId);
  const { classes } = useTabStyle();

  //refetch the data when Pending or Ongoing Verification
  //this will ensure the data is updated in UI
  useEffect(() => {
    const status = request?.status;
    const isPendingOrOngoing =
      status && ['PENDING', 'ONGOING_VERIFICATION'].includes(status);
    const isIdentityRequest = request?.service?.category === 'IDENTITY';
    if (isPendingOrOngoing && isIdentityRequest) {
      refetchInterval.start();
    } else {
      refetchInterval.stop();
    }
  }, [request?.service?.category, request?.status, refetchInterval]);

  const onTabChange = useCallback(
    (active: number, tabKey: string) => {
      navigate({
        to: `/admin/workspace-detail/${workspaceId}/${tabId}/${detailId}/${tabKey.toLocaleLowerCase()}` as string,
      });
    },
    [detailId, navigate, tabId, workspaceId]
  );

  console.log('user', user);

  const renderHeader = useMemo(() => {
    if (tabId === 'requests') {
      return isLoading || error ? (
        <RequestBodySkeleton type="header" skeleton={true} />
      ) : (
        <RequestHeader
          icon={<>{getRequestCardIcon(request!)}</>}
          text={request?.service.name}
        />
      );
    }

    return isUserLoading || isUserError ? (
      <RequestBodySkeleton type="header" skeleton={true} />
    ) : (
      <RequestHeader
        icon={
          user?.firstName && (
            <Avatar src="" alt={user.firstName!} radius="xl" color="indigo">
              {getNameInitials({
                name: `${user.firstName} ${user.lastName}`,
                maxInitials: 2,
              })}
            </Avatar>
          )
        }
        text={`${user?.firstName} ${user?.lastName}`}
        rightNode={<AdminUserDotMenu user={user!} />}
      />
    );
  }, [error, isLoading, isUserError, isUserLoading, request, tabId, user]);

  return (
    <Box>
      <Box>{renderHeader}</Box>
      <Tabs
        color="blue"
        classNames={classes}
        variant="unstyled"
        px="xl"
        py="sm"
        active={activeTab}
        onTabChange={onTabChange}
        tabPadding="md"
      >
        {Object.entries(tabByTabId).map(([key, value]) => (
          <Tabs.Tab tabKey={key} label={value} key={key} />
        ))}
      </Tabs>
      <Box
        sx={() => ({
          flex: 1,
          overflowY: 'hidden',
          height: 'calc(100vh - 367.58px)',
        })}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
