import { AdminPreviousWorkUpdateReportDto, CreatePreviousWorkVerificationDto, CreateRequestReponseDto, UpdatePreviousWorkVerificationDto } from '@iverifyng/dtos';
import axiosInstance from '../axios';

interface ICreatePreviousWorkVerificationDto extends CreatePreviousWorkVerificationDto {
    workspaceId: string;
}

export const CreatePreviousWorkVerificationApi = async ({
    workspaceId,
    ...data
}: ICreatePreviousWorkVerificationDto): Promise<CreateRequestReponseDto> => {
    const url = `/request/previouswork/${workspaceId}/new`;
    return await axiosInstance.post(url, data).then((response) => response.data);
};

export const AdminUpdatePreviousWorkReportApi = async (
    data: AdminPreviousWorkUpdateReportDto
): Promise<CreatePreviousWorkVerificationDto> => {
    const url = `/admin/requests/previouswork/reportUrl`;
    return await axiosInstance.patch(url, data).then((response) => response.data);
};

export const AdminRemovePreviousWorkReportApi = async (params: {
    previousworkId: string;
}): Promise<CreatePreviousWorkVerificationDto> => {
    const url = `/admin/requests/previouswork/${params.previousworkId}/reportUrl`;
    return await axiosInstance.delete(url).then((response) => response.data);
};

export const AdminUpdatePreviousWorkApi = async (
    data: UpdatePreviousWorkVerificationDto
): Promise<CreatePreviousWorkVerificationDto> => {
    const url = '/admin/requests/previouswork';
    return await axiosInstance.put(url, data).then((response) => response.data);
};