import {
  Box,
  Stack,
  Text,
  Group,
  TextInput,
  Button,
  Select,
  Divider,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { CreditCheckIndividualIcon } from '../../../../../../../../src/assets/icons';
import { DatePicker } from '@mantine/dates';
import { IConsumerCreditCheckProps } from '../../interface';
import { enquiryReason } from '../../common/common';
import { RequestDetailsBlock } from '../../../../../../../connected/requestDetails/block';

interface IComsumerCreditCheckRequestForm {
  introText: string;
  form: UseFormReturnType<IConsumerCreditCheckProps>;
  renderIf?: boolean;
  onBack?: () => void;
}

export const ConsumerCreditCheckForm = ({
  introText,
  form,
  renderIf,
  onBack,
}: IComsumerCreditCheckRequestForm) => {
  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Consumer Credit Check"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<CreditCheckIndividualIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="red"
            variant="subtle"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Review</Button>
        </Group>
      }
    >
      <Stack spacing="xl" p="24px">
        <Box
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">{introText}</Text>
        </Box>
        <Group direction="column" spacing="md" grow>
          <Select
            label="Report Type"
            required
            autoFocus
            placeholder="Type of report to generate"
            {...form.getInputProps('code')}
            data={[
              {
                label: 'XScore -  Detailed Report',
                value: 'XSCORE_CONSUMER_DETAILED_REPORT',
              },
              {
                label: 'Detailed Consumer Report',
                value: 'CONSUMER_DETAILED_REPORT',
              },
            ]}
          />
          <Select
            label="Reason"
            required
            searchable
            placeholder="Select reason"
            {...form.getListInputProps('data', 0, 'enquiryReason')}
            data={enquiryReason}
          />
          <RequestDetailsBlock title="Applicant Details">
            <TextInput
              label="Identification Number"
              placeholder="Enter ID Number"
              description="BVN, Mobile No, National ID, Pencom ID,Passport No or Drivers License No of Individual you intend to search. Recommended"
              {...form.getListInputProps('data', 0, 'identification')}
            />

            <Divider my="xs" label="OR" labelPosition="center" />
            <TextInput
              label="Full Name"
              placeholder="Enter full name"
              description="Name of Individual you intend to search"
              {...form.getListInputProps('data', 0, 'consumerName')}
            />
            <DatePicker
              label="Date of Birth"
              placeholder="Select date of birth"
              inputFormat="DD/MM/YYYY"
              description="DOB of Individual you intend to search"
              {...form.getListInputProps('data', 0, 'dateOfBirth')}
            />

            <Divider my="xs" label="OR" labelPosition="center" />
            <TextInput
              label="Bank Account Number"
              placeholder="Enter account number"
              description="Account number of Individual you intend to search"
              {...form.getListInputProps('data', 0, 'accountNumber')}
            />
          </RequestDetailsBlock>
        </Group>
      </Stack>
    </RequestLayout>
  );
};
