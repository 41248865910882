import { Center, Group, Skeleton, Stack } from '@mantine/core';

interface ILoaderProps {
  limit?: number;
  height?: number;
}

export const BarChartLoader = ({ limit = 9, height = 400 }: ILoaderProps) => {
  return (
    <Stack spacing="xl" px="lg" sx={{ height }}>
      <Group spacing="xl" align="end" position="apart" grow>
        {Array(limit)
          .fill('*')
          .map((_, i) => (
            <Skeleton
              key={i}
              visible={true}
              width={28}
              height={(i + (1 % limit)) * (height / 10 - 5)}
            />
          ))}
      </Group>
      <Group position="center" mt="lg">
        <Skeleton width={150} height={25} visible={true} />
      </Group>
    </Stack>
  );
};
export const PieChartLoader = ({ limit = 4 }: ILoaderProps) => {
  return (
    <Center sx={{ height: 300 }}>
      <Group>
        <Skeleton width={200} height={200} visible={true} circle radius="xl" />

        <Stack>
          {Array(limit)
            .fill('*')
            .map((_, i) => (
              <Skeleton key={i} visible={true} width={50} height={15} />
            ))}
        </Stack>
      </Group>
    </Center>
  );
};
