/* eslint-disable no-extra-boolean-cast */
import {
  Avatar,
  Box,
  Group,
  Skeleton,
  Text,
  Stack,
  Tooltip,
} from '@mantine/core';
import * as React from 'react';
import { formatPriceByCurrency, getNameInitials } from '@iverifyng/utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { IRequestCardProps } from '../../request/requestCard/requestCard';
import { useAdminCardStyle } from './adminRequestCard.style';
import {
  useStatusColor,
  useStatusText,
} from '../../../../../hooks/useStatusColor';
import { Trash } from 'tabler-icons-react';
import { useMutation, useQueryClient } from 'react-query';
import { AdminDeleteRequest } from '../../../../../services/apis/admin';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useModals } from '@mantine/modals';

dayjs.extend(advancedFormat);

interface IAdminRequestCard extends IRequestCardProps {
  imageUrl?: string;
}

export const AdminRequestCard = ({
  active,
  renderIcon,
  request,
  skeleton = false,
  onClick,
  imageUrl,
}: IAdminRequestCard) => {
  const [showDelete, setShowDelete] = React.useState(false);
  const queryClient = useQueryClient();
  const modals = useModals();
  // const navigate = useNavigate();

  const { id, name, text, supportingText, price, date, currency, status } =
    request;
  const { classes } = useAdminCardStyle({ active, skeleton });
  const statusName = useStatusText(status);
  const statusColor = useStatusColor(status);
  const requesterName = [
    request?.requester?.firstName,
    request?.requester?.lastName,
  ]
    .filter(Boolean)
    .join(' ');

  const deleteRequest = useMutation(AdminDeleteRequest);

  const handleDeleteRequest = React.useCallback(
    (requestId: string) => {
      deleteRequest.mutate(requestId, {
        onSuccess: () => {
          showNotification({
            color: 'blue',
            message: 'Request deleted',
          });

          queryClient.invalidateQueries('adminRequests');
        },
        onError: (error) => {
          showNotification({
            color: 'blue',
            message:
              (error as AxiosError<{ message: string }>).response?.data
                .message ?? 'Error occurred',
          });
        },
      });
    },
    [deleteRequest, queryClient]
  );
  return (
    <Stack
      onMouseOver={() => setShowDelete(true)}
      onMouseOut={() => setShowDelete(false)}
      px={32}
      py="xs"
      onClick={() => {
        if (!!onClick) onClick(id);
      }}
      className={classes.base}
      //sx={() => ({ flexFlow: 'nowrap', flexShrink: 0 })}
    >
      <Stack spacing={'xs'}>
        <Group spacing="xs" position="apart" align={'center'}>
          <div style={{ flex: 1 }}>
            <Skeleton visible={skeleton}>
              <Group
                spacing="xs"
                sx={() => ({ flexFlow: 'nowrap', flexShrink: 0 })}
              >
                {renderIcon}
                <Text size="sm" weight={600} lineClamp={1}>
                  {text}
                </Text>
              </Group>
            </Skeleton>
          </div>

          <Text color="blue" weight={500} size="sm" align="right">
            <Skeleton visible={skeleton}>
              {formatPriceByCurrency(price, { notation: 'standard', currency })}
            </Skeleton>
          </Text>
        </Group>
        <Skeleton visible={skeleton}>
          <Box>
            {supportingText && (
              <Text
                component="span"
                size="xs"
                lineClamp={1}
                sx={(theme) => ({ color: theme.colors.gray[8] })}
              >
                {supportingText}
              </Text>
            )}
          </Box>
        </Skeleton>
      </Stack>
      <Group position="apart" align={'center'}>
        <Group sx={() => ({ flex: 1 })}>
          <Skeleton visible={skeleton}>
            <Text size="xs" sx={(theme) => ({ color: theme.colors.gray[6] })}>
              {dayjs(date).format('Do MMM, YYYY')}&nbsp;—&nbsp;
              <Text component="span" size="xs" color={statusColor}>
                {statusName}
              </Text>
            </Text>
          </Skeleton>
        </Group>
        <Group spacing={8}>
          <Skeleton visible={skeleton} circle width={26} height={26}>
            {requesterName || imageUrl ? (
              <Tooltip label={requesterName}>
                <Avatar
                  src={imageUrl}
                  radius="xl"
                  p={0}
                  size="sm"
                  alt={name}
                  color="indigo"
                >
                  {getNameInitials({
                    name: requesterName,
                    maxInitials: 2,
                  })}
                </Avatar>
              </Tooltip>
            ) : null}
          </Skeleton>
          <Skeleton visible={skeleton} circle width={26} height={26}>
            {!skeleton && showDelete && (
              <Trash
                color="red"
                size={'14px'}
                onClick={(e) => {
                  e.stopPropagation();
                  modals.openConfirmModal({
                    id: 'delete request',
                    title: 'Delete Request',
                    children: 'Are you sure you want to delete this request?',
                    labels: { confirm: 'Confirm', cancel: 'Cancel' },
                    onCancel: () => modals.closeModal('delete Request'),
                    onConfirm: () => handleDeleteRequest(id),
                  });
                  // handleDeleteRequest(id);
                }}
              />
            )}
          </Skeleton>
        </Group>
      </Group>
    </Stack>
  );
};
