import { useCallback, useState } from 'react';
import { GuarantorModal } from './guarantorModal';
import { SingleEmployeeFormModal } from './singleEmployeeFormModal';
import { formList, useForm } from '@mantine/form';
import {
  EmployeeGuarantorDto,
  NewEmployeeVerificationDto,
} from '@iverifyng/dtos';
import { EmployeeVerificationSchema } from '@iverifyng/validators';
import { showNotification } from '@mantine/notifications';

type IModalTypes = 'single' | 'guarantor';

interface ISingleEmployeeModalProps {
  initialValues: NewEmployeeVerificationDto;
  onClose: () => void;
  onSubmit: (values: NewEmployeeVerificationDto) => void;
  closeModal: () => void;
}

export const EmployeeMainModal = ({
  initialValues,
  onClose,
  onSubmit,
  closeModal,
}: ISingleEmployeeModalProps) => {
  const [modal, setModal] = useState<IModalTypes>('single');
  const [hasIndex, setHasIndex] = useState<number | null>(null);
  const form = useForm({
    initialValues: {
      ...initialValues,
      guarantors: formList<EmployeeGuarantorDto>(initialValues.guarantors),
    },
  });

  const handleSubmit = useCallback(
    (values: NewEmployeeVerificationDto) => {
      const { error } =
        EmployeeVerificationSchema.SINGLE_EMPLOYEE_SCHEMA.validate(values);
      if (error) {
        showNotification({ message: error.message, color: 'red' });
        return;
      }

      onSubmit(values);
      closeModal();
    },
    [onSubmit, closeModal]
  );

  //I want to the handle remove to work for multiple employee edit

  const handleRemoveGuarantor = useCallback(
    (guarantorIndex: number) => {
      form.removeListItem('guarantors', guarantorIndex);
    },
    [form]
  );

  const handleEditGuarantor = useCallback(
    (guarantorIndex: number) => {
      setHasIndex(guarantorIndex);
      form.setListItem(
        'guarantors',
        guarantorIndex,
        form.values.guarantors[guarantorIndex]
      );
      setModal('guarantor');
    },

    [form]
  );

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SingleEmployeeFormModal
          form={form}
          onAddGuarantor={() => {
            setModal('guarantor');
            setHasIndex(1000); //dummy index
          }}
          onRemoveGuarantor={handleRemoveGuarantor}
          onEditGuarantor={handleEditGuarantor}
          onClose={onClose}
          renderIf={modal === 'single'}
        />
      </form>
      {hasIndex !== null && modal === 'guarantor' ? (
        <GuarantorModal
          formInitialValue={form.values.guarantors[hasIndex!]}
          onBack={() => setModal('single')}
          renderIf={modal === 'guarantor'}
          onSubmit={(values) => {
            //I want to the handle add to work for multiple employee
            if (form.values.guarantors[hasIndex!]) {
              form.setListItem('guarantors', hasIndex, values);
            } else {
              form.addListItem('guarantors', values as unknown as never);
            }

            setModal('single');
            setHasIndex(null);
          }}
        />
      ) : null}
    </>
  );
};
