import { Box, Center, SegmentedControl } from '@mantine/core';
import { ReactNode, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { AnalyticsLayout } from '../components/analyticslayout';
import { ChartPie, Timeline } from 'tabler-icons-react';
import { IHeaderProps } from '../components/header';

export type IactiveTab = 'left' | 'right';

interface ISelectChart extends IHeaderProps {
  firstNode?: ReactNode;
  secondNode?: ReactNode;
}

export const SegmentedChart = ({
  firstNode,
  secondNode,
  ...props
}: ISelectChart) => {
  const [activeTab, setActiveTab] = useState<IactiveTab>('left');
  return (
    <AnalyticsLayout
      rightNode={
        <SegmentedControl
          value={activeTab}
          onChange={(data: IactiveTab) => setActiveTab(data)}
          sx={(theme) => ({ color: theme.colors.blue[8] })}
          data={[
            {
              label: (
                <Center>
                  <ChartPie
                    size={16}
                    color={activeTab === 'left' ? '#008AFF' : '#ADB5BD'}
                    strokeWidth={1.5}
                  />
                </Center>
              ),
              value: 'left',
            },
            {
              label: (
                <Center>
                  <Timeline
                    size={16}
                    color={activeTab === 'right' ? '#008AFF' : '#ADB5BD'}
                    strokeWidth={1.5}
                  />
                </Center>
              ),
              value: 'right',
            },
          ]}
        />
      }
      {...props}
    >
      <ResponsiveContainer width="100%">
        <Box>{activeTab === 'left' ? firstNode : secondNode}</Box>
      </ResponsiveContainer>
    </AnalyticsLayout>
  );
};
