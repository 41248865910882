import { Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { UGNationalIDResponseDto } from '../../ug/interface';

export const UGNationalIDReport = (props: UGNationalIDResponseDto) => {
  return (
    <Stack spacing="sm">
      <RequestDataLayout label="Gender" data={props.sex ?? 'N/A'} />
      <RequestDataLayout label="Surname" data={props.surname ?? 'N/A'} />
      <RequestDataLayout label="Given Name" data={props.givenNames ?? 'N/A'} />
      <RequestDataLayout label="Other Name" data={props.otherNames ?? 'N/A'} />
      <RequestDataLayout
        label="Date of Birth"
        data={props.dateOfBirth ?? 'N/A'}
      />
      <RequestDataLayout label="Profession" data={props.profession ?? 'N/A'} />
      <RequestDataLayout
        label="Marital Status"
        data={props.maritalStatus ?? 'N/A'}
      />
      <RequestDataLayout
        label="Country of Birth"
        data={props.countryOfBirth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Country of Residence"
        data={props.countryOfResidence ?? 'N/A'}
      />
    </Stack>
  );
};
