import { Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { CACResponseDto } from '../../ng/interface';

export const CACReport = (props: CACResponseDto) => {
  return (
    <Stack spacing="sm" pt="xs">
      <RequestDataLayout label="Business Name" data={props.Name ?? 'N/A'} />
      <RequestDataLayout
        label="Registration Number"
        data={props.RegistrationNo ?? 'N/A'}
      />
      <RequestDataLayout
        label="Registration Date"
        data={props.RegistrationDate ?? 'N/A'}
      />
      <RequestDataLayout label="Email" data={props.Email ?? 'N/A'} />
      <RequestDataLayout label="Company" data={props.Company ?? 'N/A'} />
      <RequestDataLayout label="Status" data={props.Status ?? 'N/A'} />
      <RequestDataLayout label="Activity" data={props.Activity ?? 'N/A'} />
      <RequestDataLayout label="Address" data={props.Address ?? 'N/A'} />
      <RequestDataLayout label="City" data={props.City ?? 'N/A'} />
      <RequestDataLayout label="LGA" data={props.LGA ?? 'N/A'} />
      <RequestDataLayout label="Directors" data={props.Directors ?? 'N/A'} />
      <RequestDataLayout label="Secretary" data={props.Secretary ?? 'N/A'} />
      <RequestDataLayout label="Head Office" data={props.HeadOffice ?? 'N/A'} />
      <RequestDataLayout label="Objectives" data={props.Objectives ?? 'N/A'} />
      <RequestDataLayout
        label="Branch Office"
        data={props.BranchOffice ?? 'N/A'}
      />
      <RequestDataLayout
        label="Type of Entity"
        data={props.TypeofEntity ?? 'N/A'}
      />
    </Stack>
  );
};
