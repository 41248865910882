import { Anchor, Stack, Text } from '@mantine/core';

export const ConsentConfirmationModal = () => {
  return (
    <Stack spacing={'sm'}>
      <Text size="sm">
        <Anchor size="sm" href="https://iverify.ng" target={'_blank'}>
          iverify.ng
        </Anchor>{' '}
        is committed to global best practices on data protection and data
        privacy.{' '}
        <Anchor
          size="sm"
          href="https://storage.googleapis.com/iverify-public/forms/consent/privacy-policy-01082022.pdf"
          target={'_blank'}
        >
          Please read iVerify's privacy policy here.
        </Anchor>
      </Text>
      <Text size="sm">
        I(the applicant) hereby declare that I am the bearer of the ID or I have
        obtained the consent of the bearer of the ID to verify and validate the
        information contained therein.
      </Text>
    </Stack>
  );
};
