import { TenantStatusEnum } from '@prisma/client';
import * as Joi from 'joi';
import { PhoneNumberValidator } from './helpers';
import { IdentityValidatorSchema } from './identity';

const SINGLE_GUARANTOR_SCHEMA = Joi.object({
  fullname: Joi.string().required().label("Guarantor's Full name"),
  relationship: Joi.string().optional().label('Relationship with guarantor'),
  address: Joi.string().required().label("Guarantor's Residential Address"),
  workAddress: Joi.string()
    .allow(null, '')
    .optional()
    .label("Guarantor's Work Address"),
  phoneNo: Joi.string()
    .custom(PhoneNumberValidator)
    .required()
    .label("Guarantor's Phone Number"),
  idType: Joi.string()
    .valid('DRIVERS_LICENSE', 'NIN', 'VOTER_ID')
    .required()
    .label("Guarantor's ID Type"),
  idNumber: Joi.string()
    .when('idType', {
      switch: [
        { is: 'NIN', then: IdentityValidatorSchema.NIN.extract('idNumber') },
        {
          is: 'DRIVERS_LICENSE',
          then: IdentityValidatorSchema.DRIVERS_LICENSE.extract('idNumber'),
        },
        {
          is: 'VOTER_ID',
          then: IdentityValidatorSchema.VOTER_ID.extract('idNumber'),
        },
      ],
    })
    .required()
    .label("Guarantor's ID Number"),
});

const SINGLE_LANDLORD_SCHEMA = Joi.object({
  fullname: Joi.string().required().label("Landlord's Full name"),
  address: Joi.string().required().label("Landlord's Residential Address"),
  phoneNo: Joi.string()
    .custom(PhoneNumberValidator)
    .required()
    .label("Guarantor's Phone Number"),
});

const SINGLE_TENANT_SCHEMA = {
  fullName: Joi.string().required().label('Full name'),
  phoneNo: Joi.string()
    .custom(PhoneNumberValidator)
    .required()
    .label("Tenant's Phone Number"),
  idType: Joi.string()
    .valid('DRIVERS_LICENSE', 'NIN', 'VOTER_ID')
    .required()
    .label('Identification Type'),
  idNumber: Joi.string()
    .when('idType', {
      switch: [
        { is: 'NIN', then: IdentityValidatorSchema.NIN.extract('idNumber') },
        {
          is: 'DRIVERS_LICENSE',
          then: IdentityValidatorSchema.DRIVERS_LICENSE.extract('idNumber'),
        },
        {
          is: 'VOTER_ID',
          then: IdentityValidatorSchema.VOTER_ID.extract('idNumber'),
        },
      ],
    })
    .required()
    .label('ID Number'),
  spouseName: Joi.string().allow(null, '').optional().label('Spouse Name'),
  residentialAddress: Joi.string().required().label('Residential Address'),
  workAddress: Joi.string().allow(null, '').optional().label('Work Address'),
  relocationReason: Joi.string()
    .allow(null, '')
    .optional()
    .label('Reason for relocating'),
  performCreditCheck: Joi.boolean().optional().label('Credit Check'),
  guarantors: Joi.array()
    .items(SINGLE_GUARANTOR_SCHEMA)
    .min(1)
    .required()
    .label('Guarantor'),
  landlords: Joi.array()
    .items(SINGLE_LANDLORD_SCHEMA)
    .min(1)
    .required()
    .label('Landlord'),
};
const NEW_VERIFICATION_SCHEMA = Joi.object({
  data: Joi.array()
    .items(Joi.object(SINGLE_TENANT_SCHEMA))
    .min(1)
    .required()
    .label('Tenant data'),
});
const UPDATE_VERIFICATION_SCHEMA = Joi.object({
  ...SINGLE_TENANT_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
}).fork(
  [
    'fullName',
    'phoneNo',
    'idType',
    'idNumber',
    'spouseName',
    'residentialAddress',
    'workAddress',
    'relocationReason',
    'performCreditCheck',
    'guarantors',
    'landlords',
  ],
  (schema) => schema.optional()
);
const ADMIN_UPDATE_REPORT = Joi.object({
  tenantId: Joi.string().required().label('Tenant ID'),
  reportUrl: Joi.string().uri().required().label('Report URL'),
});

const ADMIN_UPDATE_TENANT = Joi.object({
  ...SINGLE_TENANT_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  status: Joi.string()
    .valid(...Object.values(TenantStatusEnum))
    .optional()
    .label('Status'),
}).fork(
  [
    'fullName',
    'phoneNo',
    'idType',
    'idNumber',
    'spouseName',
    'residentialAddress',
    'workAddress',
    'relocationReason',
    'performCreditCheck',
    'guarantors',
    'landlords',
  ],
  (schema) => schema.optional()
);

export const TenantVerificationSchema = {
  NEW_VERIFICATION_SCHEMA,
  UPDATE_VERIFICATION_SCHEMA,
  ADMIN_UPDATE_REPORT,
  ADMIN_UPDATE_TENANT,
};
