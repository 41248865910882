import { AccordionItem, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';
import { triggerURLDownload } from '@iverifyng/utils';
import { creditCheckStatusDescription } from '../../../utils/status';
import { CreditCheck } from '@prisma/client';
import { RequestDetailsBlock } from '../block';
import { AdminCreditCheck3DotMenu } from '../../menus/requests/creditCheck';

type payload = {
  consumerName?: string;
  businessName?: string;
  businessRegNo?: string;
  identification?: string;
  accountNumber?: string;
  dateOfBirth?: string;
};

const getTitle = (data: payload) => {
  if (data?.consumerName) return data?.consumerName;
  if (data?.identification) return data?.identification;
  if (data?.businessName) return data?.businessName;
  if (data?.businessRegNo) return data?.businessRegNo;
  if (data?.accountNumber) return data?.accountNumber;
  return undefined;
};
interface IProps {
  index: number;
  record: CreditCheck;
}
export const CreditCheckAccordionItem = ({ record, index }: IProps) => {
  const payload = record?.payload as payload;
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={getTitle(payload)}
            rightIcon={<AdminCreditCheck3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={creditCheckStatusDescription[record.status!]}
              />
            }
          />
          <RequestDetailsBlock title="Credit Check Basic Details">
            <RequestDataLayout label="Reason" data={record.enquiryReason!} />
            {payload.consumerName && (
              <RequestDataLayout
                label="Full Name"
                data={payload.consumerName}
              />
            )}
            {payload.businessName && (
              <RequestDataLayout
                label="Business Name"
                data={payload.businessName}
              />
            )}

            {payload.accountNumber && (
              <RequestDataLayout
                label="Account Number"
                data={payload.accountNumber ?? 'N/A'}
              />
            )}

            {payload.businessRegNo && (
              <RequestDataLayout
                label="Business Registration Number"
                data={payload.businessRegNo!}
              />
            )}
            {payload.identification && (
              <RequestDataLayout
                label="ID Number"
                data={payload.identification}
              />
            )}
            {payload.dateOfBirth && (
              <RequestDataLayout
                label="Date of Birth"
                data={payload.dateOfBirth ?? 'N/A'}
              />
            )}
          </RequestDetailsBlock>

          {record.status === 'VERIFIED' && record.reportUrl && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
