import { Box, Stack } from '@mantine/core';
import * as React from 'react';

interface IRequestLayoutProps {
  headerFragment?: React.ReactNode;
  footerFragment?: React.ReactNode;
}

export const RequestLayout = ({
  headerFragment,
  footerFragment,
  children,
}: React.PropsWithChildren<IRequestLayoutProps>) => {
  return (
    <Stack
      sx={() => ({ height: '100%', maxHeight: '100%', position: 'relative' })}
      spacing={0}
    >
      <Box>
        {headerFragment ? (
          <div style={{ flexShrink: 0 }}>{headerFragment}</div>
        ) : null}
      </Box>
      <Box sx={() => ({ flex: 1, overflowY: 'auto' })}>{children}</Box>
      <div>
        {footerFragment ? (
          <Box
            py="md"
            px="xl"
            sx={(theme) => ({
              border: `1px solid ${theme.colors.gray[2]}`,
              flexShrink: 0,
            })}
          >
            {footerFragment}
          </Box>
        ) : null}
      </div>
    </Stack>
  );
};
