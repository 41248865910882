import { PropertyStatusEnum } from '@prisma/client';
import * as Joi from 'joi';

enum PropertyTypes {
  residential = 'residential',
  commercial = 'commercial',
  land = 'land',
}

const SINGLE_PROPERTY_SCHEMA = {
  type: Joi.string()
    .valid(...Object.values(PropertyTypes))
    .required()
    .label('Property Type'),
  documentUrl: Joi.string().uri().required().label('Property Document'),
  documentFile: Joi.any().optional(), //for frontend
  street: Joi.string().required().label('Street address'),
  city: Joi.string().required().label('City'),
  state: Joi.string().required().label('State'),
  country: Joi.string().required().label('Country'),
};

const NEW_VERIFICATION_SCHEMA = Joi.object({
  data: Joi.array()
    .items(Joi.object(SINGLE_PROPERTY_SCHEMA))
    .min(1)
    .required()
    .label('Properties data'),
  description: Joi.string().allow('').optional(),
});

const ADMIN_UPDATE_REQUEST = Joi.object({
  ...SINGLE_PROPERTY_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  reportUrl: Joi.when('status', {
    switch: [
      {
        is: 'VERIFIED',
        then: Joi.string().uri().required(),
        otherwise: Joi.string().uri().optional().allow(null),
      },
    ],
  }).label('Report URL'),
  status: Joi.string()
    .valid(...Object.values(PropertyStatusEnum))
    .optional()
    .label('Status'),
}).fork(
  ['type', 'documentUrl', 'street', 'city', 'state', 'country'],
  (schema) => schema.optional()
);

const UPDATE_VERIFICATION_SCHEMA = Joi.object({
  ...SINGLE_PROPERTY_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
}).fork(
  ['type', 'documentUrl', 'street', 'city', 'state', 'country'],
  (schema) => schema.optional()
);

const ADMIN_UPDATE_REPORT = Joi.object({
  propertyId: Joi.string().required().label('Property ID'),
  reportUrl: Joi.string().uri().required().label('Report URL'),
});

const ADMIN_UPDATE_PROPERTY = Joi.object({
  ...SINGLE_PROPERTY_SCHEMA,
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  reportUrl: Joi.when('status', {
    switch: [
      {
        is: 'VERIFIED',
        then: Joi.string().uri().required(),
        otherwise: Joi.string().uri().optional().allow(null),
      },
    ],
  }).label('Report URL'),
  status: Joi.string()
    .valid(...Object.values(PropertyStatusEnum))
    .optional()
    .label('Status'),
}).fork(
  ['type', 'documentUrl', 'street', 'city', 'state', 'country'],
  (schema) => schema.optional()
);

export const PropertyVerificationSchema = {
  PropertyTypes,
  NEW_VERIFICATION_SCHEMA,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_REPORT,
  ADMIN_UPDATE_PROPERTY,
  UPDATE_VERIFICATION_SCHEMA,
};
