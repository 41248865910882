import { useQuery } from 'react-query';
import { RequestMyProfile } from '../apis/users';

export const useUserProfile = () => {
  const { isLoading, isError, data, error, refetch } = useQuery(
    'profile',
    RequestMyProfile
  );
  return {
    isLoading,
    isError,
    refetch,
    data: data ?? undefined,
    workspaces: data ? data.workspaces : undefined,
    error,
    fullName: data
      ? [data?.firstName, data?.lastName].filter(Boolean).join(' ')
      : null,
    roles: data?.appRoles ?? [],
    isAdmin: (data?.appRoles ?? []).includes('ADMIN'),
    isAgent: (data?.appRoles ?? []).includes('AGENT'),
  };
};
