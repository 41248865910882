import {
  Stack,
  Box,
  Text,
  Group,
  TextInput,
  Textarea,
  Button,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { useLocation } from '@tanstack/react-location';
import { VoterIcon } from '../../assets/icons';
import { NINFormData } from '../../containers/request/routes/ng/identityVerification/subRoutes/nin/containers/ninForm';
import { RequestLayout } from '../layouts/request/requestLayout';
import { RequestHeader } from '../primitives/cards/request/requestHeader/requestHeader';
import { ServiceSampleImageAccordion } from '../primitives/cards/services/serviceSampleImage';
interface VotersCardFormProps {
  form: UseFormReturnType<NINFormData>;
  renderIf?: boolean;
}

export const VotersCardForm = ({ form, renderIf }: VotersCardFormProps) => {
  const { history } = useLocation();

  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Voter ID - Nigeria"
          onClickBack={() => history.back()}
          withBackArrow
          withBackground
          icon={<VoterIcon width={42} height={42} />}
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button color="dark" variant="outline" onClick={() => history.back()}>
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Stack spacing="xl" p="24px">
        <ServiceSampleImageAccordion
          title="Sample Voter ID"
          image="https://storage.googleapis.com/iverify-public/sample-ids/ng-votersid2.png"
        />
        <Box
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">
            Voter ID verification request provides you with the owner's first
            and last name, date of birth, etc.
          </Text>
        </Box>

        <Group direction="column" spacing="lg" grow>
          <TextInput
            required
            label="VIN"
            placeholder="Enter Voter ID number"
            {...form.getInputProps('idNumber')}
          />

          <Textarea
            label="Description"
            {...form.getInputProps('description')}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
