import {
  AdminEmployeeUpdateReportDto,
  CreateEmployeeVerificationDto,
  CreateRequestReponseDto,
  EmployeeVerificationRecordDto,
  UpdateEmployeeVerificationDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';

interface ICreateNewEmployeeVerificationDto
  extends CreateEmployeeVerificationDto {
  workspaceId: string;
}
export const CreateEmployeeVerificationApi = async ({
  workspaceId,
  ...data
}: ICreateNewEmployeeVerificationDto): Promise<CreateRequestReponseDto> => {
  const url = `request/employees/${workspaceId}/new`;
  return await axiosInstance.post(url, data).then((response) => response.data);
};

export const AdminUpdateEmployeeReportApi = async (
  data: AdminEmployeeUpdateReportDto
): Promise<EmployeeVerificationRecordDto> => {
  const url = `/admin/requests/employee/reportUrl`;
  return await axiosInstance.patch(url, data).then((response) => response.data);
};

export const AdminRemoveEmployeeReportApi = async (params: {
  employeeId: string;
}): Promise<EmployeeVerificationRecordDto> => {
  const url = `/admin/requests/employee/${params.employeeId}/reportUrl`;
  return await axiosInstance.delete(url).then((response) => response.data);
};

export const AdminUpdateEmployeeApi = async (
  data: UpdateEmployeeVerificationDto
): Promise<UpdateEmployeeVerificationDto> => {
  const url = '/admin/requests/employee';
  return await axiosInstance.put(url, data).then((response) => response.data);
};
