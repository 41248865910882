import { CreateEmployeeVerificationDto } from '@iverifyng/dtos';
import { Box, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../components/primitives/price/servicecharge';
import { DataInfo } from '../../../../../../components/primitives/details/dataInfo';
import { EmployeeReviewAccordionItem } from './accordionItem';

interface ISingleEmployeeDetailsProps {
  form: UseFormReturnType<CreateEmployeeVerificationDto>;
  onEditClick: () => void;
  serviceCharge?: IServiceCharge;
  renderIf?: boolean;
}

export const SingleEmployeeDetails = ({
  form,
  onEditClick,
  serviceCharge,
  renderIf,
}: ISingleEmployeeDetailsProps) => {
  if (!renderIf) return null;
  return (
    <Stack justify="space-between">
      <Box>
        <Box px="lg">
          <DataInfo
            title="User Type"
            description="OneView - Single Verification"
            onEditClick={onEditClick}
          />
          <EmployeeReviewAccordionItem {...form.values.data[0]} />
        </Box>
      </Box>
      {!!serviceCharge && (
        <ServiceCharge
          serviceCharge={{
            currency: serviceCharge?.currency,
            amount: +serviceCharge?.amount,
          }}
          icon
          withBackground
        />
      )}
    </Stack>
  );
};
