// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayjs = require('dayjs');

export type DateRangeKey =
  | 'lastMonth'
  | 'currentMonth'
  | 'sixMonths'
  | 'threeMonths'
  | 'oneYear';

export const lastMonthRange = {
  startDateTime: dayjs().subtract(1, 'month').startOf('month').toDate(),
  endDateTime: dayjs().subtract(1, 'month').endOf('month').toDate(),
};

export const currentMonthRange = {
  startDateTime: dayjs().startOf('month').toDate(),
  endDateTime: dayjs().endOf('month').toDate(),
};

export const sixMonthsRange = {
  startDateTime: dayjs().subtract(6, 'month').startOf('month').toDate(),
  endDateTime: dayjs().endOf('month').toDate(),
};

export const threeMonthsRange = {
  startDateTime: dayjs().subtract(3, 'month').startOf('month').toDate(),
  endDateTime: dayjs().endOf('month').toDate(),
};

export const oneYearRange = {
  startDateTime: dayjs().subtract(1, 'year').startOf('month').toDate(),
  endDateTime: dayjs().endOf('month').toDate(),
};

export const dateRanges: Record<
  DateRangeKey,
  { startDateTime: Date; endDateTime: Date }
> = {
  lastMonth: lastMonthRange,
  currentMonth: currentMonthRange,
  sixMonths: sixMonthsRange,
  threeMonths: threeMonthsRange,
  oneYear: oneYearRange,
};
