/* eslint-disable no-extra-boolean-cast */
import { Box, Checkbox, Group, Skeleton, Text } from '@mantine/core';
import {
  useStatusColor,
  useStatusText,
} from '../../../../../hooks/useStatusColor';
import * as React from 'react';
import { StatusBadge } from '../../../statusBadge/statusBadge';
import { formatPriceByCurrency } from '@iverifyng/utils';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useRequestCardStyle } from './requestCard.style';
import { RequestStatusTypes } from '@iverifyng/dtos';
dayjs.extend(advancedFormat);

export type Request = {
  id: string;
  name: string;
  text?: string | null;
  supportingText?: string | null;
  status?: RequestStatusTypes;
  price: number;
  currency?: string;
  date: Date;
  requester?: {
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
  };
};
export interface IRequestCardProps {
  active?: boolean;
  request: Request;
  selected?: boolean;
  onSelect?: (reqId: string, value: boolean) => void;
  onClick?: (reqId: string) => void;
  renderIcon?: React.ReactNode;
  skeleton?: boolean;
}

export const RequestCard = ({
  active,
  renderIcon,
  request,
  selected,
  onSelect,
  onClick,
  skeleton = false,
}: IRequestCardProps) => {
  const { id, status, name, supportingText, price, date, currency } = request;
  const color = useStatusColor(status);
  const statusName = useStatusText(status);
  const { classes } = useRequestCardStyle({ active, color, skeleton });

  return (
    <Group
      px={32}
      py="xs"
      align="center"
      position="apart"
      onClick={() => {
        if (!!onClick) onClick(id);
      }}
      className={classes.base}
    >
      <Group>
        {/* <Skeleton visible={skeleton} width={24}>
          <Checkbox
            color="blue"
            checked={selected}
            onChange={(e) => {
              if (!!onSelect) onSelect(id, e.target.checked);
            }}
          />
        </Skeleton> */}
        <Box>
          <Skeleton visible={skeleton}>
            <Group spacing="xs">
              {renderIcon}
              <Text weight={600}>{name}</Text>
              <StatusBadge status={status} radius="sm">
                <Text transform="capitalize" size="xs" align="center">
                  {statusName}
                </Text>
              </StatusBadge>
            </Group>

            <Text size="sm">
              {/* {text}
              {text && supportingText ? ' — ' : null} */}
              {supportingText && (
                <Text
                  component="span"
                  size="sm"
                  sx={(theme) => ({ color: theme.colors.gray[6] })}
                >
                  {supportingText}
                </Text>
              )}
            </Text>
          </Skeleton>
        </Box>
      </Group>
      <Group direction="column" spacing={skeleton ? 5 : 0} position="right">
        <Skeleton visible={skeleton}>
          <Text color="blue" weight={700} align="right">
            {formatPriceByCurrency(price, { notation: 'standard', currency })}
          </Text>
        </Skeleton>
        <Skeleton visible={skeleton}>
          <Text
            size="xs"
            sx={(theme) => ({ color: theme.colors.gray[8] })}
            align="right"
          >
            {dayjs(date).format('Do MMM, YYYY')}
          </Text>
        </Skeleton>
      </Group>
    </Group>
  );
};
