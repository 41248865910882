import { GetRequestResponseDto, ServiceCodeTypes } from '@iverifyng/dtos';
import { ServiceCategory } from '@prisma/client';
import { NINReport } from '../../../../../connected/responseDetails/reports/ng/nin';
import React, { useMemo } from 'react';
import { SharedResponseDetail } from '../interface';
import {
  CACResponseDto,
  DriversLicenseResponseDto,
  NINResponseDto,
  PhoneNumberResponseDto,
  TINResponseDto,
  VotersResponseDto,
} from '../../../../../connected/responseDetails/ng/interface';
import { DriversLicenseReport } from '../../../../../connected/responseDetails/reports/ng/driversLicense';
import { VotersCardReport } from '../../../../../connected/responseDetails/reports/ng/votersCard';
import { CACReport } from '../../../../../connected/responseDetails/reports/ng/cac';
import { TINReport } from '../../../../../connected/responseDetails/reports/ng/tin';
import EmptyState from '../../../../../components/primitives/emptyState/emptyState';
import { ResponseZeroIcon } from '../../../../../assets/icons';
import { GHDriversLicenseReport } from '../../../../../connected/responseDetails/reports/gh/driversLicense';
import {
  GHDriversLicenseResponseDto,
  GHPassportResponseDto,
  GHSSNITResponseDto,
  GHVotersResponseDto,
} from '../../../../../connected/responseDetails/gh/interface';
import { GHPassportReport } from '../../../../../connected/responseDetails/reports/gh/passport';
import { GHSNNITReport } from '../../../../../connected/responseDetails/reports/gh/snnit';
import { GHVotersCardReport } from '../../../../../connected/responseDetails/reports/gh/votersCard';
import { KEAlienCardReport } from '../../../../../connected/responseDetails/reports/ke/alienCard';
import {
  KEAlienCardResponseDto,
  KENationalIDResponseDto,
  KEPassportResponseDto,
} from '../../../../../connected/responseDetails/ke/interface';
import { KEPassportReport } from '../../../../../connected/responseDetails/reports/ke/passport';
import { KENationalIDReport } from '../../../../../connected/responseDetails/reports/ke/nationalId';
import { ZANationalIDReport } from '../../../../../connected/responseDetails/reports/za/nationalId';
import { ZANationalIDResponseDto } from '../../../../../connected/responseDetails/za/interface';
import { UGNationalIDReport } from '../../../../../connected/responseDetails/reports/ug/nationalId';
import { UGNationalIDResponseDto } from '../../../../../connected/responseDetails/ug/interface';
import { PhoneNumberReport } from '../../../../../connected/responseDetails/reports/ng/phoneNumber';

interface ISharedReportProps {
  request: GetRequestResponseDto;
  responseDetails: SharedResponseDetail;
}

export const SharedReport = ({
  request,
  responseDetails,
}: ISharedReportProps) => {
  const element = useMemo(() => {
    switch (request.service.category as ServiceCategory) {
      case 'IDENTITY':
        switch (request.service.country) {
          case 'NG':
            switch (request.service.serviceCode as ServiceCodeTypes) {
              case 'NIN':
              case 'NIN_SLIP':
                return <NINReport {...(responseDetails as NINResponseDto)} />;
              case 'DRIVERS_LICENSE':
                return (
                  <DriversLicenseReport
                    {...(responseDetails as DriversLicenseResponseDto)}
                  />
                );
              case 'PHONE_NUMBER':
                return (
                  <PhoneNumberReport
                    {...(responseDetails as PhoneNumberResponseDto)}
                  />
                );

              case 'VOTER_ID':
                return (
                  <VotersCardReport
                    {...(responseDetails as VotersResponseDto)}
                  />
                );
              case 'CAC':
                return <CACReport {...(responseDetails as CACResponseDto)} />;
              case 'TIN':
                return <TINReport {...(responseDetails as TINResponseDto)} />;
              default:
                return (
                  <EmptyState
                    icon={<ResponseZeroIcon />}
                    subTitle="Response details not available"
                  />
                );
            }
          case 'GH':
            switch (request.service.serviceCode as ServiceCodeTypes) {
              case 'DRIVERS_LICENSE':
                return (
                  <GHDriversLicenseReport
                    {...(responseDetails as GHDriversLicenseResponseDto)}
                  />
                );
              case 'PASSPORT':
                return (
                  <GHPassportReport
                    {...(responseDetails as GHPassportResponseDto)}
                  />
                );

              case 'SSNIT':
                return (
                  <GHSNNITReport {...(responseDetails as GHSSNITResponseDto)} />
                );

              case 'VOTER_ID':
              case 'NEW_VOTER_ID':
                return (
                  <GHVotersCardReport
                    {...(responseDetails as GHVotersResponseDto)}
                  />
                );
              default:
                return (
                  <EmptyState
                    icon={<ResponseZeroIcon />}
                    subTitle="Response details not available"
                  />
                );
            }
          case 'KE':
            switch (request.service.serviceCode as ServiceCodeTypes) {
              case 'ALIEN_CARD':
                return (
                  <KEAlienCardReport
                    {...(responseDetails as KEAlienCardResponseDto)}
                  />
                );
              case 'PASSPORT':
                return (
                  <KEPassportReport
                    {...(responseDetails as KEPassportResponseDto)}
                  />
                );
              case 'NATIONAL_ID':
                return (
                  <KENationalIDReport
                    {...(responseDetails as KENationalIDResponseDto)}
                  />
                );
              default:
                return (
                  <EmptyState
                    icon={<ResponseZeroIcon />}
                    subTitle="Response details not available"
                  />
                );
            }
          case 'ZA':
            switch (request.service.serviceCode as ServiceCodeTypes) {
              case 'NATIONAL_ID':
              case 'NATIONAL_ID_NO_PHOTO':
                return (
                  <ZANationalIDReport
                    {...(responseDetails as ZANationalIDResponseDto)}
                  />
                );
              default:
                return (
                  <EmptyState
                    icon={<ResponseZeroIcon />}
                    subTitle="Response details not available"
                  />
                );
            }
          case 'UG':
            switch (request.service.serviceCode as ServiceCodeTypes) {
              case 'NATIONAL_ID_NO_PHOTO':
                return (
                  <UGNationalIDReport
                    {...(responseDetails as UGNationalIDResponseDto)}
                  />
                );
              default:
                return (
                  <EmptyState
                    icon={<ResponseZeroIcon />}
                    subTitle="Response details not available"
                  />
                );
            }
          default:
            return null;
        }
      default:
        return (
          <EmptyState
            icon={<ResponseZeroIcon />}
            subTitle="Response details not available"
          />
        );
    }
  }, [
    request.service.category,
    request.service.country,
    request.service.serviceCode,
    responseDetails,
  ]);

  return element;
};
