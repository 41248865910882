import { Group, Button, Stack, Box, Alert } from '@mantine/core';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import {
  IServiceCharge,
  ServiceCharge,
} from '../../../../../../components/primitives/price/servicecharge';
import { TenantIcon } from '../../../../../../assets/icons/index';
import { ITenantFormProps } from '../interface';
import { AlertTriangle } from 'tabler-icons-react';
import { TenantVerificationSchema } from '@iverifyng/validators';
import { useEffect, useState } from 'react';
import { SingleTenantAccordion } from './tenantRequestDetails';
import { useLocation } from '@tanstack/react-location';

interface ITenantConfirmationProps {
  formvalues: ITenantFormProps;
  billed?: IServiceCharge;
  loading?: boolean;
  onSingleEditClick?: () => void;
  onEdit: (index: number) => void;
  onBack?: () => void;
  onRemove?: (index: number) => void;
  onSubmit?: () => void;
  renderIf?: boolean;
}

export const TenantFormConfirmation = ({
  formvalues,
  onSingleEditClick,
  billed,
  onBack,
  onEdit,
  onRemove,
  loading,
  onSubmit,
  renderIf,
}: ITenantConfirmationProps) => {
  const [hasError, setHasError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const { error } =
      TenantVerificationSchema.NEW_VERIFICATION_SCHEMA.validate(formvalues);
    setHasError(error?.message);
  }, [hasError, formvalues]);

  if (!renderIf) return null;
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Tenant Verification"
          supportingText="Review"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          withBackArrow
          icon={<TenantIcon width={32} height={32} />}
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel request
          </Button>
          <Button
            onClick={() => {
              if (onSubmit) onSubmit();
            }}
            loading={loading}
            disabled={loading}
          >
            Submit
          </Button>
        </Group>
      }
    >
      <Stack justify="space-between" sx={() => ({ height: '100%' })}>
        {formvalues.data.length > 1 ? (
          <Box pt={'md'}>
            {hasError && (
              <Alert icon={<AlertTriangle size={16} />} color="yellow">
                {hasError}
              </Alert>
            )}
            <Stack px="lg" spacing={'lg'}>
              {formvalues.data.map((item, index) => (
                <SingleTenantAccordion
                  key={index}
                  formvalues={item}
                  index={index}
                  onEdit={onEdit}
                  onRemove={onRemove}
                />
              ))}
            </Stack>
          </Box>
        ) : (
          <Stack px="lg" spacing={'lg'} pt="md">
            <SingleTenantAccordion
              index={0}
              onEdit={onSingleEditClick}
              formvalues={formvalues.data[0]}
            />
          </Stack>
        )}
        {!!billed && (
          <ServiceCharge
            serviceCharge={{
              currency: billed?.currency,
              amount: +billed?.amount,
            }}
            icon
            withBackground
          />
        )}
      </Stack>
    </RequestLayout>
  );
};
