import { AccordionItem, Stack, Text } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';
import { triggerURLDownload } from '@iverifyng/utils';
import { propertyStatusDescription } from '../../../utils/status';
import { PropertyVerification } from '@prisma/client';
import { RequestDetailsBlock } from '../block';
import { AdminProperty3DotMenu } from '../../menus/requests/property';

interface IProps {
  index: number;
  record: PropertyVerification;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const PropertyAccordionItem = ({ record, index }: IProps) => {
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={[record.type, record.city, record.state]
              .filter(Boolean)
              .join(' - ')}
            rightIcon={<AdminProperty3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={propertyStatusDescription[record.status!]}
              />
            }
          />
          {record.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}

          <RequestDetailsBlock title="Property">
            <RequestDataLayout
              label="Type"
              data={
                <Text size="sm" transform="capitalize">
                  {record.type ?? 'N/A'}
                </Text>
              }
            />
            <RequestDataLayout
              label="Document"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.documentUrl!)}
                />
              }
            />
          </RequestDetailsBlock>
          <RequestDetailsBlock title="Location">
            <RequestDataLayout label="Street" data={record.street ?? 'N/A'} />
            <RequestDataLayout label="City" data={record.city ?? 'N/A'} />
            <RequestDataLayout label="State" data={record.state ?? 'N/A'} />
            <RequestDataLayout label="Country" data={record.country ?? 'N/A'} />
          </RequestDetailsBlock>
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
