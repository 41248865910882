import {
  Text,
  Group,
  TextInput,
  Textarea,
  Button,
  Stack,
  Checkbox,
} from '@mantine/core';
import { RequestHeader } from '../../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { NationalIdIcon } from '../../../../../../../assets/icons/index';
import { RequestLayout } from '../../../../../../../components/layouts/request/requestLayout';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { ZA_NationalIdRequestDto } from '@iverifyng/dtos';
import { ServiceSampleImageAccordion } from '../../../../../../../components/primitives/cards/services/serviceSampleImage';

export interface ZANationalIDFormData extends ZA_NationalIdRequestDto {
  description: string;
}

interface IZANationalIDFormProps {
  form: UseFormReturnType<ZANationalIDFormData>;
  renderIf?: boolean;
  onBack?: () => void;
  withPhoto?: boolean;
  onPhotoCheck?: (value: boolean) => void;
}

export const ZANationalIDForm = ({
  form,
  onBack,
  renderIf,
  withPhoto,
  onPhotoCheck,
}: IZANationalIDFormProps) => {
  if (!renderIf) return null;

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="National ID Verification"
          onClickBack={() => {
            if (onBack) onBack();
          }}
          icon={<NationalIdIcon width={42} height={42} />}
          withBackArrow
          withBackground
        />
      }
      footerFragment={
        <Group position="apart" grow>
          <Button
            color="dark"
            variant="outline"
            onClick={() => {
              if (onBack) onBack();
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Verify</Button>
        </Group>
      }
    >
      <Stack justify="space-between" py="md" sx={() => ({ minHeight: '100%' })}>
        <Group direction="column" px="lg" grow spacing="md">
          <ServiceSampleImageAccordion
            title="Sample National ID Slip"
            image="https://storage.googleapis.com/iverify-public/sample-ids/za-nationalid.png"
          />
          <Text color="gray" size="sm">
            National ID verification request provides you with the owner's first
            and last name, date of birth, etc.
          </Text>
          <TextInput
            label="National Identity Number"
            placeholder="Enter National ID"
            {...form.getInputProps('idNumber')}
            required
          />
          <Textarea
            label="Description"
            {...form.getInputProps('description')}
          />
          <Checkbox
            label="Do you want the Photo attached to the report?"
            checked={withPhoto}
            onChange={(e) => {
              //e.preventDefault();

              if (onPhotoCheck) {
                onPhotoCheck(e.target.checked);
              }
            }}
          />
        </Group>
      </Stack>
    </RequestLayout>
  );
};
