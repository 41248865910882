import { withAuth } from '../../../hoc/withAuth';
import { AdminLayout } from '../../../components/layouts/admin';
import { withAdmin } from '../../../hoc/withAdmin';
import { StatsGridIcons } from '../../../components/primitives/cards/admin/adminDashboardCard/adminDashboardCard';
import IntroText from '../../../connected/intro/intro';
import { Box, Stack } from '@mantine/core';
import { useGetAdminAnalyticsDashboard } from '../../../hooks/analytics';
import { AdminDashLoader } from '../../../components/primitives/skeletons/adminDash';
import { useMemo } from 'react';
import { AppRBAC } from '../../permission/app';

const dashboardPermissionAction = 'canViewDashboard';

function AdminDashboard() {
  const { data, isLoading } = useGetAdminAnalyticsDashboard();

  const isEmpty = useMemo(() => {
    if (isLoading) return false;
    if (!data || !data.length) return true;
    return false;
  }, [isLoading, data]);

  return (
    <AppRBAC action={dashboardPermissionAction}>
      <AdminLayout>
        <Box
          sx={() => ({ height: 'calc(100vh - 77.19px)', overflow: 'scroll' })}
        >
          <Stack spacing="xl" px={32} pt={48}>
            <IntroText
              heading="Hello"
              text="Measure your ROI and track your goals."
            />
          </Stack>

          {isLoading ? (
            <AdminDashLoader />
          ) : isEmpty ? (
            <AdminDashLoader />
          ) : (
            <StatsGridIcons data={data!} showDiff />
          )}
        </Box>
      </AdminLayout>
    </AppRBAC>
  );
}

export default withAuth(withAdmin(AdminDashboard));
