import { CreditCheckProductCodeEnum, CreditCheckStatus } from '@prisma/client';
import * as Joi from 'joi';

/** Commercial Schema Validation */
const commercialSchemaFields = {
  enquiryReason: Joi.string().required().label('Enquiry reason'),
  businessName: Joi.string().required().label('Business name'),
  businessRegNo: Joi.string().required().label('Business registration number'),
  accountNumber: Joi.string().required().label('Account number'),
};
const CommercialDetailedReportSchema = Joi.object({})
  .unknown(true)
  .when(Joi.object({ businessName: Joi.string().exist() }).unknown(), {
    then: Joi.object({
      businessRegNo: Joi.string().optional().allow(null, ''),
      accountNumber: Joi.string().optional().allow(null, ''),
    }),
    otherwise: Joi.object({
      businessName: commercialSchemaFields.businessName,
    }),
  })
  .when(Joi.object({ businessRegNo: Joi.string().exist() }).unknown(), {
    then: Joi.object({
      businessName: Joi.string().optional().allow(null, ''),
      accountNumber: Joi.string().optional().allow(null, ''),
    }),
    otherwise: Joi.object({
      businessRegNo: commercialSchemaFields.businessRegNo,
    }),
  })
  .when(Joi.object({ accountNumber: Joi.string().exist() }).unknown(), {
    then: Joi.object({
      businessName: Joi.string().optional().allow(null, ''),
      businessRegNo: Joi.string().optional().allow(null, ''),
    }),
    otherwise: Joi.object({
      accountNumber: commercialSchemaFields.accountNumber,
    }),
  });

/** Consumer Schema Validation */
const consumerSchemaFields = {
  enquiryReason: Joi.string().required().label('Enquiry reason'),
  consumerName: Joi.string().min(2).required().label("Consumer's Full Name"),
  identification: Joi.string().required().label('ID Number'),
  accountNumber: Joi.string().required().label('Account Number'),
  dateOfBirth: Joi.date().iso().required().label('Date of Birth'),
};

const ConsumerDetailedReportSchema = Joi.object({})
  .unknown(true)
  .when(Joi.object({ identification: Joi.string().exist() }).unknown(), {
    then: Joi.object({
      consumerName: Joi.string().optional().allow(null, ''),
      dateOfBirth: Joi.date().optional().allow(null, ''),
      accountNumber: Joi.string().optional().allow(null, ''),
    }),
    otherwise: Joi.object({
      identification: consumerSchemaFields.identification,
    }),
  })
  .when(Joi.object({ accountNumber: Joi.string().exist() }).unknown(), {
    then: Joi.object({
      consumerName: Joi.string().optional().allow(null, ''),
      dateOfBirth: Joi.date().optional().allow(null, ''),
      identification: Joi.string().optional().allow(null, ''),
    }),
    otherwise: Joi.object({
      accountNumber: consumerSchemaFields.accountNumber,
    }),
  })
  .when(
    Joi.object({
      consumerName: Joi.string().exist(),
      dateOfBirth: Joi.date().exist(),
    }).unknown(),
    {
      then: Joi.object({
        accountNumber: Joi.string().optional().allow(null, ''),
        identification: Joi.string().optional().allow(null, ''),
      }),
      otherwise: Joi.object({
        consumerName: consumerSchemaFields.consumerName,
        dateOfBirth: consumerSchemaFields.dateOfBirth,
      }),
    }
  );
const NEW_VERIFICATION_SCHEMA = Joi.object({
  code: Joi.string()
    .valid(...Object.values(CreditCheckProductCodeEnum))
    .required()
    .label('Report type'),
  data: Joi.when('code', {
    switch: [
      {
        is: CreditCheckProductCodeEnum.COMMERCIAL_DETAILED_REPORT,
        then: Joi.array().items(CommercialDetailedReportSchema),
      },
      {
        is: CreditCheckProductCodeEnum.CONSUMER_DETAILED_REPORT,
        then: Joi.array().items(ConsumerDetailedReportSchema),
      },
      {
        is: CreditCheckProductCodeEnum.XSCORE_CONSUMER_DETAILED_REPORT,
        then: Joi.array().items(ConsumerDetailedReportSchema),
      },
    ],
  })
    .required()
    .label('Form data'),
  description: Joi.string().allow('').optional(),
});

const ADMIN_UPDATE_REPORT = Joi.object({
  creditCheckId: Joi.string().required().label('Credit Check ID'),
  reportUrl: Joi.string().uri().required().label('Report URL'),
});

const ADMIN_UPDATE_CREDIT_CHECK = Joi.object({
  id: Joi.string().required().label('ID'),
  requestId: Joi.string().optional().label('Request ID'),
  status: Joi.string()
    .valid(...Object.values(CreditCheckStatus))
    .optional()
    .label('Status'),
}).fork([], (schema) => schema.optional());

export const CreditCheckSchema = {
  ADMIN_UPDATE_REPORT,
  NEW_VERIFICATION_SCHEMA,
  ADMIN_UPDATE_CREDIT_CHECK,
};
