import { Stack } from '@mantine/core';
import { companyRequestList } from '../../../../../fakedata/requestData';
import { useLocation, useNavigate } from '@tanstack/react-location';
import RequestType from '../../../../../components/primitives/cards/request/requestType';
import { RequestHeader } from '../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../components/layouts/request/requestLayout';
import { useQuery } from 'react-query';
import { GetServices } from '../../../../../services/apis/requests';
import { RequestTypeSkeleton } from '../../../../../components/primitives/skeletons/requests';

export const CompanyVerification = () => {
  const navigate = useNavigate();
  const { history } = useLocation();

  const { data, isLoading } = useQuery('services', GetServices);

  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Company Verification"
          onClickBack={() => history.back()}
          withBackArrow
        />
      }
    >
      <Stack p="xl">
        {isLoading || !data?.length ? (
          <RequestTypeSkeleton limit={2} />
        ) : (
          companyRequestList.map(
            ({ type, description, icon, pathName, abbrev, visiblefn }, idx) => {
              if (visiblefn) {
                const isvisible = visiblefn(data!);

                if (!isvisible) return null;
              }
              return (
                <RequestType
                  key={idx}
                  {...{ type }}
                  {...{ description }}
                  {...{ icon }}
                  abbrev={abbrev}
                  onClick={() => navigate({ to: pathName })}
                />
              );
            }
          )
        )}
      </Stack>
    </RequestLayout>
  );
};
