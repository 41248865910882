import { LoadingOverlay } from '@mantine/core';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { AcceptWorkspaceInviteApi } from '../../../services/apis/workspace';
import { useQuery } from 'react-query';
import { withAuth } from '../../../hoc/withAuth';
import { useCurrentWorkspace } from '../../../hooks/workspace';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useUserProfile } from '../../../services/queries/users';

const Comp = () => {
  const {
    params: { inviteId },
  } = useMatch();
  const navigate = useNavigate();
  const { refetch } = useUserProfile();
  const { setWorkspaceId } = useCurrentWorkspace();
  useQuery('accept-invite', () => AcceptWorkspaceInviteApi(inviteId), {
    enabled: !!inviteId,
    retry: false,
    onSuccess: (response) => {
      showNotification({
        message: 'Invite accepted. Redirecting...',
      });
      refetch(); //refetch user's workspaces
      setWorkspaceId(response.workspaceId);
      navigate({ to: '/dashboard' });
    },
    onError: (error) => {
      showNotification({
        color: 'red',
        message: (error as AxiosError<{ message: string }>).response?.data
          ?.message,
      });
      navigate({ to: '/dashboard' });
    },
  });
  return <LoadingOverlay visible />;
};

export const AcceptWorkspaceInvite = withAuth(Comp);
