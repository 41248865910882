import { DashRequestCard } from '../cards/dashboard/dashRequestCard/dashRequestCard';

export const DashRequestSkeleton = ({ limit = 10 }: { limit?: number }) => {
  if (limit < 1) return null;
  return (
    <>
      {Array(limit)
        .fill('*')
        .map((_, i) => (
          <DashRequestCard
            key={i}
            skeleton
            request={{
              id: '1',
              name: 'NIN Slip Verification - Nigeria',
              text: '#0000000000000',
              supportingText: 'Sample description to generate skeleton',
              price: 0,
              date: new Date(),
            }}
          />
        ))}
    </>
  );
};
