import {
  CreateAgentReportDto,
  FindAgentReportDto,
  FindAgentReportResponseDto,
} from '@iverifyng/dtos';
import axiosInstance from '../axios';

export const AgentCreateReportApi = async (data: CreateAgentReportDto) => {
  return await axiosInstance
    .post('/agent/report/create', data)
    .then((response) => response.data);
};

export const AgentFindReportApi = async (
  data: FindAgentReportDto
): Promise<FindAgentReportResponseDto> => {
  return await axiosInstance
    .post('/agent/report/find', data)
    .then((response) => response.data);
};
