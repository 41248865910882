import {
  Anchor,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  Box,
} from '@mantine/core';
import {
  Link,
  MakeGenerics,
  useNavigate,
  useSearch,
} from '@tanstack/react-location';
import { withDashboard } from '../../../hoc/withDashboard';
import { ArrowNarrowLeft } from 'tabler-icons-react';
import { AuthLayout } from '../../../components/layouts/auth';
import { VerifyAccountCodeDto } from '@iverifyng/services/auth';
import { AuthValidatorSchema } from '@iverifyng/validators';
import { useForm } from '@mantine/form';
import { OtpInput } from '../../../components/primitives/otp';
import { useCallback, useMemo } from 'react';
import { showNotification } from '@mantine/notifications';
import { useMutation } from 'react-query';
import {
  ResendVerificationCodeApi,
  VerifyAccountCodeApi,
} from '../../../services/apis/users';
import { AxiosError } from 'axios';

type MyLocationGenerics = MakeGenerics<{
  Search: {
    userId: string;
  };
}>;

function VerifyOtp() {
  const search = useSearch<MyLocationGenerics>();
  const navigate = useNavigate();
  const form = useForm<VerifyAccountCodeDto>({
    initialValues: {
      code: '',
      userId: search?.userId ?? '',
    },
  });
  const verifyCodeMutation = useMutation(VerifyAccountCodeApi);
  const resendVerificationCode = useMutation(ResendVerificationCodeApi);

  const handleSubmit = useCallback(
    (values: VerifyAccountCodeDto) => {
      const { error } =
        AuthValidatorSchema.VERIFY_ACCOUNT_CODE.validate(values);
      if (error) {
        showNotification({ message: error.message, color: 'red' });
        return;
      }

      verifyCodeMutation.mutate(
        { ...values },
        {
          onSuccess: () => {
            navigate({
              to: '/auth/resetpassword',
              replace: true,
              search: { ...values },
            });
          },
          onError: (error) => {
            showNotification({
              message:
                (error as AxiosError<{ message: string }>)?.response?.data
                  ?.message ?? 'Error occurred',
              color: 'red',
            });
          },
        }
      );
    },
    [navigate, verifyCodeMutation]
  );

  const handleResendEmail = useCallback(() => {
    resendVerificationCode.mutate(
      { userId: form.values.userId },
      {
        onSuccess: () => {
          showNotification({ message: 'Email resent', color: 'green' });
        },
        onError: () => {
          showNotification({ message: 'Error occurred', color: 'red' });
        },
      }
    );
  }, [form.values.userId, resendVerificationCode]);

  const disableButton = useMemo(() => {
    return form.values.code.length !== 4 || verifyCodeMutation.isLoading;
  }, [form.values.code.length, verifyCodeMutation.isLoading]);
  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <Stack spacing={8} justify="center" align={'center'} mb="xl">
          <Title order={2}>Verification code</Title>
          <Text size="md" align="center">
            To continue, please enter the verification code that was sent to
            your email.
          </Text>
        </Stack>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <OtpInput
            value={form.values.code}
            onChange={(otp: string) => form.setFieldValue('code', otp)}
          />
          <Group position="apart" mt="xl">
            <Button type="submit" fullWidth disabled={disableButton}>
              Verify email
            </Button>
          </Group>
        </form>
        <Group position="center" mt="xl">
          <Text size="sm">
            Didn't receive the email?{' '}
            <Anchor
              component="button"
              type="button"
              color="blue"
              size="sm"
              disabled={resendVerificationCode.isLoading}
              onClick={handleResendEmail}
            >
              {'Click to resend'}
            </Anchor>
          </Text>
        </Group>
        <Group position="center" mt="xl">
          <Link to={'/auth/login'}>
            <Anchor component="button" type="button" color="blue" size="sm">
              <Center inline>
                <ArrowNarrowLeft size={16} />
                <Box ml={5}>{'Back to Login'}</Box>
              </Center>
            </Anchor>
          </Link>
        </Group>
      </Paper>
    </AuthLayout>
  );
}

export default withDashboard(VerifyOtp);
