import { AccordionItem, Divider, Stack } from '@mantine/core';
import RequestDataLayout from '../../../components/layouts/request/requestDataLayout';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../components/controls/accordions/formListAccordion/formListAccordion';
import { RequestRecordStatusBadge } from '../../../components/primitives/statusBadge/statusBadge';
import { PDFDownloadButton } from '../../../components/primitives/button/download';

import { triggerURLDownload } from '@iverifyng/utils';
import { addressStatusDescription } from '../../../utils/status';

import { AddressVerification } from '@prisma/client';
import { RequestDetailsBlock } from '../block';
import { AdminAddressWork3DotMenu } from '../../menus/requests/address';

interface IProps {
  index: number;
  record: AddressVerification;
  onFileUploadSuccess?: ((url: string, file: File) => void) | undefined;
}
export const AddressAccordionItem = ({ record, index }: IProps) => {
  return (
    <FormListAccordion
      iconPosition="right"
      initialItem={0}
      multiple
      key={index}
      pb="md"
    >
      <AccordionItem
        label={
          <FormListAccordionLabel
            listCount={index + 1}
            name={record.fullName!}
            rightIcon={<AdminAddressWork3DotMenu record={record} />}
          />
        }
      >
        <Stack spacing="sm">
          <RequestDataLayout
            label="Status"
            data={
              <RequestRecordStatusBadge
                status={record.status!}
                statusName={addressStatusDescription[record.status!]}
              />
            }
          />
          <RequestDetailsBlock title="Address Details">
            <RequestDataLayout label="Full Name" data={record.fullName} />
            <RequestDataLayout label="Phone Number" data={record.phoneNo!} />
            <RequestDataLayout label=" Address" data={record.address} />
          </RequestDetailsBlock>

          <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />

          {record.status === 'VERIFIED' && (
            <RequestDataLayout
              label="Completed Report"
              data={
                <PDFDownloadButton
                  disabled={!record.reportUrl}
                  onClick={() => triggerURLDownload(record.reportUrl!)}
                />
              }
            />
          )}
        </Stack>
      </AccordionItem>
    </FormListAccordion>
  );
};
