import { GetRequestResponseDto, ServiceCodeTypes } from '@iverifyng/dtos';
import {
  AlienCardIcon,
  CACIcon,
  DriverIcon,
  NationalIdIcon,
  NINIcon,
  PassportIcon,
  SSNITIcon,
  TINIcon,
  ValidateCriminalCheckIcon,
  VoterIcon,
  CertificateIcon,
  TenantIcon,
  GuarantorIcon,
  EmployeeIcon,
  CreditIcon,
  PropertyIcon,
  TelephoneIcon,
  PreviousWorkAddressIcon,
} from '../../../../../assets/icons';
import { replace } from 'lodash-es';

export const getRequestCardText = (request: GetRequestResponseDto) => {
  if (request.service.category === 'IDENTITY') {
    return `#${request.identity.idNumber}`;
  }
  return null;
};

export const getRequestCardSupportText = (request: GetRequestResponseDto) => {
  if (request.service.category === 'IDENTITY') {
    return [
      replace(request.service.serviceCode, '_', '-'),
      `#${request.identity.idNumber}`,
      request.description ?? request.service.name,
    ]
      .filter(Boolean)
      .join(' - ');
  }
  return [request.id.toUpperCase(), request.description ?? request.service.name]
    .filter(Boolean)
    .join(' - ');
};

export const getRequestCardIcon = (
  request: GetRequestResponseDto,
  size = 24
) => {
  switch (request?.service.category) {
    case 'IDENTITY':
      switch (request.service.serviceCode as ServiceCodeTypes) {
        case 'NIN':
        case 'NIN_SLIP':
          return <NINIcon width={size} height={size} />;
        case 'PHONE_NUMBER':
          return <TelephoneIcon width={size} height={size} />;
        case 'CAC':
          return <CACIcon width={size} height={size} />;
        case 'DRIVERS_LICENSE':
          return <DriverIcon width={size} height={size} />;
        case 'SSNIT':
          return <SSNITIcon width={size} height={size} />;
        case 'PASSPORT':
          return <PassportIcon width={size} height={size} />;
        case 'TIN':
          return <TINIcon width={size} height={size} />;
        case 'VOTER_ID':
        case 'NEW_VOTER_ID':
          return <VoterIcon width={size} height={size} />;
        case 'NATIONAL_ID':
        case 'NATIONAL_ID_NO_PHOTO':
          return <NationalIdIcon width={size} height={size} />;
        case 'ALIEN_CARD':
          return <AlienCardIcon width={size} height={size} />;
        default:
          return null;
      }
    case 'CRIMINAL_RECORD':
      return <ValidateCriminalCheckIcon width={size} height={size} />;
    case 'CERTIFICATE':
      return <CertificateIcon width={size} height={size} />;
    case 'TENANT':
      return <TenantIcon width={size} height={size} />;
    case 'GUARANTOR':
      return <GuarantorIcon width={size} height={size} />;
    case 'EMPLOYEE':
      return <EmployeeIcon width={size} height={size} />;
    case 'PROPERTY':
      return <PropertyIcon width={size} height={size} />;
    case 'CREDIT_CHECK':
      return <CreditIcon width={size} height={size} />;
    case 'PREVIOUS_WORK':
      return <PreviousWorkAddressIcon width={size} height={size} />;
    default:
      return null;
  }
};

export const getRequestTypeLabel = (request: GetRequestResponseDto) => {
  switch (request.service.category) {
    case 'IDENTITY':
      switch (request.service.serviceCode as ServiceCodeTypes) {
        case 'NIN':
        case 'NIN_SLIP':
          return 'NIN';
        case 'CAC':
          return 'RC/N';
        case 'DRIVERS_LICENSE':
          return 'L/NO';
        case 'SSNIT':
          return 'SSNIT Number';
        case 'PASSPORT':
          return 'Passport No';
        case 'TIN':
          return 'TIN';
        case 'VOTER_ID':
        case 'NEW_VOTER_ID':
          return 'VIN';
        case 'NATIONAL_ID':
          return 'ID Number';
        case 'ALIEN_CARD':
          return 'Indiv. Number';
        default:
          return 'ID Number';
      }
    case 'CRIMINAL_RECORD':
      return 'ID Number';
    default:
      return null;
  }
};

export const getTenantIDType = (id: string) => {
  switch (id) {
    case 'NIN':
    case 'NIN_SLIP':
      return 'National Identity Number (NIN)';
    case 'CAC':
      return 'RC/N';
    case 'DRIVERS_LICENSE':
      return "Driver's License";
    case 'SSNIT':
      return 'SSNIT Number';
    case 'PASSPORT':
      return 'Passport No';
    case 'TIN':
      return 'TIN';
    case 'VOTER_ID':
    case 'NEW_VOTER_ID':
      return 'VIN';
    case 'NATIONAL_ID':
      return 'ID Number';
    case 'ALIEN_CARD':
      return 'Indiv. Number';
    default:
      return 'ID Number';
  }
};
