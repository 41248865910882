import { Transition } from '@mantine/core';
import { Outlet } from '@tanstack/react-location';
import * as React from 'react';

export const RequestDrawer = () => {
  return (
    <Transition
      mounted={true}
      transition={'slide-left'}
      duration={300}
      timingFunction="ease"
    >
      {(styles) => (
        <div
          id="request-me"
          style={{ ...styles, width: 400, position: 'relative' }}
        >
          <div
            style={{
              background: '#FFF',
              width: '100%',
              height: 'calc(100vh - 68px)',
              boxShadow: '-2px 1px 5px 0px rgba(0,0,0,0.15)',
            }}
          >
            <Outlet />
          </div>
        </div>
      )}
    </Transition>
  );
};
