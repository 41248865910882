import { Center, Group, Text, UnstyledButton } from '@mantine/core';

interface IEmployeeTypeProps {
  isActive?: boolean;
  icon: React.ReactElement;
  actionText: string;
  onClick: () => void;
}

export const VerificationType = ({
  isActive,
  icon,
  actionText,
  onClick,
}: IEmployeeTypeProps) => {
  return (
    <UnstyledButton onClick={onClick}>
      <Center
        sx={(theme) => ({
          backgroundColor: isActive
            ? theme.colors.blue[0]
            : theme.colors.gray[1],
          color: isActive ? theme.colors.blue[4] : theme.colors.gray[5],
          borderRadius: '8px',
          border: isActive ? `1px solid ${theme.colors.blue[4]}` : 'none',
          width: 164,
          height: 164,
        })}
      >
        <Group direction="column" align={'center'} spacing={4}>
          {icon}
          <Text color="gray">{actionText}</Text>
        </Group>
      </Center>
    </UnstyledButton>
  );
};
