import {
  CreateRequestCommentDto,
  GetAdminRequestsResponseDto,
  GetAgentResponseDto,
  GetAssignedAgentsResponseDto,
  GetPagedUsersResponseDto,
  GetRequestCommentsDto,
  GetRequestResponseDto,
  GetUserResponseDto,
  GetWorkspaceUsersResponseDto,
  GetWorkspaceUserResponseDto,
  ServiceCodeTypes,
  GetPagedWorkspacesResponseDto,
  AdminDashboardResponseDto,
  AdminGetServiceDto,
  AdminGetServicesDto,
  AssignAgentsRequestDto,
  AssignAgentsPerRequestDto,
} from '@iverifyng/dtos';
import qs from 'query-string';
import {
  CommentType,
  Prisma,
  RequestStatus,
  Service,
  User,
  Workspace,
} from '@prisma/client';
import { StatusTab } from '../../containers/admin/request/request';
import axiosInstance from '../axios';
import { IUpdateWorkspaceRequestDto } from './workspace';
import { Interface } from 'readline';

type AgentQuery = {
  requestId: string;
  users: string[];
};

type AssignAgentsDto = {
  requestId: string;
  data: AssignAgentsRequestDto['data'];
};
type AssignPerAgentsDto = {
  requestId: string;
  data: AssignAgentsPerRequestDto;
};

export interface CreateCommentParam {
  requestId: string;
  comment: CreateRequestCommentDto;
}
export interface UpdateStatusParam {
  requestId: string;
  status: RequestStatus;
}

export interface RequestCommentResponseDto {
  id: string;
  message: string;
  createdAt: string;
  requestId: string;
  type: CommentType;
  updatedAt: string;
  userId: string;
}

export interface IAdminRequestQueryDto {
  status?: StatusTab;
  serviceCode?: ServiceCodeTypes;
  startDate?: string;
  endDate?: string;

  page?: number;
  limit?: number;
  workspaces?: string[];
  searchterm?: string;
  userId?: string;
}

export interface IAdminUsers {
  page?: number;
  limit?: number;
  searchterm?: string;
}

export interface IUpdateServiceVisibilityDto {
  id: string;
  data: Prisma.ServiceUpdateInput;
};

export interface IAssignAgentToRequestPerJobDTa extends AssignPerAgentsDto {
  jobId: string
}

//get available Agents
export const GetAdminAvailableAgents = async (): Promise<User[]> => {
  return await axiosInstance
    .get(`/admin/agents/available`)
    .then((response) => response.data);
};

export const AssignAgentToRequest = async ({
  requestId,
  data,
}: AssignAgentsDto): Promise<GetAgentResponseDto> => {
  return await axiosInstance
    .post(`/admin/requests/${requestId}/agents/assign`, { data })
    .then((response) => response.data);
};
export const AssignAgentToRequestPerJob = async ({
  requestId,
  jobId,
  data,
}: IAssignAgentToRequestPerJobDTa): Promise<GetAgentResponseDto> => {
  return await axiosInstance
    .post(`/admin/requests/${requestId}/agents/assign/${jobId}`, { data })
    .then((response) => response.data);
};

export const UnAssignAgentFromRequest = async ({
  requestId,
  users,
}: AgentQuery): Promise<GetAgentResponseDto> => {
  return await axiosInstance
    .post(`/admin/requests/${requestId}/agents/unassign`, { users })
    .then((response) => response.data);
};

// get assigned agents by response
export const GetRequestAssignedAgents = async (
  requestId: string
): Promise<GetAssignedAgentsResponseDto> => {
  return await axiosInstance
    .get(`/admin/requests/${requestId}/agents`)
    .then((response) => response.data);
};

// get assigned agents by response
export const GetAdminRequests = async ({
  status,
  ...query
}: IAdminRequestQueryDto): Promise<GetAdminRequestsResponseDto> => {
  const url = qs.stringifyUrl({
    url: `/admin/requests`,
    query: { ...query, status: status?.toUpperCase() },
  });
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetRequestDetails = async (
  requestId: string
): Promise<GetRequestResponseDto> => {
  const url = `/admin/requests/${requestId}`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetIdentityResponseDetail = async (
  requestId: string,
  identityId: string
): Promise<unknown> => {
  const url = `/admin/requests/${requestId}/identity/responseDetails/${identityId}`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetRequestComments = async (
  requestId: string
): Promise<GetRequestCommentsDto> => {
  const url = `/admin/requests/${requestId}/comments`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const CreateComment = async ({
  requestId,
  comment,
}: CreateCommentParam): Promise<RequestCommentResponseDto[]> => {
  const url = `/admin/requests/${requestId}/comments`;
  return await axiosInstance
    .post(url, comment)
    .then((response) => response.data);
};

export const DeleteComment = async (
  requestId: string,
  commentId: string
): Promise<unknown> => {
  const url = `/admin/requests/${requestId}/comments/${commentId}`;
  return await axiosInstance.delete(url).then((response) => response.data);
};

export const UpdatedRequestStatus = async ({
  status,
  requestId,
}: UpdateStatusParam): Promise<unknown> => {
  const url = `/admin/requests/${requestId}/updateStatus`;

  return await axiosInstance
    .post(url, { status })
    .then((response) => response.data);
};

export const GetAdminUsers = async (
  params: IAdminUsers
): Promise<GetPagedUsersResponseDto> => {
  const url = qs.stringifyUrl({
    url: 'admin/users',
    query: { ...params },
  });

  return axiosInstance.get(url).then((response) => response.data);
};

export const GetAdminUserDetail = async (
  userId: string
): Promise<GetUserResponseDto> => {
  const url = `admin/users/${userId}`;

  return axiosInstance.get(url).then((response) => response.data);
};

export const AdminDeactivateUser = async (
  userId: string
): Promise<GetUserResponseDto> => {
  const url = `admin/users/${userId}/deactivate`;

  return axiosInstance.post(url).then((response) => response.data);
};

export const AdminReactivateUser = async (
  userId: string
): Promise<GetUserResponseDto> => {
  const url = `admin/users/${userId}/reactivate`;

  return axiosInstance.post(url).then((response) => response.data);
};

export const AdminUpdateUserDetailsApi = async ({
  data,
  userId,
}: {
  userId: string;
  data: Prisma.UserUpdateInput;
}): Promise<GetUserResponseDto> => {
  const url = `/admin/users/${userId}`;
  return await axiosInstance.put(url, data).then((response) => response.data);
};

export const AdminUpdateUserWorkspaceDetailsApi = async ({
  workspace,
  workspaceId,
}: IUpdateWorkspaceRequestDto): Promise<GetUserResponseDto> => {
  const url = `/admin/workspaces/${workspaceId}`;
  return await axiosInstance
    .put(url, workspace)
    .then((response) => response.data);
};

export const AdminGetWorkspaceApi = async (
  workspaceId: string
): Promise<Workspace> => {
  const url = `/admin/workspaces/${workspaceId}`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetAdminUserWorkspaces = async (
  userId: string
): Promise<GetWorkspaceUserResponseDto[]> => {
  const url = `/admin/users/${userId}/workspaces`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const DeactivateAdminWorkspaceApi = async (
  workspaceId: string
): Promise<GetWorkspaceUsersResponseDto> => {
  return await axiosInstance
    .post(`/admin/workspaces/${workspaceId}/deactivate`)
    .then((response) => response.data);
};

export const ReactivateAdminWorkspaceApi = async (
  workspaceId: string
): Promise<GetWorkspaceUsersResponseDto> => {
  return await axiosInstance
    .post(`/admin/workspaces/${workspaceId}/reactivate`)
    .then((response) => response.data);
};

export const GetAdminWorkspaceMembersApi = async (
  workspaceId: string
): Promise<GetWorkspaceUsersResponseDto> => {
  return await axiosInstance
    .get(`/admin/workspaces/${workspaceId}/members`)
    .then((response) => response.data);
};

//delete workspace team member
export const DeleteAdminWorkspaceMember = async (params: {
  WorkspaceId: string;
  memberId: string;
}): Promise<void> => {
  return await axiosInstance
    .delete(`admin/workspaces/${params.WorkspaceId}/members/${params.memberId}`)
    .then((response) => response.data);
};
export const AdminGetWorkspaceUserApi = async (
  memberId: string,
  workspaceId: string
): Promise<GetWorkspaceUserResponseDto> => {
  const url = `/admin/workspaces/${workspaceId}/members/${memberId}`;
  return await axiosInstance.get(url).then((response) => response.data);
};

export const GetAdminWorkspace = async (
  params: IAdminUsers
): Promise<GetPagedWorkspacesResponseDto> => {
  const url = qs.stringifyUrl({
    url: 'admin/workspaces',
    query: { ...params },
  });

  return axiosInstance.get(url).then((response) => response.data);
};

export const GetAdminAnalyticsDashboard =
  async (): Promise<AdminDashboardResponseDto> => {
    return await axiosInstance
      .get(`analytics/admin/dashboard`)
      .then((response) => response.data);
  };

export const GetAdminServices = async (): Promise<AdminGetServicesDto[]> => {
  return await axiosInstance
    .get('admin/services')
    .then((response) => response.data);
};

export const AdminUpdateServiceApi = async ({
  id,
  data,
}: IUpdateServiceVisibilityDto): Promise<AdminGetServiceDto> => {
  const url = `/admin/services/${id}`;
  return await axiosInstance
    .put(url, { ...data })
    .then((response) => response.data);
};


export const AdminDeleteRequest = async (requestId: string): Promise<void> => {
  return await axiosInstance
    .delete(`admin/requests/${requestId}`)
    .then((response) => response.data);
}