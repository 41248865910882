import { Button, Group, Textarea } from '@mantine/core';
import { useState } from 'react';

interface IProps {
  onConfirm: (comment: string) => void;
  submitting?: boolean;
  disableSubmit?: boolean;
}
export const AssignAgentModal = ({
  onConfirm,
  submitting,
  disableSubmit,
}: IProps) => {
  const [comment, setComment] = useState('');
  return (
    <Group spacing={'md'} direction="column" grow>
      <Textarea
        value={comment}
        placeholder="Leave a comment (optional)"
        onChange={(event) => setComment(event.currentTarget.value)}
      />
      <Group position="right" mt={'md'}>
        <Button
          type="submit"
          onClick={() => onConfirm(comment)}
          disabled={disableSubmit}
          loading={submitting}
        >
          Submit
        </Button>
      </Group>
    </Group>
  );
};
