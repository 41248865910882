import { showNotification, updateNotification } from '@mantine/notifications';
import { useNavigate } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { CreateCertificateVerificationApi } from '../apis/certificate';

export const useCreateCertificateVerification = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(CreateCertificateVerificationApi, {
    onMutate: () => {
      showNotification({
        id: 'certificateverification',
        title: 'Certificate Verification',
        message: 'Processing your request. Please hold...',
        loading: true,
      });
    },
    onSuccess: (data) => {
      updateNotification({
        color: 'green',
        id: 'certificateverification',
        message: 'Request created. Processing next steps...',
      });

      //redirect to payment page
      if (data.paymentUrl) {
        window.location.href = data.paymentUrl;
        return;
      }

      //redirect to request details
      navigate({ to: `/request/details/${data.requestId}` });
      //invalidate queries list on page 1
      queryClient.invalidateQueries(['requests/mine', 1]);
    },
    onError: (error) => {
      showNotification({
        id: 'certificateverification',
        color: 'red',
        message:
          (error as AxiosError<{ message?: string }>).response?.data.message ??
          'Request failed. Please try again later.',
      });
    },
  });
};
