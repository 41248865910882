export const workspaceRoleDescription = {
  REQUESTER:
    'Requesters can submit new service requests but cannot access features for an ADMIN or OWNER',
  VIEWER:
    "Viewers can only view requests. They can't create/edit requests, manage workspace. This role is fit for external invites, or analysts.",
  ADMIN:
    'Admin can manage users with their permissions, view and manage requests. They cannot manage other admins.',
  OWNER:
    'Owner has access to the entire workspace, and is the highest role level. They also have necessar permissions to delete a workspace.',
};
