import { AdminDashboardResponseDto } from '@iverifyng/dtos';
import { Box, Group, Paper, ScrollArea, Text } from '@mantine/core';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  Tooltip,
} from 'recharts';
import { Props } from 'recharts/types/component/DefaultLegendContent';
import { colorVariants, useColors } from './LineChart';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderLegend = (props: Props) => {
  const { payload } = props;

  return (
    <Box style={{ height: 200 }}>
      <Box
        sx={{
          position: 'absolute',
          left: 270,
        }}
      >
        <ScrollArea style={{ height: 150, width: 170 }}>
          {payload &&
            payload.map((entry, index) => {
              const val = entry.value;
              return (
                <Group sx={{ width: 170 }} key={`${entry.value}-${index}`}>
                  <Box
                    component="span"
                    sx={{ width: 10, height: 10, backgroundColor: entry.color }}
                  />
                  <Text
                    size="xs"
                    key={`item-${index}`}
                    weight={500}
                    lineClamp={1}
                    sx={{ maxWidth: 120 }}
                  >
                    {val.charAt(0).toUpperCase() +
                      val
                        .slice(1)
                        .toLowerCase()
                        .toLowerCase()
                        .split('_')
                        .join(' ')}
                  </Text>
                </Group>
              );
            })}
        </ScrollArea>
      </Box>
    </Box>
  );
};

interface IPChart {
  data: AdminDashboardResponseDto;
  dataKey: string;
  nameKey: string;
  isDoughnut?: boolean;
}
export function AdminPieChart({ data, dataKey, nameKey, isDoughnut }: IPChart) {
  const colors = useColors();

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart width={200} height={300}>
        <Legend content={renderLegend} />
        <Tooltip />
        <Pie
          data={data}
          cx={120}
          cy={150}
          labelLine={false}
          label={!isDoughnut && renderCustomizedLabel}
          outerRadius={110}
          fill="#8884d8"
          dataKey={dataKey}
          nameKey={nameKey}
          paddingAngle={isDoughnut ? 1 : 0}
          innerRadius={isDoughnut ? 86 : 0}
        >
          {data.map((entry, index) => {
            console.log(entry);

            return (
              <Cell
                key={`cell-${index}`}
                fill={
                  colors.reverse()[index % colors.length][
                    colorVariants[
                      Math.floor(index / colors.length) % colorVariants.length
                    ]
                  ]
                }
              />
            );
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
