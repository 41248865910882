import { WorkspacePermission } from '@prisma/client';
import * as Joi from 'joi';
export const WorkspaceValidatorSchema = {
  UPDATE_WORKSPACE: Joi.object({
    name: Joi.string().optional().label('Workspace Name'),
    domain: Joi.string().domain().allow(null, '').label('Public Domain'),
    type: Joi.optional().valid('CORPORATE', 'PERSONAL').label('Account Type'),
    allowOfflinePayment: Joi.boolean()
      .optional()
      .label('Allow offline payment'),
  }),
  CREATE_WORKSPACE: Joi.object({
    name: Joi.string().min(6).required().label('Workspace Name'),
    type: Joi.required().valid('CORPORATE', 'PERSONAL').label('Account Type'),
  }),
  INVITE_MEMBER: Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    role: Joi.string()
      .required()
      .valid('VIEWER', 'REQUESTER', 'OWNER', 'ADMIN')
      .label('Role'),
  }),
  UPDATE_MEMBER_ROLE: Joi.object({
    role: Joi.string()
      .valid(...Object.values(WorkspacePermission))
      .required()
      .label('Role'),
    userId: Joi.string().required().label('User ID'),
  }),
};
