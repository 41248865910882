import {
  Group,
  Button,
  Stack,
  Text,
  InputWrapper,
  TextInput,
  Select,
  AccordionItem,
  ActionIcon,
} from '@mantine/core';
import { RequestHeader } from '../../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../../components/layouts/request/requestLayout';
import { PropertyIcon } from '../../../../../../assets/icons';
import { MIME_TYPES } from '@iverifyng/utils';
import { FileUploader } from '../../../../../../components/controls/fileuploader/fileuploader';
import { Country, State, Type } from '../../../../../../utils/property';
import { UseFormReturnType } from '@mantine/form/lib/use-form';
import { IPropertyFormProps } from './interface';
import {
  FormListAccordion,
  FormListAccordionLabel,
} from '../../../../../../components/controls/accordions/formListAccordion/formListAccordion';
import { Trash } from 'tabler-icons-react';
import { AddMoreButton } from '../../../../../../components/primitives/button/download';

interface IFormProps {
  form: UseFormReturnType<IPropertyFormProps>;
  //   initialValues: ITenantFormItemProps;
  renderIf?: boolean;
  onBack?: () => void;
  onSubmit: () => void;
}

const FormItem = ({
  form,
  index,
}: {
  index: number;
  form: UseFormReturnType<IPropertyFormProps>;
}) => {
  return (
    <Group direction="column" spacing="md" grow>
      <Select
        label="Property Type"
        placeholder="Select Property Type"
        data={Type}
        {...form.getListInputProps('data', index, 'type')}
        required
      />
      <InputWrapper
        required
        label="Property Document"
        description="This could be your land title, deed of assignment etc."
      >
        <FileUploader
          file={form.values.data[index]?.documentFile}
          accept={[MIME_TYPES.pdf]}
          onSuccess={(url, file) => {
            form.setListItem('data', index, {
              ...form.values.data[index],
              documentUrl: url,
              documentFile: file,
            });
          }}
          instantUpload
        />
      </InputWrapper>
      <TextInput
        label="Street"
        placeholder="e.g. Oswald street"
        {...form.getListInputProps('data', index, 'street')}
        required
      />
      <TextInput
        label="City"
        placeholder="e.g. Lekki"
        {...form.getListInputProps('data', index, 'city')}
        required
      />
      <Select
        label="State"
        placeholder="e.g. Lagos"
        data={State}
        searchable
        {...form.getListInputProps('data', index, 'state')}
        required
      />
      <Select
        label="Country"
        data={Country}
        placeholder="Country"
        {...form.getListInputProps('data', index, 'country')}
        required
      />
    </Group>
  );
};

export const PropertyForm = ({
  renderIf,
  onBack,
  onSubmit,
  form,
}: IFormProps) => {
  if (!renderIf) return null;
  return (
    <form style={{ height: '100%' }} onSubmit={form.onSubmit(onSubmit)}>
      <RequestLayout
        headerFragment={
          <RequestHeader
            text="Property Verification"
            onClickBack={() => {
              if (onBack) onBack();
            }}
            icon={<PropertyIcon width={42} height={42} />}
            withBackArrow
            withBackground
          />
        }
        footerFragment={
          <Group position="apart" grow>
            <Button
              color="dark"
              variant="outline"
              onClick={() => {
                if (onBack) onBack();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Review</Button>
          </Group>
        }
      >
        <Stack spacing={24} p={24} sx={() => ({ minHeight: '100%' })}>
          <Text color="gray" size="sm">
            Verify residential, commercial or land documents such as titles,
            deeds etc.
          </Text>

          {form.values.data.length > 1 ? (
            <Stack spacing="lg">
              {form.values.data.map((item, index) => (
                <FormListAccordion
                  key={index}
                  iconPosition="right"
                  initialItem={0}
                  multiple
                >
                  <AccordionItem
                    label={
                      <FormListAccordionLabel
                        listCount={index + 1}
                        rightIcon={
                          <ActionIcon
                            onClick={() => {
                              form.removeListItem('data', index);
                            }}
                          >
                            <Trash color="gray" size={16} />
                          </ActionIcon>
                        }
                      />
                    }
                  >
                    <FormItem form={form} index={index} />
                  </AccordionItem>
                </FormListAccordion>
              ))}
            </Stack>
          ) : (
            <FormItem form={form} index={0} />
          )}
          <Group position="left">
            <AddMoreButton
              onClick={() => {
                form.addListItem('data', {
                  country: 'Nigeria',
                  street: '',
                  city: '',
                  state: '',
                  type: '',
                  documentUrl: '',
                });
              }}
            />
          </Group>
        </Stack>
      </RequestLayout>
    </form>
  );
};
