import React, {
  ElementType,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';

type BadgePosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'center-left'
  | 'center'
  | 'center-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

type WidgetPosition =
  | 'top-left'
  | 'top-right'
  | 'center-left'
  | 'center-right'
  | 'bottom-left'
  | 'bottom-right';

type Translation = {
  title: string;
  labels: {
    new: string;
    update: string;
    improvement: string;
  };
  readMore: string;
  footer: string;
};

interface Options {
  account: string;
  callbacks: {
    onWidgetReady: () => void;
    onShowWidget: () => void;
    onShowDetails: () => void;
    onReadMore: () => void;
    onHideWidget: () => void;
  };
  translations: Translation;
  trigger?: any;
}

interface IHeadwayWidgetProps {
  account: string;
  /**
   * id of the Widget. It is required if you have more than one widget instance on the page.
   */
  id?: string;
  badgePosition?: BadgePosition;
  widgetPosition?: WidgetPosition;

  /**
   * callback fired when widget has loaded
   */
  onWidgetReady?: () => void;
  /**
   * callback fired when widget is shown
   */
  onShowWidget?: () => void;
  /**
   * callback fired when an item in the widget is clicked
   */
  onShowDetails?: () => void;
  /**
   * callback fired when user clicks on readMore link
   */
  onReadMore?: () => void;
  /**
   * callback fired when user closes the widget
   */
  onHideWidget?: () => void;
  /**
   * Translating the text inside of the widget
   * */
  translations: Translation;
  /**
   * options defined in classic object format. Read more at https://docs.headwayapp.co/widget
   */
  options?: Options;
  trigger?: boolean;
  styles?: unknown;
  component?: ElementType;
}

const HeadwayWidgetClassName = 'HW_widget_component';
const HeadwayWidgetSelector = '.' + HeadwayWidgetClassName;
const HeadwayWidgetTriggerClassName = 'HW_trigger';
const HeadwayWidgetTriggerSelector = '.' + HeadwayWidgetTriggerClassName;

const HeadwayWidgetTrigger = ({
  widgetId,
  component,
  children,
}: {
  widgetId: string;
  component?: ElementType;
  children: React.ReactNode;
}) => {
  const Component = component || 'div';
  const className = HeadwayWidgetTriggerClassName + `_${widgetId}`;

  return <Component className={className}>{children}</Component>;
};

HeadwayWidgetTrigger.defaultProps = {
  widgetId: 'widget-1',
};

declare global {
  interface Window {
    Headway: any;
  }
}

const HeadwayWidget = ({
  id,
  account,
  children,
  widgetPosition,
  badgePosition,
  component,
  trigger,
  onWidgetReady,
  onShowWidget,
  onShowDetails,
  onReadMore,
  onHideWidget,
  styles,
  options,
  translations,
  ...rest
}: PropsWithChildren<IHeadwayWidgetProps>) => {
  const onWidgetReadyRef = useRef(onWidgetReady);
  const onShowWidgetRef = useRef(onShowWidget);
  const onShowDetailsRef = useRef(onShowDetails);
  const onReadMoreRef = useRef(onReadMore);
  const onHideWidgetRef = useRef(onHideWidget);
  const optionsRef = useRef(options);
  useEffect(() => {
    onWidgetReadyRef.current =
      onWidgetReady || (options?.callbacks && options.callbacks.onWidgetReady);
    onShowWidgetRef.current =
      onShowWidget || (options?.callbacks && options?.callbacks.onShowWidget);
    onShowDetailsRef.current =
      onShowDetails || (options?.callbacks && options?.callbacks.onShowDetails);
    onReadMoreRef.current =
      onReadMore || (options?.callbacks && options?.callbacks.onReadMore);
    onHideWidgetRef.current =
      onHideWidget || (options?.callbacks && options?.callbacks.onHideWidget);
    optionsRef.current = options;
  });

  const handleWidgetReady = useCallback(
    () => onWidgetReadyRef.current && onWidgetReadyRef.current(),
    []
  );
  const handleShowWidget = useCallback(
    () => onShowWidgetRef.current && onShowWidgetRef.current(),
    []
  );
  const handleShowDetails = useCallback(
    () => onShowDetailsRef.current && onShowDetailsRef.current(),
    []
  );
  const handleReadMore = useCallback(
    () => onReadMoreRef.current && onReadMoreRef.current(),
    []
  );
  const handleHideWidget = useCallback(
    () => onHideWidgetRef.current && onHideWidgetRef.current(),
    []
  );

  const initHeadway = useCallback(() => {
    const hwConfig = {
      selector: HeadwayWidgetSelector + `_${id}`,
      account: account ?? options?.account,
      trigger: trigger
        ? HeadwayWidgetSelector + `_${id}`
        : options?.trigger || HeadwayWidgetTriggerSelector + `_${id}`,
      callbacks: {
        onWidgetReady: handleWidgetReady,
        onShowWidget: handleShowWidget,
        onShowDetails: handleShowDetails,
        onReadMore: handleReadMore,
        onHideWidget: handleHideWidget,
      },
      translations,
      widgetPosition,
      badgePosition,
      __component: true,
      styles,
      // ...options,
    };

    const widget = window.Headway.getNewWidget();
    widget.init(hwConfig);

    return () => {
      widget.destroy();
    };
  }, [
    id,
    account,
    options,
    trigger,
    handleWidgetReady,
    handleShowWidget,
    handleShowDetails,
    handleReadMore,
    handleHideWidget,
    translations,
    widgetPosition,
    badgePosition,
    styles,
  ]);

  useEffect(() => {
    let destroy;
    if (window && typeof window !== 'undefined' && window.Headway) {
      destroy = initHeadway();
    } else {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      const p = new Promise((resolve) => {
        script.onload = () => {
          const d = initHeadway();
          resolve(d);
        };
      });

      destroy = () => {
        p.then((d: any) => d());
      };
      script.src = 'https://cdn.headwayapp.co/widget.js'; // "https://cdn.headwaystaging.com/widget.js"; //;
      head.appendChild(script);
    }
    return destroy;
  }, [initHeadway]);

  const Component = component || 'div';

  return (
    <Component
      className={HeadwayWidgetClassName + `_${id}`}
      {...rest}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
    </Component>
  );
};

export default HeadwayWidget;

HeadwayWidget.defaultProps = {
  account: '',
  id: 'widget-1',
  badgePosition: 'bottom-right',
  widgetPosition: 'bottom-right',
  styles: {},
  translations: {},
  options: {},
};

export { HeadwayWidgetTrigger };
