
import { InputWrapper , Group, Anchor, Text} from '@mantine/core';
import { FileUploader } from '../../../../components/controls/fileuploader/fileuploader';
import { Download } from 'tabler-icons-react';
import { MIME_TYPES } from '@iverifyng/utils';
import PapaParse from 'papaparse';
import { IAddressCSVDataType } from './interface';
import { IAddressFormListItem } from 'apps/client/src/containers/request/routes/ng/address/interface';

interface IMultipleAddressModal {
    closeModal: () => void;
    onFileParsed: (values: Array<IAddressFormListItem>) => void;
  }

export const MutlipleAddressModal = ({closeModal, onFileParsed}:IMultipleAddressModal) => {
  return (
    <InputWrapper
    label={
        <Group position="apart" sx={() => ({ flex: 1 })}>
          <InputWrapper
            label="Upload CSV Document"
            description="Only CSV file allowed. Download sample csv →"
            required
            children={null}
          />
          <Anchor
            href="https://storage.googleapis.com/iverify-public/forms/csv/multiple-address-verification.csv"
            target="_blank"
            download={true}
          >
            <Group spacing={2}>
              <Download width={16} height={16} />
              <Text size="sm">Download format</Text>
            </Group>
          </Anchor>
        </Group>
    }
    styles={{ label: { display: 'flex' } }}
    >
        <FileUploader 
        accept={[MIME_TYPES.csv]}
        instantUpload={false}
        
        onFileSelect={(file) => {
            PapaParse.parse<IAddressCSVDataType>(file, {
                header: true,
                complete: (results, file) => {
                console.log('result', results)
                const mapping: Array<IAddressFormListItem> = results.data.map((value) => {
                    return {
                        fullName: value?.fullName ?? '',
                        phoneNo: value?.phoneNo ?? '',
                        address: value?.address ?? '',
                    }
                })
                onFileParsed(mapping)
                }
                
    
            })
            closeModal()
            
        }}
        
        />
    </InputWrapper>
  )
}
