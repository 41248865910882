import { Box, Stack } from '@mantine/core';
import React from 'react';

interface IAdminRequestLayoutProps {
  footerFragment?: React.ReactNode;
}

export const AdminRequestLayout = ({
  children,
  footerFragment,
}: React.PropsWithChildren<IAdminRequestLayoutProps>) => {
  return (
    <Stack
      sx={() => ({
        height: '100%',
        position: 'relative',
        overflowY: 'hidden',
      })}
      spacing={0}
    >
      <Stack
        px="xl"
        sx={() => ({
          flex: 1,
          overflowY: 'scroll',
          height: '100%',
        })}
      >
        {children}
      </Stack>
      {footerFragment && (
        <Box
          py="sm"
          px="lg"
          sx={(theme) => ({
            position: 'sticky',
            bottom: 0,
            backgroundColor: theme.colors.gray[0],
            borderTop: `1px solid ${theme.colors.gray[3]}`,
          })}
        >
          {footerFragment}
        </Box>
      )}
    </Stack>
  );
};
