import { GetPagedWorkspacesQueryDto } from '@iverifyng/dtos';
import { useQuery } from 'react-query';
import {
  AdminGetWorkspaceApi,
  GetAdminRequests,
  GetAdminUserWorkspaces,
  GetAdminWorkspace,
  GetAdminWorkspaceMembersApi,
  IAdminRequestQueryDto,
} from '../services/apis/admin';

export const useAdminUserWorkspaces = (userId: string) => {
  const { data, isError, isLoading, isFetching, error } = useQuery(
    ['adminUserWorkspaces', userId],
    () => GetAdminUserWorkspaces(userId),
    {
      enabled: !!userId,
    }
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
  };
};

export const useGetAdminWorkspaceMembers = (workspaceId: string) => {
  const queryKey = ['adminWorkspaceMembers', workspaceId];
  const { isError, isLoading, data, error, isFetching } = useQuery(
    queryKey,
    () => GetAdminWorkspaceMembersApi(workspaceId),
    {
      enabled: !!workspaceId,
    }
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data || [],
    error,
    queryKey,
  };
};

export const useGetAdminWorkspaces = (params: GetPagedWorkspacesQueryDto) => {
  const queryKey = ['adminWorkspaces', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => GetAdminWorkspace({ ...params }),
    {
      enabled: !!params.limit,
    }
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};

export const useAdminWorkspaceRequests = ({
  ...query
}: IAdminRequestQueryDto) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['adminRequests', query],
    () => GetAdminRequests({ ...query })
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
  };
};

export const useAdminGetWorkspace = (workspaceId: string) => {
  const { isError, isLoading, data, error, isFetching } = useQuery(
    ['workspace-details', workspaceId],
    () => AdminGetWorkspaceApi(workspaceId)
  );

  return {
    isFetching,
    isLoading,
    isError,
    data: data ?? undefined,
    error,
  };
};
