import { Group, Text, Box, Stack, ActionIcon } from '@mantine/core';
import { Edit } from '../../../assets/icons';

interface IDataInfoProps {
  title: string;
  description: string;
  icon?: boolean;
  onEditClick?: () => void;
}

export const DataInfo = ({
  icon,
  title,
  description,
  onEditClick,
}: IDataInfoProps) => {
  return (
    <Box
      py="sm"
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
      })}
    >
      <Stack spacing={4}>
        <Group position="apart">
          <Text>{title}</Text>
          {!!icon && (
            <ActionIcon onClick={onEditClick}>
              <Edit />
            </ActionIcon>
          )}
        </Group>
        <Box
          component="div"
          sx={(theme) => ({
            color: theme.colors.gray[7],
          })}
        >
          <Text size="sm">{description}</Text>
        </Box>
      </Stack>
    </Box>
  );
};

DataInfo.defaultProps = {
  icon: true,
};
