import { GetUserResponseDto } from '@iverifyng/dtos';
import { Stack, Text, Title, Group, Checkbox, Loader } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateUserProfile } from '../../services/apis/users';
import { useUserProfile } from '../../services/queries/users';

interface IForm {
  notify_new_request: boolean;
  notify_new_activity: boolean;
  notify_weekly_newsletter: boolean;
  notify_news_updates: boolean;
}
const NotificationForm = ({
  notification,
}: {
  notification: GetUserResponseDto;
}) => {
  const form = useForm<IForm>({
    initialValues: {
      notify_new_request: notification.notify_new_request,
      notify_new_activity: notification.notify_new_activity,
      notify_weekly_newsletter: notification.notify_weekly_newsletter,
      notify_news_updates: notification.notify_news_updates,
    },
  });

  const queryClient = useQueryClient();
  const updateUserProfile = useMutation(UpdateUserProfile, {
    onSuccess: () => {
      showNotification({
        color: 'green',
        title: 'Account Notifications',
        message: 'Profile settings updated.',
      });
      queryClient.invalidateQueries('profile');
    },
  });

  const handleChange = useCallback(
    (field: keyof IForm, event: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(field, event.target.checked);
      updateUserProfile.mutate({
        [field]: event.target.checked,
      });
    },
    [form, updateUserProfile]
  );

  return (
    <Group direction="column" spacing="md">
      <Checkbox
        {...form.getInputProps('notify_new_request', {
          type: 'checkbox',
        })}
        disabled={updateUserProfile.isLoading}
        onChange={(event) => handleChange('notify_new_request', event)}
        label={
          <div>
            <Title order={5} style={{ fontWeight: 600 }}>
              Alerts & Notification
            </Title>
            <Text component="span" weight={500} color="gray">
              Get notifications on all transactions perfomed on your account.
            </Text>
          </div>
        }
      />
      <Checkbox
        {...form.getInputProps('notify_new_activity', {
          type: 'checkbox',
        })}
        disabled={updateUserProfile.isLoading}
        onChange={(event) => handleChange('notify_new_activity', event)}
        label={
          <div>
            <Title order={5} style={{ fontWeight: 600 }}>
              Account Activities
            </Title>
            <Text component="span" weight={500} color="gray">
              Get important notifications about activities you've missed.
            </Text>
          </div>
        }
      />
      <Checkbox
        {...form.getInputProps('notify_weekly_newsletter', {
          type: 'checkbox',
        })}
        disabled={updateUserProfile.isLoading}
        onChange={(event) => handleChange('notify_weekly_newsletter', event)}
        label={
          <div>
            <Title order={5} style={{ fontWeight: 600 }}>
              Weekly Newletters
            </Title>
            <Text component="span" weight={500} color="gray">
              Tips on getting more out of iVerify.
            </Text>
          </div>
        }
      />
      <Checkbox
        {...form.getInputProps('notify_news_updates', {
          type: 'checkbox',
        })}
        disabled={updateUserProfile.isLoading}
        onChange={(event) => handleChange('notify_news_updates', event)}
        label={
          <div>
            <Title order={5} style={{ fontWeight: 600 }}>
              News and updates
            </Title>
            <Text component="span" weight={500} color="gray">
              News about product and feature updates.
            </Text>
          </div>
        }
      />
    </Group>
  );
};

export const Notification = () => {
  const { data: user, isLoading } = useUserProfile();
  return (
    <form>
      <Stack spacing={'xl'}>
        <div>
          <Title order={2}>Notification</Title>
          <Text component="span" weight={500} color="gray">
            Choose when and how we contact you.
          </Text>
        </div>
        <Stack spacing="lg">
          <div>
            <Title order={5} style={{ fontWeight: 600 }}>
              Notification from us
            </Title>
            <Text component="span" weight={500} color="gray">
              Receive the latest news, updates and industry tutorials from us.
            </Text>
          </div>
          {isLoading ? (
            <Loader variant="dots" />
          ) : (
            <NotificationForm notification={user!} />
          )}
        </Stack>
      </Stack>
    </form>
  );
};
