import { useAdminRequestDetails } from '../../../../../hooks/admin';
import { useMatch } from '@tanstack/react-location';
import { RequestGenerics } from '../tabOutlet';
import { AdminRequestPaymentDetailsView } from '../../../components/payment/payment';

export const AdminRequestPaymentDetails = () => {
  const {
    params: { requestId },
  } = useMatch<RequestGenerics>();
  const { error, data: request, isLoading } = useAdminRequestDetails(requestId);

  return (
    <AdminRequestPaymentDetailsView
      request={request}
      isLoading={isLoading}
      error={error}
    />
  );
};
