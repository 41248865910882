import * as CryptoJS from 'crypto-js';
const cipherKey =
  process.env['CRYPT_ENCRYPT_KEY'] ?? '01824564690zdi378KLp2383';
export const encryptText = (plaintext: string) => {
  const ciphertext = CryptoJS.AES.encrypt(plaintext, cipherKey).toString();
  return ciphertext;
};

export const decryptText = (ciphertext: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, cipherKey);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
