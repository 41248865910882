import {
  Stack,
  Group,
  Image,
  AccordionItem,
  Textarea,
  InputWrapper,
  Divider,
  Button,
} from '@mantine/core';
import {
  ReportAccordionLabel,
  ReportFromAccordion,
} from '../../../../components/controls/accordions/reportAccordion/requestDetails';
import {
  Controller,
  Control,
  useFieldArray,
  UseFormSetValue,
} from 'react-hook-form';
import { AddMoreButton } from '../../../../components/primitives/button/download';
import { FileUploader } from '../../../../components/controls/fileuploader/fileuploader';
import { CreateTenantAgentReportDto } from '@iverifyng/dtos';
import { IMAGE_MIME_TYPE } from '@iverifyng/utils';

interface IVisualsProps {
  control: Control<CreateTenantAgentReportDto>;
  setValue: UseFormSetValue<CreateTenantAgentReportDto>;
}

interface IVisualformProps extends IVisualsProps {
  index: number;
}

const VisualFormItem = ({ control, setValue, index }: IVisualformProps) => {
  return (
    <Stack>
      <Controller
        name={`visuals.${index}.description`}
        control={control}
        render={({ field }) => (
          <Textarea
            label={'Title'}
            variant="default"
            placeholder="Enter text"
            {...field}
          />
        )}
      />
      <Controller
        name={`visuals.${index}.imageUrl`}
        control={control}
        render={({ field }) => (
          <InputWrapper label="Upload Image" {...field}>
            <FileUploader
              instantUpload
              onSuccess={(url) => {
                setValue(`visuals.${index}.imageUrl`, url);
              }}
              accept={[IMAGE_MIME_TYPE]}
            />

            {field.value && typeof field.value === 'string' ? (
              <div
                style={{
                  marginTop: 24,
                  width: 140,
                  height: 80,
                }}
              >
                <Image radius="md" src={field.value} />
              </div>
            ) : null}
          </InputWrapper>
        )}
      />
    </Stack>
  );
};
export const VisualsForm = ({ control, setValue }: IVisualsProps) => {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `visuals`, // unique name for your Field Array
  });

  return (
    <ReportFromAccordion iconPosition="right" initialItem={0} multiple>
      <AccordionItem label={<ReportAccordionLabel title="Visuals" />}>
        {fields.length > 0 ? (
          <Stack spacing={24} mb={24}>
            {fields.map((item, index) => {
              return (
                <Stack key={item.id}>
                  <VisualFormItem
                    key={item.id}
                    control={control}
                    setValue={setValue}
                    index={index}
                  />
                  <Group position="left" mt={24}>
                    <Button
                      size="xs"
                      variant="outline"
                      color={'red'}
                      onClick={() => remove(index)}
                    >
                      Delete
                    </Button>
                  </Group>
                  <Divider
                    sx={(theme) => ({ borderColor: theme.colors['gray'][1] })}
                  />
                </Stack>
              );
            })}
          </Stack>
        ) : null}

        <Group position="left" mt={24}>
          <AddMoreButton
            text="Add More Visuals"
            onClick={() =>
              append({
                description: '',
                imageUrl: '',
              })
            }
          />
        </Group>
      </AccordionItem>
    </ReportFromAccordion>
  );
};
