// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './app.module.scss';
import { withAuth } from '../../hoc/withAuth';
import { Box, Button, Group, Paper, Stack } from '@mantine/core';
import IntroText from '../../connected/intro/intro';
import EmptyState from '../../components/primitives/emptyState/emptyState';
import { DashboardZeroIcon } from '../../assets/icons';
import { DashboardLayout } from '../../components/layouts/dashboard';
import { Link, useNavigate } from '@tanstack/react-location';
import { DashRequestCard } from '../../components/primitives/cards/dashboard/dashRequestCard/dashRequestCard';
import { DashStatCard } from '../../components/primitives/cards/dashboard/dashStatCard/dashStatCard';
import { StatsSkeleton } from '../../components/primitives/skeletons/stats';
import { useCurrentWorkspace } from '../../hooks/workspace';
import {
  useUncompletedRequests,
  useUserDashboardRequestsAggregate,
} from '../../hooks/request';
import {
  getRequestCardIcon,
  getRequestCardText,
} from '../../components/primitives/cards/request/requestCard/helper';
import { DashRequestSkeleton } from '../../components/primitives/skeletons/dashRequests';
import { WorkspaceRBAC } from '../permission/workspace';

function Dashboard() {
  const navigate = useNavigate();
  const { workspaceId } = useCurrentWorkspace();

  const {
    data: uncompletedRequest,
    isFetching,
    isLoading,
    isError,
  } = useUncompletedRequests(workspaceId!);
  const {
    data: requestsAggregate,
    isFetching: fetchingAggregate,
    isLoading: loadingAggregate,
    isError: isAggregateError,
  } = useUserDashboardRequestsAggregate(workspaceId!);

  

  return (
    <DashboardLayout>
      <Box
        pt={48}
        pb={24}
        px={32}
        sx={() => ({
          height: 'calc(100vh - 77.19px)',
          overflow: 'scroll',
        })}
      >
        <Group position="apart" align="center" pb="xl">
          <IntroText
            heading="Hello"
            text="You can easily verify documents and customer's data with iVerify."
          />

          {fetchingAggregate || loadingAggregate || isAggregateError ? (
            <StatsSkeleton />
          ) : !fetchingAggregate && !!requestsAggregate ? (
            <Group>
              {requestsAggregate?.data.map((stat, idx) => (
                <DashStatCard key={idx} {...{ stat }} />
              ))}
            </Group>
          ) : null}
        </Group>
        <Paper shadow="xs" withBorder={true} radius="md" py="md">
          {isFetching || isLoading || isError ? (
            <DashRequestSkeleton limit={5} />
          ) : !isFetching &&
            uncompletedRequest?.data &&
            uncompletedRequest?.data.length > 0 ? (
            <Stack px="lg">
              {uncompletedRequest?.data.map((request) => (
                <DashRequestCard
                  request={{
                    id: request.id,
                    status: request.status,
                    name: request.service.description!,
                    text: getRequestCardText(request),
                    supportingText: request.description!,
                    price: Number(request.payment.amount),
                    currency: request.payment.currency,
                    date: request.createdAt,
                  }}
                  renderIcon={<>{getRequestCardIcon(request)}</>}
                  key={request.id}
                  onClick={() =>
                    navigate({ to: `/request/details/${request.id}` })
                  }
                />
              ))}
            </Stack>
          ) : (
            <Box py={82}>
              <EmptyState
                title="Start your verification"
                subTitle="Initiate your first documents or customer verification"
                button={
                  <WorkspaceRBAC action="canMakeRequest" renderNoAccess>
                    <Link to="/request/new">
                      <Button type="submit" size="md">
                        Make a new request
                      </Button>
                    </Link>
                  </WorkspaceRBAC>
                }
                icon={<DashboardZeroIcon />}
              />
            </Box>
          )}
        </Paper>
      </Box>
    </DashboardLayout>
  );
}

export default withAuth(Dashboard);
