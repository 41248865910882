import { Badge, BadgeProps, Button, Text } from '@mantine/core';
import { useStatusColor } from '../../../hooks/useStatusColor';
import * as React from 'react';
import { RequestStatusTypes } from '@iverifyng/dtos';
import {
  CriminalRecordStatusEnum,
  PaymentStatusEnum,
  CertificateStatusEnum,
  EmployeeStatusEnum,
} from '@prisma/client';

interface IStatusBadgeProps extends Omit<BadgeProps<'div'>, 'color'> {
  status?: RequestStatusTypes;
}

export const StatusBadge = ({
  status,
  children,
  ...props
}: React.PropsWithChildren<IStatusBadgeProps>) => {
  const color = useStatusColor(status);
  return (
    <Badge {...{ color }} {...props}>
      {children}
    </Badge>
  );
};

interface IDownloadBadgeProps {
  status: PaymentStatusEnum;
  onDownload?: () => void;
  // onFailed?: () => void;
  // onPending?: () => void;
}

export const DownloadStatus = ({ status, onDownload }: IDownloadBadgeProps) => {
  return (
    <>
      {['SUCCESS', 'UNVERIFIED'].includes(status) && (
        <Button variant="subtle" onClick={onDownload}>
          Download
        </Button>
      )}
      {status === 'FAILED' && (
        <Button variant="subtle" color="red">
          Retry
        </Button>
      )}
      {status === 'PENDING' && (
        <Button variant="subtle" color="gray" onClick={() => null}>
          Download
        </Button>
      )}
    </>
  );
};

export const PaymentStatus = ({ status }: IDownloadBadgeProps) => {
  const color = useStatusColor(status);
  return (
    <Badge {...{ color }} radius="sm">
      {status}
    </Badge>
  );
};

interface ICriminalRecordStatusBadgeProps {
  status: CriminalRecordStatusEnum | CertificateStatusEnum | EmployeeStatusEnum;
  statusName: React.ReactNode;
}

export const RequestRecordStatusBadge = ({
  status,
  statusName,
}: ICriminalRecordStatusBadgeProps) => {
  const color = useStatusColor(status);
  return (
    <Badge {...{ color }} radius="sm">
      <Text transform="capitalize" size="xs" align="center">
        {statusName}
      </Text>
    </Badge>
  );
};
