import { RequestBodySkeleton } from '../../../../components/primitives/skeletons/requestDetail';
import { Box, Divider, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { RequestDetailsBlock } from '../../../../connected/requestDetails/block';
import {
  GetRequestResponseDto,
  GetWorkspaceUserResponseDto,
} from '@iverifyng/dtos';

interface IAdminServiceRequesterProps {
  request?: GetRequestResponseDto;
  isLoading?: boolean;
  error?: unknown;
  requesterError?: unknown;
  loadingRequester?: boolean;
  requester?: GetWorkspaceUserResponseDto;
}
export const AdminServiceRequesterDetailsView = ({
  request,
  isLoading,
  error,
  loadingRequester,
  requester,
  requesterError,
}: IAdminServiceRequesterProps) => {
  if (!isLoading && !request) return null;
  if (!loadingRequester && !requester) return null;
  if (error || requesterError) return <div>Error occurred.</div>;
  if (isLoading || loadingRequester)
    return <RequestBodySkeleton skeleton={true} />;
  return (
    <Box
      style={{
        height: '100%',
        overflowY: 'hidden',
      }}
    >
      <Stack px={'xl'} sx={() => ({ overflow: 'scroll', height: '100%' })}>
        <RequestDetailsBlock title="Customer Information">
          <RequestDataLayout
            label="First Name"
            data={requester?.user.firstName}
          />
          <RequestDataLayout
            label="Last Name"
            data={requester?.user.lastName}
          />
          <RequestDataLayout label="Email" data={requester?.user.email} />
          <RequestDataLayout
            label="Date Joined"
            data={dayjs(requester?.user.createdAt).format(
              'Do MMM, YYYY HH:mm A'
            )}
          />
        </RequestDetailsBlock>

        <Divider sx={(theme) => ({ borderColor: theme.colors.gray[1] })} />

        <RequestDetailsBlock title="Workspace Information">
          <RequestDataLayout label="Name" data={requester?.workspace.name} />
          <RequestDataLayout
            label="Domain"
            data={requester?.workspace.domain ?? 'N/A'}
          />
          <RequestDataLayout
            label="Type"
            data={requester?.workspace.type ?? 'N/A'}
          />
        </RequestDetailsBlock>
      </Stack>
    </Box>
  );
};
