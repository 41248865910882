import { TenantVerificationSchema } from '@iverifyng/validators';
import { formList, joiResolver, useForm } from '@mantine/form';
import { useLocation } from '@tanstack/react-location';
import { useCurrentCountry } from '../../../../../../src/hooks/country';
import { useServiceCharge } from '../../../../../../src/hooks/request';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TenantFormConfirmation } from './containers/tenantConfirmation';
import { TenantForm } from './containers/tenantForm';
import { ITenantFormItemProps, ITenantFormProps } from './interface';
import { ServiceCountry } from '@prisma/client';
import { debounce } from 'lodash';
import { useCreateTenantVerification } from '../../../../../../src/services/mutations/tenant';
import { useCurrentWorkspace } from '../../../../../../src/hooks/workspace';
import { showNotification } from '@mantine/notifications';
import { Group, Stack, Text } from '@mantine/core';
import { verificationTypes } from '../employeeVerification/employeeVerification';
import { VerificationType } from '../../../../../components/primitives/cards/employee/employeeType';
import { useModals } from '@mantine/modals';
import { MultipleTenantModal } from '../../../../../connected/modals/requests/tenant/multipleTenantModal';
import { TenantFormModal } from './containers/tenantFormModal';
import { RequestLayout } from '../../../../../components/layouts/request/requestLayout';
import { RequestHeader } from '../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { TenantIcon } from '../../../../../assets/icons';

export type ITenantPage =
  | 'select-tenant-type'
  | 'single-tenant'
  | 'multiple-tenant'
  | 'review';

export const TenantVerification = () => {
  const [page, setPage] = useState<ITenantPage>('select-tenant-type');
  const [active, setActive] = useState('');

  const modals = useModals();

  const { history } = useLocation();
  const { workspaceId } = useCurrentWorkspace();
  const { country } = useCurrentCountry();

  const form = useForm<ITenantFormProps>({
    initialValues: {
      data: formList([
        {
          fullName: '',
          performCreditCheck: true,
          guarantors: formList([
            {
              fullname: '',
              address: '',
              workAddress: '',
              relationship: '',
              phoneNo: '',
              idType: '',
              idNumber: '',
            },
          ]),
          landlords: formList([
            {
              fullname: '',
              address: '',
              phoneNo: '',
            },
          ]),
        },
      ]),
    },
    schema: joiResolver(TenantVerificationSchema.NEW_VERIFICATION_SCHEMA),
  });

  const noOfTenants = useMemo(
    () => form?.values?.data.length,
    [form?.values?.data]
  );

  const { data: serviceCharge, refetch } = useServiceCharge({
    code: 'TENANT_VERIFICATION',
    country: country! as ServiceCountry,
    quantity: noOfTenants ?? 1,
  });

  const onVerify = useCallback(
    (values: ITenantFormItemProps) => {
      const { error } =
        TenantVerificationSchema.NEW_VERIFICATION_SCHEMA.validate({
          data: [values],
        });
      if (error) {
        showNotification({ message: error.message, color: 'red' });
        return;
      }
      form.setListItem('data', 0, values);

      setPage('review');
    },
    [form]
  );

  const createTenant = useCreateTenantVerification();

  const onSubmit = useCallback(() => {
    createTenant.mutate({
      workspaceId: workspaceId!,
      data: form.values.data,
    });
  }, [createTenant, form.values.data, workspaceId]);

  const handleDoubleClick = debounce(onSubmit, 300, { leading: true });

  const handleRemoveTenant = useCallback(
    (index: number) => {
      const data = form.values.data.filter((_, i) => i !== index);
      form.setFieldValue('data', formList(data));
    },
    [form]
  );

  useEffect(() => {
    if (page === 'review' && form.values.data.length) {
      refetch();
    }
  }, [form.values.data.length, page, refetch]);

  return (
    <>
      <TenantForm
        initialValues={form.values.data[0]}
        onBack={() => history.back()}
        renderIf={page === 'single-tenant'}
        onSubmit={onVerify}
      />
      {page === 'select-tenant-type' && (
        <RequestLayout
          headerFragment={
            <RequestHeader
              text="Tenant Verification"
              icon={<TenantIcon width={42} height={42} />}
              withBackground
              onClickBack={() => history.back()}
              withBackArrow
            />
          }
        >
          <Stack spacing="xl" p="24px">
            <Text
              size="sm"
              sx={(theme) => ({ color: theme.colors['gray'][7] })}
            >
              Verify your tenants by filling the details below.
            </Text>
            <Text weight={'bolder'}>
              How many verifications are you looking to conduct?
            </Text>
            <Group position="apart">
              <Group position="apart">
                {verificationTypes.map((type, index) => (
                  <VerificationType
                    key={index}
                    icon={type.icon}
                    actionText={type.text}
                    isActive={active === type.value}
                    onClick={() => {
                      setActive(type.value);
                      if (type.value === 'single') {
                        setPage('single-tenant');
                      }
                      if (type.value === 'multiple') {
                        modals.openModal({
                          id: 'multile-request',
                          title: 'Tenant - Multiple Verification',
                          centered: true,
                          closeOnClickOutside: false,
                          children: (
                            <MultipleTenantModal
                              closeModal={() => {
                                modals.closeModal('multipe-request');
                                setPage('review');
                              }}
                              onFileParsed={async (values) => {
                                if (values.length) {
                                  form.setFieldValue('data', formList(values));
                                }
                              }}
                            />
                          ),
                        });
                      }
                    }}
                  />
                ))}
              </Group>
            </Group>
          </Stack>
        </RequestLayout>
      )}

      <TenantFormConfirmation
        onSubmit={handleDoubleClick}
        formvalues={form.values}
        loading={createTenant.isLoading}
        onSingleEditClick={() => setPage('single-tenant')}
        billed={serviceCharge}
        onBack={() => history.back()}
        onRemove={handleRemoveTenant}
        onEdit={(index) => {
          modals.openModal({
            id: 'editTenantInfo',
            title: 'Edit Tenant Info',
            size: 'xl',
            closeOnClickOutside: false,
            closeOnEscape: false,
            children: (
              <TenantFormModal
                initialValues={form.values.data[index]}
                onSubmit={(values) => {
                  console.log('value got here too', values);
                  form.setListItem('data', index, {
                    ...values,
                    landlords: formList(values.landlords),
                    guarantors: formList(values.guarantors),
                  });
                }}
                onClose={() => modals.closeModal('editTenantInfo')}
              />
            ),
          });
        }}
        renderIf={page === 'review'}
      />
    </>
  );
};
