import { useCurrentCountry } from '../../../hooks/country';
import React from 'react';
import { GHPassport } from '../routes/gh/identityVerification/intlPassport/passport';
import { KEPassport } from '../routes/ke/identityVerification/passport/passport';

export const SharedPassport = () => {
  const { country } = useCurrentCountry();

  if (country === 'KE') return <KEPassport />;

  if (country === 'GH') return <GHPassport />;

  return null;
};
