import {
  Paper,
  Stack,
  Title,
  Text,
  Center,
  Group,
  Button,
  useMantineTheme,
} from '@mantine/core';
import { AuthLayout } from '../../../components/layouts/auth';
import { StarCheckIcon } from '../../../assets/icons';

export default function OtpVerified() {
  const theme = useMantineTheme();
  return (
    <AuthLayout>
      <Paper radius="md" p="xl" withBorder>
        <Center style={{ marginBottom: 20 }}>
          <StarCheckIcon color={theme.colors.blue[5]} />
        </Center>
        <Stack spacing={8} justify="center" align={'center'} mb="xl">
          <Title order={2}>Verified</Title>
          <Text size="md" align="center">
            Hooo! You have successfully verified your email.
          </Text>
        </Stack>
        <Group position="apart" mt="xl">
          <Button type="submit" fullWidth>
            Go to Dashboard
          </Button>
        </Group>
      </Paper>
    </AuthLayout>
  );
}
