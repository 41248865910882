import { Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { KEAlienCardResponseDto } from '../../ke/interface';

export const KEAlienCardReport = (props: KEAlienCardResponseDto) => {
  return (
    <Stack spacing="sm">
      <RequestDataLayout
        label="Date of Birth"
        data={props.Date_of_Birth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Expiry Date"
        data={props.Date_of_Expiry ?? 'N/A'}
      />
      <RequestDataLayout label="Gender" data={props.Gender ?? 'N/A'} />
      <RequestDataLayout label="Surname" data={props.Surname ?? 'N/A'} />
      <RequestDataLayout label="First Name" data={props.First_Name ?? 'N/A'} />
      <RequestDataLayout label="Other Name" data={props.Other_Name ?? 'N/A'} />
      <RequestDataLayout
        label="Citizenship"
        data={props.Citizenship ?? 'N/A'}
      />
      <RequestDataLayout
        label="Serial Number"
        data={props.Serial_Number ?? 'N/A'}
      />
      <RequestDataLayout
        label="Place of Residence"
        data={props.Place_of_Live ?? 'N/A'}
      />

      <RequestDataLayout
        label="Place of Birth"
        data={props.Place_of_Birth ?? 'N/A'}
      />
    </Stack>
  );
};
