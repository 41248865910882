import {
  createStyles,
  Group,
  ScrollArea,
  Stack,
  Table,
  Text,
  Skeleton,
  Paper,
  Badge,
} from '@mantine/core';
import { AdminLayout } from '../../../components/layouts/admin';
import { withAdmin } from '../../../hoc/withAdmin';
import { withAuth } from '../../../hoc/withAuth';
import { useMemo } from 'react';
import { AdminGetServicesDto } from '@iverifyng/dtos';
import { AdminServicesDotMenu } from '../../../connected/menus/admin/services';
import { useGetAdminServices } from '../../../hooks/admin-services';
import { formatPriceByCurrency } from '@iverifyng/utils';
import { AppRBAC } from '../../permission/app';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colors.blue[8],
    zIndex: 50,
  },
}));

function AdminServices() {
  const { classes } = useStyles();
  const { data: services, isLoading } = useGetAdminServices();

  const header = useMemo(
    () => (
      <tr>
        <th style={{ color: '#fff' }}>
          <Group spacing="sm">
            {/*<Checkbox />*/}
            <Text size="sm">Name</Text>
          </Group>
        </th>
        <th style={{ color: '#fff' }}>Code</th>
        <th style={{ color: '#fff' }}>Country</th>
        <th style={{ color: '#fff' }}>Amount</th>
        <th style={{ color: '#fff' }}>Request Count</th>
        <th style={{ color: '#fff' }}>Visible</th>
        <th style={{ color: '#fff' }}>Available</th>
        <th></th>
      </tr>
    ),
    []
  );

  const rows = useMemo(() => {
    const data = isLoading ? Array(4).fill('*') : services;
    return ((data || []) as Array<AdminGetServicesDto>).map((service, i) => (
      <tr key={i}>
        <td>
          <Skeleton visible={isLoading}>
            <Group spacing="sm">
              {/*<Checkbox />*/}
              <Text
                sx={() => ({
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: 600,
                })}
              >
                {service?.name}
              </Text>
            </Group>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Text>{service?.serviceCode}</Text>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Text>{service?.country}</Text>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Text>
              {formatPriceByCurrency(Number(service?.costAmount), {
                currency: service?.costCurrency,
              })}
            </Text>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Badge>{service?._count?.requests}</Badge>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Badge color={service?.visible ? 'green' : 'red'}>
              {service?.visible ? 'True' : 'False'}
            </Badge>
          </Skeleton>
        </td>
        <td>
          <Skeleton visible={isLoading}>
            <Badge color={service?.available ? 'green' : 'red'}>
              {service?.available ? 'True' : 'False'}
            </Badge>
          </Skeleton>
        </td>
        <td
          style={{
            textAlign: 'right',
          }}
        >
          <Skeleton visible={isLoading}>
            <AdminServicesDotMenu
              markVisibility={service?.visible}
              markAvailability={service?.available}
              service={service}
            />
          </Skeleton>
        </td>
      </tr>
    ));
  }, [isLoading, services]);

  return (
    <AppRBAC action={'canViewService'}>
      <AdminLayout>
        <Stack spacing="xl" px={32}>
          <Text
            pt="xl"
            sx={() => ({
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: 400,
            })}
          >
            Services
          </Text>

          <Paper shadow="xs" withBorder={true} radius="md">
            <ScrollArea style={{ height: 'calc(100vh - 180px)' }}>
              <Table
                horizontalSpacing="xl"
                verticalSpacing="sm"
                highlightOnHover
              >
                <thead className={classes.header}>{header}</thead>
                <tbody>{rows}</tbody>
              </Table>
            </ScrollArea>
          </Paper>
        </Stack>
      </AdminLayout>
    </AppRBAC>
  );
}

export default withAuth(withAdmin(AdminServices));
