import {
  Accordion,
  AccordionItem,
  AccordionItemProps,
  AccordionProps,
  Group,
  Text,
} from '@mantine/core';
import React, { PropsWithChildren } from 'react';
import { useRequestAccordionStyle } from './requestAccordion.style';
import { CaretDown } from 'tabler-icons-react';

interface AccordionLabelProps {
  title?: string;
  subtitle?: string;
  rightNode?: React.ReactNode;
}

export type RequestAccordionItemProps = AccordionLabelProps &
  AccordionItemProps;

interface IRequestAccordionProps {
  Item: typeof RequestAccordionItem;
}

export const AccordionLabel = ({
  title,
  subtitle,
  rightNode,
}: AccordionLabelProps) => {
  return (
    <Group position="apart">
      {!!title && (
        <Group spacing="xs">
          <Text size="sm" weight={600} color="white" transform="uppercase">
            {title}
          </Text>
          {!!subtitle && (
            <Text
              size="sm"
              component="span"
              transform="uppercase"
              sx={(theme) => ({
                color: theme.colors.blue[2],
              })}
            >
              ({subtitle})
            </Text>
          )}
        </Group>
      )}

      {!!rightNode && <span>{rightNode}</span>}
    </Group>
  );
};

export const RequestAccordion: React.FC<AccordionProps> &
  IRequestAccordionProps = ({ children, ...props }) => {
  const { classes } = useRequestAccordionStyle();
  return (
    <Accordion classNames={{ ...classes }} {...props}>
      {children}
    </Accordion>
  );
};

export const RequestAccordionItem = ({
  title,
  children,
  ...props
}: PropsWithChildren<AccordionItemProps>) => {
  return (
    <AccordionItem
      {...props}
      icon={<CaretDown size={20} color={'white'} />}
      label={<AccordionLabel title={title} />}
    >
      {children}
    </AccordionItem>
  );
};

RequestAccordion.Item = RequestAccordionItem;
