import { GetAdminPaymentsQueryDto } from '@iverifyng/dtos';
import { useQuery } from 'react-query';
import { AdminGetPaymentsApi } from '../services/apis/payments';

export const useGetAdminPayments = (params: GetAdminPaymentsQueryDto) => {
  const queryKey = ['adminpayments', params];
  const { data, isError, isLoading, isFetching, error } = useQuery(
    queryKey,
    () => AdminGetPaymentsApi({ ...params }),
    {
      enabled: !!params.limit,
    }
  );

  return {
    data: data ?? undefined,
    isError,
    isLoading,
    isFetching,
    error,
    queryKey,
  };
};
