import { Center, Stack } from '@mantine/core';
import { identityRequestList } from '../../../../../fakedata/requestData';
import { useLocation, useNavigate } from '@tanstack/react-location';
import RequestType from '../../../../../components/primitives/cards/request/requestType';
import { RequestHeader } from '../../../../../components/primitives/cards/request/requestHeader/requestHeader';
import { RequestLayout } from '../../../../../components/layouts/request/requestLayout';
import { useCurrentCountry } from '../../../../../hooks/country';
import EmptyState from '../../../../../components/primitives/emptyState/emptyState';
import { DashboardImage } from '../../../../../assets/icons';
import { useQuery } from 'react-query';
import { GetServices } from '../../../../../services/apis/requests';
import { RequestTypeSkeleton } from '../../../../../components/primitives/skeletons/requests';

export const IdentityVerification = () => {
  const navigate = useNavigate();
  const { history } = useLocation();
  const { country } = useCurrentCountry();
  const servicesList = identityRequestList[country!] ?? null;

  const { data, isLoading } = useQuery('services', GetServices);
  return (
    <RequestLayout
      headerFragment={
        <RequestHeader
          text="Identity Verification"
          onClickBack={() => history.back()}
          withBackArrow
        />
      }
    >
      {isLoading || !data?.length ? (
        <RequestTypeSkeleton limit={3} />
      ) : servicesList ? (
        <Stack p="xl">
          {servicesList.map(
            ({ type, description, icon, pathName, abbrev, visiblefn }, idx) => {
              if (visiblefn) {
                const isvisible = visiblefn(data!);
                if (!isvisible) return null;
              }
              return (
                <RequestType
                  key={idx}
                  {...{ type }}
                  {...{ description }}
                  {...{ icon }}
                  abbrev={abbrev}
                  onClick={() => navigate({ to: pathName })}
                />
              );
            }
          )}
        </Stack>
      ) : (
        <Center p={'xl'} style={{ height: '100%' }}>
          <EmptyState
            title="No Service"
            subTitle="There are no identity verification services available. Please check back later."
            icon={<DashboardImage />}
          />
        </Center>
      )}
    </RequestLayout>
  );
};
