import { Avatar, Center, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { KENationalIDResponseDto } from '../../ke/interface';

export const KENationalIDReport = (props: KENationalIDResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.Photo ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64,${props.Photo}`}
            radius="xl"
            size="lg"
            alt=""
          />
        </Center>
      ) : null}
      <RequestDataLayout label="Gender" data={props.Gender ?? 'N/A'} />
      <RequestDataLayout label="Surname" data={props.Surname ?? 'N/A'} />
      <RequestDataLayout label="First Name" data={props.First_Name ?? 'N/A'} />
      <RequestDataLayout label="Other Name" data={props.Other_Name ?? 'N/A'} />
      <RequestDataLayout
        label="Citizenship"
        data={props.Citizenship ?? 'N/A'}
      />
      <RequestDataLayout
        label="Date of Birth"
        data={props.Date_of_Birth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Date of Issue"
        data={props.Date_of_Issue ?? 'N/A'}
      />
      <RequestDataLayout
        label="Place of Residence"
        data={props.Place_of_Live ?? 'N/A'}
      />
      <RequestDataLayout
        label="Serial Number"
        data={props.Serial_Number ?? 'N/A'}
      />
      <RequestDataLayout
        label="Place of Birth"
        data={props.Place_of_Birth ?? 'N/A'}
      />
    </Stack>
  );
};
