import { Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { PhoneNumberResponseDto } from '../../ng/interface';

export const PhoneNumberReport = (props: PhoneNumberResponseDto) => {
  return (
    <Stack spacing="sm">
      <RequestDataLayout label="Gender" data={props.GENDER ?? ''} />
      <RequestDataLayout label="Phone No" data={props.MSISDN ?? 'N/A'} />
      <RequestDataLayout label="First Name" data={props.FIRST_NAME ?? 'N/A'} />
      <RequestDataLayout label="Last Name" data={props.LAST_NAME ?? 'N/A'} />
      <RequestDataLayout
        label="State of Origin"
        data={props.STATE_ORG ?? 'N/A'}
      />
      <RequestDataLayout label="Occupation" data={props.OCCUPATION ?? 'N/A'} />
      <RequestDataLayout
        label="Residential Address"
        data={props.RESID_ADDR ?? 'N/A'}
      />
      <RequestDataLayout
        label="Residential LGA"
        data={props.RESID_LGA ?? 'N/A'}
      />
      <RequestDataLayout
        label="Residential State"
        data={props.RESID_STATE ?? 'N/A'}
      />
      <RequestDataLayout
        label="Date of Birth"
        data={props.DATE_OF_BIRTH ?? 'N/A'}
      />
    </Stack>
  );
};
