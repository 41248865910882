import { Avatar, Center, Stack } from '@mantine/core';
import RequestDataLayout from '../../../../components/layouts/request/requestDataLayout';
import { ZANationalIDResponseDto } from '../../za/interface';

export const ZANationalIDReport = (props: ZANationalIDResponseDto) => {
  return (
    <Stack spacing="sm">
      {props.photo ? (
        <Center pt="xs">
          <Avatar
            src={`data:image/jpeg;base64,${props.photo}`}
            radius="xl"
            size="lg"
            alt=""
          />
        </Center>
      ) : null}
      <RequestDataLayout label="Gender" data={props.gender ?? 'N/A'} />
      <RequestDataLayout label="Surname" data={props.surname ?? 'N/A'} />
      <RequestDataLayout label="First Name" data={props.firstName ?? 'N/A'} />
      <RequestDataLayout label="Middle Name" data={props.middleName ?? 'N/A'} />
      <RequestDataLayout
        label="Date of Birth"
        data={props.dateOfBirth ?? 'N/A'}
      />
      <RequestDataLayout
        label="Citizenship Indicator"
        data={props.citizenshipIndicator ?? 'N/A'}
      />
    </Stack>
  );
};
