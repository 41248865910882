import { Service } from '@prisma/client';
import { ReactElement } from 'react';
import {
  AlienCardIcon,
  CACIcon,
  CertificateIcon,
  CompanyIcon,
  CreditCheckCommercialIcon,
  CreditCheckIndividualIcon,
  CreditIcon,
  CriminalIcon,
  DriverIcon,
  EmployeeIcon,
  GuarantorIcon,
  IdentityIcon,
  NationalIdIcon,
  NewCriminalCheckIcon,
  NINIcon,
  PassportIcon,
  PropertyIcon,
  SSNITIcon,
  TelephoneIcon,
  TenantIcon,
  TINIcon,
  ValidateCriminalCheckIcon,
  VoterIcon,
  PreviousWorkAddressIcon,
} from '../assets/icons';
import { getServiceByProperty } from '../utils/service';

type RequestTypeItem = {
  icon?: ReactElement;
  type: string;
  abbrev?: string;
  description: string;
  pathName: string;
  visiblefn?: (servicesList: Array<Service>) => boolean;
};

export const requestTypeList: Record<string, RequestTypeItem[]> = {
  NG: [
    {
      icon: <IdentityIcon />,
      type: 'Identity Verification',
      description: "NIN, Driver's License, Voter ID, CAC, etc.",
      pathName: 'identity',
    },
    {
      icon: <CompanyIcon />,
      type: 'Company Verification',
      description:
        'Build a portfolio and Increase your pre-contractual knowledge of any company registered in Nigeria.',
      pathName: 'company',
      visiblefn: (services) => {
        const service = getServiceByProperty('serviceCode', 'CAC', services);
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <EmployeeIcon />,
      type: 'OneView',
      description:
        'Verify your employees, suppliers, partners, and others by filling the details below.',
      pathName: 'employee',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'EMPLOYEE_VERIFICATION',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <PreviousWorkAddressIcon />,
      type: 'Previous Work',
      description: 'Verify your employees previous work',
      pathName: 'previous-work',

      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'PREVIOUS_WORK',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <PropertyIcon />,
      type: 'Address',
      description: 'Verify any address',
      pathName: 'address',

      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'ADDRESS_VERIFICATION',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <CreditIcon />,
      type: 'Credit Check',
      description: 'Generate credit reports for businesses and individuals',
      pathName: 'credit-check',
      visiblefn: (services) => {
        const consumerService = getServiceByProperty(
          'serviceCode',
          'CREDIT_CHECK_DETAILED_CONSUMER',
          services
        );
        const commercialService = getServiceByProperty(
          'serviceCode',
          'CREDIT_CHECK_DETAILED_COMMERCIAL',
          services
        );
        const xscoreService = getServiceByProperty(
          'serviceCode',
          'CREDIT_CHECK_XSCORE_DETAILED_CONSUMER',
          services
        );
        if (consumerService && commercialService && xscoreService) {
          if (
            !consumerService.visible &&
            !commercialService.visible &&
            !xscoreService.visible
          )
            return false;
        }
        return !!(consumerService || commercialService || xscoreService);
      },
    },
    {
      icon: <CriminalIcon />,
      type: 'Criminal Record',
      description: 'Create or validate an existing criminal record',
      pathName: 'criminal-record',
      visiblefn: (services) => {
        const criminalRecordService = getServiceByProperty(
          'serviceCode',
          'CRIMINAL_RECORD_CHECK',
          services
        );
        const criminalEmploymentService = getServiceByProperty(
          'serviceCode',
          'CRIMINAL_RECORD_CHECK_EMPLOYMENT',
          services
        );
        const criminalImmigrationService = getServiceByProperty(
          'serviceCode',
          'CRIMINAL_RECORD_CHECK_IMMIGRATION',
          services
        );
        const validateCriminalService = getServiceByProperty(
          'serviceCode',
          'VALIDATE_CRIMINAL_RECORD',
          services
        );
        if (
          criminalRecordService &&
          criminalEmploymentService &&
          criminalImmigrationService &&
          validateCriminalService
        ) {
          if (
            !criminalRecordService.visible &&
            !criminalEmploymentService.visible &&
            !criminalImmigrationService.visible &&
            !validateCriminalService
          )
            return false;
        }
        return !!(
          criminalRecordService ||
          criminalEmploymentService ||
          criminalImmigrationService ||
          validateCriminalService
        );
      },
    },

    {
      icon: <CertificateIcon />,
      type: 'Certificate Verification',
      description: 'Evaluate academic and professional certificates',
      pathName: 'certificate',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'CERTIFICATE_VERIFICATION',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <TenantIcon />,
      type: 'Tenant Verification',
      description:
        'Conduct Identity verification on your current or prospective tenant',
      pathName: 'tenant',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'TENANT_VERIFICATION',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <GuarantorIcon />,
      type: 'Guarantor Verification',
      description:
        'Verify the legitimacy and existence of provided guarantors.',
      pathName: 'guarantor',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'GUARANTOR_VERIFICATION',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },

    {
      icon: <PropertyIcon />,
      type: 'Property Verification',
      description: 'Verify property documents such as deeds, land titles etc.',
      pathName: 'property',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'PROPERTY_VERIFICATION',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
  ],
  GH: [
    {
      icon: <IdentityIcon />,
      type: 'Identity Verification',
      description: "Driver's License, Passport, SSNIT, Voter ID etc.",
      pathName: 'identity',
    },
  ],
  KE: [
    {
      icon: <IdentityIcon />,
      type: 'Identity Verification',
      description: 'Alien Card, National ID, and Passport',
      pathName: 'identity',
    },
  ],
  ZA: [
    {
      icon: <IdentityIcon />,
      type: 'Identity Verification',
      description: 'Verify National ID with or without photo.',
      pathName: 'identity',
    },
  ],
  UG: [
    {
      icon: <IdentityIcon />,
      type: 'Identity Verification',
      description: 'Verify National ID (without photo)',
      pathName: 'identity',
    },
  ],
};

export const identityRequestList: Record<string, RequestTypeItem[]> = {
  NG: [
    {
      icon: <NINIcon width={42} height={42} />,
      type: 'National Identity Number',
      abbrev: 'NIN',
      description: 'Verify Nigerian National Identity Number',
      pathName: 'nin',
      visiblefn: (services) => {
        const service = getServiceByProperty('serviceCode', 'NIN', services);
        if (service) return service.visible;
        return !!service;
      },
    },

    {
      icon: <DriverIcon width={42} height={42} />,
      type: 'Drivers License',
      description: 'Verify Nigeria-issued Drivers License',
      pathName: 'driver-license',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'DRIVERS_LICENSE',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <VoterIcon width={42} height={42} />,
      type: 'Voter ID',
      description: 'Verify Nigeria-issued Voter ID',
      pathName: 'voters-card',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'VOTER_ID',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <TelephoneIcon width={42} height={42} />,
      type: 'Phone Number',
      description: 'Get data related to a phone number',
      pathName: 'phone-number',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'PHONE_NUMBER',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
  ],
  GH: [
    {
      icon: <DriverIcon width={42} height={42} />,
      type: 'Drivers License',
      description: 'Verify Ghana-issued Drivers License',
      pathName: 'driver-license',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'DRIVERS_LICENSE',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <VoterIcon width={42} height={42} />,
      type: 'Voter ID',
      description: 'Verify Ghana-issued Voter ID',
      pathName: 'voters-card',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'NEW_VOTER_ID',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <SSNITIcon width={42} height={42} />,
      type: 'SSNIT',
      abbrev: '',
      description: 'Verify Ghana-issued SSNIT card',
      pathName: 'ssnit',
      visiblefn: (services) => {
        const service = getServiceByProperty('serviceCode', 'SSNIT', services);
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <PassportIcon width={42} height={42} />,
      type: 'International Passport',
      abbrev: '',
      description: "Verify Ghana's International Passport",
      pathName: 'passport',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'PASSPORT',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
  ],
  KE: [
    {
      icon: <AlienCardIcon />,
      type: 'Alien Card ',
      description: 'Verify Kenya-issued Alien Card',
      pathName: 'alien-card',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'ALIEN_CARD',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <NationalIdIcon />,
      type: 'National ID',
      description: 'Verify Kenya-issued National ID',
      pathName: 'national-id',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'NATIONAL_ID',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
    {
      icon: <PassportIcon />,
      type: 'International Passport',
      abbrev: '',
      description: "Verify Kenya's International Passport",
      pathName: 'passport',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'PASSPORT',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
  ],
  ZA: [
    {
      icon: <NationalIdIcon />,
      type: 'National ID',
      description: 'Verify South-Africa issued National ID',
      pathName: 'national-id',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'NATIONAL_ID',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
  ],
  UG: [
    {
      icon: <NationalIdIcon />,
      type: 'National ID (without photo)',
      description: 'Verify Uganda issued National ID',
      pathName: 'national-id',
      visiblefn: (services) => {
        const service = getServiceByProperty(
          'serviceCode',
          'NATIONAL_ID_NO_PHOTO',
          services
        );
        if (service) return service.visible;
        return !!service;
      },
    },
  ],
};

export const companyRequestList: RequestTypeItem[] = [
  {
    icon: <CACIcon />,
    type: 'Corporate Affairs Commission',
    abbrev: 'CAC',
    description: 'Verify any Nigerian registered business',
    pathName: 'cac',
    visiblefn: (services) => {
      const service = getServiceByProperty('serviceCode', 'CAC', services);
      if (service) return service.visible;
      return !!service;
    },
  },
  {
    icon: <TINIcon />,
    type: 'Tax Identification Number',
    abbrev: 'TIN',
    description: 'Verify any business or individual Tax status',
    pathName: 'tin',
    visiblefn: (services) => {
      const service = getServiceByProperty('serviceCode', 'TIN', services);
      if (service) return service.visible;
      return !!service;
    },
  },
];

export const criminalRecordRequestList: RequestTypeItem[] = [
  {
    icon: <NewCriminalCheckIcon />,
    type: 'New Criminal Record Check',

    description: 'Perform a new criminal record check',
    pathName: 'new-criminal-check',
    visiblefn: (services) => {
      const criminalEmploymentService = getServiceByProperty(
        'serviceCode',
        'CRIMINAL_RECORD_CHECK_EMPLOYMENT',
        services
      );
      const criminalImmigrationService = getServiceByProperty(
        'serviceCode',
        'CRIMINAL_RECORD_CHECK_IMMIGRATION',
        services
      );
      if (criminalEmploymentService && criminalImmigrationService) {
        if (
          !criminalEmploymentService.visible &&
          !criminalImmigrationService.visible
        )
          return false;
      }
      return !!(criminalEmploymentService || criminalImmigrationService);
    },
  },
  {
    icon: <ValidateCriminalCheckIcon />,
    type: 'Validate Criminal Record Certificate',
    description:
      'Verify an existing criminal record certificate from registry.',
    pathName: 'validate-criminal-check',
    visiblefn: (services) => {
      const service = getServiceByProperty(
        'serviceCode',
        'VALIDATE_CRIMINAL_RECORD',
        services
      );
      if (service) return service.visible;
      return !!service;
    },
  },
];

export const creditCheckRequestList: RequestTypeItem[] = [
  {
    icon: <CreditCheckCommercialIcon />,
    type: 'Commercial',
    description: 'Reports for commercial or business enterprises',
    pathName: 'commercial',
    visiblefn: (services) => {
      const commercialService = getServiceByProperty(
        'serviceCode',
        'CREDIT_CHECK_DETAILED_COMMERCIAL',
        services
      );

      if (commercialService) return commercialService.visible;

      return !!commercialService;
    },
  },
  {
    icon: <CreditCheckIndividualIcon />,
    type: 'Consumer',
    description: 'Reports for individuals',
    pathName: 'consumer',
    visiblefn: (services) => {
      const consumerService = getServiceByProperty(
        'serviceCode',
        'CREDIT_CHECK_DETAILED_CONSUMER',
        services
      );

      const xscoreService = getServiceByProperty(
        'serviceCode',
        'CREDIT_CHECK_XSCORE_DETAILED_CONSUMER',
        services
      );
      if (consumerService && xscoreService) {
        if (!consumerService.visible && !xscoreService.visible) return false;
      }
      return !!(consumerService || xscoreService);
    },
  },
];
