import { Button, Stack, Text, Title, Box, PasswordInput } from '@mantine/core';
import { joiResolver, useForm } from '@mantine/form';
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  signOut,
  updatePassword,
} from 'firebase/auth';
import { AuthValidatorSchema } from '@iverifyng/validators';
import { useState } from 'react';
import { ChangePasswordFormDto } from '../../dto/auth';
import { firebaseApp, getFirebaseAuth } from '../../services/firebase';
import { showNotification } from '@mantine/notifications';

export const Password = () => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm<ChangePasswordFormDto>({
    schema: joiResolver(AuthValidatorSchema.CHANGE_PASSWORD),
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const auth = getAuth(firebaseApp);

  const changePassword = async () => {
    const user = auth.currentUser;
    const cred = EmailAuthProvider.credential(
      user?.email as string,
      form.values.currentPassword
    );
    if (user) {
      try {
        setIsLoading(true);
        await reauthenticateWithCredential(user, cred);
        // User entered correct credentials
        // Update password
        await updatePassword(user, form.values.newPassword);
        setIsLoading(false);
        showNotification({
          color: 'green',
          title: 'Password updated',
          message: 'You will be redirected to the login page',
        });
        signOut(getFirebaseAuth);
      } catch (error: any) {
        setIsLoading(false);
        showNotification({
          color: 'red',
          title: error?.message || 'Wrong password',
          message: 'Check password and try again',
        });
      }
    }
  };

  return (
    <form onSubmit={form.onSubmit(changePassword)}>
      <Stack spacing={'xl'}>
        <div>
          <Title order={2}>Password</Title>
          <Text component="span" weight={500} color="gray">
            Please enter your current password to change your password.
          </Text>
        </div>

        <Stack spacing="md">
          <PasswordInput
            placeholder="******"
            label="Current Password"
            {...form.getInputProps('currentPassword')}
          />
          <PasswordInput
            placeholder="*******"
            label="New Password"
            {...form.getInputProps('newPassword')}
          />
          <PasswordInput
            placeholder="******"
            label="Confirm New Password"
            {...form.getInputProps('confirmPassword')}
          />
        </Stack>
        <Box>
          <Button fullWidth={false} type="submit" loading={isLoading}>
            Save Changes
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
